import styled from 'styled-components';

const ProfileViewHeader = styled.div`
    .icon {
        position: absolute;
        left: 12px;
        top: 12px;
        cursor: pointer;
    }
    .img {
        width: 100%;
    }

    .brand {
        position: relative;
        bottom: 24px;
        padding-left: 10px;
        .brand-img {
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.04));
            height: 64px;
            width: 64px;
            position: relative;
            bottom: 12px;
            .brand-logo {
                width: 64px;
                height: 64px;
                object-fit: cover;
                border-radius: 48px;
                border: 2px solid #ffffff;
            }
        }

        .brand-details {
            position: relative;
            bottom: 8px;
            padding-left: 6px;
            display: flex;
            flex-direction: column;
            gap: 2px;
            .brand-title {
                font-weight: 700;
                font-size: 24px;
                line-height: 140%;
                color: #1a202c;
            }
            .brand-content {
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                color: #1f2933;
            }
            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: #616e7c;
            }
            .link {
                padding-top: 12px;
                a {
                    font-size: 14px;
                    line-height: 150%;
                    font-weight: 500;
                    color: #3956c6;
                    text-decoration: none;
                }
            }
            .social-platform {
                padding-top: 16px;
                display: flex;
                gap: 6px;
                img {
                    cursor: pointer;
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
`;
const BrandDetailsView = styled.div`
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.008em;
        color: #1f2933;
    }

    .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #616e7c;
        display: inline;
        margin: 0;
    }

    .read-or-hide {
        color: #3956c6;
        cursor: pointer;
        margin-left: 4px;
    }
    .company-logo {
        padding-top: 12px;
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 12px;
        flex-wrap: wrap;
    }
`;

export { ProfileViewHeader, BrandDetailsView };
