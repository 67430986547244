import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    signUpPageActions,
    useSignUpPageSlice,
} from 'app/pages/SignUpPage/slice';
import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';

import { TabList } from 'app/pages/Tabs/TabMenu';
import AppWrapper from 'app/components/AppWrapper';
import { NewsArticles } from './components/Articles';
import { UserDashbord } from './components/Dashboard';
import { CampignsCard } from './components/Campaigns';
import { ReferReward } from './components/ReferRewards ';
import UserProfileStatusCard from '../ProfileTabSection/components/ProfileStatusSection';

import DefaultProfileImg from 'app/images/placeholder-user-icon.svg';
import { HomeSection } from './index.styled';
import { CustomAppWrapper } from '../index.styled';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { ProfileImageSkeleton } from '../TabMenu/CustomLoaderSkeletons/SkeletonLoaders';
import { TabHeader } from '../Header';
import QoruzLogo from 'app/images/qoruzLogo.svg';
interface Props {}

export const HomeTabSection = memo((props: Props) => {
    useSignUpPageSlice();
    const dispatch = useDispatch();
    const history = useHistory();

    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const {
        loading,
        profileSummary,
        profileSummary: { name = '', profile_image = null },
    } = profileSummarySelector;

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(
                signUpPageActions.getInfluencerProfileSummary({
                    token: localStorage.getItem('token'),
                }),
            );
            dispatch(
                signUpPageActions.getInfluencerCampaigns({
                    token: localStorage.getItem('token'),
                }),
            );
            dispatch(
                signUpPageActions.getDashboardAnalytics({
                    token: localStorage.getItem('token'),
                }),
            );
        } else {
            history.push('/signup');
        }
    }, []);

    return (
        <CustomAppWrapper>
            <AppWrapper className="custom-wrapper">
                <TabList profile_id={profileSummary?.profile_id} />
                <TabHeader icon={QoruzLogo} />
                <HomeSection className="">
                    {/* <div className="header-profile">
                        <div className="user-profile">
                            <span>
                                Welcome
                                {profileSummary?.personal_details?.full_name
                                    ? ','
                                    : ''}
                            </span>
                            <h4>
                                {loading ? (
                                    <Skeleton />
                                ) : (
                                    profileSummary?.personal_details?.full_name
                                )}
                            </h4>
                        </div>
                        {loading ? (
                            <ProfileImageSkeleton imgWidth={56} />
                        ) : (
                            <img
                                src={profile_image || DefaultProfileImg}
                                onError={e => {
                                    const imgHtmlEl =
                                        e.target as HTMLImageElement;
                                    imgHtmlEl.src = DefaultProfileImg;
                                }}
                                alt="influencerProfileImage"
                            />
                        )}
                    </div> */}
                    {/* <div className="status-card">
                        <UserProfileStatusCard CustomStyled={true} />
                    </div> */}
                    <UserDashbord />
                    <CampignsCard />
                    <ReferReward />
                    <NewsArticles />
                </HomeSection>
            </AppWrapper>
        </CustomAppWrapper>
    );
});
