import styled from 'styled-components';

const ProfileSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;

    p {
        margin: 0 !important;
    }
`;

const HeaderTittle = styled.div`
    height: 50px;
    display: flex;
    align-items: center;

    img {
        cursor: pointer;
    }

    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 27px;
        padding-left: 8px;
    }
`;

const PersonalInfoCard = styled.div`
    width: 100%;
    border: 1px solid #e4e7eb;
    box-shadow: 0px 0px 12px rgba(9, 30, 66, 0.08);
    border-radius: 8px;
    padding: 0;

    display: flex;
    flex-direction: column;

    .info-card__header {
        padding: 16px 16px 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            margin: 0;
            padding: 0;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
        }

        span {
            font-weight: 600;
            font-size: 12px;
            line-height: 24px;
            color: #3956c6;
            cursor: pointer;

            //Preferences toggle button module Style
            &.toggle-button-cover {
                .switch {
                    position: relative;
                    display: inline-block;
                    width: 22px;
                    height: 12px;
                    input {
                        opacity: 0;
                        width: 0;
                        height: 0;
                    }
                    .slider {
                        position: absolute;
                        cursor: pointer;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: #c1c7d0;
                        transition: 0.4s;
                        &:before {
                            position: absolute;
                            content: '';
                            height: 8px;
                            width: 8px;
                            left: 2px;
                            bottom: 2px;
                            background-color: #ffffff;
                            transition: 0.4s;
                        }

                        &.round {
                            border-radius: 8px;

                            &:before {
                                border-radius: 50%;
                            }
                            &:after {
                                border-radius: 50%;
                            }
                        }
                    }
                }

                input:checked + .slider {
                    background-color: #36b37e;
                }

                input:checked + .slider:before {
                    transform: translateX(10px);
                }
            }
        }
    }

    .info-card__body {
        p.prompt-message {
            background: #fffaeb;
            border-radius: 8px;
            padding: 16px 12px;
            margin: 12px 12px 16px 12px !important;

            font-weight: 500;
            font-size: 12px;
            color: #1f2933;

            span {
                font-weight: 700;
            }
        }

        .header-border {
            border-top: 1px solid #e4e7eb;
            margin: 12px 0 0 0;
        }
    }

    .card-content {
        height: 100%;
        background: #fffaeb;
        border-radius: 8px;
        padding: 16px 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: #1f2933;
        margin: 0px 1rem 1rem;
    }

    .pereference-card-content {
        margin-bottom: 8px;
        padding: 1rem;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #7b8794;
    }

    ul {
        height: auto;
        list-style: none;
        margin: 0 !important;
        padding: 18px 16px !important;

        display: flex;
        flex-direction: column;
        gap: 16px;

        li {
            display: flex;
            img {
                width: 18px;
                height: 18px;
                margin-right: 16px;
            }
            &.select-list-qualities {
                padding: 6px 12px;
                background: #f1f4f8;
                border-radius: 6px;
                font-weight: 500;
                font-size: 13px;
                line-height: 140%;
                letter-spacing: -0.008em;
                color: #323f4b;
            }
        }
        &.select-show-qualities {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
        }
        &.pet-style {
            gap: 0 !important;
        }
    }

    &.family-info-card {
        .kid-details-na {
            color: var(--gray-400);
        }

        .kids-details-list {
            padding: 0 !important;
            gap: 8px;

            .kid-detail__list-item {
                span.kid-age {
                    font-weight: 700;
                    color: var(--gray-900);
                }
            }
        }
    }
`;

export { HeaderTittle, ProfileSection, PersonalInfoCard };
