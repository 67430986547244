/**
 *
 * Button
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    bgColor?: string;
    color?: string;
    children: any;
    icon?: any;
    rightIcon?: any;
    onClick?: any;
    disabled?: boolean;
}

export const Button = memo((props: Props) => {
    const { children, bgColor, color, icon, rightIcon, onClick, ...rest } =
        props;
    const renderIcon = () => {
        if (icon) {
            return <img src={icon} alt={'icon'} />;
        }
        return <></>;
    };
    const renderRightIcon = () => {
        if (rightIcon) {
            return <img src={rightIcon} alt={'icon'} />;
        }
        return <></>;
    };
    return (
        <ButtonStyled
            className="button"
            bgColor={bgColor}
            color={color}
            onClick={onClick}
            {...rest}
        >
            {renderIcon()}
            {children}
            {renderRightIcon()}
        </ButtonStyled>
    );
});

const ButtonStyled = styled.button<{ bgColor?: string; color?: string }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    cursor: pointer;
    background: ${props => props.bgColor || '#F35627'};
    border-radius: 8px;
    color: ${props => props.color || 'white'};
    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    margin-top: 24px;

    > img {
        margin-left: 8px;
        &:first-child {
            margin-right: 8px;
            margin-left: 0;
        }
    }
    &.disabled,
    &:disabled {
        background-color: #9aa5b1;
        pointer-events: auto !important;
        cursor: not-allowed !important;
    }
`;
