import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.otpVerifyPopUp || initialState;

export const selectOtpVerifyPopUp = createSelector(
    [selectSlice],
    state => state,
);

export const selectGenerateOTP = createSelector(
    [selectSlice],
    state => state.generateOTP,
);

export const selectVerifyOTP = createSelector(
    [selectSlice],
    state => state.verifyOTP,
);