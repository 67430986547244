import styled from 'styled-components/macro';
import Tick from './assets/tick.svg';

const Label = styled.label`
  position: relative;
  display: block;
  padding-left: 16px;
  margin-top: 1.5rem;
  span {
    &:after,
    &:before {
      content: '';
      position: absolute;
      border-radius: 50%;
    }
    &:before {
      width: 18px;
      height: 18px;
      border: 1px solid #cbd2d9;
      background-position: center;
      background-repeat: no-repeat;
      left: 0;
      top: 2px;
    }
    &:after {
      transform: scale(0);
      width: 10px;
      height: 10px;
      left: 4px;
      top: 6px;
    }
  }
  input {
    opacity: 0;
    &:checked {
      & + span {
        &:before {
          border-color: #1f2933;
        }
        &:after {
          transform: scale(1);
          background-color: #1f2933;
        }
      }
    }
  }
`;
const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  textarea {
    background-color: #f5f7fa;
    border-radius: 8px;
    border: 0;
    font-size: 12px;
    margin-left: 30px;
    margin-top: 8px;
    resize: none;
    padding: 12px;
    height: 63px;
    &:focus {
      outline: none;
    }
  }
  button {
    width: 100%;
    margin-top: 1.5rem;
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
  }
`;

export { Label, CheckboxWrapper };
