import styled from 'styled-components/macro';

const RadioStyled = styled.div`
    display: inline-flex;
    margin: 8px 4px 0;
    label {
        background-color: #f5f7fa;
        border-radius: 8px;
        color: #3e4c59;
        font-size: 16px;
        padding: 10px 12px;
        cursor: pointer;
    }
    input {
        display: none;
        &:checked {
            & + label {
                color: #ffffff;
                background-color: #1f2933;
            }
        }
    }
`;

const RadioGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
    margin-right: -4px;
    &.custom-style {
        margin-top: 32px;
        gap: 8px;
    }
`;

export { RadioStyled, RadioGroup };
