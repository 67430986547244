import styled from 'styled-components';
import SearchIcon from 'app/images/search-gray-icon.svg';

const HeaderSection = styled.div<{
    height?: number;
}>`
    height: ${props => props.height}px;
    box-shadow: 0px 4px 6px rgba(9, 30, 66, 0.06);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    font-size: 16px;

    .qoruz-logo {
        height: 24px;
        width: 72px;
    }
    img {
        cursor: pointer;
    }
    .profile-info {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }

    .search-container {
        display: contents;
        input {
            position: absolute;
            height: 56px;
            background-color: #ffffff;
            border: none;
            font-weight: 500;
            &:focus {
                outline: none;
            }
            &::placeholder {
                color: #9aa5b1;
            }
        }

        &.location {
            input {
                background-image: url(${SearchIcon});
                background-position: center left 0px;
                background-repeat: no-repeat;
                padding-left: 24px;
                cursor: pointer;
            }
        }
    }
`;

const BrandProfileList = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 0px 0px;
    gap: 4px;
    height: 100%;
    img {
        height: 40px;
        width: 40px;
        border-radius: 48px;
    }

    .profile-row {
        display: flex;
        padding: 16px;
        gap: 12px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        &.thread_selected,
        &:hover {
            background: #fff3eb;
        }
    }
    .no-data-msg {
        margin: 1rem;
        font-size: 16px;
        color: var(--gray-500);
        text-align: center;
    }
`;

const Notify = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    font-weight: 400;
    font-size: 9px;
    line-height: 160%;
    color: #7b8794;
    text-align: center;

    span {
        color: #ffffff !important;
        width: 16px;
        height: 16px;
        background: #2f8132;
        border-radius: 50%;
        font-weight: 600;
        line-height: 1;
        display: grid;
        place-content: center;
    }
    &.custom-notify {
        position: absolute;
        bottom: 52px;
        right: 30px;
        span {
            width: 14px;
            height: 14px;
            font-size: 7px;
            background: #ba2525;
            color: #ffffff !important;
        }
    }
`;

const BrandMangerList = styled.div`
    display: flex;
    gap: 12px;
    flex: 1;
    img {
        border-radius: 50%;
        object-fit: cover;
        width: 40px;
        height: 40px;
        min-width: 40px;
    }

    .profile-details {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        color: #1f2933;

        div {
            display: flex;
            .manager-name {
                width: fit-content;
                white-space: normal;
                @media screen and (max-width: 680px) {
                    display: inline-block;
                    max-width: 140px;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;
                }
            }
            .brand {
                color: #7b8794;
            }
        }

        p.message-peek {
            width: 95%;
            display: -webkit-box;
            overflow: hidden !important;
            -webkit-line-clamp: 1; //clamp the number to lines to show to 1
            -webkit-box-orient: vertical;

            margin: 0;
            padding-top: 2px;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #7b8794;
        }

        span.creator-name {
            margin-left: 3px;
        }
    }
`;

export const DataIsProcessingAlertWrapper = styled.section`
    width: 100%;
    margin: auto 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .data-unavailable-alert {
        margin: 0 2rem;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

export { BrandProfileList, HeaderSection, BrandMangerList, Notify };
