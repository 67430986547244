import styled from 'styled-components/macro';

const GetStartedStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: -16px;
    margin-right: -16px;
    flex: 100%;
    min-height: calc(100vh - 16px);
    padding-top: 16px;
    > button {
        width: calc(100% - 32px);
        height: 48px;
        margin: auto 16px 0;
    }
    .brand {
        margin: 0 16px 16px;
    }
`;

const CarouselInner = styled.div`
    h2,
    span {
        padding-left: 16px;
        padding-right: 16px;
    }
    img {
        max-height: 50vh;
    }
    h2 {
        font-size: 1.375rem;
        font-weight: bold;
        margin: 2.5rem 0 0;
    }
    span {
        margin-top: 10px;
        display: block;
        text-align: center;
    }
`;

const CarouselWrapper = styled.div`
    padding-bottom: calc(48px + 16px);
    .carousel-slider {
        padding-bottom: 30px;
        .control-dots {
            margin: 0;
            bottom: 0;
            .dot {
                width: 8px;
                height: 4px;
                background-color: #e4e7eb;
                border-radius: 10px;
                box-shadow: none;
                margin-left: 4px;
                margin-right: 4px;
                &:hover,
                &.selected {
                    box-shadow: none;
                }
                &.selected {
                    width: 24px;
                    background-color: #cbd2d9;
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }
    span {
        color: #7b8794;
    }
`;

export { CarouselInner, CarouselWrapper, GetStartedStyled };
