import styled from 'styled-components/macro';
import TickIcon from 'app/images/tick.svg';

const Checkbox = styled.div`
    margin-top: 8px;

    label {
        cursor: pointer;
        position: relative;
        padding-left: 20px;
        font-size: 13px;
        color: #52606d;

        &:before {
            content: '';
            width: 14px;
            height: 14px;
            border: 1px solid #616e7c;
            left: 0;
            top: 0;
            position: absolute;
            border-radius: 2px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 8px;
        }
    }

    input {
        display: none;

        &:checked {
            & + label {
                &:before {
                    background-color: #1f2933;
                    border-color: #1f2933;
                    background-image: url(${TickIcon});
                }
            }
        }
    }
`;

export { Checkbox };
