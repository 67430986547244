import React, { memo } from 'react';

import { Paytm } from './Paytm';
import { UPIPayment } from './UPI';
import { BackAccount } from './BankAccount';

import CreditCardIcon from 'app/images/creditcard.svg';
import { Border } from 'app/pages/Tabs/ProfileTabSection/index.styled';
import { PaymentSection } from './index.styled';

interface Props {
    prefilledPaymentOptionsDetails?: any;
}

export const PaymentOptionTypes = memo((props: Props) => {
    const {
        prefilledPaymentOptionsDetails: {
            prefilledUpiId = '',
            prefilledBankDetails,
            prefilledPaytmNumber = '',
        } = {},
    } = props;

    return (
        <PaymentSection>
            <div className="cardheader">
                <img src={CreditCardIcon} alt="creditCard-icon" />
                <span>Payment Options</span>
            </div>
            <Border />
            <div className="payment-types">
                {/* <Paytm prefilledPaytmNumber={prefilledPaytmNumber || ''} /> */}
                <Border />
                <UPIPayment prefilledUpiId={prefilledUpiId || ''} />
                <Border />
                <BackAccount
                    prefilledBankDetails={prefilledBankDetails || {}}
                />
            </div>
        </PaymentSection>
    );
});
