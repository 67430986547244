import React, { memo } from 'react';
import { HeaderTitle } from '../../index.styled';
import { ArticleSection, ArticleCardSection } from './index.styled';
import EyeIcon from 'app/images/view.svg';
import { RowSliderComponent } from 'app/components/ScrollComponent';
import { NewsCategories } from '../label';

interface Props {}

export const NewsArticles = memo((props: Props) => {
    return (
        <ArticleSection>
            <HeaderTitle>
                <span> ✨ Top news articles for you</span>
            </HeaderTitle>
            <RowSliderComponent scrollBarHeight={260}>
                {NewsCategories.length &&
                    NewsCategories.map((article, index) => {
                        return (
                            <ArticleCardSection
                                key={index}
                                onClick={() =>
                                    window.open(article.articles_link)
                                }
                            >
                                <img
                                    className="background-img"
                                    src={article.background}
                                    alt="article-img"
                                />
                                <div className="news-contents">
                                    <span>{article.headerTittle}</span>
                                    <div className="news-view">
                                        <span>{article.date}</span>

                                        {/* <span>
                                                <img
                                                    src={EyeIcon}
                                                    alt="view-icon"
                                                />
                                                {article.viewsCount} views
                                            </span> */}
                                    </div>
                                </div>
                                {/* <div className="categories-type">
                                        <span>
                                            <img
                                                src={article.categoriesIon}
                                                alt="icon"
                                            />
                                            {article.categoriesContent}
                                        </span>
                                    </div> */}
                            </ArticleCardSection>
                        );
                    })}
            </RowSliderComponent>
        </ArticleSection>
    );
});
