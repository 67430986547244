import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';

import { PetInfo } from './components/PetInfo';
import AppWrapper from 'app/components/AppWrapper';
import { FamilyInfo } from './components/FamilyInfo';
import { ContactInfo } from './components/ContactInfo';
import { PersonalInfo } from './components/PersonalInfo';
import { SpecialQualites } from './components/SpecialQualities';

import PreviewBlockIcon from 'app/images/black-left-arrow.svg';
import { HeaderTittle, ProfileSection } from './index.styled';

interface Props {}

export const AboutPage = memo((props: Props) => {
    const history = useHistory();
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);

    const {
        profileSummary: {
            personal_details = {},
            family_info = {},
            pet_info = {},
            special_qualities = {},
            contact_details = {},
        },
    } = profileSummarySelector;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <AppWrapper>
            <HeaderTittle>
                <img
                    src={PreviewBlockIcon}
                    onClick={history.goBack}
                    alt="preview-icon"
                />
                <span>About Me</span>
            </HeaderTittle>
            <ProfileSection>
                <PersonalInfo personalDetails={personal_details} />
                <FamilyInfo familyDetails={family_info} />
                <PetInfo petDetails={pet_info} />
                {/* <SpecialQualites specialQualities={special_qualities} /> */}
                <ContactInfo contactDetails={contact_details} />
            </ProfileSection>
        </AppWrapper>
    );
});
