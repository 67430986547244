import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { paymentPopupSaga } from './saga';
import { PaymentPopupState } from './types';

export const initialState: PaymentPopupState = {
    loading: false,
    error: null,
    paymentDetails: '',
};

const slice = createSlice({
    name: 'paymentPopup',
    initialState,
    reducers: {
        getPaymentDetails(state, action: PayloadAction<any>) {
            state.loading = true;
        },
        getPaymentDetailsSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.paymentDetails = action.payload?.data;
        },
        getPaymentDetailsError(state, action: PayloadAction<any>) {
            state.loading = false;
            state.error = action.payload?.error;
        },
    },
});

export const { actions: paymentPopupActions } = slice;

export const usePaymentPopupSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga: paymentPopupSaga });
    return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = usePaymentPopupSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
