import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';

import { signupStepsKeyList } from 'app/pages/SignUpPage/label';
import { HtmlElementSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';
import { PersonalInfoCard } from '../../../About/index.styled';
interface Props {
    isInterestedInBarterCampaign: boolean;
    isInterestedInEvents: boolean;
}

export const PreferenceCard = memo((props: Props) => {
    const dispatch = useDispatch();
    const { isInterestedInBarterCampaign, isInterestedInEvents } = props;
    const [barterInterest, setBarterInterest] = useState(false);
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const { loading: profileSummaryLoading } = profileSummarySelector;

    useEffect(() => {
        setBarterInterest(isInterestedInBarterCampaign);
    }, [isInterestedInBarterCampaign]);

    const handleUpdateBarterInterest = (isInterested: boolean) => {
        setBarterInterest(isInterested);
        dispatch(
            signUpPageActions.register({
                current_step: signupStepsKeyList['campaignDetails'],
                barter_available: isInterested ? 1 : 0,
                interested_in_events: isInterestedInEvents ? 1 : 0,
                currentStep: 'campaignDetails',
                token: localStorage.getItem('token'),
                action_type: 'edit',
            }),
        );
    };

    return (
        <PersonalInfoCard>
            <div className="info-card__header">
                <h4>Available for barter campaigns?</h4>
                <span className="toggle-button-cover">
                    {profileSummaryLoading ? (
                        <HtmlElementSkeleton width={25} height={12} />
                    ) : (
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={barterInterest}
                                onChange={e =>
                                    handleUpdateBarterInterest(e.target.checked)
                                }
                            />
                            <span className="slider round"></span>
                        </label>
                    )}
                </span>
            </div>
            <div className="info-card__body">
                <div className="header-border"></div>
                <div className="pereference-card-content">
                    Would you be willing to participate in campaigns that offer
                    goods in the form of compensation?
                </div>
            </div>
        </PersonalInfoCard>
    );
});
