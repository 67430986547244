import React from 'react';
import './svg.css';

export default function PackageIcon() {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.75 8.3333L6.25 4.0083"
                stroke="#7B8794"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.5 13.8333V7.16663C17.4997 6.87435 17.4225 6.5873 17.2763 6.33426C17.13 6.08122 16.9198 5.87109 16.6667 5.72496L10.8333 2.39163C10.58 2.24535 10.2926 2.16833 10 2.16833C9.70744 2.16833 9.42003 2.24535 9.16667 2.39163L3.33333 5.72496C3.08022 5.87109 2.86998 6.08122 2.72372 6.33426C2.57745 6.5873 2.5003 6.87435 2.5 7.16663V13.8333C2.5003 14.1256 2.57745 14.4126 2.72372 14.6657C2.86998 14.9187 3.08022 15.1288 3.33333 15.275L9.16667 18.6083C9.42003 18.7546 9.70744 18.8316 10 18.8316C10.2926 18.8316 10.58 18.7546 10.8333 18.6083L16.6667 15.275C16.9198 15.1288 17.13 14.9187 17.2763 14.6657C17.4225 14.4126 17.4997 14.1256 17.5 13.8333Z"
                stroke="#7B8794"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.72461 6.30005L9.99961 10.5084L17.2746 6.30005"
                stroke="#7B8794"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 18.9V10.5"
                stroke="#7B8794"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
