import styled from 'styled-components';

const RaiseTicket = styled.div<{
    fontWeight?: number;
}>`
    padding: 12px;
    background: #fffaeb;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .content {
        font-weight: ${props => props.fontWeight || 500};
        font-size: 14px;
        line-height: 20px;
        color: #3e4c59;
        /* font-weight: 500 !important; */
    }

    .raise-ticket-btn {
        background: var(--gray-900);
        border-radius: 8px;
        color: #ffffff;
        cursor: pointer;
        padding: 8px;
        width: 180px;
        font-weight: 600;

        &:disabled {
            background: var(--gray-400);
        }
    }
`;
export { RaiseTicket };
