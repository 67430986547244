/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from '../styles/global-styles';
import { HomePage } from 'app/pages/HomePage/Loadable';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { SignUpPage } from 'app/pages/SignUpPage/Loadable';
import { OnBoardingPage } from './pages/OnBoardingPage';
import { LandingPage } from 'app/pages/LandingPage/Loadable';
import { useTranslation } from 'react-i18next';
import { HelpTabSection } from './pages/Tabs/HelpdeskTabSection';
import { ProfileSection } from './pages/Tabs/ProfileTabSection';
import { HomeTabSection } from './pages/Tabs/HomeTabSection';
import { CampaignDetails } from './pages/Tabs/CampaignTabSection ';
import { Messenger } from './pages/Tabs/MessengerTabSection';

export function App() {
    const { i18n } = useTranslation();
    return (
        <BrowserRouter>
            <Helmet
                titleTemplate="%s - Qoruz Influencer"
                defaultTitle="Qoruz Influencer"
                htmlAttributes={{ lang: i18n.language }}
            >
                <meta name="description" content="Qoruz Influencer" />
            </Helmet>

            <Switch>
                <Route
                    exact
                    path={process.env.PUBLIC_URL + '/'}
                    component={LandingPage}
                />

                <Route
                    exact
                    path={process.env.PUBLIC_URL + '/get-started'}
                    component={OnBoardingPage}
                />

                <Route
                    exact
                    path={process.env.PUBLIC_URL + '/signup'}
                    component={SignUpPage}
                />
                <Route
                    path={process.env.PUBLIC_URL + '/helpdesk'}
                    component={HelpTabSection}
                />

                <Route
                    exact
                    path={
                        process.env.PUBLIC_URL +
                        '/campaign/details/group/:groupId/profile/:profileId'
                    }
                    component={HomePage}
                />
                <Route
                    exact
                    path={process.env.PUBLIC_URL + 'profile'}
                    component={HomePage}
                />

                <Route
                    path={process.env.PUBLIC_URL + '/profile'}
                    component={ProfileSection}
                />

                <Route
                    exact
                    path={process.env.PUBLIC_URL + '/home'}
                    component={HomeTabSection}
                />

                <Route
                    exact
                    path={process.env.PUBLIC_URL + '/campaign'}
                    component={CampaignDetails}
                />

                <Route
                    path={process.env.PUBLIC_URL + '/messenger'}
                    component={Messenger}
                />

                <Route component={NotFoundPage} />
            </Switch>
            <GlobalStyle />
        </BrowserRouter>
    );
}
