import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.signUpPage || initialState;

export const selectGetSteps = createSelector(
    [selectSlice],
    state => state.stepDetails,
);

export const selectGetCategories = createSelector(
    [selectSlice],
    state => state.categoryDetails,
);

export const selectGetSpecialQualities = createSelector(
    [selectSlice],
    state => state.specialQualities,
);

export const selectGetCities = createSelector(
    [selectSlice],
    state => state.cities,
);

export const selectGetLanguages = createSelector(
    [selectSlice],
    state => state.languageDetails,
);

export const selectInfluencerProfileSummary = createSelector(
    [selectSlice],
    state => state.influencerProfileSummary,
);

export const selectInfluencerCampaigns = createSelector(
    [selectSlice],
    state => state.influencerCampaigns,
);

export const selectDashboardAnalytics = createSelector(
    [selectSlice],
    state => state.dashboardAnalyticsData,
);

export const selectAdditionalInfluencerInfo = createSelector(
    [selectSlice],
    state => state.additionalInfluencerInfo,
);

export const selectInfluencerHelpdeskInfo = createSelector(
    [selectSlice],
    state => state.influencerHelpdeskInfo,
);

export const selectUpdateOrDeleteAddress = createSelector(
    [selectSlice],
    state => state.updateOrDeleteAddress,
);

export const selectUpdateOrDeletePaymentDetail = createSelector(
    [selectSlice],
    state => state.updateOrDeletePaymentDetail,
);

export const selectShowPopupStatus = createSelector(
    [selectSlice],
    state => state.isShowingPopup,
);

export const selectLogoutInfluencerState = createSelector(
    [selectSlice],
    state => state.logoutInfluencer,
);

export const selectSignUpPage = createSelector([selectSlice], state => state);
