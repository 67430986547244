import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { campaignCostSliceSaga } from './saga';
import { CampaignCostSliceState } from './types';

export const initialState: CampaignCostSliceState = {
  loading: false,
  error: false,
  validation_errors: false,
  closeCampaignCostPopUp: '',
};

const slice = createSlice({
  name: 'campaignCostSlice',
  initialState,
  reducers: {
    updateCampaignCost(state, action: PayloadAction<any>) {
      state.loading = true;
      state.closeCampaignCostPopUp = '';
    },
    updateCampaignCostSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.closeCampaignCostPopUp = 'yes';
    },
    updateCampaignCostError(state, action: PayloadAction<any>) {
      state.loading = true;
      state.closeCampaignCostPopUp = '';
      state.error = action.payload?.error;
      state.validation_errors = action.payload?.validation_errors;
    },
  },
});

export const { actions: campaignCostSliceActions } = slice;

export const useCampaignCostSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: campaignCostSliceSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCampaignCostSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
