import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import {
    selectInfluencerProfileSummary,
    selectShowPopupStatus,
    selectSignUpPage,
} from 'app/pages/SignUpPage/slice/selectors';

import {
    ListSkeleton,
    HtmlElementSkeleton,
} from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';
import { PersonalInfoCard } from '../../../About/index.styled';
import { Categoriespopup } from '../CategoriesPopup';
import { topContentCategories } from './data';

interface Props {
    contentCategories: any;
}

export const ContentCategories = memo((props: Props) => {
    const { contentCategories } = props;

    const dispatch = useDispatch();
    const signUpPageStore = useSelector(selectSignUpPage);
    const isShowingPopup = useSelector(selectShowPopupStatus);
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const { loading: profileSummaryLoading } = profileSummarySelector;

    const [showContentCategoryPopup, setShowContentCategoryPopup] =
        useState(false);

    const renderCategoryEmoji = (cat: {
        id: number;
        name: string;
        emoji_name: string;
    }) => {
        let filteredCat = topContentCategories.filter(
            topCat => parseInt(topCat.api_id) === cat.id,
        );

        return filteredCat.length !== 0 ? filteredCat[0]['emoji'] : '';
    };

    const handleShowContentCategoryPopup = (showState: boolean = false) => {
        setShowContentCategoryPopup(showState);
        dispatch(signUpPageActions.setIsShowPopup(showState));
    };

    useEffect(() => {
        if (!signUpPageStore.loading && signUpPageStore.isStepSuccess) {
            handleShowContentCategoryPopup(false);
        }
    }, [signUpPageStore]);

    return (
        <>
            <PersonalInfoCard>
                <div className="info-card__header">
                    <h4>Content Categories</h4>
                    {profileSummaryLoading ? (
                        <HtmlElementSkeleton width={25} height={12} />
                    ) : (
                        <span
                            onClick={() => {
                                handleShowContentCategoryPopup(true);
                                dispatch(
                                    signUpPageActions.resetProfileSummaryUpdateSuccess(),
                                );
                            }}
                        >
                            {contentCategories.length > 0
                                ? 'Edit'
                                : ' Add Categories'}
                        </span>
                    )}
                </div>
                <div className="info-card__body">
                    {profileSummaryLoading ? (
                        <ListSkeleton
                            width={80}
                            height={27}
                            flexDirection="row"
                            listItemCount={5}
                        />
                    ) : contentCategories.length > 0 ? (
                        <>
                            <div className="header-border"></div>
                            <ul className="select-show-qualities">
                                {contentCategories.map((category: any) => {
                                    return (
                                        <li
                                            className="select-list-qualities"
                                            key={category.id}
                                        >
                                            {renderCategoryEmoji(category)}{' '}
                                            {category.name}
                                        </li>
                                    );
                                })}
                            </ul>
                        </>
                    ) : (
                        <p className="prompt-message">
                            Add content categories to get more relevant
                            campaigns.
                        </p>
                    )}
                </div>
            </PersonalInfoCard>

            {showContentCategoryPopup && isShowingPopup && (
                <Categoriespopup
                    onClose={() => handleShowContentCategoryPopup(false)}
                    categoriesList={contentCategories}
                />
            )}
        </>
    );
});
