import { StyledSpinner } from './index.styled';

const Spinner = ({ color }) => {
    return (
        <StyledSpinner className="spinner" color={color}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </StyledSpinner>
    );
};

export default Spinner;
