import React, { memo } from 'react';

import { Button } from 'app/components/Button';
import { RaiseTicket } from './index.styled';
interface Props {
    fontStyle?: number;
}

const Ticket = memo((props: Props) => {
    const { fontStyle } = props;

    const onRaiseTicket = () => {
        window.open(
            `https://api.whatsapp.com/send/?phone=${process.env.REACT_APP_QORUZ_HELPDESK_NUMBER}`,
            '_blank',
        );
    };

    return (
        <RaiseTicket fontWeight={fontStyle} onClick={() => onRaiseTicket()}>
            <span className="content">
                Can’t find a solution to your problem?
            </span>
            <Button className="raise-ticket-btn">
                <span>Contact Support</span>
            </Button>
        </RaiseTicket>
    );
});

export default Ticket;
