import styled from 'styled-components';
import { PersonalInfoCard } from '../About/index.styled';

const SocialPlatformsInfo = styled(PersonalInfoCard)`
    width: 100%;
    border: 1px solid #e4e7eb;
    box-shadow: 0px 0px 12px rgba(9, 30, 66, 0.08);
    border-radius: 8px;
    flex-direction: column;
    display: flex;

    .info-card__header {
        display: flex;
        justify-content: space-between;
        padding: 1rem;

        h4 {
            margin: 0;
            padding: 0;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;

            &.platform-name-header {
                text-transform: capitalize;
            }
        }

        .show-button {
            display: flex;
            gap: 1rem;
            span {
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                color: #3956c6;
                cursor: pointer;
            }
        }

        .header-profile {
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }

    .info-card__body {
        .header-border {
            margin: 0 !important;
        }

        p.prompt-message {
            span.social-platform {
                font-weight: 500;
                text-transform: capitalize !important;
            }
        }

        .basic-social-profile-details {
            width: auto;
            height: 80px;
            margin: 12px 16px;
            padding: 16px;
            border-radius: 8px;

            display: flex;
            align-items: center;
            gap: 16px;

            background: linear-gradient(
                86.95deg,
                rgba(253, 173, 26, 0.1) -1.3%,
                rgba(248, 62, 9, 0.1) 33.11%,
                rgba(244, 0, 2, 0.1) 52.89%,
                rgba(226, 0, 58, 0.1) 67.19%,
                rgba(209, 0, 108, 0.1) 86.34%,
                rgba(191, 0, 163, 0.1) 116.82%
            );

            .user-img {
                width: 48px;
                height: 48px;
                border-radius: 50%;
            }

            .user-brief {
                display: flex;
                flex-direction: column;
                gap: 4px;
                .user-id {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 150%;
                    letter-spacing: -0.008em;
                    color: #1a202c;
                }
            }

            .user-followers {
                display: flex;
                gap: 16px;

                p {
                    font-size: 12px;

                    span {
                        &.count-value {
                            color: var(--gray-600);
                            font-weight: 700;
                        }

                        &.count-label {
                            color: var(--gray-500);
                        }
                    }
                }
            }
            &.custom-color {
                background: #ffeeee;
            }
            &.amzaon-custom-color {
                background: rgba(255, 153, 0, 0.2);
            }
            &.flipkard-custom-color {
                background: rgba(0, 124, 216, 0.2);
            }
        }
    }

    .card-content {
        height: 100%;
        background: #fffaeb;
        border-radius: 8px;
        padding: 16px 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: #1f2933;
        margin: 0px 1rem 1rem;
    }

    .header-border {
        border-top: 1px solid #e4e7eb;
    }

    ul {
        padding: 0 16px 16px 16px;
        height: auto;
        list-style: none;
        margin: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        gap: 16px;

        li {
            display: flex;
            gap: 12px;
            align-items: center;

            svg {
                width: 20px;
                height: 20px;
            }

            svg path {
                stroke: #7b8794;
                stroke-width: '1.5';
            }

            p {
                text-transform: lowercase;
                font-size: 14px;
                color: var(--gray-700) !important;

                span {
                    line-height: 1.5;

                    &.cost-text {
                        font-weight: 800;
                        text-transform: uppercase;
                    }

                    &.type-text {
                        color: var(--gray-500);
                    }
                }
            }

            img {
                width: 18px;
            }
        }
    }
`;
export { SocialPlatformsInfo };
