import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { panCardPopupSaga } from './saga';
import { PanCardPopupState } from './types';

export const initialState: PanCardPopupState = {
    loading: false,
    error: false,
    isPanCardUpdated: '',
    isTokenValid: '',
};

const slice = createSlice({
    name: 'panCardPopup',
    initialState,
    reducers: {
        updatePanCardDetails(state, action: PayloadAction<any>) {
            state.loading = true;
            state.isPanCardUpdated = '';
        },
        updatePanCardDetailsSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.isPanCardUpdated = 'yes';
            state.isTokenValid = 'yes';
        },
        updatePanCardDetailsError(state, action: PayloadAction<any>) {
            state.loading = false;
            if (action.payload && action.payload.error === 'token_invalid') {
                state.isTokenValid = 'no';
            }
        },
    },
});

export const { actions: panCardPopupActions } = slice;

export const usePanCardPopupSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga: panCardPopupSaga });
    return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = usePanCardPopupSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
