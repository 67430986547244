import NewsIcon from 'app/images/news.svg';
import CourseIcon from 'app/images/course.svg';
import ChatIcon from 'app/images/chat.svg';
import ProductImg from 'app/images/burger.png';
import BrandImg from 'app/images/mbrand.png';
import InstagramOutlineIcon from 'app/images/blueinstagram.svg';
import TwitterIcon from 'app/images/twitter.svg';
import FacebookIcon from 'app/images/facebook.svg';
import InstagramIcon from 'app/images/instagram-round.svg';
import YoutubeIcon from 'app/images/youtube-round.svg';

import InstagramVerified from 'app/images/instagram-verified.jpg';
import Youtube from 'app/images/youtube.jpg';
import ContentTool from 'app/images/content-tool.jpg';
import Community from 'app/images/community.jpg';

export const NewsCategories = [
    {
        background: InstagramVerified,
        categoriesIon: CourseIcon,
        categoriesContent: 'COURSES',
        headerTittle: 'How to Get Verified on Instagram?',
        date: 'Nov 3, 2022',
        articles_link:
            'https://www.qoruz.com/blog/how-to-get-verified-on-instagram/',
    },
    {
        background: Youtube,
        categoriesIon: ChatIcon,
        categoriesContent: 'INTERVIEWS',
        headerTittle: 'How to Start a Successful YouTube Channel?',
        date: 'Nov 3, 2022',
        articles_link:
            'https://www.qoruz.com/blog/how-to-start-a-successful-youtube-channel/',
    },
    {
        background: ContentTool,
        categoriesIon: NewsIcon,
        categoriesContent: 'ARTICLES',
        headerTittle:
            'Top Tools to Help Content Creation to Posting Process Simpler',
        date: 'Nov 3, 2022',
        articles_link:
            'https://www.qoruz.com/blog/top-tools-to-help-content-creation-to-posting-process-simpler/',
    },
    {
        background: Community,
        categoriesIon: NewsIcon,
        categoriesContent: 'ARTICLES',
        headerTittle: 'How to Build a Successful Community?',
        date: 'Nov 3, 2022',
        articles_link:
            'https://www.qoruz.com/blog/how-to-build-a-successful-community/',
    },
];

export const CampaignData = [
    {
        img: ProductImg,
        title: 'Veg Aaloo Tikki Promotion',
        brand: BrandImg,
        type: 'for McDonalds',
        socialmediaIcon: InstagramIcon,
        socialmediaType: 'Instagram',
        productsCost: '$400',
    },
    {
        img: ProductImg,
        title: 'Veg Aaloo Tikki Promotion',
        brand: BrandImg,
        type: 'for McDonalds',
        socialmediaIcon: InstagramIcon,
        socialmediaType: 'Instagram',
        productsCost: '$500',
    },
    {
        img: ProductImg,
        title: 'Veg Aaloo Tikki Promotion',
        brand: BrandImg,
        type: 'for McDonalds',
        socialmediaIcon: InstagramIcon,
        socialmediaType: 'Instagram',
        productsCost: '$900',
    },
];

export const platformIcons = {
    instagram: {
        icon: InstagramOutlineIcon,
    },
    twitter: {
        icon: TwitterIcon,
    },
    youtube: {
        icon: YoutubeIcon,
    },
    facebook: {
        icon: FacebookIcon,
    },
};
