import { call, put, takeLatest } from 'redux-saga/effects';
import { signUpPageActions as actions, signUpPageActions } from '.';
import { request } from 'utils/request';

function* getStepsSaga({ payload }) {
    try {
        const queryString = Object.keys(payload)
            .map(key => `${key}=${payload[key]}`)
            .join('&');
        const requestURL = `/api/get.token.steps?${queryString}`;

        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        yield put(actions.getStepsSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getStepsError(response));

            if (
                response.error === 'influencer_token_expired' ||
                response.error === 'influencer_does_not_exists' ||
                response.error === 'token_invalid'
            ) {
                localStorage.removeItem('token');
                window.location.href = '/signup';
            }
        } catch (jsonError) {
            yield put(actions.getStepsError(error));
        }
    }
}

function* registerSaga({ payload }) {
    try {
        const requestURL = `/api/influencer.register`;

        const response = yield call(request, requestURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(payload),
        });
        if (response.token) {
            localStorage.setItem('token', response.token);
        }
        /** Clarity Push User Details */
        if (payload.phone_number) {
            window.clarity('set', 'phone_number', payload.phone_number);
        }
        if (payload.instagram_handle) {
            window.clarity('set', 'instagram_handle', payload.instagram_handle);
        }
        if (payload.name) {
            window.clarity('set', 'name', payload.name);
        }
        if (payload.email) {
            window.clarity('set', 'email', payload.email);
        }
        yield put(actions.registerSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.registerError(response));
            if (
                response.error === 'influencer_token_expired' ||
                response.error === 'influencer_does_not_exists' ||
                response.error === 'token_invalid'
            ) {
                localStorage.removeItem('token');
                window.location.href = '/signup';
                yield put(signUpPageActions.setIsShowPopup(false));
            }
        } catch (jsonError) {
            yield put(actions.registerError(error));
        }
    }
}

function* getCategoriesSaga({ payload }) {
    try {
        const requestURL = `/api/nano.influencer.categories`;

        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        yield put(actions.getCategoriesSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getCategoriesError(response));
        } catch (jsonError) {
            yield put(actions.getCategoriesError(error));
        }
    }
}

function* getSpecialQualitiesSaga({ payload }) {
    try {
        const queryString = Object.keys(payload)
            .map(key => `${key}=${payload[key]}`)
            .join('&');
        const requestURL = `/api/nano.specialQualities?tag=${queryString}`;

        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        yield put(actions.getSpecialQualitiesSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getSpecialQualitiesError(response));
        } catch (jsonError) {
            yield put(actions.getSpecialQualitiesError(error));
        }
    }
}

function* getCitiesSaga({ payload }) {
    try {
        const queryString = Object.keys(payload)
            .map(key => `${key}=${payload[key]}`)
            .join('&');
        const requestURL = `/api/locations.cities?${queryString}`;

        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        yield put(actions.getCitiesSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getCitiesError(response));
        } catch (jsonError) {
            yield put(actions.getCitiesError(error));
        }
    }
}

function* getLanguagesSaga({ payload }) {
    try {
        const requestURL = `/api/get/languages`;

        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        yield put(actions.getLanguagesSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getLanguagesError(response));
        } catch (jsonError) {
            yield put(actions.getLanguagesError(error));
        }
    }
}

function* getInfluencerProfileSummarySaga({ payload }) {
    try {
        const requestURL = `/api/get.influencer.profile.summary?token=${payload.token}`;

        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        yield put(actions.getInfluencerProfileSummarySuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getInfluencerProfileSummaryError(response));

            if (
                response.error === 'influencer_token_expired' ||
                response.error === 'influencer_does_not_exists'
            ) {
                localStorage.removeItem('token');
                window.location.href = '/signup';
            }
        } catch (jsonError) {
            yield put(actions.getInfluencerProfileSummaryError(error));
        }
    }
}

function* getInfluencerCampaignsSaga({ payload }) {
    try {
        const requestURL = `/api/get.influencer.campaigns?token=${payload.token}`;

        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        yield put(actions.getInfluencerCampaignsSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getInfluencerCampaignsError(response));

            if (
                response.error === 'influencer_token_expired' ||
                response.error === 'influencer_does_not_exists'
            ) {
                localStorage.removeItem('token');
                window.location.href = '/signup';
            }
        } catch (jsonError) {
            yield put(actions.getInfluencerCampaignsError(error));
        }
    }
}

function* getDashboardAnalyticsSaga({ payload }) {
    try {
        const requestURL = `/api/get.influencer.dashboard.analytics?token=${payload.token}`;

        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        yield put(actions.getDashboardAnalyticsSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getDashboardAnalyticsError(response));

            if (
                response.error === 'influencer_token_expired' ||
                response.error === 'influencer_does_not_exists'
            ) {
                localStorage.removeItem('token');
                window.location.href = '/signup';
            }
        } catch (jsonError) {
            yield put(actions.getDashboardAnalyticsError(error));
        }
    }
}

function* getAdditionalInfluencerInfoSaga({ payload }) {
    try {
        const requestURL = `/api/get.influencer.additional.info?token=${payload.token}`;

        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        yield put(actions.getAdditionalInfluencerInfoSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getAdditionalInfluencerInfoError(response));

            if (
                response.error === 'influencer_token_expired' ||
                response.error === 'influencer_does_not_exists'
            ) {
                localStorage.removeItem('token');
                window.location.href = '/signup';
                yield put(signUpPageActions.setIsShowPopup(false));
            }
        } catch (jsonError) {
            yield put(actions.getAdditionalInfluencerInfoError(error));
        }
    }
}

function* getInfluencerHelpdeskInfoSaga({ payload }) {
    try {
        const requestURL = `/api/get.influencer.helpdesk?token=${payload.token}`;

        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        yield put(actions.getInfluencerHelpdeskInfoSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getInfluencerHelpdeskInfoError(response));

            if (
                response.error === 'influencer_token_expired' ||
                response.error === 'influencer_does_not_exists'
            ) {
                localStorage.removeItem('token');
                window.location.href = '/signup';
            }
        } catch (jsonError) {
            yield put(actions.getInfluencerHelpdeskInfoError(error));
        }
    }
}

function* updateOrDeleteAddressSaga({ payload }) {
    try {
        const requestURL = `/api/influencer.address.update`;

        const response = yield call(request, requestURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(payload),
        });

        yield put(actions.updateOrDeleteAddressSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.updateOrDeleteAddressError(response));

            if (
                response.error === 'influencer_token_expired' ||
                response.error === 'influencer_does_not_exists'
            ) {
                localStorage.removeItem('token');
                window.location.href = '/signup';
                yield put(signUpPageActions.setIsShowPopup(false));
            }
        } catch (jsonError) {
            yield put(actions.updateOrDeleteAddressError(error));
        }
    }
}

function* updateOrDeletePaymentDetailSaga({ payload }) {
    try {
        const requestURL = `/api/influencer.payments.update`;

        const response = yield call(request, requestURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(payload),
        });

        yield put(actions.updateOrDeletePaymentDetailSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.updateOrDeletePaymentDetailError(response));

            if (
                response.error === 'influencer_token_expired' ||
                response.error === 'influencer_does_not_exists'
            ) {
                localStorage.removeItem('token');
                window.location.href = '/signup';
                yield put(signUpPageActions.setIsShowPopup(false));
            }
        } catch (jsonError) {
            yield put(actions.updateOrDeletePaymentDetailError(error));
        }
    }
}

function* logoutInfluencerSaga({ payload }) {
    try {
        const requestURL = `/api/influencer.logoutInfluencer?token=${payload.token}`;

        const response = yield call(request, requestURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        yield put(actions.logoutInfluencerSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.logoutInfluencerSuccess(response));
        } catch (jsonError) {
            yield put(actions.logoutInfluencerSuccess(error));
        }
    }
}

export function* signUpPageSaga() {
    yield takeLatest(actions.register, registerSaga);
    yield takeLatest(actions.getSteps, getStepsSaga);
    yield takeLatest(actions.getCategories, getCategoriesSaga);
    yield takeLatest(actions.getSpecialQualities, getSpecialQualitiesSaga);
    yield takeLatest(actions.getCities, getCitiesSaga);
    yield takeLatest(actions.getLanguages, getLanguagesSaga);
    yield takeLatest(
        actions.getInfluencerProfileSummary,
        getInfluencerProfileSummarySaga,
    );
    yield takeLatest(
        actions.getInfluencerCampaigns,
        getInfluencerCampaignsSaga,
    );
    yield takeLatest(actions.getDashboardAnalytics, getDashboardAnalyticsSaga);
    yield takeLatest(
        actions.getAdditionalInfluencerInfo,
        getAdditionalInfluencerInfoSaga,
    );
    yield takeLatest(
        actions.getInfluencerHelpdeskInfo,
        getInfluencerHelpdeskInfoSaga,
    );
    yield takeLatest(actions.updateOrDeleteAddress, updateOrDeleteAddressSaga);
    yield takeLatest(
        actions.updateOrDeletePaymentDetail,
        updateOrDeletePaymentDetailSaga,
    );
    yield takeLatest(actions.logoutInfluencer, logoutInfluencerSaga);
}
