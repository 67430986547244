import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noScroll } from 'utils/helper';

import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import {
    selectShowPopupStatus,
    selectSignUpPage,
} from 'app/pages/SignUpPage/slice/selectors';

import { YouTubePopUp } from './YoutubePopup';
import { InstagramPopUp } from './InstagramPopup';

import YoutubeIcon from 'app/images/SVGComponents/YoutubeIcon';
import InstagramIcon from 'app/images/SVGComponents/InstagramIcon';
import { SocialPlatformsInfo } from '../index.styled';

interface Props {
    platform?: string;
    isNoProfileLinked?: boolean;
}

export const LinkSocialProfilePromptCard = memo((props: Props) => {
    const { platform, isNoProfileLinked } = props;
    const dispatch = useDispatch();
    const signUpPageStore = useSelector(selectSignUpPage);
    const isShowingPopup = useSelector(selectShowPopupStatus);

    const [showAddSocialProfilePopup, setShowSocialProfilePopup] =
        useState(false);

    useEffect(() => {
        noScroll(showAddSocialProfilePopup);
    }, [showAddSocialProfilePopup]);

    const handleShowBlankPopup = (showState: boolean = false) => {
        setShowSocialProfilePopup(showState);
        dispatch(signUpPageActions.setIsShowPopup(showState));
    };

    useEffect(() => {
        if (!signUpPageStore.loading && signUpPageStore.isStepSuccess) {
            handleShowBlankPopup(false);
        }
    }, [signUpPageStore]);

    return (
        <>
            <SocialPlatformsInfo>
                <div className="info-card__header">
                    <span className="header-profile">
                        {platform === 'instagram' && <InstagramIcon />}
                        {platform === 'YouTube' && <YoutubeIcon />}
                        <h4 className="platform-name-header">{platform}</h4>
                    </span>
                    <span
                        onClick={() => {
                            handleShowBlankPopup(true);
                            dispatch(
                                signUpPageActions.resetProfileSummaryUpdateSuccess(),
                            );
                        }}
                    >
                        + Add {!isNoProfileLinked && 'another'} profile
                    </span>
                </div>

                <div className="info-card__body">
                    <p className="prompt-message">
                        Link another{' '}
                        <span className="social-platform">{platform}</span>{' '}
                        profile, add commercials & get relevant campaigns.
                    </p>
                </div>
            </SocialPlatformsInfo>

            {showAddSocialProfilePopup && isShowingPopup && (
                <>
                    {platform === 'instagram' && (
                        <InstagramPopUp
                            onClose={() => handleShowBlankPopup(false)}
                        />
                    )}
                    {platform === 'YouTube' && (
                        <YouTubePopUp
                            onClose={() => handleShowBlankPopup(false)}
                        />
                    )}
                </>
            )}
        </>
    );
});
