import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.messenger || initialState;

export const selectMessenger = createSelector([selectSlice], state => state);

export const selectMessengerThreadList = createSelector(
    [selectSlice],
    state => state.messengerthreadInfo,
);

export const selectThreadMessages = createSelector(
    [selectSlice],
    state => state.threadMessagesInfo,
);

export const selectReadMessages = createSelector(
    [selectSlice],
    state => state.messagesReadInfo,
);

export const selectCreateMessage = createSelector(
    [selectSlice],
    state => state.createMessageInfo,
);

export const selectinfluencerAddress = createSelector(
    [selectSlice],
    state => state.addressListInfo,
);

export const selectGetDeliverablesList = createSelector(
    [selectSlice],
    state => state.deliverablesList,
);

export const selectGetUnreadThreadCount = createSelector(
    [selectSlice],
    state => state.unreadThreadCount,
);
