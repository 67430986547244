/**
 *
 * Splash
 *
 */
import React, { memo, useEffect } from 'react';
import styled from 'styled-components/macro';
import Logo from 'app/images/logo-white.svg';

interface Props {}

export const Splash = memo((props: Props) => {
    useEffect(() => {
        let appWrapperEl = document.getElementById('app-wrapper');
        appWrapperEl?.classList.add('splash-active');
        setTimeout(() => {
            let splashScreen = document.getElementById('splash-screen');
            splashScreen?.classList.add('slide-up');
        }, 250 + 500);
        return () => {
            appWrapperEl?.classList.remove('splash-active');
        };
    });
    return (
        <SplashStyled className="splash-screen" id="splash-screen">
            <img src={Logo} alt="logo" />
        </SplashStyled>
    );
});

const SplashStyled = styled.div`
    @keyframes slideUp {
        0% {
            top: 50%;
        }
        100% {
            top: -60%;
        }
    }
    @keyframes drop {
        0% {
            transform: translate(-50%, -50%) scale(0);
            opasity: 0;
            border-radius: 50%;
        }
        100% {
            transform: translate(-50%, -50%) scale(1);
            opacity: 1;
            border-radius: 50%;
        }
    }
    background-color: #f35627;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120vh;
    height: 120vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
    border-radius: 0;
    opacity: 1;
    animation: drop 0.5s ease-in-out alternate;
    &.slide-up {
        top: -60%;
        opacity: 1;
        animation: slideUp 0.25s ease-in-out alternate;
    }
`;
