import styled from 'styled-components';

const Text = styled.div<{ fontSize: any }>`
    font-weight: 400;
    font-size: ${props => props.fontSize}px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.00533333em;
    color: #7b8794;
    span {
        text-decoration: underline;
    }
`;
const ShareReferralLink = styled.div`
    * {
        margin: 0;
        padding: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    .share-contents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        span {
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #323f4b;
        }
        .price {
            color: #3956c6;
        }
    }
    .copylink {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 8px 8px 12px;
        gap: 10px;
        width: 90%;
        height: 52px;
        border: 1px dashed #9aa5b1;
        border-radius: 8px;
        line-height: 20px;
        span {
            font-weight: 400;
            font-size: 16px;
            color: #000000;
        }
        .share-btn {
            cursor: pointer;
            padding: 8px 16px;
            gap: 10px;
            height: 36px;
            background: #1f2933;
            border-radius: 8px;
            text-align: center;
            letter-spacing: -0.00533333em;
            font-weight: 600;
            font-size: 14px;
            color: #ffffff;
        }
    }
`;

const ShareReferralSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    .reward-contents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        .share-png {
            padding-top: 24px;
        }
    }
    Button {
        height: 44px;
        display: flex;
        gap: 8px;
    }
`;
export { ShareReferralLink, ShareReferralSection, Text };
