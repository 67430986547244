import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'app/components/Spinner';
import dayjs from 'dayjs';
import parse from 'html-react-parser';

import {
    renderDeliverableIcon,
    renderMessengerTime,
    convertToInternationalFormat,
} from 'utils/helper';

import {
    MessageBoxContainer,
    DefaultMessegeSection,
    CampaignDeliverables,
    MessageTimeCategoryPill,
    EventInformation,
} from './index.styled';
import ReadTickIcon from 'app/images/double-tick-gray.png';
import DoubleTickBlue from 'app/images/SVGComponents/DoubleTickBlue';
import WhatsAppIcon from 'app/images/whatasapp-icon.svg';
import {
    MessengerType,
    ShareButtonContents,
    Button,
    ShowSharedDetails,
    DeliverablesCostView,
} from 'app/pages/Tabs/MessengerTabSection/components/ChatWindow/index.styled';

import { contactIconList } from 'app/pages/Tabs/label';
import { Accept, Decline, FooterStyled } from 'app/components/Footer';
import { AppDeclinePopUp } from 'app/pages/Tabs/MessengerTabSection/components//DeclinePopup';
import { AddContactPopup } from 'app/pages/Tabs/MessengerTabSection/components/AddContactPopup';
import { AddressListPopup } from 'app/pages/Tabs/MessengerTabSection/components/AddressListPopup';
import { CampaignCostPopup } from 'app/pages/Tabs/MessengerTabSection/components/CampaignCostPopup';
import { QoruzRecommendedCostPopup } from 'app/pages/Tabs/MessengerTabSection/components/QoruzRecommendedCostPopup';

import { messengerActions } from 'app/pages/Tabs/MessengerTabSection/slice';
import {
    selectCreateMessage,
    selectThreadMessages,
} from 'app/pages/Tabs/MessengerTabSection/slice/selectors';

interface Props {
    message?: any;
    renderDeliverablesList?: any;
}
const renderMsgTime = (createdAt: any) => {
    const { messageTime } = renderMessengerTime(createdAt);
    return messageTime;
};

const renderMessageTimeCategory = (created_at: any) => {
    const { messageCalendarDay, isMsgDayToday, isMsgDayYesterday } =
        renderMessengerTime(created_at);

    if (isMsgDayToday) {
        return 'today';
    }
    if (isMsgDayYesterday) {
        return 'yesterday';
    }
    if (!isMsgDayToday && !isMsgDayYesterday) {
        return messageCalendarDay;
    }
};

const MessageRead = (
    readAt: any,
    createdBy: any,
    showRead: boolean = false,
) => {
    return (
        <>
            <span className="time">
                {renderMsgTime(createdBy)}
                {showRead ? (
                    readAt ? (
                        <DoubleTickBlue />
                    ) : (
                        <img src={ReadTickIcon} alt="read" />
                    )
                ) : (
                    ''
                )}
            </span>
        </>
    );
};

const declineRequestAction = (payload: Object, dispatch: any) => {
    if (payload) {
        dispatch(messengerActions.createMessage(payload));
    }
};

export const OrganizationIntroTextV1_1 = memo((props: Props) => {
    const { message } = props;

    return (
        <DefaultMessegeSection>
            <MessageTimeCategoryPill>
                {renderMessageTimeCategory(message?.created_at)}
            </MessageTimeCategoryPill>
            <MessageBoxContainer>
                <p>
                    <span className="creator-name">
                        {message?.template_value.text.split(',')[0]}
                    </span>
                    ,
                </p>
                <p className="message">
                    {message?.template_value.text.split(',')[1]}
                </p>
                {message?.created_at && (
                    <span className="time">
                        {renderMsgTime(message?.created_at)}
                    </span>
                )}
            </MessageBoxContainer>
        </DefaultMessegeSection>
    );
});

export const OrganizationIntroTextV1_2 = memo((props: Props) => {
    const { message } = props;

    return (
        <DefaultMessegeSection>
            <MessageBoxContainer>
                <span> {message?.template_value.text}</span>
                {message?.created_at && (
                    <span className="time">
                        {renderMsgTime(message?.created_at)}
                    </span>
                )}
            </MessageBoxContainer>
        </DefaultMessegeSection>
    );
});

export const DeliverableBriefV1 = memo((props: Props) => {
    const { threadInfo } = useSelector(selectThreadMessages);
    const { message, renderDeliverablesList } = props;
    const messageDescription =
        message?.template_value?.campaign_brief ||
        message?.template_value?.message ||
        message?.template_value?.description;

    const renderLabel = {
        get_cost_for_campaign: 'Campaign Brief',
        barter_campaign_enquiry: 'Campaign Brief',
        event_invitation: 'Description',
        another_reason: 'Message',
    };

    return (
        <MessageBoxContainer>
            <CampaignDeliverables>
                <div className="campaign-deliverables">
                    <span>{parse(message?.template_value?.text)}</span>
                    <div className=" campaign-deliverables campaign-details">
                        <span className="title">
                            {renderLabel[threadInfo?.reason_to_connect]}
                        </span>
                        <span>
                            {messageDescription
                                ? parse(messageDescription)
                                : ''}
                        </span>
                    </div>
                    <div className=" campaign-deliverables campaign-details">
                        <span className="title">Brand Name:</span>
                        <span>{threadInfo?.brand?.name}</span>
                    </div>
                    <div className="campaign-deliverables campaign-details ">
                        <span className="title">
                            {threadInfo?.reason_to_connect ===
                            'event_invitation'
                                ? 'Event Details'
                                : threadInfo.reason_to_connect !==
                                  'another_reason'
                                ? 'Deliverables'
                                : ''}
                        </span>
                        <div className="campaign-deliverables deliverables-types">
                            {threadInfo?.reason_to_connect ===
                            'event_invitation' ? (
                                <>
                                    <EventInformation>
                                        <span>Event name</span>
                                        <p>
                                            {
                                                threadInfo?.event_information
                                                    ?.name
                                            }
                                        </p>
                                    </EventInformation>
                                    <EventInformation>
                                        <span>Location</span>
                                        <p>
                                            {
                                                threadInfo?.event_information
                                                    ?.location
                                            }
                                        </p>
                                    </EventInformation>
                                    <EventInformation>
                                        <span>Event date</span>
                                        <p>
                                            {dayjs(
                                                threadInfo?.event_information
                                                    ?.start_date,
                                            ).format('MMM D, YYYY')}
                                        </p>
                                    </EventInformation>
                                </>
                            ) : message?.template_value?.deliverables
                                  ?.length ? (
                                message?.template_value?.deliverables.map(
                                    deliverable => (
                                        <span>
                                            <img
                                                src={renderDeliverableIcon(
                                                    renderDeliverablesList,
                                                    deliverable.deliverable_value,
                                                )}
                                                alt={`${deliverable.deliverable_value}-icon`}
                                                className="deliverable-icon"
                                            />
                                            <span className="count">
                                                {deliverable.count}
                                            </span>{' '}
                                            {''}x {deliverable.deliverable_name}
                                        </span>
                                    ),
                                )
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                {message?.created_at && (
                    <span className="time">
                        {renderMsgTime(message?.created_at)}
                    </span>
                )}
            </CampaignDeliverables>
        </MessageBoxContainer>
    );
});

export const InvitationActionV1 = memo((props: Props) => {
    const { message } = props;
    const dispatch = useDispatch();
    const createMessageInfo = useSelector(selectCreateMessage);
    const [DeclinePopup, setDeclinePopup] = useState(false);

    const InviteDeclinePayload = {
        thread_id_hash: message?.thread_id_hash,
        ...(localStorage.getItem('token') && {
            token: localStorage.getItem('token'),
        }),
        message_action_type: 'update',
        message_id: message && message.id,
        message_action: 'invite_status',
        message_action_status: 'accepted',
        template_name: 'invitation_action_v1',
        template_type: 'Invitation',
        type: 'influencer',
        message: {
            invite_status: 'accepted',
        },
    };

    return (
        <>
            <FooterStyled className="footer addfooter-style">
                <Decline
                    className="decline add-decline"
                    onClick={() => {
                        setDeclinePopup(true);
                    }}
                >
                    Decline Invite
                </Decline>
                <Accept
                    className="accept addaccept-style"
                    onClick={e => {
                        e.preventDefault();
                        declineRequestAction(InviteDeclinePayload, dispatch);
                    }}
                >
                    {!createMessageInfo?.loading ? (
                        'Accept Invite'
                    ) : (
                        <Spinner color="light" />
                    )}
                </Accept>
            </FooterStyled>
            {DeclinePopup && (
                <AppDeclinePopUp
                    onClose={() => setDeclinePopup(false)}
                    message={message}
                />
            )}
        </>
    );
});

export const SystemNotifyCard = memo((props: Props) => {
    const { message } = props;
    return <MessengerType>{message}</MessengerType>;
});

export const InviteDeclineText = memo((props: Props) => {
    const { message } = props;
    return (
        <div className="recived-message">
            <MessageBoxContainer className="bgcolor recived-message">
                <span>{parse(message?.template_value.text)}</span>

                {message?.created_at && (
                    <span className="time">
                        {message?.participant_type === 'admin'
                            ? 'Sent by qoruz support'
                            : ''}{' '}
                        {renderMsgTime(message?.created_at)}
                        {message?.read_at ? (
                            <DoubleTickBlue />
                        ) : (
                            <img src={ReadTickIcon} alt="read" />
                        )}
                    </span>
                )}
            </MessageBoxContainer>
        </div>
    );
});

export const InviteAcceptText = memo((props: Props) => {
    const { message } = props;
    return (
        <div className="recived-message">
            <MessageBoxContainer className="bgcolor recived-message">
                <span>{parse(message?.template_value.text)}</span>

                {message?.created_at && (
                    <span className="time">
                        {message?.participant_type === 'admin'
                            ? 'Sent by qoruz support'
                            : ''}{' '}
                        {renderMsgTime(message?.created_at)}
                        {message?.read_at ? (
                            <DoubleTickBlue />
                        ) : (
                            <img src={ReadTickIcon} alt="read" />
                        )}
                    </span>
                )}
            </MessageBoxContainer>
        </div>
    );
});

export const ContactActionV1 = memo((props: Props) => {
    const createMessageInfo = useSelector(selectCreateMessage);
    const [addContactPopup, setAddContactPopup] = useState(false);
    const { message } = props;
    const dispatch = useDispatch();

    const contactDeclinePayload = {
        thread_id_hash: message?.thread_id_hash,
        message_action_type: 'update',
        message_id: message && message.id,
        ...(localStorage.getItem('token') && {
            token: localStorage.getItem('token'),
        }),
        message_action: 'share_contact',
        message_action_status: 'declined',
        template_name: 'contact_action_v1',
        template_type: 'contact',
        type: 'influencer',
    };

    return (
        <div className="recived-message">
            <MessageBoxContainer className="bgcolor">
                <ShareButtonContents>
                    <span>{message?.template_value?.text}</span>
                    <div className="button">
                        <Button
                            onClick={() => {
                                setAddContactPopup(true);
                            }}
                        >
                            {' '}
                            Share contact
                        </Button>
                        <Button
                            onClick={() =>
                                declineRequestAction(
                                    contactDeclinePayload,
                                    dispatch,
                                )
                            }
                        >
                            {!createMessageInfo?.loading ? (
                                'Decline request'
                            ) : (
                                <Spinner color="red" />
                            )}
                        </Button>
                    </div>
                </ShareButtonContents>
            </MessageBoxContainer>
            {addContactPopup && (
                <AddContactPopup
                    onClose={() => setAddContactPopup(false)}
                    message={message}
                />
            )}
        </div>
    );
});

export const ProfilContactDetailsV1 = memo((props: Props) => {
    const { message } = props;
    const conatctList = [
        ...message?.template_value?.contact_details?.emails,
        ...message?.template_value?.contact_details?.phone,
    ];
    return (
        <div className="recived-message">
            <MessageBoxContainer className="bgcolor">
                <ShareButtonContents className="ShowSharedDetails">
                    <span>{message?.template_value?.text}</span>
                    <div className="button contact-list">
                        {conatctList.length
                            ? conatctList.map(contact => {
                                  return (
                                      <ShowSharedDetails>
                                          <img
                                              src={
                                                  contactIconList[contact.icon]
                                              }
                                              alt="contact-icons"
                                          />
                                          {contact.value}
                                      </ShowSharedDetails>
                                  );
                              })
                            : ''}
                    </div>
                </ShareButtonContents>
                {message?.created_at && (
                    <span className="time">
                        {message?.participant_type === 'admin'
                            ? 'Sent by qoruz support'
                            : ''}{' '}
                        {renderMsgTime(message?.created_at)}
                        {message?.read_at !== null ? (
                            <DoubleTickBlue />
                        ) : (
                            <img src={ReadTickIcon} alt="clock-icon" />
                        )}
                    </span>
                )}
            </MessageBoxContainer>
        </div>
    );
});

export const AddressActionV1 = memo((props: Props) => {
    const [addressListPopup, updateAddressListPopup] = useState(false);
    const { message } = props;
    const createMessageInfo = useSelector(selectCreateMessage);
    const dispatch = useDispatch();

    const addressDeclinePayload = {
        thread_id_hash: message?.thread_id_hash,
        message_action_type: 'update',
        message_id: message && message.id,
        ...(localStorage.getItem('token') && {
            token: localStorage.getItem('token'),
        }),
        type: 'influencer',
        message_action: 'share_address',
        message_action_status: 'declined',
        template_name: 'address_action_v1',
        template_type: 'address',
    };
    return (
        <div className="recived-message">
            <MessageBoxContainer className="bgcolor">
                <ShareButtonContents>
                    <span>{message?.template_value?.text}</span>

                    <div className="button">
                        <Button
                            onClick={() => {
                                updateAddressListPopup(true);
                            }}
                        >
                            {' '}
                            Share Address
                        </Button>
                        <Button
                            onClick={() =>
                                declineRequestAction(
                                    addressDeclinePayload,
                                    dispatch,
                                )
                            }
                        >
                            {!createMessageInfo?.loading ? (
                                'Decline request'
                            ) : (
                                <Spinner color="red" />
                            )}
                        </Button>
                    </div>
                </ShareButtonContents>
            </MessageBoxContainer>
            {addressListPopup && (
                <AddressListPopup
                    onClose={() => updateAddressListPopup(false)}
                    message={message}
                />
            )}
        </div>
    );
});

export const ProfileAddressDetails = memo((props: Props) => {
    const { message } = props;
    return (
        <div className="recived-message">
            <MessageBoxContainer className="bgcolor">
                <ShareButtonContents className="ShowSharedDetails">
                    <span className="recived-message-style">
                        {message?.template_value?.text}
                    </span>
                    <DeliverablesCostView className="address">
                        {message?.template_value?.address?.address_1},
                        {message?.template_value?.address?.address_2},
                        {message?.template_value?.address?.city},
                        {message?.template_value?.address?.state},
                        {message?.template_value?.address?.pincode},
                    </DeliverablesCostView>
                    {message?.created_at && (
                        <span className="time">
                            {message?.participant_type === 'admin'
                                ? 'Sent by qoruz support'
                                : ''}{' '}
                            {renderMsgTime(message?.created_at)}
                            {message.read_at !== null ? (
                                <DoubleTickBlue />
                            ) : (
                                <img src={ReadTickIcon} alt="read" />
                            )}
                        </span>
                    )}
                </ShareButtonContents>
            </MessageBoxContainer>
        </div>
    );
});

export const CompletionCard = memo((props: Props) => {
    const { message } = props;
    return (
        <MessageBoxContainer>
            <p>
                <span className="creator-name">
                    {parse(message?.template_value.text)}
                </span>
            </p>
            <span className="time">{renderMsgTime(message?.created_at)}</span>
        </MessageBoxContainer>
    );
});

export const CostActionV1 = memo((props: Props) => {
    const createMessageInfo = useSelector(selectCreateMessage);
    const { threadInfo } = useSelector(selectThreadMessages);
    const [addCampaignCostPopup, setAddCampaignCostPopup] = useState(false);
    const [costPopup, addCostPopup] = useState(false);
    const { message, renderDeliverablesList } = props;
    const dispatch = useDispatch();

    const costDeclinePayload = {
        thread_id_hash: message?.thread_id_hash,
        message_action_type: 'update',
        message_id: message && message.id,
        ...(localStorage.getItem('token') && {
            token: localStorage.getItem('token'),
        }),
        message_action: 'share_cost',
        message_action_status: 'declined',
        template_name: 'cost_action_v1',
        template_type: 'cost',
        type: 'influencer',
    };
    return (
        <div className="recived-message">
            <MessageBoxContainer className="bgcolor">
                <ShareButtonContents>
                    <span>{message?.template_value?.text}</span>
                    <div className="button">
                        <Button
                            onClick={() => {
                                setAddCampaignCostPopup(true);
                            }}
                        >
                            {' '}
                            Share Cost
                        </Button>
                        <Button
                            onClick={() =>
                                declineRequestAction(
                                    costDeclinePayload,
                                    dispatch,
                                )
                            }
                        >
                            {!createMessageInfo?.loading ? (
                                'Decline request'
                            ) : (
                                <Spinner color="red" />
                            )}
                        </Button>
                    </div>
                </ShareButtonContents>{' '}
                {addCampaignCostPopup && (
                    <CampaignCostPopup
                        onClose={() => setAddCampaignCostPopup(false)}
                        deliverables={
                            message?.template_value?.deliverables
                                ? message?.template_value?.deliverables
                                : [
                                      {
                                          deliverable_id: 8,
                                          deliverable_value: 'custom_cost',
                                          deliverable_name: 'Custom Cost',
                                          cost: null,
                                          platform: 'custom',
                                          count: 1,
                                      },
                                  ]
                        }
                        message={message}
                        threadInfo={threadInfo}
                        renderDeliverablesList={renderDeliverablesList}
                    />
                )}
                {costPopup && (
                    <QoruzRecommendedCostPopup
                        onClose={() => addCostPopup(false)}
                    />
                )}
            </MessageBoxContainer>
        </div>
    );
});

export const ProfileCostDetailsV1 = memo((props: Props) => {
    const { message, renderDeliverablesList } = props;
    return (
        <div className="recived-message">
            <MessageBoxContainer className="bgcolor">
                <ShareButtonContents className="ShowSharedDetails">
                    <span>Here is my cost estimate </span>
                    <DeliverablesCostView>
                        {message?.template_value?.deliverables?.length
                            ? message?.template_value?.deliverables.map(
                                  deliverable => {
                                      return (
                                          <div className="deliverables-content">
                                              <div className="deliverables-platform">
                                                  <div className="deliverables-type">
                                                      {deliverable.deliverable_value !==
                                                          'custom_cost' && (
                                                          <img
                                                              src={renderDeliverableIcon(
                                                                  renderDeliverablesList,
                                                                  deliverable.deliverable_value,
                                                              )}
                                                              alt={`${deliverable.deliverable_value}-icon`}
                                                              className="deliverables-icon"
                                                          />
                                                      )}

                                                      <div>
                                                          {[
                                                              'custom_cost',
                                                          ].includes(
                                                              deliverable.deliverable_value,
                                                          ) ? (
                                                              <div>
                                                                  {' '}
                                                                  &nbsp; Cost{' '}
                                                              </div>
                                                          ) : (
                                                              <div>
                                                                  <span>
                                                                      {
                                                                          deliverable.count
                                                                      }
                                                                  </span>{' '}
                                                                  x{' '}
                                                                  {
                                                                      deliverable.deliverable_name
                                                                  }{' '}
                                                              </div>
                                                          )}
                                                      </div>
                                                  </div>
                                                  <span>
                                                      ₹
                                                      {convertToInternationalFormat(
                                                          deliverable.cost,
                                                      )}
                                                  </span>
                                              </div>
                                          </div>
                                      );
                                  },
                              )
                            : ''}

                        <div className="total-cost">
                            Total Cost
                            <span className="total">
                                ₹{' '}
                                {convertToInternationalFormat(
                                    message?.template_value?.deliverables?.reduce(
                                        (prev: any, cur: any) =>
                                            prev +
                                            cur.cost *
                                                (cur.count ? cur.count : 1),
                                        0,
                                    ),
                                )}
                            </span>
                        </div>
                    </DeliverablesCostView>

                    {message?.created_at && (
                        <span className="time">
                            {message?.participant_type === 'admin'
                                ? 'Sent by qoruz support'
                                : ''}{' '}
                            {renderMsgTime(message?.created_at)}
                            {message.read_at !== null ? (
                                <DoubleTickBlue />
                            ) : (
                                <img src={ReadTickIcon} alt="read" />
                            )}
                        </span>
                    )}
                </ShareButtonContents>
            </MessageBoxContainer>
        </div>
    );
});
