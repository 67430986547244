import styled from 'styled-components/macro';

export const AuthScreen = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    .alert {
        position: absolute;
        left: 16px;
        right: 16px;
        bottom: 16px;
    }

    &.get-started,
    &.enter-otp,
    &.enter-mobile {
        .brand {
            margin: 0 16px 16px;
        }
    }
    &.signup-loading,
    &.hold-on {
        .content-area {
            max-width: 328px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100vh - 8px - 2.5rem - 16px);
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            h2 {
                font-size: 1.5rem;
                text-align: center;
            }
        }
    }
    &.signup-loading {
        .content-area {
            .progress-bar {
                max-width: 120px;
            }
        }
    }
    form {
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 16px;
        padding-bottom: calc(48px + 16px);
        &.form-list {
            .form-list-item {
                &:first-child {
                    margin-top: 8px;
                }

                label.stepper-form-label {
                    .rupee-text-symbol {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
    .brand {
        margin-bottom: 16px;
    }
    &.custom-auth-styled {
        padding-top: 0 !important;
        min-height: auto !important;
    }
`;

export default AuthScreen;
