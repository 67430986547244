import parse from 'html-react-parser';
import { memo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useSignUpPageSlice } from 'app/pages/SignUpPage/slice';
import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';

import AppWrapper from 'app/components/AppWrapper';
import Spinner from 'app/components/Spinner_V2';
import { TabList } from 'app/pages/Tabs/TabMenu';
import { DataUnavailable } from 'app/pages/Tabs/MessengerTabSection/components/dataUnavailable';

import { CustomAppWrapper } from 'app/pages/Tabs/index.styled';
import { BrandMangerList, BrandProfileList, Notify } from '../../index.styled';

import { TabHeader } from 'app/pages/Tabs/Header';
import { getDefaultQLogo, renderMessengerTime } from 'utils/helper';
import { colorCodeList } from 'app/pages/Tabs/MessengerTabSection/label';

import { BaseChatSection, SearchSection, UserEmptyLogo } from './index.styled';
import { Button } from 'react-bootstrap';
import { renderMsgTime } from 'app/pages/Tabs/MessengerTabSection/helpers';
import { messengerActions } from '../../slice';

interface Props {
    setSearchKeyword: Function;
    threadList: any;
    setPage: Function;
    setLoadMore: Function;
    meta_details: any;
    loading: any;
    selectThreadId: any;
    isFirstTimeLoading: boolean;
    setIsFirstTimeLoading: Function;
    searchKeyword: any;
    profile_id: number;
    setIsUnreadCount: Function;
    loadMore: boolean;
    page: number;
}

export const BaseMessengerView = memo((props: Props) => {
    useSignUpPageSlice();
    const history = useHistory();
    const scrollRef: any = useRef();
    const {
        setSearchKeyword,
        searchKeyword,
        threadList,
        setPage,
        setLoadMore,
        meta_details,
        loading,
        selectThreadId,
        isFirstTimeLoading,
        setIsFirstTimeLoading,
        profile_id,
        setIsUnreadCount,
        loadMore,
        page,
    } = props;

    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const { profileSummary = {}, loading: profileSummaryLoading } =
        profileSummarySelector;
    const dispatch = useDispatch();

    useEffect(() => {
        document.body.classList.add('no-scroll');
        if (!localStorage.getItem('token')) {
            return history.push('/signup');
        }
    }, []);

    useEffect(() => {
        if (localStorage.getItem('token') && profile_id) {
            dispatch(
                messengerActions.getMessengerThread({
                    type: 'influencer',
                    token: localStorage.getItem('token'),
                    ...(localStorage.getItem('token') &&
                        profile_id && {
                            profile_id: profile_id,
                        }),
                    search_by: searchKeyword,
                    sort_by: 'newest',
                    page,
                    loadMore,
                }),
            );
        }
    }, [searchKeyword, page, profileSummary]);

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    const onScroll = () => {
        if (scrollRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
            if (Math.floor(scrollTop + clientHeight) === scrollHeight) {
                loadMorePage();
            }
        }
    };

    const loadMorePage = () => {
        setIsFirstTimeLoading(false);
        if (
            meta_details.has_more &&
            meta_details?.response_metadata?.next_page_url !== null
        ) {
            const queryString =
                meta_details.response_metadata?.next_page_url.split('?')[1];
            const params = new URLSearchParams(queryString);
            const nextPage = params.get('page');
            setPage(nextPage);
            setLoadMore(true);
        } else {
            setLoadMore(false);
        }
    };

    const renderUserEmptyProfileColor = () => {
        const randomIndex = Math.floor(Math.random() * colorCodeList.length);
        const colorCode = colorCodeList[randomIndex];
        return colorCode;
    };
    return (
        <CustomAppWrapper>
            <AppWrapper className="custom-wrapper">
                <TabHeader tabcontentname="Messages" />
                <BaseChatSection onScroll={() => onScroll()} ref={scrollRef}>
                    <SearchSection className="search-input">
                        <input
                            type="search"
                            name=""
                            placeholder="Search name, brand"
                            onChange={e => {
                                setSearchKeyword(e.target.value);
                                setPage(1);
                            }}
                        />
                    </SearchSection>

                    <BrandProfileList>
                        {threadList.length
                            ? threadList.map((thread: any, index) => (
                                  <>
                                      {thread && thread.length
                                          ? thread.map(threadDetails => (
                                                <div
                                                    key={index}
                                                    className={`profile-row ${
                                                        selectThreadId ===
                                                        threadDetails?.id_hash
                                                            ? 'thread_selected'
                                                            : ''
                                                    }`}
                                                    onClick={e => {
                                                        threadDetails?.unread_messages_count
                                                            ? setIsUnreadCount(
                                                                  true,
                                                              )
                                                            : setIsUnreadCount(
                                                                  false,
                                                              );
                                                        history.push(
                                                            `/messenger/${threadDetails?.id_hash}`,
                                                        );
                                                    }}
                                                >
                                                    <BrandMangerList>
                                                        {threadDetails &&
                                                        threadDetails?.user
                                                            ?.profile_image_data
                                                            ?.data_url ? (
                                                            <img
                                                                src={
                                                                    threadDetails &&
                                                                    threadDetails
                                                                        ?.user
                                                                        ?.profile_image_data
                                                                        ?.data_url
                                                                }
                                                                onError={e =>
                                                                    ((
                                                                        e.target as HTMLInputElement
                                                                    ).src =
                                                                        getDefaultQLogo())
                                                                }
                                                                alt="profile-img"
                                                            />
                                                        ) : (
                                                            <UserEmptyLogo
                                                                bgcolor={renderUserEmptyProfileColor()}
                                                            >
                                                                {threadDetails &&
                                                                    threadDetails?.user?.name.charAt(
                                                                        0,
                                                                    )}
                                                            </UserEmptyLogo>
                                                        )}

                                                        <div className="profile-details">
                                                            <div>
                                                                <span className="manager-name">
                                                                    {threadDetails &&
                                                                        threadDetails
                                                                            .user
                                                                            ?.name}
                                                                </span>

                                                                {threadDetails &&
                                                                threadDetails
                                                                    .organization
                                                                    ?.name ? (
                                                                    <span className="brand">
                                                                        {
                                                                            '\u00a0'
                                                                        }
                                                                        {'<>'}
                                                                        {
                                                                            '\u00a0'
                                                                        }
                                                                        {threadDetails &&
                                                                            threadDetails
                                                                                .organization
                                                                                ?.name}
                                                                    </span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                            {threadDetails?.last_message ? (
                                                                <p
                                                                    className="message-peek"
                                                                    key={index}
                                                                >
                                                                    {/* Hello
                                                                                <span className="creator-name">
                                                                                    {threadDetails &&
                                                                                        threadDetails
                                                                                            .profile
                                                                                            ?.name}
                                                                                </span>
                                                                                ,{' '} */}
                                                                    {parse(
                                                                        threadDetails
                                                                            ?.last_message
                                                                            ?.text,
                                                                    )}
                                                                </p>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </div>
                                                    </BrandMangerList>

                                                    <Notify>
                                                        {threadDetails?.created_at &&
                                                            renderMsgTime(
                                                                threadDetails?.last_messaged_at
                                                                    ? threadDetails?.last_messaged_at
                                                                    : threadDetails?.created_at,
                                                            )}

                                                        {threadDetails?.unread_messages_count ? (
                                                            <span className="notification-count">
                                                                {
                                                                    threadDetails?.unread_messages_count
                                                                }
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </Notify>
                                                </div>
                                            ))
                                          : ''}
                                  </>
                              ))
                            : ''}

                        <div className="d-flex justify-content-center mb-3 mt-3">
                            {loading &&
                            meta_details &&
                            meta_details.next_page_url !== null ? (
                                <Spinner />
                            ) : !loading &&
                              threadList.length === 0 &&
                              profile_id ? (
                                <i className="">No results found</i>
                            ) : !loading &&
                              profile_id &&
                              !threadList.length &&
                              !searchKeyword.length ? (
                                <DataUnavailable />
                            ) : (
                                ''
                            )}
                        </div>

                        {/* <div className="d-flex justify-content-center mb-4 mt-4">
                            {!loading &&
                            meta_details &&
                            meta_details.next_page_url !== null ? (
                                <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => {
                                        loadMorePage();
                                    }}
                                >
                                    Load More
                                </Button>
                            ) : (
                                ''
                            )}
                        </div> */}
                    </BrandProfileList>
                    <TabList profile_id={profile_id} />
                </BaseChatSection>
            </AppWrapper>
        </CustomAppWrapper>
    );
});
