/**
 *
 * GetStarted
 *
 */
import React, { memo, useState, useEffect } from 'react';
import { Splash } from '../Splash';
import { Button } from 'app/components/Button';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Slide1 from 'app/images/slide1.svg';
import Slide2 from 'app/images/slide2.svg';
import Slide3 from 'app/images/slide3.svg';
import { CarouselInner, CarouselWrapper } from './index.styled';
import { Logo } from 'app/components/Logo';
import AuthScreen from 'app/components/AuthScreen';
import AuthFooter from 'app/components/AuthFooter';

interface Props {
    onGetStarted: Function;
}

export const GetStarted = memo((props: Props) => {
    const { onGetStarted } = props;
    const [showSplash, setShowSplash] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setShowSplash(false);
        }, 250 + 500 + 250);
    }, []);
    if (showSplash) {
        return <Splash />;
    }
    return (
        <AuthScreen className="get-started">
            <Logo />
            <CarouselWrapper className="carousel-wrapper">
                <Carousel
                    autoPlay
                    infiniteLoop
                    showThumbs={false}
                    showIndicators={true}
                    showStatus={false}
                    showArrows={false}
                >
                    <CarouselInner className="carousel-innner">
                        <img alt="Slide 1" src={Slide1} />
                        <h2>
                            Earn Money, Event Invites, Free Products & more..
                        </h2>
                        <span>
                            India’s no. 1 platform for influencer-brand
                            connections and collaborations!
                        </span>
                    </CarouselInner>
                    <CarouselInner className="carousel-innner">
                        <img alt="Slide 2" src={Slide2} />
                        <h2>Get discovered by 100s of brands</h2>
                        <span>
                            Participate in campaigns from India’s top brands in
                            every sector
                        </span>
                    </CarouselInner>
                    <CarouselInner className="carousel-innner">
                        <img alt="Slide 3" src={Slide3} />
                        <h2>{`Learn & grow as an influencer`}</h2>
                        <span>
                            {`Take up courses, read articles and learn from other
                            influencers & experts..`}
                        </span>
                    </CarouselInner>
                </Carousel>
            </CarouselWrapper>
            <AuthFooter className="footer">
                <Button onClick={() => onGetStarted()}>
                    Let’s get started
                </Button>
            </AuthFooter>
        </AuthScreen>
    );
});
