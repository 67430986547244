import styled from 'styled-components';
import Search from 'app/images/search.svg';
import CloseIcon from 'app/images/close-icon.svg';

const SearchSection = styled.div`
    margin: 0 16px;
    position: sticky;
    top: 0px;
    input {
        height: 40px;
        width: 100%;
        background: #f1f4f8;
        border-radius: 8px;
        border: 0;
        background-image: url(${Search});
        background-position: center left 12px;
        background-repeat: no-repeat;
        padding-left: 34px;
        padding-right: 12px;
        font-size: 16px;
        font-weight: 500;
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: #9aa5b1;
        }
    }

    input[type='search']::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 1em;
        width: 1em;
        border-radius: 50em;
        background: url(${CloseIcon});
        background-size: contain;
        opacity: 0;
        pointer-events: none;
        cursor: pointer;
    }

    input[type='search']:focus::-webkit-search-cancel-button {
        opacity: 0.3;
        pointer-events: all;
    }

    input[type='search'].dark::-webkit-search-cancel-button {
        filter: invert(1);
    }
`;

const BaseChatSection = styled.div`
    position: static;
    height: 85vh;
    overflow-y: scroll;
    scroll-behavior: smooth;
    ::-webkit-scrollbar {
        width: 5px;
        height: 10px !important;
    }
    @media screen and (max-width: 680px) {
        height: calc(100vh - 200px);
        /* height: 80vh; */
    }
`;

export const UserEmptyLogo = styled.div<{ bgcolor?: string }>`
    height: 40px;
    width: 40px;
    border-radius: 48px;
    min-width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.bgcolor};
    color: #ffffff;
    font-size: 20px;
    font-weight: 900;
`;

export { SearchSection, BaseChatSection };
