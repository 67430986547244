import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noScroll } from 'utils/helper';

import {
    selectInfluencerProfileSummary,
    selectShowPopupStatus,
    selectSignUpPage,
} from 'app/pages/SignUpPage/slice/selectors';
import { signUpPageActions } from 'app/pages/SignUpPage/slice';

import {
    ListSkeleton,
    HtmlElementSkeleton,
} from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';

import PetIcon from 'app/images/pet-icon.svg';
import { PetInfoPopUp } from '../PetInfoPopup';
import { PersonalInfoCard } from '../../index.styled';

interface Props {
    petDetails: any;
}

export const PetInfo = memo((props: Props) => {
    const {
        petDetails: { pets_available, pets_details = [] },
    } = props;
    const dispatch = useDispatch();
    const signUpPageStore = useSelector(selectSignUpPage);
    const isShowingPopup = useSelector(selectShowPopupStatus);
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const { loading: profileSummaryLoading } = profileSummarySelector;

    const [showPetInfoPopup, setShowPetInfoPopup] = useState(false);

    useEffect(() => {
        noScroll(showPetInfoPopup);
    }, [showPetInfoPopup]);

    const handleShowPetInfoPopup = (showState: boolean = false) => {
        setShowPetInfoPopup(showState);
        dispatch(signUpPageActions.setIsShowPopup(showState));
    };

    useEffect(() => {
        if (!signUpPageStore.loading && signUpPageStore.isStepSuccess) {
            handleShowPetInfoPopup(false);
        }
    }, [signUpPageStore]);

    const hasPetInfo = () => {
        return pets_available !== null && pets_available !== undefined;
    };

    return (
        <>
            <PersonalInfoCard>
                <div className="info-card__header">
                    <h4>Pet(s) Info</h4>
                    {profileSummaryLoading ? (
                        <HtmlElementSkeleton width={25} height={12} />
                    ) : (
                        <span
                            onClick={() => {
                                handleShowPetInfoPopup(true);
                                dispatch(
                                    signUpPageActions.resetProfileSummaryUpdateSuccess(),
                                );
                            }}
                        >
                            {hasPetInfo() ? 'Edit' : '+ Add'}
                        </span>
                    )}
                </div>

                <div className="info-card__body">
                    {profileSummaryLoading ? (
                        <ListSkeleton
                            customClassName="pet-info-loader"
                            height={20}
                            listItemCount={1}
                        />
                    ) : hasPetInfo() ? (
                        <>
                            <div className="header-border"></div>
                            <ul className="pet-style">
                                <li>
                                    <img src={PetIcon} alt="pet-icon" />
                                    {Object.keys(pets_details).length !== 0 ? (
                                        <>
                                            {Object.entries(pets_details)
                                                .map(([key, val]: any) => {
                                                    const petType =
                                                        val > 1
                                                            ? `${key}s`
                                                            : `${key}`;
                                                    const petCount = val;
                                                    return `${petCount} ${petType}`;
                                                })
                                                .join(' & ')}
                                        </>
                                    ) : (
                                        'No pets'
                                    )}
                                </li>
                            </ul>
                        </>
                    ) : (
                        <p className="prompt-message">
                            Add pet info to get more relevant campaigns.
                        </p>
                    )}
                </div>
            </PersonalInfoCard>

            {showPetInfoPopup && isShowingPopup && (
                <PetInfoPopUp
                    onClose={() => handleShowPetInfoPopup(false)}
                    prefillPetDetails={{
                        has_pets: pets_available,
                        pets_list: pets_details,
                    }}
                />
            )}
        </>
    );
});
