import styled from 'styled-components';

const ArticleCardSection = styled.div`
    width: 312px;
    height: 260px;
    filter: drop-shadow(0px 2px 6px rgba(9, 30, 66, 0.04))
        drop-shadow(0px 8px 12px rgba(9, 30, 66, 0.08));
    margin-bottom: 24px;
    background: #ffffff;
    border-radius: 8px;
    cursor: pointer;
    .background-img {
        background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.1),
                rgba(0, 0, 0, 0.1)
            ),
            url(.jpg);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        width: 312px;
        height: 180px;
    }

    .news-contents {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 11px;
        gap: 8px;
        span {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.4px;
            color: #3e4c59;
        }

        .news-view {
            width: 100%;
            display: flex;
            justify-content: space-between;
            span {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0px;
                gap: 6px;
                font-weight: 600;
                font-size: 10px;
                line-height: 160%;
                color: #7b8794;
            }
        }
    }

    .categories-type {
        position: absolute;
        width: fit-content;
        height: 24px;
        left: 12px;
        top: 12px;
        background: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(4px);
        padding: 4px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        span {
            img {
                padding-right: 4px;
            }
            font-weight: 600;
            font-size: 10px;
            line-height: 160%;
            display: flex;
            align-items: center;
            letter-spacing: 0.1em;
            color: #ffffff;
        }
    }
`;

const ArticleSection = styled.div`
    padding-top: 16px;
`;

export { ArticleSection, ArticleCardSection };
