import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import parse from 'html-react-parser';

import { selectInfluencerHelpdeskInfo } from 'app/pages/SignUpPage/slice/selectors';

import { Button } from 'app/components/Button';
import AppWrapper from 'app/components/AppWrapper';
import { CategoryViewDetails, CategoryFooterSection } from './index.styled';

import PreviewBlockIcon from 'app/images/black-left-arrow.svg';
import { PromptMessage } from 'app/pages/Tabs/TabMenu/CommonComponents/PromptMessage';

interface Props {
    match?: any;
}

export const FaqDetailsViewPage = memo((props: Props) => {
    const history = useHistory();
    const { match } = props;
    const { category: routeCategory = '', faqId } = match.params;

    const influencerHelpdeskInfoSelector = useSelector(
        selectInfluencerHelpdeskInfo,
    );
    const { loading, helpdeskCategoryDetails = [] } =
        influencerHelpdeskInfoSelector;

    const [faq, setFaq] = useState<any>({});

    useEffect(() => {
        if (
            !loading &&
            helpdeskCategoryDetails &&
            helpdeskCategoryDetails.length !== 0
        ) {
            // filter the matching category
            const filteredCat =
                helpdeskCategoryDetails.filter(
                    (category: any) =>
                        category.category_name.toLowerCase() === routeCategory,
                ) || [];

            // filter the matching category's faq list
            const filteredCatFaqs =
                filteredCat[0]?.helpdesk_category_faq_details || [];

            // filter the matching faq ID
            const filteredFaq =
                filteredCatFaqs.filter(
                    (faqItem: any) => faqItem.id === Number(faqId),
                )[0] || {};

            setFaq({ ...filteredFaq });
        }
    }, [influencerHelpdeskInfoSelector]);

    return (
        <>
            <AppWrapper>
                <CategoryViewDetails>
                    <div className="view-detail-header">
                        <img
                            src={PreviewBlockIcon}
                            alt="preview-icon"
                            onClick={() => {
                                routeCategory &&
                                    history.push(
                                        `/helpdesk/category-faqs/${routeCategory}`,
                                    );
                            }}
                        />
                    </div>

                    <div className="view-title">
                        {loading ? (
                            <>
                                <Skeleton
                                    height={30}
                                    borderRadius={4}
                                    style={{ marginBottom: '10px' }}
                                />
                                <Skeleton height={150} borderRadius={4} />
                            </>
                        ) : faq?.question || faq?.answer ? (
                            <>
                                {faq?.question && <span>{faq.question}</span>}
                                {faq?.answer && (
                                    <p>{parse(faq.answer || '')}</p>
                                )}
                            </>
                        ) : (
                            <PromptMessage>
                                Sorry, no data available for this FAQ
                            </PromptMessage>
                        )}
                    </div>
                </CategoryViewDetails>
            </AppWrapper>
            {/* <CategoryFooterSection>
                <h4>Was this helpful?</h4>
                <div className="help-buttons">
                    <Button className="button">
                        👍
                        <span className="btn-text-yes"> Yes</span>
                    </Button>

                    <Button className="button">
                        👎
                        <span className="btn-text-no"> No</span>
                    </Button>
                </div>
            </CategoryFooterSection> */}
        </>
    );
});
