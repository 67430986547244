import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { panCardPopupActions as actions } from '.';
import { campaignProgressActions } from 'app/pages/HomePage/components/AcceptedTimeline/slice';

export function* updatePanCardDetailsSaga({ payload }) {
    try {
        const requestURL = `/api/campaign.profile.updatePanCardDetails`;
        const response = yield call(request, requestURL, {
            method: 'POST',
            headers: {
                // /Authorization: authHeader(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(payload),
        });
        yield put(
            campaignProgressActions.getCampaignProgressDetails({
                profile_id_hash: payload.profile_id_hash,
                group_id_hash: payload.groupId,
            }),
        );
        yield put(actions.updatePanCardDetailsSuccess(response));

        // yield put(
        //     addToast({
        //         type: response.ok === true ? 'success' : 'error',
        //         message: response.stuff,
        //     }),
        // );
    } catch (error) {
        try {
            const response = yield error.response.json();
            yield put(actions.updatePanCardDetailsError(response));
        } catch (jsonError) {
            yield put(actions.updatePanCardDetailsError(error));
        }
    }
}

export function* panCardPopupSaga() {
    yield takeLatest(actions.updatePanCardDetails, updatePanCardDetailsSaga);
}
