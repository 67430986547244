import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';

import { Youtube } from './components/YouTube';
import { Instagram } from './components/Instagram';
import AppWrapper from 'app/components/AppWrapper';

import PreviewBlockIcon from 'app/images/black-left-arrow.svg';
import { HeaderTittle, ProfileSection } from '../About/index.styled';
import { LinkSocialProfilePromptCard } from './components/LinkSocialProfilePromptCard';
import { ListSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';

interface Props {}

export const SocialProfiles = memo((props: Props) => {
    const history = useHistory();
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const {
        profileSummary: {
            social_profiles_details: {
                instagram: instagramProfiles,
                youtube: youtubeProfiles,
            } = {
                instagram: [],
                youtube: [],
            },
        },
        loading: profileSummaryLoading,
    } = profileSummarySelector;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <AppWrapper>
            <HeaderTittle>
                <img
                    src={PreviewBlockIcon}
                    onClick={history.goBack}
                    alt="preview-icon"
                />
                <span>Social Profiles</span>
            </HeaderTittle>
            <ProfileSection>
                {profileSummaryLoading ? (
                    <ListSkeleton
                        listItemCount={2}
                        height={180}
                        customListItemStyles={{
                            marginBottom: '12px;',
                            borderRadius: '8px',
                        }}
                    />
                ) : (
                    <>
                        {instagramProfiles &&
                            instagramProfiles.length !== 0 &&
                            instagramProfiles.map(
                                (instaProfile: any, index: number) => (
                                    <Instagram
                                        key={
                                            instaProfile?.basic_details
                                                ?.profile_id || index
                                        }
                                        instagramProfileDetails={instaProfile}
                                    />
                                ),
                            )}
                        <LinkSocialProfilePromptCard
                            platform="instagram"
                            isNoProfileLinked={
                                instagramProfiles
                                    ? Boolean(instagramProfiles.length === 0)
                                    : false
                            }
                        />

                        {youtubeProfiles &&
                            youtubeProfiles.length !== 0 &&
                            youtubeProfiles.map(
                                (youtubeProfile: any, index: number) => (
                                    <Youtube
                                        key={
                                            youtubeProfile?.basic_details
                                                ?.profile_id || index
                                        }
                                        youtubeProfileDetails={youtubeProfile}
                                    />
                                ),
                            )}
                        <LinkSocialProfilePromptCard
                            platform="YouTube"
                            isNoProfileLinked={
                                youtubeProfiles
                                    ? Boolean(youtubeProfiles.length === 0)
                                    : false
                            }
                        />
                    </>
                )}
            </ProfileSection>
        </AppWrapper>
    );
});
