import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { campaignProgressSaga } from './saga';
import { CampaignProgressState } from './types';

export const initialState: CampaignProgressState = {
  loading: false,
  campaignTimeline: '',
  error: false,
  campaignInfoPopupContent: '',
};

const slice = createSlice({
  name: 'campaignProgress',
  initialState,
  reducers: {
    getCampaignProgressDetails(state, action: PayloadAction<any>) {
      state.loading = true;
      state.campaignInfoPopupContent = '';
    },
    getCampaignProgressDetailsSuccess(state, action: PayloadAction<any>) {
      state.loading = false;
      state.campaignTimeline = action.payload?.steps;
      state.campaignInfoPopupContent = action.payload?.campaign_info_popup;
    },
    getCampaignProgressDetailsError(state, action: PayloadAction<any>) {
      state.error = false;
      state.campaignInfoPopupContent = '';
    },
  },
});

export const { actions: campaignProgressActions } = slice;

export const useCampaignProgressSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: campaignProgressSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCampaignProgressSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
