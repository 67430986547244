import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { addUpiSaga } from './saga';
import { AddUpiState } from './types';

export const initialState: AddUpiState = {
    loading: false,
    error: false,
    isPaymentDataAdded: '',
    isTokenValid: '',
};

const slice = createSlice({
    name: 'addUpi',
    initialState,
    reducers: {
        addUPINumber(state, action: PayloadAction<any>) {
            state.loading = true;
        },
        addUPINumberSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.isPaymentDataAdded = 'yes';
            state.isTokenValid = 'yes';
        },
        addUPINumberError(state, action: PayloadAction<any>) {
            state.loading = false;
            if (action.payload && action.payload.error === 'token_invalid') {
                state.isTokenValid = 'no';
            }
        },
    },
});

export const { actions: addUpiActions } = slice;

export const useAddUpiSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga: addUpiSaga });
    return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAddUpiSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
