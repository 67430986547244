import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DetailLists, helpdeskCategoryIcons } from '../label';

import { useSignUpPageSlice } from 'app/pages/SignUpPage/slice';

import RaiseTicket from '../RaiseTicket';
import AppWrapper from 'app/components/AppWrapper';

import viewIcon from 'app/images/campaignviewicon.svg';
import CampaignIcon from 'app/images/helpcampaign.svg';
import previewIcon from 'app/images/white-left-arrow.svg';

import { CustomAppWrapper } from 'app/pages/Tabs/index.styled';
import { CategoryHeaderSection, CategoryBodySection } from './index.styles';
import { selectInfluencerHelpdeskInfo } from 'app/pages/SignUpPage/slice/selectors';
import { useSelector } from 'react-redux';
import {
    HtmlElementSkeleton,
    ListSkeleton,
} from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';
import { PromptMessage } from 'app/pages/Tabs/TabMenu/CommonComponents/PromptMessage';

interface Props {
    match?: any;
}

export const CategoriesFAQPage = memo((props: Props) => {
    useSignUpPageSlice();
    const { match } = props;
    const { category: routeCategory = '' } = match.params;
    const history = useHistory();

    const influencerHelpdeskInfoSelector = useSelector(
        selectInfluencerHelpdeskInfo,
    );
    const { loading: helpdeskInfoLoading, helpdeskCategoryDetails = [] } =
        influencerHelpdeskInfoSelector;

    const [selectedCategory, setSelectedCategory] = useState<any>({});

    useEffect(() => {
        if (!helpdeskInfoLoading && helpdeskCategoryDetails.length !== 0) {
            const filteredCat = helpdeskCategoryDetails.filter(
                (category: any) =>
                    category.category_name.toLowerCase() === routeCategory,
            );

            const tempSelectedCategory =
                filteredCat && filteredCat.length !== 0 ? filteredCat[0] : {};

            setSelectedCategory(tempSelectedCategory);
        }
    }, [influencerHelpdeskInfoSelector]);

    return (
        <CustomAppWrapper>
            <AppWrapper className="custom-wrapper">
                <CategoryHeaderSection>
                    <img
                        src={previewIcon}
                        alt="preview-icon"
                        className="left-Arrow"
                        onClick={() => {
                            history.push('/helpdesk');
                        }}
                    />

                    <div className="header-contents">
                        {helpdeskInfoLoading ? (
                            <>
                                <HtmlElementSkeleton
                                    width={35}
                                    height={35}
                                    borderRadius={2}
                                />
                                <HtmlElementSkeleton
                                    width={100}
                                    height={15}
                                    borderRadius={2}
                                />
                            </>
                        ) : (
                            <>
                                {helpdeskCategoryIcons[
                                    selectedCategory.category_name
                                ] && (
                                    <p
                                        className={`helpdesk ${selectedCategory.category_name} icon category-icon`}
                                    >
                                        {
                                            helpdeskCategoryIcons[
                                                selectedCategory.category_name
                                            ].categoryIcon
                                        }
                                    </p>
                                )}
                                <div className="category-content">
                                    <span>
                                        {selectedCategory.category_name || 'NA'}
                                    </span>
                                    {selectedCategory?.description && (
                                        <p className="category-description">
                                            {selectedCategory.description}
                                        </p>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </CategoryHeaderSection>

                <CategoryBodySection>
                    {helpdeskInfoLoading ? (
                        <ListSkeleton
                            listItemCount={3}
                            flexDirection="column"
                            height={30}
                            customListItemStyles={{ margin: '10px 0 5px 0' }}
                        />
                    ) : (
                        <>
                            {selectedCategory?.helpdesk_category_faq_details &&
                            selectedCategory?.helpdesk_category_faq_details
                                .length !== 0 ? (
                                selectedCategory?.helpdesk_category_faq_details.map(
                                    (catFaq: any, index: number) => (
                                        <h5
                                            key={catFaq?.id || index}
                                            onClick={() => {
                                                history.push(
                                                    `/helpdesk/category-faqs/${routeCategory}/faq/${catFaq.id}`,
                                                );
                                            }}
                                        >
                                            <span>{catFaq?.question}</span>
                                            <span>
                                                <img
                                                    src={viewIcon}
                                                    alt="leftArrow-icon"
                                                />
                                            </span>
                                        </h5>
                                    ),
                                )
                            ) : (
                                <PromptMessage>
                                    No help faqs available
                                </PromptMessage>
                            )}

                            <div className="raise-ticket">
                                <RaiseTicket />
                            </div>
                        </>
                    )}
                </CategoryBodySection>
            </AppWrapper>
        </CustomAppWrapper>
    );
});
