/**
 *
 * PopupNotification
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import CampaignSuccessIcon from 'app/images/campaign_success.svg';
import CampaignErrorIcon from 'app/images/campaign_error.svg';
import ResponseSuccessIcon from 'app/images/response_success.svg';
import parse from 'html-react-parser';

interface PopupNotificationProps
    extends React.HTMLAttributes<HTMLButtonElement> {
    iconType: string;
    title: string;
    subTitle: string;
    responseType: string;
}

export const PopupNotification = memo((props: PopupNotificationProps) => {
    const { responseType, iconType, title, subTitle } = props;

    const getCampaignResponseIcon = {
        success: CampaignSuccessIcon,
        error: CampaignErrorIcon,
    };

    const getContentSubmissionResponseIcon = {
        success: ResponseSuccessIcon,
    };

    return (
        <PopupNotificationStyled>
            <img
                src={
                    responseType === 'timeline'
                        ? getCampaignResponseIcon[iconType]
                        : getContentSubmissionResponseIcon[iconType]
                }
            />
            <PopupNotificationContentStyled>
                <p> {title}</p>
                <p>{subTitle && parse(subTitle)}</p>
            </PopupNotificationContentStyled>
        </PopupNotificationStyled>
    );
});

const PopupNotificationStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const PopupNotificationContentStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p:nth-child(1) {
        text-align: center;
        font-family: Inter;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
    }
    p:nth-child(2) {
        text-align: center;
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.00466667em;
        color: #7b8794;
    }
`;

export default PopupNotificationStyled;
export { PopupNotificationContentStyled };
