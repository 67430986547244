import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noScroll } from 'utils/helper';

import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import {
    selectInfluencerProfileSummary,
    selectShowPopupStatus,
    selectSignUpPage,
} from 'app/pages/SignUpPage/slice/selectors';

import {
    ListSkeleton,
    HtmlElementSkeleton,
} from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';
import { ContactInfoPopUp } from '../ContactInfoPopup';

import EmailIcon from 'app/images/mail-icon.svg';
import PhoneIcon from 'app/images/phone-icon.svg';
import WhatsAppIcon from 'app/images/whatasapp-icon.svg';
import { PersonalInfoCard } from '../../index.styled';

interface Props {
    contactDetails: any;
}

export const ContactInfo = memo((props: Props) => {
    const { contactDetails = {} } = props;
    const {
        whatsapp_number = {},
        phone_number = {},
        email = '',
    } = contactDetails;

    const dispatch = useDispatch();
    const signUpPageStore = useSelector(selectSignUpPage);
    const isShowingPopup = useSelector(selectShowPopupStatus);
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const { loading: profileSummaryLoading } = profileSummarySelector;

    const [addContacts, setaddContacts] = useState(false);

    const handleShowContactDetailsPopup = (showState: boolean = false) => {
        setaddContacts(showState);
        dispatch(signUpPageActions.setIsShowPopup(showState));
    };

    useEffect(() => {
        noScroll(addContacts);
    }, [addContacts]);

    useEffect(() => {
        if (!signUpPageStore.loading && signUpPageStore.isStepSuccess) {
            handleShowContactDetailsPopup(false);
        }
    }, [signUpPageStore]);

    const hasContactDetails = () => {
        return (
            (whatsapp_number &&
                Object.values(whatsapp_number).every(item => item)) ||
            (phone_number && Object.values(phone_number).every(item => item)) ||
            email
        );
    };

    return (
        <>
            <PersonalInfoCard>
                <div className="info-card__header">
                    <h4>Contact Info</h4>
                    {profileSummaryLoading ? (
                        <HtmlElementSkeleton width={25} height={12} />
                    ) : (
                        <span
                            onClick={() => {
                                handleShowContactDetailsPopup(true);
                                dispatch(
                                    signUpPageActions.resetProfileSummaryUpdateSuccess(),
                                );
                            }}
                        >
                            {hasContactDetails() ? 'Edit' : '+ Add'}
                        </span>
                    )}
                </div>

                <div className="info-card__body">
                    {profileSummaryLoading ? (
                        <ListSkeleton
                            listItemCount={3}
                            height={20}
                            customClassName="contact-info-loader"
                            flexDirection="column"
                        />
                    ) : Object.keys(contactDetails).length !== 0 &&
                      hasContactDetails() ? (
                        <>
                            <div className="header-border"></div>
                            <ul>
                                {whatsapp_number &&
                                    whatsapp_number.phone_number && (
                                        <li>
                                            <img
                                                src={WhatsAppIcon}
                                                alt="whatsapp-icon"
                                            />
                                            {whatsapp_number?.country_code ||
                                                ''}{' '}
                                            {whatsapp_number.phone_number}
                                        </li>
                                    )}
                                {phone_number && phone_number?.phone_number && (
                                    <li>
                                        <img src={PhoneIcon} alt="phone-icon" />
                                        {phone_number?.country_code || ''}{' '}
                                        {phone_number.phone_number}
                                    </li>
                                )}
                                {email && (
                                    <li>
                                        <img src={EmailIcon} alt="email-icon" />
                                        {email}
                                    </li>
                                )}
                            </ul>
                        </>
                    ) : (
                        <p className="prompt-message">
                            Adding personal info increases your chances of
                            getting campaign by <span>80%</span>
                        </p>
                    )}
                </div>
            </PersonalInfoCard>

            {addContacts && isShowingPopup && (
                <ContactInfoPopUp
                    onClose={() => {
                        handleShowContactDetailsPopup(false);
                    }}
                    prefillContactDetails={contactDetails}
                />
            )}
        </>
    );
});
