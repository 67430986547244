import styled from 'styled-components';

const CampaignCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 312px;
    min-height: 378px !important;
    height: fit-content;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.04),
        0px 8px 12px rgba(9, 30, 66, 0.08);
    border-radius: 8px;
    cursor: pointer;

    &.campaign-page-card {
        height: auto !important;
        min-height: initial !important;
        width: 100%;

        .campaign-cover-img {
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            height: 220px;
            object-fit: contain;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 33.45%,
                rgba(0, 0, 0, 0.2) 100%
            );

            @media screen and (max-width: 680px) {
                width: inherit;
                height: 148px;
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0) 33.45%,
                    rgba(0, 0, 0, 0.2) 100%
                );
                border-top-right-radius: 8px;
                border-top-left-radius: 8px;
            }
        }
    }

    .product-post {
        height: 148px;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 33.45%,
            rgba(0, 0, 0, 0.2) 100%
        );
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }

    .brand-type {
        padding: 16px 16px;
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: space-between;

        img {
            width: 48px;
            height: 48px;
            border-radius: 8px;
        }

        .product-name {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .campaign-name {
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                letter-spacing: -0.008em;
                color: #1f2933;

                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            span {
                font-weight: 500;
                font-size: 12px;
                line-height: 150%;
                color: #7b8794;
            }
        }
    }
`;

const InviteSection = styled.div`
    height: 40px;
    padding: 11px 16px;
    margin-top: auto;

    display: flex;
    justify-content: space-between;
    gap: 10px;

    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.008em;
    color: #1f2933;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    span {
        font-weight: 500;
        font-size: 12px;
    }

    &.is-due {
        background-color: var(--red-50);

        span {
            color: #ba2525;
        }
    }

    &.has-expired {
        background-color: var(--gray-50);

        span {
            color: var(--gray-400);
        }
    }

    &.has-accepted {
        background-color: var(--green-50);

        span {
            color: var(--gray-700);
        }
    }
`;

const CampaignSection = styled.div`
    padding-bottom: 4px;
`;

const CampaignDetailSection = styled.div`
    display: flex;
    gap: 12px;
    flex-direction: column;
    padding: 0px 16px 16px;
    font-size: 13px;

    .product-details {
        display: flex;
        gap: 12px;

        span {
            height: 26px;
            display: flex;
            gap: 4px;
            background: #edf2fd;
            border-radius: 6px;
            padding: 4px 6px;
            font-weight: 500;
            line-height: 140%;
            letter-spacing: -0.008em;
            color: #3047bb;
            text-transform: capitalize;

            img {
                width: 14px;
            }

            svg {
                width: 14px;
                & path {
                    stroke: #3047bb;
                }
            }
        }
    }

    .product-cost {
        width: fit-content;
        height: 26px;
        padding: 4px 6px;

        display: flex;
        gap: 4px;
        align-items: center;

        font-weight: 400;
        background: #e3f9e5 !important;
        border-radius: 4px;
        color: #323f4b;
        line-height: 160%;

        .cost-value {
            font-weight: 700;
            line-height: 110%;
            color: #005907;
        }
    }
`;
export { CampaignSection, CampaignCard, InviteSection, CampaignDetailSection };
