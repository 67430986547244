import React, { memo, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    signUpPageActions,
    useSignUpPageSlice,
} from 'app/pages/SignUpPage/slice';
import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';

import { useMessengerSlice, messengerActions } from './slice';
import {
    selectMessengerThreadList,
    selectReadMessages,
    selectThreadMessages,
    selectGetDeliverablesList,
} from './slice/selectors';
import { MessageChatPage } from './components/ChatWindow';
import { BaseMessengerView } from './components/BaseMessengerView';
import { NotFoundPage } from 'app/pages/NotFoundPage';

interface Props {
    setShowUser: any;
}

export const Messenger = memo((props: Props) => {
    useSignUpPageSlice();
    useMessengerSlice();
    let { path } = useRouteMatch();
    const dispatch = useDispatch();
    const history = useHistory();

    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const threadListinfo = useSelector(selectMessengerThreadList);
    const threadMessagesInfo = useSelector(selectThreadMessages);
    const { deliverables } = useSelector(selectGetDeliverablesList);
    const threadReadInfo = useSelector(selectReadMessages);

    const { profileSummary = {} } = profileSummarySelector;
    const { messenger_thread_list, loading, meta_details } = threadListinfo;
    const [selectThreadId, setSelectThreadId] = useState<any>('');
    const [searchKeyword, setSearchKeyword] = useState<any>('');
    const [page, setPage] = useState<any>(1);
    const [loadMore, setLoadMore] = useState<any>(false);
    const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);
    const [isUnreadCount, setIsUnreadCount] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            if (Object.keys(profileSummary).length === 0) {
                dispatch(
                    signUpPageActions.getInfluencerProfileSummary({
                        token: localStorage.getItem('token'),
                    }),
                );
            }
        }

        // get request for deliverable list
        dispatch(messengerActions.getDeliverables({}));
    }, []);

    useEffect(() => {
        if (selectThreadId) {
            dispatch(
                messengerActions.threadMessagesList({
                    type: 'influencer',
                    ...(localStorage.getItem('token')
                        ? { token: localStorage.getItem('token') }
                        : ''),
                    thread_id_hash: selectThreadId,
                }),
            );

            dispatch(
                messengerActions.readThreadMessages({
                    type: 'influencer',
                    thread_id_hash: selectThreadId,
                    ...(localStorage.getItem('token')
                        ? { token: localStorage.getItem('token') }
                        : ''),
                }),
            );
        }
    }, [selectThreadId]);

    //get unread thead count for tab view
    useEffect(() => {
        if (threadReadInfo?.isThreadRead && isUnreadCount) {
            setIsUnreadCount(false);
            dispatch(
                messengerActions.getUnreadThreadCount({
                    type: 'influencer',
                    profile_id: profileSummary?.profile_id,
                }),
            );
        }
    }, [threadReadInfo]);

    return (
        <Switch>
            <Route exact path={path}>
                <BaseMessengerView
                    setSearchKeyword={setSearchKeyword}
                    searchKeyword={searchKeyword}
                    threadList={messenger_thread_list}
                    setPage={setPage}
                    setLoadMore={setLoadMore}
                    meta_details={meta_details}
                    loading={loading}
                    selectThreadId={selectThreadId}
                    isFirstTimeLoading={isFirstTimeLoading}
                    setIsFirstTimeLoading={setIsFirstTimeLoading}
                    profile_id={profileSummary?.profile_id}
                    setIsUnreadCount={setIsUnreadCount}
                    page={page}
                    loadMore={loadMore}
                />
            </Route>
            <Route
                exact
                path={process.env.PUBLIC_URL + '/messenger/:threadId'}
                component={() => (
                    <MessageChatPage
                        selectThreadId={selectThreadId}
                        setSelectThreadId={setSelectThreadId}
                        threadMessagesInfo={threadMessagesInfo}
                        selectedThreadDetails={
                            threadMessagesInfo && threadMessagesInfo.threadInfo
                        }
                        renderDeliverablesList={deliverables}
                    />
                )}
            />
            <Route component={NotFoundPage} />
        </Switch>
    );
});
