import React, { memo, useEffect, useState } from 'react';
import { convertToInternationalFormat, noScroll } from 'utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import NumericLabel from 'react-pretty-numbers';
import { baseDeliverablesData } from '../data';

import {
    selectShowPopupStatus,
    selectSignUpPage,
} from 'app/pages/SignUpPage/slice/selectors';
import { signUpPageActions } from 'app/pages/SignUpPage/slice';

import { InstagramPopUp } from '../InstagramPopup';
import InstagramIcon from 'app/images/SVGComponents/InstagramIcon';

import DefaultProfileImg from 'app/images/placeholder-user-icon.svg';
import { SocialPlatformsInfo } from '../../index.styled';

interface Props {
    instagramProfileDetails: {
        basic_details: any;
        deliverables: [];
    };
}

export const Instagram = memo((props: Props) => {
    const {
        instagramProfileDetails,
        instagramProfileDetails: { basic_details, deliverables },
    } = props;
    const dispatch = useDispatch();
    const signUpPageStore = useSelector(selectSignUpPage);
    const isShowingPopup = useSelector(selectShowPopupStatus);

    const renderDeliverableData = (targetDeliverable: any, index: number) => {
        const filteredDel = baseDeliverablesData['instagram'][
            targetDeliverable?.value
        ]
            ? baseDeliverablesData['instagram'][targetDeliverable?.value]
            : null;

        return filteredDel ? (
            <li
                key={index}
                className={`${
                    targetDeliverable?.deliverable || ''
                } deliverable-icon`}
            >
                {filteredDel.deliverable_icon}
                <p>
                    <span className="cost-text">
                        {targetDeliverable?.cost
                            ? `₹${convertToInternationalFormat(
                                  targetDeliverable?.cost,
                              )}`
                            : 'NA'}
                    </span>{' '}
                    <span className="type-text">
                        per {filteredDel.deliverable_type}
                    </span>
                </p>
            </li>
        ) : (
            ''
        );
    };

    const [showInstaProfileDetailsPopup, setShowInstaProfileDetailsPopup] =
        useState(false);

    useEffect(() => {
        noScroll(showInstaProfileDetailsPopup);
    }, [showInstaProfileDetailsPopup]);

    const handleShowInstagramInfoPopup = (showState: boolean = false) => {
        setShowInstaProfileDetailsPopup(showState);
        dispatch(signUpPageActions.setIsShowPopup(showState));
    };

    useEffect(() => {
        if (!signUpPageStore.loading && signUpPageStore.isStepSuccess) {
            handleShowInstagramInfoPopup(false);
        }
    }, [signUpPageStore]);

    return (
        <>
            <SocialPlatformsInfo>
                <div className="info-card__header">
                    <span className="header-profile">
                        <InstagramIcon />
                        <h4>Instagram</h4>
                    </span>
                    <div className="show-button">
                        <span
                            onClick={() => {
                                handleShowInstagramInfoPopup(true);
                                dispatch(
                                    signUpPageActions.resetProfileSummaryUpdateSuccess(),
                                );
                            }}
                        >
                            {(basic_details &&
                                Object.keys(basic_details).length !== 0) ||
                            deliverables.length !== 0
                                ? 'Edit'
                                : '+ Add Profile'}
                        </span>
                        {/* <span onClick={() => {}}>
                            {(basic_details &&
                                Object.keys(basic_details).length !== 0) ||
                            deliverables.length !== 0
                                ? 'Delete'
                                : ''}
                        </span> */}
                    </div>
                </div>

                <div className="info-card__body">
                    {(basic_details &&
                        Object.keys(basic_details).length !== 0) ||
                    deliverables.length !== 0 ? (
                        <>
                            <div className="header-border"></div>
                            <div className="basic-social-profile-details">
                                <img
                                    src={
                                        basic_details?.marketplace_profile_image ||
                                        DefaultProfileImg
                                    }
                                    onError={e => {
                                        const imgEl =
                                            e.target as HTMLImageElement;
                                        imgEl.src = DefaultProfileImg;
                                    }}
                                    className="user-img"
                                    alt="profile-img"
                                />
                                <div className="user-brief">
                                    <span className="user-id">
                                        {basic_details?.username || 'NA'}
                                    </span>
                                    <div className="user-followers">
                                        <p className="followers-count">
                                            {basic_details.followers_count ? (
                                                <NumericLabel
                                                    params={{
                                                        cssClass: [
                                                            'count-value',
                                                        ],
                                                        justification: 'L',
                                                        locales: 'en-IN',
                                                        commafy: true,
                                                        shortFormat: true,
                                                        shortFormatMinValue: 1000,
                                                    }}
                                                >
                                                    {parseInt(
                                                        basic_details.followers_count,
                                                    )}
                                                </NumericLabel>
                                            ) : (
                                                'NA'
                                            )}{' '}
                                            <span className="count-label">
                                                followers
                                            </span>
                                        </p>
                                        <p className="following-count">
                                            {basic_details.follows_count ? (
                                                <NumericLabel
                                                    params={{
                                                        cssClass: [
                                                            'count-value',
                                                        ],
                                                        justification: 'L',
                                                        locales: 'en-IN',
                                                        commafy: true,
                                                        shortFormat: true,
                                                        shortFormatMinValue: 1000,
                                                    }}
                                                >
                                                    {parseInt(
                                                        basic_details.follows_count,
                                                    )}
                                                </NumericLabel>
                                            ) : (
                                                'NA'
                                            )}{' '}
                                            <span className="count-label">
                                                following
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {deliverables &&
                                deliverables.length !== 0 &&
                                deliverables.some(
                                    (del: any) => del.value && del.cost,
                                ) && (
                                    <ul className="deliverables-list">
                                        {deliverables
                                            .filter(
                                                (del: any) =>
                                                    del.value && del.cost,
                                            )
                                            .map(
                                                (
                                                    deliverableItem: any,
                                                    index: number,
                                                ) =>
                                                    renderDeliverableData(
                                                        deliverableItem,
                                                        index,
                                                    ),
                                            )}
                                    </ul>
                                )}
                        </>
                    ) : (
                        <p className="prompt-message">
                            Link your Instagram profile, add commercials & get
                            relevant campaigns.
                        </p>
                    )}
                </div>
            </SocialPlatformsInfo>

            {showInstaProfileDetailsPopup && isShowingPopup && (
                <InstagramPopUp
                    onClose={() => {
                        handleShowInstagramInfoPopup(false);
                        dispatch(signUpPageActions.clearValidationError(''));
                    }}
                    prefilledInstaProfileInfo={instagramProfileDetails || {}}
                />
            )}
        </>
    );
});
