import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { homeActions as actions } from '.';
import { request } from 'utils/request';

function* acceptCampaignSaga({ payload }) {
    try {
        const requestURL = `/api/campaign.details`;
        const response = yield call(request, requestURL, {
            method: 'POST',
            headers: {
                // /Authorization: authHeader(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(payload),
        });
        yield put(actions.acceptCampaignSuccess(response));
        yield put(actions.setIsAccepted(true));
        // yield put(
        //     addToast({
        //         type: response.ok === true ? 'success' : 'error',
        //         message: response.stuff,
        //     }),
        // );
    } catch (error) {
        try {
            const response = yield error.response.json();
            yield put(actions.acceptCampaignError(response));
        } catch (jsonError) {
            yield put(actions.acceptCampaignError(error));
        }
    }
}

export function* homePageSaga() {
    yield takeLatest(actions.acceptCampaign, acceptCampaignSaga);
}
