import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { messengerActions as actions } from '.';

export function* getThreadListSaga({ payload }) {
    try {
        const queryString = Object.keys(payload)
            .map(key => `${key}=${payload[key]}`)
            .join('&');
        const requestURL = `/api/messenger.get.threads?${queryString}`;

        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });

        yield put(actions.getMessengerThreadSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getMessengerThreadError(response));
        } catch (jsonError) {
            yield put(actions.getMessengerThreadError(error));
        }
    }
}

export function* getThreadMessagesSaga({ payload }) {
    try {
        const queryString = Object.keys(payload)
            .map(key => `${key}=${payload[key]}`)
            .join('&');
        const requestURL = `/api/messenger.thread.messages?${queryString}`;
        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        yield put(actions.threadMessagesListSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.threadMessagesListSuccess(response));
        } catch (jsonError) {
            yield put(actions.threadMessagesListSuccess(error));
        }
    }
}

export function* threadMessagesReadSaga({ payload }) {
    try {
        const requestURL = `/api/messenger.thread.messages.read`;
        const response = yield call(request, requestURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(payload),
        });
        yield put(actions.readThreadMessagesSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.readThreadMessagesError(response));
        } catch (jsonError) {
            yield put(actions.readThreadMessagesError(error));
        }
    }
}

export function* createThreadMessageSaga({ payload }) {
    try {
        const requestURL = `/api/messenger.thread.message.create`;
        const response = yield call(request, requestURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(payload),
        });
        yield put(actions.createMessageSuccess(response));
        yield put(
            actions.readThreadMessages({
                type: payload.type,
                thread_id_hash: payload.thread_id_hash,
                ...(localStorage.getItem('token')
                    ? { token: localStorage.getItem('token') }
                    : ''),
            }),
        );
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.createMessageError(response));
        } catch (jsonError) {
            yield put(actions.createMessageError(error));
        }
    }
}

export function* influencerAddressSaga({ payload }) {
    try {
        const requestURL = `/api/get.influencer.addresses?token=${payload.token}`;
        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        yield put(actions.getInfluencerAddressSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getInfluencerAddressError(response));
        } catch (jsonError) {
            yield put(actions.getInfluencerAddressError(error));
        }
    }
}

// get deliverables list
export function* getDeliverablesListSaga({ payload }) {
    try {
        const requestURL = `/api/deliverables.get?option=messenger`;
        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        yield put(actions.getDeliverablesSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getDeliverablesError(response));
        } catch (jsonError) {
            yield put(actions.getDeliverablesError(error));
        }
    }
}

// get unread thread count saga
export function* getUnreadThreadCountSaga({ payload }) {
    try {
        const queryString = Object.keys(payload)
            .map(key => `${key}=${payload[key]}`)
            .join('&');
        const requestURL = `/api/messenger.getUnreadThreadsCount?${queryString}`;
        const response = yield call(request, requestURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        yield put(actions.getUnreadThreadCountSuccess(response));
    } catch (error: any) {
        try {
            const response = yield error.response.json();
            yield put(actions.getUnreadThreadCountError(response));
        } catch (jsonError) {
            yield put(actions.getUnreadThreadCountError(error));
        }
    }
}

export function* messengerSaga() {
    yield takeLatest(actions.getMessengerThread, getThreadListSaga);
    yield takeLatest(actions.threadMessagesList, getThreadMessagesSaga);
    yield takeLatest(actions.readThreadMessages, threadMessagesReadSaga);
    yield takeLatest(actions.createMessage, createThreadMessageSaga);
    yield takeLatest(actions.getInfluencerAddress, influencerAddressSaga);
    yield takeLatest(actions.getDeliverables, getDeliverablesListSaga);
    yield takeLatest(actions.getUnreadThreadCount, getUnreadThreadCountSaga);
}
