import React from 'react';

import { useSelector } from 'react-redux';
import { selectInfluencerHelpdeskInfo } from 'app/pages/SignUpPage/slice/selectors';

import { TabList } from 'app/pages/Tabs/TabMenu';
import HelpCategoryTypes from '../CategoryTypes';
import AppWrapper from 'app/components/AppWrapper';
import { RowSliderComponent } from 'app/components/ScrollComponent';
import RaiseTicket from 'app/pages/Tabs/HelpdeskTabSection/components/RaiseTicket';
import TicketListShow from 'app/pages/Tabs/HelpdeskTabSection/components/TicketContentCard';

import {
    CategoriesCardSection,
    HelpCategoryTitle,
    TicketListSection,
} from '../../index.styled';
import { CustomAppWrapper } from 'app/pages/Tabs/index.styled';
import { ListSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';
import { TabHeader } from 'app/pages/Tabs/Header';

export const BaseHelpdeskView = ({ profile_id }) => {
    const influencerHelpdeskInfoSelector = useSelector(
        selectInfluencerHelpdeskInfo,
    );
    const { loading: helpDeskInfoLoading } = influencerHelpdeskInfoSelector;

    return (
        <CustomAppWrapper>
            <AppWrapper className="custom-wrapper">
                <TabList profile_id={profile_id} />
                {/* <HelpCategoryTitle>Open Tickets</HelpCategoryTitle>
                <RowSliderComponent scrollBarHeight={144} paddingStyle={true}>
                    <TicketListSection>
                        <TicketListShow />
                    </TicketListSection>
                </RowSliderComponent>
                <div className="border-line"></div> */}
                <TabHeader tabcontentname="Helpdesk" />
                <CategoriesCardSection>
                    {/* <HelpCategoryTitle className="helpcategory-title">
                        Help Categories
                    </HelpCategoryTitle> */}

                    {helpDeskInfoLoading ? (
                        <ListSkeleton
                            listItemCount={4}
                            flexGap={16}
                            height={140}
                            customClassName="helpdesk-loader"
                        />
                    ) : (
                        <>
                            <HelpCategoryTypes />
                            <RaiseTicket fontStyle={600} />
                        </>
                    )}
                </CategoriesCardSection>
            </AppWrapper>
        </CustomAppWrapper>
    );
};
