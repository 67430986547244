import React from 'react';
import './svg.css';

export default function UserIcon() {
    return (
        <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.1666 17.5V15.8333C17.1666 14.9493 16.8154 14.1014 16.1903 13.4763C15.5652 12.8512 14.7173 12.5 13.8333 12.5H7.16658C6.28253 12.5 5.43468 12.8512 4.80956 13.4763C4.18444 14.1014 3.83325 14.9493 3.83325 15.8333V17.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5001 9.16667C12.341 9.16667 13.8334 7.67428 13.8334 5.83333C13.8334 3.99238 12.341 2.5 10.5001 2.5C8.65913 2.5 7.16675 3.99238 7.16675 5.83333C7.16675 7.67428 8.65913 9.16667 10.5001 9.16667Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
