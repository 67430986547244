import React from 'react';
import { StyledPromptMessage } from './index.styled';

export const PromptMessage = (props: any) => {
    return (
        <StyledPromptMessage
            className="prompt-message"
            style={props.customStyles}
        >
            {props.children}
        </StyledPromptMessage>
    );
};
