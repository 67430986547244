import React, { memo, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';

import { TabList } from 'app/pages/Tabs/TabMenu';
import ProfileStatusSection from '../ProfileStatusSection';
import AppWrapper from 'app/components/AppWrapper';
import { ProfileImageSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';

import UserIcon from 'app/images/user.svg';
import LogoutIcon from 'app/images/log-out.svg';
import DefaultProfileImg from 'app/images/placeholder-user-icon.svg';
import HomeIcon from 'app/images/home-icon.svg';
import ShareIocn from 'app/images/share-icon.svg';
import DollarIcon from 'app/images/dollar-icon.svg';
import ThumbsUpIcon from 'app/images/thumbs-up-icon.svg';
import PreferenceIcon from 'app/images/preferences-icon.svg';
import ProfileChangeIcon from 'app/images/change-profile-icon.svg';

import { CustomAppWrapper } from 'app/pages/Tabs/index.styled';
import { PersonalStatusInfo, ProfileHeaderSection } from '../../index.styled';
import Arrow from 'app/images/leftarrow.svg';

interface Props {}

export const BaseProfileTab = memo((props: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const { profileSummary = {}, loading: profileSummaryLoading } =
        profileSummarySelector;

    const handleLogout = () => {
        dispatch(
            signUpPageActions.logoutInfluencer({
                token: localStorage.getItem('token'),
            }),
        );
        localStorage.removeItem('token');
        history.push('/signup');
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <CustomAppWrapper>
            <AppWrapper className="custom-wrapper">
                <ProfileHeaderSection>
                    <img
                        src={Arrow}
                        className="previous-arrow"
                        onClick={history.goBack}
                    />
                    <div className="userprofile-contents">
                        {profileSummaryLoading ? (
                            <ProfileImageSkeleton imgWidth={80} />
                        ) : (
                            <img
                                src={
                                    profileSummary?.profile_image ||
                                    DefaultProfileImg
                                }
                                onError={e => {
                                    const imgEl = e.target as HTMLImageElement;
                                    imgEl.src = DefaultProfileImg;
                                }}
                                className="creator-profile-image"
                                alt="creator-profile-img"
                            />
                        )}

                        {/* <div className="img-upload">
                            <label htmlFor="file-input">
                                <img
                                    src={ProfileChangeIcon}
                                    className="upload-icon"
                                    alt="imgUpload-icon"
                                />
                            </label>
                            <input id="file-input" type="file" />
                        </div> */}

                        <span className="user-name">
                            {profileSummaryLoading ? (
                                <Skeleton width={160} height={30} />
                            ) : (
                                profileSummary?.personal_details?.full_name ||
                                'NA'
                            )}
                        </span>

                        {profileSummaryLoading ? (
                            <span style={{ marginTop: '5px' }}>
                                <Skeleton height={16} />
                            </span>
                        ) : (
                            profileSummary?.bio && (
                                <span className="influencer-bio">
                                    {profileSummary.bio}
                                </span>
                            )
                        )}
                    </div>
                </ProfileHeaderSection>

                <PersonalStatusInfo>
                    {/* <ProfileStatusSection /> */}
                    <ul className="list-types">
                        <li
                            onClick={() => {
                                history.push('/profile/about');
                            }}
                            className="list-content"
                        >
                            <img src={UserIcon} alt="user-icon" />
                            <strong>About Me</strong>
                        </li>

                        <li
                            onClick={() => {
                                history.push('/profile/preferences');
                            }}
                            className="list-content"
                        >
                            <img src={PreferenceIcon} alt="icon" />
                            <strong> Preferences</strong>
                            {/* <span>70% incomplete</span> */}
                        </li>
                        <li
                            onClick={() => {
                                history.push('/profile/socialprofile');
                            }}
                            className="list-content"
                        >
                            <img src={ThumbsUpIcon} alt="thumbsup-icon" />
                            <strong>Social Profiles</strong>
                            {/* <span>70% incomplete</span> */}
                        </li>
                        <li
                            onClick={() => {
                                history.push('/profile/address');
                            }}
                            className="list-content"
                        >
                            <img src={HomeIcon} alt="home-icon" />
                            <strong>Addresses</strong>
                            {/* <span className="update">1 update</span> */}
                        </li>
                        <li
                            onClick={() => {
                                history.push('/profile/payment');
                            }}
                            className="list-content"
                        >
                            <img src={DollarIcon} alt="dollar-icon" />
                            <strong>Payment Info</strong>
                        </li>
                        {/* <li className="list-content">
                            <img src={ShareIocn} alt="share-icon" />
                            <strong>Refer & Earn</strong>
                        </li> */}
                        <li
                            className="list-content"
                            onClick={() => handleLogout()}
                        >
                            <img src={LogoutIcon} alt="logout-iocn" />
                            <strong>Logout</strong>
                        </li>
                    </ul>
                </PersonalStatusInfo>
            </AppWrapper>
        </CustomAppWrapper>
    );
});
