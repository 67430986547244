import React from 'react';
import './svg.css';

export default function MessengerIcon() {
    return (
        <svg
            width="26"
            height="28"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.75 13.75C2.75 14.2362 2.94315 14.7025 3.28697 15.0464C3.63079 15.3902 4.0971 15.5833 4.58333 15.5833H15.5833L19.25 19.25V4.58333C19.25 4.0971 19.0568 3.63079 18.713 3.28697C18.3692 2.94315 17.9029 2.75 17.4167 2.75H4.58333C4.0971 2.75 3.63079 2.94315 3.28697 3.28697C2.94315 3.63079 2.75 4.0971 2.75 4.58333V13.75Z"
                fill="#7B8794"
                stroke="#7B8794"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
