import React from 'react';
import './svg.css';

export default function CameraIcon() {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.1663 16.3333C19.1663 16.7754 18.9907 17.1993 18.6782 17.5118C18.3656 17.8244 17.9417 18 17.4997 18H2.49967C2.05765 18 1.63372 17.8244 1.32116 17.5118C1.0086 17.1993 0.833008 16.7754 0.833008 16.3333V7.16667C0.833008 6.72464 1.0086 6.30072 1.32116 5.98816C1.63372 5.67559 2.05765 5.5 2.49967 5.5H5.83301L7.49967 3H12.4997L14.1663 5.5H17.4997C17.9417 5.5 18.3656 5.67559 18.6782 5.98816C18.9907 6.30072 19.1663 6.72464 19.1663 7.16667V16.3333Z"
                stroke="#7B8794"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.0003 14.6667C11.8413 14.6667 13.3337 13.1743 13.3337 11.3333C13.3337 9.49238 11.8413 8 10.0003 8C8.15938 8 6.66699 9.49238 6.66699 11.3333C6.66699 13.1743 8.15938 14.6667 10.0003 14.6667Z"
                stroke="#7B8794"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
