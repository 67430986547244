import styled from 'styled-components';

const SpinnerSection = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
    justify-content: center;
    .threads-spinner {
        width: 1rem !important;
        height: 1rem !important;
        border-width: 0.2em !important;
    }
`;

export { SpinnerSection };
