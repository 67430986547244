 import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { otpVerifyPopUpActions as actions } from '.';

export function* generateOTPSaga({ payload }) {
    try {
        const requestURL = `/api/campaign.profile.generate.otp`;

        const response = yield call(request, requestURL, {
            method: 'POST',
            headers: {
                // /Authorization: authHeader(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(payload),
        });
        yield put(actions.generateOTPSuccess(response));
    } catch (error) {
        try {
            const response = yield error.response.json();
            yield put(actions.generateOTPError(response));
        } catch (jsonError) {
            yield put(actions.generateOTPError(error));
        }
    }
}

export function* verifyOTPSaga({ payload }) {
    try {
        const requestURL = `/api/campaign.profile.verify.otp`;

        const response = yield call(request, requestURL, {
            method: 'POST',
            headers: {
                // /Authorization: authHeader(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(payload),
        });
        yield put(actions.verifyOTPSuccess(response));
    } catch (error) {
        try {
            const response = yield error.response.json();
            yield put(actions.verifyOTPError(response));
        } catch (jsonError) {
            yield put(actions.verifyOTPError(error));
        }
    }
}

export function* otpVerifyPopUpSaga() {
    yield takeLatest(actions.generateOTP, generateOTPSaga);
    yield takeLatest(actions.verifyOTP, verifyOTPSaga);
}
