import React, { memo, useState, useEffect } from 'react';
import { HeaderTitle } from '../../index.styled';
import DiamondIcon from 'app/images/diamond.svg';
import { ReferRewardStyle, RewardSection } from './index.styled';
import RewardImg from 'app/images/reward.png';
import ShareIcon from 'app/images/referwhiteicon.svg';
import { Button } from 'app/components/Button';
import { ShareLinkPopup } from 'app/pages/Tabs/HomeTabSection/components/SharePopup';
import { noScroll } from 'utils/helper';

interface Props {}

export const ReferReward = memo((props: Props) => {
    const [sharePopup, setSharePopup] = useState(false);
    const [copyLink, setCopyLink] = useState(
        `${window.location.origin.substring(
            window.location.origin.lastIndexOf('/') + 1,
        )}`,
    );

    useEffect(() => {
        noScroll(sharePopup);
    }, [sharePopup]);

    return (
        <RewardSection>
            <HeaderTitle>
                <img src={DiamondIcon} alt="diamond-icon" />
                <span>Refer friends </span>
            </HeaderTitle>
            <ReferRewardStyle>
                <div className="Reward-contents">
                    <img src={RewardImg} alt="reward-png" />
                    <span>
                        Invite your friends and help them enjoy Qoruz
                        privileges!
                    </span>
                </div>
                <Button
                    className="link-btn"
                    onClick={() => setSharePopup(true)}
                >
                    <img src={ShareIcon} alt="share-icon" />
                    <span className="btn-text">Refer friends</span>
                </Button>
                {sharePopup && (
                    <ShareLinkPopup
                        copyLink={copyLink}
                        onClose={() => {
                            setSharePopup(false);
                        }}
                    />
                )}
            </ReferRewardStyle>
        </RewardSection>
    );
});
