import React, { memo } from 'react';
import { HelpCategorySection } from './index.styled';
import { useHistory } from 'react-router-dom';
import { helpdeskCategoryIcons } from '../label';
import { useSelector } from 'react-redux';
import { selectInfluencerHelpdeskInfo } from 'app/pages/SignUpPage/slice/selectors';
import { useSignUpPageSlice } from 'app/pages/SignUpPage/slice';
import { PromptMessage } from 'app/pages/Tabs/TabMenu/CommonComponents/PromptMessage';

interface Props {}

const HelpCategorieTypes = memo((props: Props) => {
    useSignUpPageSlice();
    const history = useHistory();
    const influencerHelpdeskInfoSelector = useSelector(
        selectInfluencerHelpdeskInfo,
    );
    const { helpdeskCategoryDetails = [] } = influencerHelpdeskInfoSelector;

    return (
        <HelpCategorySection
            className={`${
                helpdeskCategoryDetails && helpdeskCategoryDetails.length !== 0
                    ? 'grid'
                    : 'block'
            }`}
        >
            {helpdeskCategoryDetails && helpdeskCategoryDetails.length !== 0 ? (
                helpdeskCategoryDetails.map((category: any, index: number) => (
                    <div
                        key={category?.id || index}
                        className="category-types"
                        onClick={() => {
                            category.category_name &&
                                history.push(
                                    `/helpdesk/category-faqs/${category.category_name.toLowerCase()}`,
                                );
                        }}
                    >
                        {helpdeskCategoryIcons[category.category_name] && (
                            <p
                                className={`helpdesk ${category.category_name} icon category-icon`}
                            >
                                {
                                    helpdeskCategoryIcons[
                                        category.category_name
                                    ].categoryIcon
                                }
                            </p>
                        )}
                        <div className="category-contents">
                            <span className="category-title">
                                {category?.category_name || 'NA'}
                            </span>
                            {category?.description && (
                                <p className="category-description">
                                    {category?.description}
                                </p>
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <PromptMessage customStyles={{ width: '100%' }}>
                    No help categories available
                </PromptMessage>
            )}
        </HelpCategorySection>
    );
});

export default HelpCategorieTypes;
