import styled from 'styled-components/macro';

export const Intro = styled.div`
    padding: 8px 16px;
    background-color: white;
    z-index: 1;
    &.custom-intro-style {
        padding: 0 !important;
        h2 {
            font-weight: 600;
            font-size: 18px;
        }
        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            letter-spacing: -0.008em;
        }
    }

    h2 {
        margin: 0;
        font-size: 20px;
    }
    span {
        display: block;
        margin-top: 6px;
        color: #7b8794;
    }
`;

export default Intro;
