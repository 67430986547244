import styled from 'styled-components';

const CampaignCostPopupStyle = styled.div`
    .showtotal {
        padding: 8px 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: -0.00666667em;
        color: #1f2933;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
        background: #edf2fd;
        border-radius: 8px;
        .total {
            font-weight: 700;
            font-size: 16px;
        }
    }
    .value {
        font-weight: 900;
        font-size: 14px;
        line-height: 30px;
        color: #1f2933;
        padding-right: 2px;
    }
`;
export { CampaignCostPopupStyle };
