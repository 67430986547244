import { memo, useEffect, useRef, useState } from 'react';
import Select, { createFilter } from 'react-select';
import { getCountryCodeDetails, onlyNumberKey } from 'utils/helper';
import { CountryCode } from './index.styled';

interface Props {
    placeholderText: string;
    phoneNumber: string;
    setPhoneNumber: Function;
    countryCode: string;
    countryAlphaTwoCode: string;
    setCountryCode: Function;
    countryCodeList: any;
    pageIdForComp: string;
    customFormStyle?: boolean;
    enterKeyPressed?: any;
}

const InputWithCountryCode = memo((props: Props) => {
    const {
        placeholderText,
        phoneNumber,
        setPhoneNumber,
        setCountryCode,
        countryCodeList,
        countryCode,
        countryAlphaTwoCode,
        pageIdForComp,
        customFormStyle,
        enterKeyPressed,
    } = props;

    const [focus, setFocus] = useState(false);

    const customStyles = {
        control: () => ({
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            height: 44,
            fontSize: 18,
            fontWeight: 400,
        }),
    };

    const handleCountryCodeChange = (selectedCountryCode: any) => {
        setCountryCode(selectedCountryCode);
    };

    return (
        <CountryCode
            className={`country-code ${focus ? 'focus' : ''}`}
            id={pageIdForComp}
        >
            <Select
                className={`country-code__select-menu__container ${
                    customFormStyle ? 'custom-style' : false
                }`}
                classNamePrefix="country-code__select-menu"
                value={getCountryCodeDetails(
                    countryCode || '+91',
                    countryAlphaTwoCode || 'IN',
                )}
                options={countryCodeList}
                onChange={(selectedCountry: any) => {
                    handleCountryCodeChange(selectedCountry);
                }}
                menuPlacement="auto"
                maxMenuHeight={285}
                styles={customStyles}
                isOptionSelected={(option: any, countryCodeList: any) =>
                    countryCodeList.some(
                        (code: any) =>
                            code.countryCodeValue === option.countryCodeValue &&
                            code.countryAlphaTwoCode ===
                                option.countryAlphaTwoCode,
                    )
                }
                filterOption={createFilter({
                    stringify: (option: any) => {
                        return `${option.value} || ${option.label} ||  ${option.data.countryName} || ${option.data.countryAlphaTwoCode}`;
                    },
                })}
                blurInputOnSelect
            />
            <input
                className="phone-number-input"
                type="tel"
                min={0}
                placeholder={placeholderText}
                value={phoneNumber || ''}
                onChange={e => {
                    setPhoneNumber(e.target.value);
                }}
                onFocus={() => {
                    setFocus(true);
                }}
                onBlur={() => setFocus(false)}
                onKeyPress={e => {
                    !onlyNumberKey(e) && e.preventDefault();
                }}
                onKeyDown={event => {
                    if (event.key === 'Enter') {
                        enterKeyPressed();
                    }
                }}
            />
        </CountryCode>
    );
});

export default InputWithCountryCode;
