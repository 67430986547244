import CampaignIcon from 'app/images/helpcampaign.svg';
import BarterPrductIcon from 'app/images/barter-icon.svg';
import UploadIcon from 'app/images/upload-logo.svg';
import RewardIcon from 'app/images/reward-icon.svg';
import QoruzIcon from 'app/images/about-qoruz-icon.svg';
import MessageIcon from 'app/images/messageIcon.svg';
import PaymentIcon from 'app/images/payment.svg';
import UserIcon from 'app/images/user.svg';

export const CategoriesTypeList = [
    {
        icon: CampaignIcon,
        categoryType: 'Campaign',
        categoryContent: 'Some explaination for this topic',
    },
    {
        icon: BarterPrductIcon,
        categoryType: 'Barter Products',
        categoryContent: 'Some kind of explaination',
    },
    {
        icon: UploadIcon,
        categoryType: 'Upload',
        categoryContent: 'Some explaination for this topic',
    },
    {
        icon: RewardIcon,
        categoryType: 'Rewards',
        categoryContent: 'Some explaination for this topic',
    },
    {
        icon: QoruzIcon,
        categoryType: 'About Qoruz',
        categoryContent: 'Some kind of explaination ',
    },
];

export const helpdeskCategoryIcons = {
    General: {
        categoryIcon: '❓',
    },
    Campaigns: {
        // categoryIcon: CampaignIcon,
        categoryIcon: '📢',
    },
    Messages: {
        // categoryIcon: MessageIcon,
        categoryIcon: '💬',
    },
    Payments: {
        // categoryIcon: PaymentIcon,
        categoryIcon: '💰',
    },
    Profile: {
        // categoryIcon: UserIcon,
        categoryIcon: '👨‍🦱',
    },
};

export const TicketLists = [
    {
        icon: CampaignIcon,
        ticketListcategorie: 'Campaign',
        ticketListContent:
            'Issue name comes here. It can be as long as 2 lines. Anything after that...',
        ticketListNumber: 2344565768,
    },
    {
        icon: BarterPrductIcon,
        ticketListcategorie: 'BarterPrduct',
        ticketListContent:
            'Issue name comes here. It can be as long as 2 lines. Anything after that...',
        ticketListNumber: 2344565768,
    },
    {
        icon: UploadIcon,
        ticketListcategorie: 'Campaign',
        ticketListContent:
            'Issue name comes here. It can be as long as 2 lines. Anything after that...',
        ticketListNumber: 2344565768,
    },
    {
        icon: RewardIcon,
        ticketListcategorie: 'Campaign',
        ticketListContent:
            'Issue name comes here. It can be as long as 2 lines. Anything after that...',
        ticketListNumber: 2344565768,
    },
];

export const DetailLists = [
    {
        question: 'What is a campaign?',
    },
    {
        question: 'How do we get campaigns?',
    },
    {
        question: 'Can we choose what campaigns we’ll get?',
    },
    {
        question: 'Can we get money in exchange of the barter products?',
    },
    {
        question: 'How do we get campaigns?',
    },
    {
        question: 'Can we choose what campaigns we’ll get?',
    },
];
