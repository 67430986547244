/**
 *
 * AddBankPopup
 *
 */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from 'utils/helper';

import {
    useAddBankSlice,
    addBankActions,
} from 'app/pages/HomePage/components/AddBankPopup/slice';
import { usePaymentPopupSlice } from 'app/pages/HomePage/components/PaymentsPopup/slice';
import { selectAddBank } from 'app/pages/HomePage/components/AddBankPopup/slice/selectors';
import { selectVerifyOTP } from 'app/pages/HomePage/components/OtpVerifyPopUp/slice/selectors';
import { selectPaymentPopup } from 'app/pages/HomePage/components/PaymentsPopup/slice/selectors';
import { selectCampaignDetails } from 'app/pages/HomePage/components/CampaignDetails/slice/selectors';
import { AddBankPopupProps } from 'app/pages/HomePage/components/AddBankPopup/types';

import Spinner from 'app/components/Spinner';
import { Alert } from 'app/components/Alert';
import { Popup } from 'app/components/Popup';
import { Button } from 'app/components/Button';
import PopupContent from 'app/components/PopupContent';
import Form, { FormField, FormLabel, Required } from 'app/components/Form';
import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import { selectUpdateOrDeletePaymentDetail } from 'app/pages/SignUpPage/slice/selectors';

export const AddBankPopup = memo((props: AddBankPopupProps) => {
    usePaymentPopupSlice();
    useAddBankSlice();
    const dispatch = useDispatch();

    const {
        onClose,
        profileId,
        groupId,
        setPayments,
        isUpdatingProfileSummary = false,
        prefilledBankDetails = {},
    } = props;

    const [bankDetails, setBankDetails] = useState({
        name: '',
        ifsc: '',
        number: '',
        confirmNumber: '',
    });

    const addBankStore = useSelector(selectAddBank);
    const verifyOTPStore = useSelector(selectVerifyOTP);
    const paymentPopupStore = useSelector(selectPaymentPopup);
    const campaignDetailsStore = useSelector(selectCampaignDetails);
    const updatePaymentDetailsStore = useSelector(
        selectUpdateOrDeletePaymentDetail,
    );

    const { loading, isPaymentDataAdded, isTokenValid } = addBankStore;
    const { campaignData } = campaignDetailsStore;
    const { paymentDetails } = paymentPopupStore;

    useEffect(() => {
        if (isPaymentDataAdded === 'yes') {
            onClose();
        }
        if (
            isTokenValid === 'no' &&
            verifyOTPStore &&
            !verifyOTPStore.isVerifySuccess
        ) {
            onClose('otp');
        }
    }, [isPaymentDataAdded, isTokenValid, verifyOTPStore]);

    useEffect(() => {
        if (
            prefilledBankDetails &&
            Object.keys(prefilledBankDetails).length !== 0
        ) {
            setBankDetails({
                ...bankDetails,
                name: prefilledBankDetails?.bank_account_name || '',
                ifsc: prefilledBankDetails?.bank_account_ifsc || '',
                number: prefilledBankDetails?.bank_account_number || '',
                confirmNumber: prefilledBankDetails?.bank_account_number || '',
            });
        }
    }, []);

    const onBankDetailsChange = ({ target: { name, value } }) => {
        setBankDetails({
            ...bankDetails,
            [name]: value,
        });
    };

    const dispatchAddBankDetails = () => {
        if (
            bankDetails.name &&
            bankDetails.ifsc &&
            bankDetails.number &&
            bankDetails.confirmNumber &&
            bankDetails.number === bankDetails.confirmNumber &&
            !loading
        ) {
            const dispatchData = {
                preferred_mode: 'bank',
                mode_type: 'update',
                bank_account_name: bankDetails.name,
                bank_account_ifsc: bankDetails.ifsc,
                bank_account_number: bankDetails.number,
            };

            if (!isUpdatingProfileSummary) {
                dispatchData['profile_id_hash'] = profileId;
                dispatchData['groupId'] = groupId;
                dispatchData['token'] = getCookie(
                    `qzc_token_${campaignData.id}`,
                );
            } else {
                dispatchData['token'] = localStorage.getItem('token');
                dispatchData['request_type'] = 'profile_update';
            }

            !isUpdatingProfileSummary
                ? dispatch(addBankActions.addBankDetails(dispatchData))
                : dispatch(
                      signUpPageActions.updateOrDeletePaymentDetail(
                          dispatchData,
                      ),
                  );
        }
    };

    const OnPopupClose = () => {
        onClose();
        setPayments(true);
    };

    return (
        <Popup onClose={OnPopupClose}>
            <PopupContent className="popup-content bank-account-popup">
                <h2>Add Bank Account</h2>
                <Form className="form-list">
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="accountHolder">
                            Account Holders Name
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="text"
                            placeholder="Who’s name is the bank account in?"
                            id="accountHolder"
                            name="name"
                            value={bankDetails.name}
                            onChange={onBankDetailsChange}
                        />
                    </FormField>
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="ifscCode">
                            IFSC Code
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="text"
                            placeholder="Eg. KKBK0001"
                            id="ifscCode"
                            name="ifsc"
                            value={bankDetails.ifsc}
                            onChange={onBankDetailsChange}
                        />
                    </FormField>
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="accountNumber">
                            Account Number
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="number"
                            min={0}
                            placeholder="Eg. 00874666272"
                            id="accountNumber"
                            name="number"
                            value={bankDetails.number}
                            onChange={onBankDetailsChange}
                        />
                    </FormField>
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="confirmAccountNumber">
                            Confirm Account Number
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="number"
                            min={0}
                            placeholder="Eg. 00874666272"
                            id="confirmAccountNumber"
                            name="confirmNumber"
                            value={bankDetails.confirmNumber}
                            onChange={onBankDetailsChange}
                        />
                    </FormField>
                    <Alert details={'Only 1 bank account can be added.'} />
                    <Button
                        className={`${
                            !bankDetails.name ||
                            !bankDetails.ifsc ||
                            !bankDetails.number ||
                            !bankDetails.confirmNumber ||
                            bankDetails.number !== bankDetails.confirmNumber
                                ? 'disabled'
                                : ''
                        }`}
                        onClick={e => {
                            e.preventDefault();
                            dispatchAddBankDetails();
                        }}
                    >
                        {loading || updatePaymentDetailsStore.loading ? (
                            <Spinner color="light" />
                        ) : (
                            'Add Bank Account'
                        )}
                    </Button>
                </Form>
            </PopupContent>
        </Popup>
    );
});
