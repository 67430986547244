import styled from 'styled-components';

const MessengerChatSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0px 12px;
    margin-top: 16px;
    .recived-message {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-end;
    }

    .message-date-pills {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f7f7f7;
        width: fit-content;
        margin: 0 auto;
        padding: 4px 8px;
        border-radius: 8px;

        p {
            margin: 0;
            font-size: 14px;
        }
    }
`;

const ShareButtonContents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    .button {
        height: 96px;
        display: flex;
        flex-direction: column;
        padding: 0px;
        gap: 8px;
        &.contact-list {
            height: auto !important;
        }
    }
    &.ShowSharedDetails {
        gap: 8px !important;
    }
`;

const ShowSharedDetails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 10px;
    gap: 8px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: -0.00666667em;
    color: #1f2933;
    img {
        width: 16px;
        height: 16px;
    }
`;
const DeliverablesCostView = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 12px;
    background: #ffffff;
    border-radius: 8px;
    gap: 20px;
    font-size: 14px;
    line-height: 149%;
    color: #1f2933;
    &.address {
        font-weight: 500;
        font-size: 13px;
        padding: 12px;
    }
    .deliverables-content {
        padding: 0px 12px 0px 8px;
        display: flex;
        gap: 16px;
        flex-direction: column;
    }
    .deliverables-platform {
        height: 21px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        .deliverables-type {
            display: flex;
            gap: 2px;
            .deliverables-icon {
                width: 16px;
                height: 16px;
            }
        }
        span {
            padding-left: 6px;
            color: #1f2933;
            font-weight: 700;
            font-size: 14px;
        }
    }
    .total-cost {
        height: 42px;
        padding: 8px 12px;
        background: #edf2fd;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        span {
            color: #1f2933;
            font-weight: 700;
            font-size: 14px;
        }
    }
`;

const BrandDetails = styled.div`
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    .user-img {
        height: 40px;
        width: 40px;
        cursor: pointer;
        object-fit: cover;
        border-radius: 50%;
    }
    .user-contents {
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: #1f2933;
        cursor: pointer;
        p {
            margin: 0;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #7b8794;
        }
    }
`;

const MessengerType = styled.div`
    height: 36px;
    background: #fffaeb;
    border-radius: 8px;
    text-align: center;
    padding: 8px;
    letter-spacing: -0.00466667em;
    color: #1f2933;
`;

const Button = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    letter-spacing: -0.00466667em;
    color: #de3a11;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    height: 44px;
    background: #ffffff;
    border-radius: 8px;
    cursor: pointer;
    gap: 4px;
    &.custom {
        cursor: default;
        padding: 8px 10px;
        gap: 8px;
        display: flex;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 13px;
        line-height: 24px;
        color: #1f2933;
        img {
            width: 16px;
        }
    }
`;

const MessageBoxContainer = styled.div<{
    height?: number;
}>`
    height: ${props => props.height}px;
    padding: 12px;
    width: 296px;
    background: #f5f7fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    span {
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        letter-spacing: -0.00466667em;
        color: #1f2933;
    }
    .time {
        display: flex;
        justify-content: end;
        align-items: center;
        font-size: 10px;
        line-height: 12px;
        color: #52606d;
        gap: 4px;
        svg path {
            fill: #0caad9;
        }
    }
    &.bgcolor {
        background: #fff3eb;
    }
`;

export {
    MessengerChatSection,
    DeliverablesCostView,
    MessengerType,
    ShareButtonContents,
    ShowSharedDetails,
    BrandDetails,
    Button,
    MessageBoxContainer,
};
