/**
 *
 * Alert
 *
 */
import React, { memo } from 'react';
import AlertStyled, { AlertDetails, AlertIcon } from './index.styled';

interface Props {
  details?: string;
  title?: string;
  icon?: any;
  children?: any;
  bgColor?: string;
  borderColor?: string;
}

export const Alert = memo((props: Props) => {
  const { icon, title, details, children, bgColor, borderColor } = props;
  return (
    <AlertStyled className="alert" bgColor={bgColor} borderColor={borderColor}>
      {icon && (
        <AlertIcon className="alert-icon">
          <img src={icon} alt="" />
        </AlertIcon>
      )}
      <AlertDetails className="alert-details">
        {title && <h4>{title}</h4>}
        {details && <span>{details}</span>}
        {children}
      </AlertDetails>
    </AlertStyled>
  );
});
