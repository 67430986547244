import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

interface ListSkeletonProps {
    flexDirection?: string;
    flexGap?: number;
    customPadding?: number;
}

interface ElementSkeletonProps {
    marginTop?: number;
    marginBottom?: number;
    marginLeft?: number;
    marginRight?: number;
    customStyles?: any;
}

export const StyledHtmlElementSkeleton = styled.div<ElementSkeletonProps>`
    & > span {
        display: flex;
        align-items: center;
    }
    br {
        display: none !important;
    }
`;
export const StyleHomepageCardSkeleton = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
`;

export const StyledListSkeleton = styled.ul<ListSkeletonProps>`
    padding: ${props =>
        props.customPadding ? `${props.customPadding}px` : '0px'} !important;

    & > span {
        padding: 0 !important;
        display: flex;
        flex-direction: ${(props: any) => props?.flexDirection || 'row'};
        gap: ${props =>
            props.flexGap ? `${props.flexGap}px` : '12px'} !important;
        flex-wrap: wrap;
    }
    br {
        display: none !important;
    }
`;

export const StyledAddressCardLoader = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 20px;

    .address-info-skeleton {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
`;

export const StyledMessengerSkeletonLoader = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 16px;
    margin-top: 4px;

    span {
        line-height: 0 !important;
    }

    .message-info-body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 10px;
    }

    & > span:last-child {
        margin-left: auto;
        align-self: flex-start;
    }
`;

export const StyledChatMessageSkeleton = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    span:nth-child(2) {
        align-self: flex-end;
    }
`;

export const StyledChatWindowHeaderSkeleton = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;

    span {
        line-height: 0 !important;
    }

    .sender-brief {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
    }

    .actions {
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 10px;
    }
`;

export const StyledCampaignDetailsPageSkeleton = styled.div`
    display: flex;
    gap: 20px;

    .accordion-skeleton {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
`;
