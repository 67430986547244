import InstagramReelsIcon from 'app/images/instagram-reels-icon.svg';
import InstagramImageIcon from 'app/images/instagram-post.svg';
import InstagramStoryIcon from 'app/images/instagram-story.svg';
import CameraIcon from 'app/images/redcamera-icon.svg';
import YoutubeIcon from 'app/images/youtube-round-icon.svg';
import FacebookIcon from 'app/images/facebook.svg';
import LinkedinIcon from 'app/images/linkedin.svg';
import TwitterIcon from 'app/images/twitter.svg';
import YoutubeVideoIcon from 'app/images/youtube-video.svg';
import YoutubeShortsIcon from 'app/images/youtube-shorts.svg';
import YoutubeVideoShareIcon from 'app/images/youtube-video-share.svg';

export const platformsList = {
    facebook_url: FacebookIcon,
    linkedin_url: LinkedinIcon,
    twitter_url: TwitterIcon,
    youtube_url: YoutubeIcon,
};

// deliverable icons list
export const deliverablesIconList = {
    instagram_images: CameraIcon,
    instagram_reels: InstagramReelsIcon,
    instagram_videos: InstagramReelsIcon,
    instagram_carousel: CameraIcon,
    instagram_story: InstagramStoryIcon,
    youtube_videos: YoutubeVideoIcon,
    youtube_shorts: YoutubeShortsIcon,
    facebook_posts: FacebookIcon,
    instagram_igtv: InstagramReelsIcon,
    twitter_tweet: TwitterIcon,
    twitter_text: TwitterIcon,
};

// campaign type list based on reason to connect

export const campaignTypeList = {
    get_cost_for_campaign: 'paid',
    barter_campaign_enquiry: 'barter',
    event_invitation: 'marketing',
    another_reason: 'marketing',
};

export const colorCodeList = [
    '#fb64b8',
    '#4dcde6',
    '#fdc935',
    '#b05bf7',
    '#fa8f3d',
    '#f26061',
    '#5eb974',
];
