import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noScroll } from 'utils/helper';

import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import {
    selectInfluencerProfileSummary,
    selectShowPopupStatus,
    selectUpdateOrDeletePaymentDetail,
} from 'app/pages/SignUpPage/slice/selectors';
import { selectAddUpi } from 'app/pages/HomePage/components/AddUPIPopup/slice/selectors';

import { AddUPIPopup } from 'app/pages/HomePage/components/AddUPIPopup';
import UPIIcon from 'app/images/upi-icon.svg';
import { PaymentTypes } from '../index.styled';
import { HtmlElementSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';

interface Props {
    prefilledUpiId?: string;
}

export const UPIPayment = memo((props: Props) => {
    const { prefilledUpiId } = props;
    const dispatch = useDispatch();

    const addUpiStore = useSelector(selectAddUpi);
    const updatePaymentDetailsStore = useSelector(
        selectUpdateOrDeletePaymentDetail,
    );
    const isShowingPopup = useSelector(selectShowPopupStatus);
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);

    const { isPaymentDataAdded } = addUpiStore;
    const {
        isUpdateOrDeletePaymentDetailSuccess,
        loading: paymentDetailUpdateLoading,
        currentlyUpdatingPayDetail,
    } = updatePaymentDetailsStore;
    const { loading: profileSummaryLoading } = profileSummarySelector;

    const [addUPIPopup, setAddUPIPopup] = useState(false);

    useEffect(() => {
        noScroll(addUPIPopup);
    }, [addUPIPopup]);

    const handleDeleteUpiDetails = () => {
        dispatch(
            signUpPageActions.updateOrDeletePaymentDetail({
                mode_type: 'delete',
                preferred_mode: 'upi',
                token: localStorage.getItem('token'),
                request_type: 'profile_update',
            }),
        );
    };

    const handleShowUPIInfoPopup = (showBool: boolean = false) => {
        setAddUPIPopup(showBool);
        dispatch(signUpPageActions.setIsShowPopup(showBool));
    };

    useEffect(() => {
        if (prefilledUpiId) {
            if (
                !updatePaymentDetailsStore.loading &&
                isUpdateOrDeletePaymentDetailSuccess
            ) {
                handleShowUPIInfoPopup(false);
            }
        } else {
            if (!addUpiStore.loading && isPaymentDataAdded) {
                handleShowUPIInfoPopup(false);
            }
        }
    }, [addUpiStore, updatePaymentDetailsStore]);

    return (
        <>
            <PaymentTypes>
                <div className="payment-type">
                    <img src={UPIIcon} alt="upi-icon" />
                    <div className="account-details">
                        <h6>UPI ID</h6>
                        {profileSummaryLoading ||
                        (paymentDetailUpdateLoading &&
                            currentlyUpdatingPayDetail === 'upi') ? (
                            <HtmlElementSkeleton
                                width={100}
                                height={10}
                                customStyles={{
                                    marginLeft: 'auto',
                                }}
                            />
                        ) : (
                            prefilledUpiId && (
                                <span className="">{prefilledUpiId}</span>
                            )
                        )}
                    </div>
                </div>

                <div className="buttons">
                    {profileSummaryLoading ? (
                        <HtmlElementSkeleton
                            width={60}
                            height={15}
                            customStyles={{
                                marginTop: '12px',
                            }}
                        />
                    ) : prefilledUpiId ? (
                        <>
                            <span
                                onClick={() => {
                                    handleShowUPIInfoPopup(true);
                                    dispatch(
                                        signUpPageActions.resetPaymentDetailUpdateSuccess(),
                                    );
                                }}
                            >
                                Edit
                            </span>
                            <span onClick={() => handleDeleteUpiDetails()}>
                                Delete
                            </span>
                        </>
                    ) : (
                        <span
                            onClick={() => {
                                handleShowUPIInfoPopup(true);
                            }}
                        >
                            + Link UPI ID
                        </span>
                    )}
                </div>
            </PaymentTypes>

            {addUPIPopup && isShowingPopup && (
                <AddUPIPopup
                    onClose={() => handleShowUPIInfoPopup(false)}
                    profileId={''}
                    groupId={''}
                    setPayments={() => {}}
                    isUpdatingProfileSummary={true}
                    prefilledUpiId={prefilledUpiId}
                />
            )}
        </>
    );
});
