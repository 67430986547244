import styled from 'styled-components';
const AboutDetails = styled.div`
    height: 336px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px 14px 20px;
    span {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.008em;
        color: #1f2933;
    }
`;

const ContactUserProfile = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    .user-page {
        padding: 8px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        background: #fff3eb;
        border-radius: 8px;
        .user-details {
            display: flex;
            gap: 8px;
            h4 {
                margin: 0;
                font-weight: 600;
                font-size: 14px;
                line-height: 140%;
                color: #1f2933;
            }
            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;
                color: #3e4c59;
            }
        }
        .user-img {
            width: 40px;
            height: 40px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.04));
            border-radius: 48px;
        }
        img {
            width: 28px;
        }
    }
    .user-linkedin-icon {
        cursor: pointer;
    }
`;
const ContactDetails = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.008em;
    color: #7b8794;
    display: flex;
    flex-direction: column;
    gap: 8px;
    ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        list-style: none;
        margin: 0;
        padding: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #1f2933;

        a {
            text-decoration: none;
            color: #1f2933;
        }
        img {
            width: 16px;
            margin-right: 8px;
        }
    }
`;
export { ContactUserProfile, AboutDetails, ContactDetails };
