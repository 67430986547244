import React, { memo } from 'react';
import styled from 'styled-components';

interface Props {
    headerText: any;
}
const PopUpHeader = (props: Props) => {
    const { headerText } = props;
    return (
        <HeaderSection>
            <HeaderText>{headerText}</HeaderText>
        </HeaderSection>
    );
};

export default PopUpHeader;

const HeaderSection = styled.div`
    font-style: normal;
`;

const HeaderText = styled.div`
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: -0.008em;
    color: #1f2933;
`;

const SubHeaderText = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: -0.00666667em;
    color: #7b8794;
`;
