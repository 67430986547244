import styled from 'styled-components';

const AddressStyled = styled.div`
    padding: 0px 12px;
    &.customstyle {
        padding: 0;
    }
    .custom {
        font-weight: 600;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: -0.008em;
        color: #1f2933;
    }
    .header-style {
        padding-left: 4px;
        img {
            cursor: pointer;
        }
    }

    .add-address {
        height: 57px;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #f35627;
        gap: 8px;
        padding-left: 4px;
        img {
            cursor: pointer;
        }

        span {
            cursor: pointer;
        }
    }
    .new-add-address {
        margin-top: 10px;
    }

    .address-header {
        padding-top: 32px;
        padding-left: 4px;

        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            color: #3e4c59;
        }

        p.prompt-message {
            margin: 12px 0 !important;
        }
    }
`;

const SavedAddressSection = styled.div`
    padding-top: 28px;
    display: flex;
    gap: 16px;
    align-items: flex-start;
    cursor: pointer;
    .address-body {
        display: flex;
        flex-direction: column;
        height: 100px;
        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: -0.008em;
            color: #1f2933;
            margin-bottom: 8px;
        }

        p {
            width: fit-content;
            white-space: normal;
        }
        @media screen and (max-width: 680px) {
            p {
                display: inline-block;
                width: 296px;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
            }
        }

        .button {
            display: flex;
            gap: 16px;
            span {
                font-weight: 600;
                font-size: 12px;
                line-height: 24px;
                color: #3956c6;
                margin: 0 !important;
                cursor: pointer;
            }
        }
    }
`;

export { AddressStyled, SavedAddressSection };
