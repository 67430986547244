import { useState } from 'react';

export const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className="text">
            {isReadMore ? text.slice(0, 212) : text}
            {text.length > 212 ? (
                <span onClick={toggleReadMore} className="read-or-hide">
                    {isReadMore ? '...Read more' : ' Show less'}
                </span>
            ) : (
                ''
            )}
        </p>
    );
};
