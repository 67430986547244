import React, { memo } from 'react';
import { HeaderScrollAnimation } from './index.styled';
import BellIcon from 'app/images/bell.svg';
import { ProfileImageSkeleton } from '../TabMenu/CustomLoaderSkeletons/SkeletonLoaders';
import DefaultProfileImg from 'app/images/placeholder-user-icon.svg';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';
interface Props {
    icon?: any;
    tabcontentname?: any;
}
export const TabHeader = memo((props: Props) => {
    const history = useHistory();
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const { profileSummary = {}, loading: profileSummaryLoading } =
        profileSummarySelector;

    return (
        <HeaderScrollAnimation>
            <div className="header">
                {props.icon ? (
                    <img src={props.icon} alt="logo" className="logo" />
                ) : (
                    <span>{props.tabcontentname}</span>
                )}

                <div className="influencer-profile">
                    {/* <img src={BellIcon} alt="bell" /> */}
                    <span
                        onClick={() => {
                            history.push('/profile');
                        }}
                    >
                        {profileSummaryLoading ? (
                            <ProfileImageSkeleton imgWidth={30} />
                        ) : (
                            <img
                                src={
                                    profileSummary?.profile_image ||
                                    DefaultProfileImg
                                }
                                onError={e => {
                                    const imgEl = e.target as HTMLImageElement;
                                    imgEl.src = DefaultProfileImg;
                                }}
                                className="creator-profile-image"
                                alt="creator-profile-img"
                            />
                        )}
                    </span>
                </div>
            </div>
        </HeaderScrollAnimation>
    );
});
