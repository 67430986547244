import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import {
    signUpPageActions,
    useSignUpPageSlice,
} from 'app/pages/SignUpPage/slice';
import {
    selectAdditionalInfluencerInfo,
    selectInfluencerCampaigns,
    selectInfluencerProfileSummary,
} from 'app/pages/SignUpPage/slice/selectors';

import { TabList } from '../TabMenu';
import AppWrapper from 'app/components/AppWrapper';
import { PromptMessage } from '../TabMenu/CommonComponents/PromptMessage';
import { CreatorCampaignCard } from '../TabMenu/CommonComponents/CampaignCard';
import { AddInformationList } from './components/UserProfileCompletionWidgets';

import { additionalStepsData } from '../label';
import QoruzLogo from 'app/images/qoruzLogo.svg';
import { CampaignStyle, EmptyCampaignPage } from './index.styled';
import { CustomAppWrapper } from '../index.styled';
import { TabHeader } from '../Header';
import CampaignEmptyPage from 'app/images/campaign.svg';
import { Button } from 'react-bootstrap';
import UserIcon from 'app/images/SVGComponents/UserIcon';
import { TextButton } from 'app/components/TextButton';
import { colors } from 'theme';
interface Props {}

export const CampaignDetails = memo((props: Props) => {
    useSignUpPageSlice();
    const dispatch = useDispatch();
    const history = useHistory();

    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const { profileSummary } = profileSummarySelector;
    const influencerCampaignsSelector = useSelector(selectInfluencerCampaigns);
    const additionalInfluencerInfoSelector = useSelector(
        selectAdditionalInfluencerInfo,
    );

    const { allCampaigns: allCampaignsList = [], loading } =
        influencerCampaignsSelector;

    const { additionalInfo: { additional_info_details = [] } = {} } =
        additionalInfluencerInfoSelector;

    const incompleteAdditionalSteps =
        additional_info_details && additional_info_details?.length !== 0
            ? additional_info_details.filter((info: any) => !info.is_completed)
            : [];

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(
                signUpPageActions.getInfluencerCampaigns({
                    token: localStorage.getItem('token'),
                }),
            );
        } else {
            history.push('/signup');
        }
    }, []);

    return (
        <CustomAppWrapper>
            <AppWrapper className="custom-wrapper">
                <TabList profile_id={profileSummary?.profile_id} />
                <TabHeader tabcontentname="Campaigns" />
                <CampaignStyle>
                    <div className="campaign-list">
                        {/* {additionalInfluencerInfoSelector.loading
                            ? 'loading...'
                            : incompleteAdditionalSteps?.length !== 0 &&
                              incompleteAdditionalSteps.map(
                                  (addStep: any, index: number) => (
                                      <AddInformationList
                                          key={addStep?.id || index}
                                          additionalStepData={
                                              additionalStepsData[
                                                  addStep?.info_name
                                              ]
                                          }
                                      />
                                  ),
                              )} */}

                        {/* <BarterCampaign /> */}
                        {loading ? (
                            <Skeleton
                                count={2}
                                height={220}
                                borderRadius={8}
                                style={{
                                    marginBottom: '10px',
                                }}
                            />
                        ) : allCampaignsList &&
                          allCampaignsList.length !== 0 ? (
                            allCampaignsList.map(
                                (campaignDetail: any, index: number) => (
                                    <CreatorCampaignCard
                                        key={campaignDetail?.id || index}
                                        campaignInfo={campaignDetail}
                                        styledClassName="campaign-page-card"
                                    />
                                ),
                            )
                        ) : (
                            <EmptyCampaignPage>
                                <img src={CampaignEmptyPage} alt="icon" />

                                <div className="campaign-content">
                                    <h4>No Campaigns </h4>
                                    <span>
                                        Campaigns appear here when you’ve been
                                        invited by a brand for collaboration.
                                    </span>
                                    <span>
                                        Keep your profile updated to increase
                                        your chances!
                                    </span>
                                    <TextButton
                                        onClick={() => {
                                            history.push('/profile');
                                        }}
                                        color="#FFFFFF"
                                    >
                                        <UserIcon />
                                        Update Profile
                                    </TextButton>
                                </div>
                            </EmptyCampaignPage>
                        )}
                    </div>
                </CampaignStyle>
            </AppWrapper>
        </CustomAppWrapper>
    );
});
