import React, { memo } from 'react';
import {
    OrganizationIntroTextV1_1,
    OrganizationIntroTextV1_2,
    DeliverableBriefV1,
    InvitationActionV1,
    SystemNotifyCard,
    InviteDeclineText,
    InviteAcceptText,
    ContactActionV1,
    ProfilContactDetailsV1,
    AddressActionV1,
    ProfileAddressDetails,
    CompletionCard,
    CostActionV1,
    ProfileCostDetailsV1,
} from './DefaultSendMessage/index';

export const getTemplateName = message => {
    return message && message.template_name;
};

//get next message template is there or not
const isNextTemplateName = (messagesList: any, currMessage: any) => {
    if (messagesList?.length && currMessage) {
        const currMessageIdx = messagesList.findIndex(
            message => message.id === currMessage.id,
        );

        if (
            currMessageIdx &&
            currMessageIdx >= 0 &&
            currMessageIdx < messagesList.length - 1
        ) {
            const nextTemplate =
                messagesList?.length && messagesList[currMessageIdx + 1]
                    ? true
                    : false;
            return nextTemplate;
        }
    }
};

export const getMessageTemplate = (
    message: any,
    templateName: any,
    messagesList: any,
    renderDeliverablesList: any,
) => {
    switch (templateName) {
        case 'organization_intro_text_v1_1':
            return <OrganizationIntroTextV1_1 message={message} />;
        case 'organization_intro_text_v1_2':
            return <OrganizationIntroTextV1_2 message={message} />;
        case 'deliverable_brief_v1':
            return (
                <DeliverableBriefV1
                    message={message}
                    renderDeliverablesList={renderDeliverablesList}
                />
            );
        case 'invitation_action_v1':
            if (!isNextTemplateName(messagesList, message)) {
                return <InvitationActionV1 message={message} />;
            }
            break;

        // case 'notification_influencer_accepted_request_v1':
        //     return <SystemNotifyCard message={message} />;
        // case 'notification_influencer_declined_request_v1':
        //     return <SystemNotifyCard message={message} />;

        case 'profile_invite_accepted_text_v1_1':
            if (message?.participant_type === 'admin') {
                return (
                    <>
                        <SystemNotifyCard
                            message={'🤩 Accepted by qoruz support'}
                        />
                        <InviteAcceptText message={message} />
                    </>
                );
            } else return <InviteAcceptText message={message} />;

        case 'profile_invite_declined_text_v1_1':
            if (message?.participant_type === 'admin') {
                return (
                    <>
                        <SystemNotifyCard
                            message={'🤐 Decline by qoruz support'}
                        />
                        <InviteDeclineText message={message} />
                    </>
                );
            } else return <InviteDeclineText message={message} />;

        case 'contact_action_v1':
            if (!isNextTemplateName(messagesList, message)) {
                return <ContactActionV1 message={message} />;
            }
            break;
        case 'profile_contact_details_v1':
            return <ProfilContactDetailsV1 message={message} />;

        case 'profile_contact_declined_text_v1_1':
            return <InviteDeclineText message={message} />;
        case 'address_action_v1':
            if (!isNextTemplateName(messagesList, message)) {
                return <AddressActionV1 message={message} />;
            }
            break;
        case 'profile_address_details_v1':
            return <ProfileAddressDetails message={message} />;
        case 'profile_address_declined_text_v1_1':
            return <InviteDeclineText message={message} />;
        case 'cost_action_v1':
            if (!isNextTemplateName(messagesList, message)) {
                return (
                    <CostActionV1
                        message={message}
                        renderDeliverablesList={renderDeliverablesList}
                    />
                );
            }
            break;
        case 'profile_cost_details_v1':
            return (
                <ProfileCostDetailsV1
                    message={message}
                    renderDeliverablesList={renderDeliverablesList}
                />
            );
        case 'profile_cost_declined_text_v1_1':
            return <InviteDeclineText message={message} />;
        case 'thank_you_after_sharing_text_v1_1':
            return <CompletionCard message={message} />;
        default:
            return null;
    }
};
