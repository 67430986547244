export const baseCountryCodeData = [
    { country: 'India', code: 91 },
    { country: 'Canada', code: 1 },
    { country: 'United States', code: 1 },
    { country: 'Kazakhstan', code: 7 },
    { country: 'Russia', code: 7 },
    { country: 'Egypt', code: 20 },
    { country: 'South Africa', code: 27 },
    { country: 'Greece', code: 30 },
    { country: 'Netherlands', code: 31 },
    { country: 'Belgium', code: 32 },
    { country: 'France', code: 33 },
    { country: 'Spain', code: 34 },
    { country: 'Hungary', code: 36 },
    { country: 'Italy', code: 39 },
    { country: 'Romania', code: 40 },
    { country: 'Switzerland', code: 41 },
    { country: 'Austria', code: 43 },
    { country: 'United Kingdom', code: 44 },
    { country: 'Denmark', code: 45 },
    { country: 'Sweden', code: 46 },
    { country: 'Norway', code: 47 },
    { country: 'Svalbard and Jan Mayen', code: 47 },
    { country: 'Poland', code: 48 },
    { country: 'Germany', code: 49 },
    { country: 'Peru', code: 51 },
    { country: 'Mexico', code: 52 },
    { country: 'Cuba', code: 53 },
    { country: 'Argentina', code: 54 },
    { country: 'Brazil', code: 55 },
    { country: 'Chile', code: 56 },
    { country: 'Colombia', code: 57 },
    { country: 'Venezuela', code: 58 },
    { country: 'Malaysia', code: 60 },
    { country: 'Australia', code: 61 },
    { country: 'Christmas Island', code: 61 },
    { country: 'Cocos Islands', code: 61 },
    { country: 'Indonesia', code: 62 },
    { country: 'Philippines', code: 63 },
    { country: 'New Zealand', code: 64 },
    { country: 'Pitcairn', code: 64 },
    { country: 'Singapore', code: 65 },
    { country: 'Thailand', code: 66 },
    { country: 'Japan', code: 81 },
    { country: 'South Korea', code: 82 },
    { country: 'Vietnam', code: 84 },
    { country: 'China', code: 86 },
    { country: 'Turkey', code: 90 },
    { country: 'Pakistan', code: 92 },
    { country: 'Afghanistan', code: 93 },
    { country: 'Sri Lanka', code: 94 },
    { country: 'Myanmar', code: 95 },
    { country: 'Iran', code: 98 },
    { country: 'South Sudan', code: 211 },
    { country: 'Morocco', code: 212 },
    { country: 'Western Sahara', code: 212 },
    { country: 'Algeria', code: 213 },
    { country: 'Tunisia', code: 216 },
    { country: 'Libya', code: 218 },
    { country: 'Gambia', code: 220 },
    { country: 'Senegal', code: 221 },
    { country: 'Mauritania', code: 222 },
    { country: 'Mali', code: 223 },
    { country: 'Guinea', code: 224 },
    { country: 'Ivory Coast', code: 225 },
    { country: 'Burkina Faso', code: 226 },
    { country: 'Niger', code: 227 },
    { country: 'Togo', code: 228 },
    { country: 'Benin', code: 229 },
    { country: 'Mauritius', code: 230 },
    { country: 'Liberia', code: 231 },
    { country: 'Sierra Leone', code: 232 },
    { country: 'Ghana', code: 233 },
    { country: 'Nigeria', code: 234 },
    { country: 'Chad', code: 235 },
    { country: 'Central African Republic', code: 236 },
    { country: 'Cameroon', code: 237 },
    { country: 'Cape Verde', code: 238 },
    { country: 'Sao Tome and Principe', code: 239 },
    { country: 'Equatorial Guinea', code: 240 },
    { country: 'Gabon', code: 241 },
    { country: 'Republic of the Congo', code: 242 },
    { country: 'Democratic Republic of the Congo', code: 243 },
    { country: 'Angola', code: 244 },
    { country: 'Guinea-Bissau', code: 245 },
    { country: 'British Indian Ocean Territory', code: 246 },
    { country: 'Seychelles', code: 248 },
    { country: 'Sudan', code: 249 },
    { country: 'Rwanda', code: 250 },
    { country: 'Ethiopia', code: 251 },
    { country: 'Somalia', code: 252 },
    { country: 'Djibouti', code: 253 },
    { country: 'Kenya', code: 254 },
    { country: 'Tanzania', code: 255 },
    { country: 'Uganda', code: 256 },
    { country: 'Burundi', code: 257 },
    { country: 'Mozambique', code: 258 },
    { country: 'Zambia', code: 260 },
    { country: 'Madagascar', code: 261 },
    { country: 'Mayotte', code: 262 },
    { country: 'Reunion', code: 262 },
    { country: 'Zimbabwe', code: 263 },
    { country: 'Namibia', code: 264 },
    { country: 'Malawi', code: 265 },
    { country: 'Lesotho', code: 266 },
    { country: 'Botswana', code: 267 },
    { country: 'Swaziland', code: 268 },
    { country: 'Comoros', code: 269 },
    { country: 'Saint Helena', code: 290 },
    { country: 'Eritrea', code: 291 },
    { country: 'Aruba', code: 297 },
    { country: 'Faroe Islands', code: 298 },
    { country: 'Greenland', code: 299 },
    { country: 'Gibraltar', code: 350 },
    { country: 'Portugal', code: 351 },
    { country: 'Luxembourg', code: 352 },
    { country: 'Ireland', code: 353 },
    { country: 'Iceland', code: 354 },
    { country: 'Albania', code: 355 },
    { country: 'Malta', code: 356 },
    { country: 'Cyprus', code: 357 },
    { country: 'Finland', code: 358 },
    { country: 'Bulgaria', code: 359 },
    { country: 'Lithuania', code: 370 },
    { country: 'Latvia', code: 371 },
    { country: 'Estonia', code: 372 },
    { country: 'Moldova', code: 373 },
    { country: 'Armenia', code: 374 },
    { country: 'Belarus', code: 375 },
    { country: 'Andorra', code: 376 },
    { country: 'Monaco', code: 377 },
    { country: 'San Marino', code: 378 },
    { country: 'Vatican', code: 379 },
    { country: 'Ukraine', code: 380 },
    { country: 'Serbia', code: 381 },
    { country: 'Montenegro', code: 382 },
    { country: 'Kosovo', code: 383 },
    { country: 'Croatia', code: 385 },
    { country: 'Slovenia', code: 386 },
    { country: 'Bosnia and Herzegovina', code: 387 },
    { country: 'Macedonia', code: 389 },
    { country: 'Czech Republic', code: 420 },
    { country: 'Slovakia', code: 421 },
    { country: 'Liechtenstein', code: 423 },
    { country: 'Falkland Islands', code: 500 },
    { country: 'Belize', code: 501 },
    { country: 'Guatemala', code: 502 },
    { country: 'El Salvador', code: 503 },
    { country: 'Honduras', code: 504 },
    { country: 'Nicaragua', code: 505 },
    { country: 'Costa Rica', code: 506 },
    { country: 'Panama', code: 507 },
    { country: 'Saint Pierre and Miquelon', code: 508 },
    { country: 'Haiti', code: 509 },
    { country: 'Saint Barthelemy', code: 590 },
    { country: 'Saint Martin', code: 590 },
    { country: 'Bolivia', code: 591 },
    { country: 'Guyana', code: 592 },
    { country: 'Ecuador', code: 593 },
    { country: 'Paraguay', code: 595 },
    { country: 'Suriname', code: 597 },
    { country: 'Uruguay', code: 598 },
    { country: 'Curacao', code: 599 },
    { country: 'Netherlands Antilles', code: 599 },
    { country: 'East Timor', code: 670 },
    { country: 'Antarctica', code: 672 },
    { country: 'Brunei', code: 673 },
    { country: 'Nauru', code: 674 },
    { country: 'Papua New Guinea', code: 675 },
    { country: 'Tonga', code: 676 },
    { country: 'Solomon Islands', code: 677 },
    { country: 'Vanuatu', code: 678 },
    { country: 'Fiji', code: 679 },
    { country: 'Palau', code: 680 },
    { country: 'Wallis and Futuna', code: 681 },
    { country: 'Cook Islands', code: 682 },
    { country: 'Niue', code: 683 },
    { country: 'Samoa', code: 685 },
    { country: 'Kiribati', code: 686 },
    { country: 'New Caledonia', code: 687 },
    { country: 'Tuvalu', code: 688 },
    { country: 'French Polynesia', code: 689 },
    { country: 'Tokelau', code: 690 },
    { country: 'Micronesia', code: 691 },
    { country: 'Marshall Islands', code: 692 },
    { country: 'North Korea', code: 850 },
    { country: 'Hong Kong', code: 852 },
    { country: 'Macau', code: 853 },
    { country: 'Cambodia', code: 855 },
    { country: 'Laos', code: 856 },
    { country: 'Bangladesh', code: 880 },
    { country: 'Taiwan', code: 886 },
    { country: 'Maldives', code: 960 },
    { country: 'Lebanon', code: 961 },
    { country: 'Jordan', code: 962 },
    { country: 'Syria', code: 963 },
    { country: 'Iraq', code: 964 },
    { country: 'Kuwait', code: 965 },
    { country: 'Saudi Arabia', code: 966 },
    { country: 'Yemen', code: 967 },
    { country: 'Oman', code: 968 },
    { country: 'Palestine', code: 970 },
    { country: 'United Arab Emirates', code: 971 },
    { country: 'Israel', code: 972 },
    { country: 'Bahrain', code: 973 },
    { country: 'Qatar', code: 974 },
    { country: 'Bhutan', code: 975 },
    { country: 'Mongolia', code: 976 },
    { country: 'Nepal', code: 977 },
    { country: 'Tajikistan', code: 992 },
    { country: 'Turkmenistan', code: 993 },
    { country: 'Azerbaijan', code: 994 },
    { country: 'Georgia', code: 995 },
    { country: 'Kyrgyzstan', code: 996 },
    { country: 'Uzbekistan', code: 998 },
];

export const baseCountryCodesList = [
    {
        countryCodeValue: '+91',
        rawCountryCode: 91,
        countryName: 'India',
        countryFlag: '🇮🇳',
        countryAlphaTwoCode: 'IN',
    },
    {
        countryCodeValue: '+1',
        rawCountryCode: 1,
        countryName: 'Canada',
        countryFlag: '🇨🇦',
        countryAlphaTwoCode: 'CA',
    },
    {
        countryCodeValue: '+1',
        rawCountryCode: 1,
        countryName: 'United States',
        countryFlag: '🇺🇸',
        countryAlphaTwoCode: 'US',
    },
    {
        countryCodeValue: '+1242',
        rawCountryCode: 1242,
        countryName: 'Bahamas',
        countryFlag: '🇧🇸',
        countryAlphaTwoCode: 'BS',
    },
    {
        countryCodeValue: '+1246',
        rawCountryCode: 1246,
        countryName: 'Barbados',
        countryFlag: '🇧🇧',
        countryAlphaTwoCode: 'BB',
    },
    {
        countryCodeValue: '+1264',
        rawCountryCode: 1264,
        countryName: 'Anguilla',
        countryFlag: '🇦🇮',
        countryAlphaTwoCode: 'AI',
    },
    {
        countryCodeValue: '+1268',
        rawCountryCode: 1268,
        countryName: 'Antigua and Barbuda',
        countryFlag: '🇦🇬',
        countryAlphaTwoCode: 'AG',
    },
    {
        countryCodeValue: '+1284',
        rawCountryCode: 1284,
        countryName: 'Virgin Islands, British',
        countryFlag: '🇻🇬',
        countryAlphaTwoCode: 'VG',
    },
    {
        countryCodeValue: '+1340',
        rawCountryCode: 1340,
        countryName: 'Virgin Islands, U.S.',
        countryFlag: '🇻🇮',
        countryAlphaTwoCode: 'VI',
    },
    {
        countryCodeValue: '+1441',
        rawCountryCode: 1441,
        countryName: 'Bermuda',
        countryFlag: '🇧🇲',
        countryAlphaTwoCode: 'BM',
    },
    {
        countryCodeValue: '+1473',
        rawCountryCode: 1473,
        countryName: 'Grenada',
        countryFlag: '🇬🇩',
        countryAlphaTwoCode: 'GD',
    },
    {
        countryCodeValue: '+1649',
        rawCountryCode: 1649,
        countryName: 'Turks and Caicos Islands',
        countryFlag: '🇹🇨',
        countryAlphaTwoCode: 'TC',
    },
    {
        countryCodeValue: '+1664',
        rawCountryCode: 1664,
        countryName: 'Montserrat',
        countryFlag: '🇲🇸',
        countryAlphaTwoCode: 'MS',
    },
    {
        countryCodeValue: '+1670',
        rawCountryCode: 1670,
        countryName: 'Northern Mariana Islands',
        countryFlag: '🇲🇵',
        countryAlphaTwoCode: 'MP',
    },
    {
        countryCodeValue: '+1671',
        rawCountryCode: 1671,
        countryName: 'Guam',
        countryFlag: '🇬🇺',
        countryAlphaTwoCode: 'GU',
    },
    {
        countryCodeValue: '+1684',
        rawCountryCode: 1684,
        countryName: 'American Samoa',
        countryFlag: '🇦🇸',
        countryAlphaTwoCode: 'AS',
    },
    {
        countryCodeValue: '+1758',
        rawCountryCode: 1758,
        countryName: 'Saint Lucia',
        countryFlag: '🇱🇨',
        countryAlphaTwoCode: 'LC',
    },
    {
        countryCodeValue: '+1767',
        rawCountryCode: 1767,
        countryName: 'Dominica',
        countryFlag: '🇩🇲',
        countryAlphaTwoCode: 'DM',
    },
    {
        countryCodeValue: '+1784',
        rawCountryCode: 1784,
        countryName: 'Saint Vincent and the Grenadines',
        countryFlag: '🇻🇨',
        countryAlphaTwoCode: 'VC',
    },
    {
        countryCodeValue: '+1849',
        rawCountryCode: 1849,
        countryName: 'Dominican Republic',
        countryFlag: '🇩🇴',
        countryAlphaTwoCode: 'DO',
    },
    {
        countryCodeValue: '+1868',
        rawCountryCode: 1868,
        countryName: 'Trinidad and Tobago',
        countryFlag: '🇹🇹',
        countryAlphaTwoCode: 'TT',
    },
    {
        countryCodeValue: '+1869',
        rawCountryCode: 1869,
        countryName: 'Saint Kitts and Nevis',
        countryFlag: '🇰🇳',
        countryAlphaTwoCode: 'KN',
    },
    {
        countryCodeValue: '+1876',
        rawCountryCode: 1876,
        countryName: 'Jamaica',
        countryFlag: '🇯🇲',
        countryAlphaTwoCode: 'JM',
    },
    {
        countryCodeValue: '+1939',
        rawCountryCode: 1939,
        countryName: 'Puerto Rico',
        countryFlag: '🇵🇷',
        countryAlphaTwoCode: 'PR',
    },
    {
        countryCodeValue: '+20',
        rawCountryCode: 20,
        countryName: 'Egypt',
        countryFlag: '🇪🇬',
        countryAlphaTwoCode: 'EG',
    },
    {
        countryCodeValue: '+211',
        rawCountryCode: 211,
        countryName: 'South Sudan',
        countryFlag: '🇸🇸',
        countryAlphaTwoCode: 'SS',
    },
    {
        countryCodeValue: '+212',
        rawCountryCode: 212,
        countryName: 'Morocco',
        countryFlag: '🇲🇦',
        countryAlphaTwoCode: 'MA',
    },
    {
        countryCodeValue: '+213',
        rawCountryCode: 213,
        countryName: 'Algeria',
        countryFlag: '🇩🇿',
        countryAlphaTwoCode: 'DZ',
    },
    {
        countryCodeValue: '+216',
        rawCountryCode: 216,
        countryName: 'Tunisia',
        countryFlag: '🇹🇳',
        countryAlphaTwoCode: 'TN',
    },
    {
        countryCodeValue: '+218',
        rawCountryCode: 218,
        countryName: 'Libyan Arab Jamahiriya',
        countryFlag: '🇱🇾',
        countryAlphaTwoCode: 'LY',
    },
    {
        countryCodeValue: '+220',
        rawCountryCode: 220,
        countryName: 'Gambia',
        countryFlag: '🇬🇲',
        countryAlphaTwoCode: 'GM',
    },
    {
        countryCodeValue: '+221',
        rawCountryCode: 221,
        countryName: 'Senegal',
        countryFlag: '🇸🇳',
        countryAlphaTwoCode: 'SN',
    },
    {
        countryCodeValue: '+222',
        rawCountryCode: 222,
        countryName: 'Mauritania',
        countryFlag: '🇲🇷',
        countryAlphaTwoCode: 'MR',
    },
    {
        countryCodeValue: '+223',
        rawCountryCode: 223,
        countryName: 'Mali',
        countryFlag: '🇲🇱',
        countryAlphaTwoCode: 'ML',
    },
    {
        countryCodeValue: '+224',
        rawCountryCode: 224,
        countryName: 'Guinea',
        countryFlag: '🇬🇳',
        countryAlphaTwoCode: 'GN',
    },
    {
        countryCodeValue: '+225',
        rawCountryCode: 225,
        countryName: "Cote d'Ivoire",
        countryFlag: '🇨🇮',
        countryAlphaTwoCode: 'CI',
    },
    {
        countryCodeValue: '+226',
        rawCountryCode: 226,
        countryName: 'Burkina Faso',
        countryFlag: '🇧🇫',
        countryAlphaTwoCode: 'BF',
    },
    {
        countryCodeValue: '+227',
        rawCountryCode: 227,
        countryName: 'Niger',
        countryFlag: '🇳🇪',
        countryAlphaTwoCode: 'NE',
    },
    {
        countryCodeValue: '+228',
        rawCountryCode: 228,
        countryName: 'Togo',
        countryFlag: '🇹🇬',
        countryAlphaTwoCode: 'TG',
    },
    {
        countryCodeValue: '+229',
        rawCountryCode: 229,
        countryName: 'Benin',
        countryFlag: '🇧🇯',
        countryAlphaTwoCode: 'BJ',
    },
    {
        countryCodeValue: '+230',
        rawCountryCode: 230,
        countryName: 'Mauritius',
        countryFlag: '🇲🇺',
        countryAlphaTwoCode: 'MU',
    },
    {
        countryCodeValue: '+231',
        rawCountryCode: 231,
        countryName: 'Liberia',
        countryFlag: '🇱🇷',
        countryAlphaTwoCode: 'LR',
    },
    {
        countryCodeValue: '+232',
        rawCountryCode: 232,
        countryName: 'Sierra Leone',
        countryFlag: '🇸🇱',
        countryAlphaTwoCode: 'SL',
    },
    {
        countryCodeValue: '+233',
        rawCountryCode: 233,
        countryName: 'Ghana',
        countryFlag: '🇬🇭',
        countryAlphaTwoCode: 'GH',
    },
    {
        countryCodeValue: '+234',
        rawCountryCode: 234,
        countryName: 'Nigeria',
        countryFlag: '🇳🇬',
        countryAlphaTwoCode: 'NG',
    },
    {
        countryCodeValue: '+235',
        rawCountryCode: 235,
        countryName: 'Chad',
        countryFlag: '🇹🇩',
        countryAlphaTwoCode: 'TD',
    },
    {
        countryCodeValue: '+236',
        rawCountryCode: 236,
        countryName: 'Central African Republic',
        countryFlag: '🇨🇫',
        countryAlphaTwoCode: 'CF',
    },
    {
        countryCodeValue: '+237',
        rawCountryCode: 237,
        countryName: 'Cameroon',
        countryFlag: '🇨🇲',
        countryAlphaTwoCode: 'CM',
    },
    {
        countryCodeValue: '+238',
        rawCountryCode: 238,
        countryName: 'Cape Verde',
        countryFlag: '🇨🇻',
        countryAlphaTwoCode: 'CV',
    },
    {
        countryCodeValue: '+239',
        rawCountryCode: 239,
        countryName: 'Sao Tome and Principe',
        countryFlag: '🇸🇹',
        countryAlphaTwoCode: 'ST',
    },
    {
        countryCodeValue: '+240',
        rawCountryCode: 240,
        countryName: 'Equatorial Guinea',
        countryFlag: '🇬🇶',
        countryAlphaTwoCode: 'GQ',
    },
    {
        countryCodeValue: '+241',
        rawCountryCode: 241,
        countryName: 'Gabon',
        countryFlag: '🇬🇦',
        countryAlphaTwoCode: 'GA',
    },
    {
        countryCodeValue: '+242',
        rawCountryCode: 242,
        countryName: 'Congo',
        countryFlag: '🇨🇬',
        countryAlphaTwoCode: 'CG',
    },
    {
        countryCodeValue: '+243',
        rawCountryCode: 243,
        countryName: 'Congo, The Democratic Republic of the Congo',
        countryFlag: '🇨🇩',
        countryAlphaTwoCode: 'CD',
    },
    {
        countryCodeValue: '+244',
        rawCountryCode: 244,
        countryName: 'Angola',
        countryFlag: '🇦🇴',
        countryAlphaTwoCode: 'AO',
    },
    {
        countryCodeValue: '+245',
        rawCountryCode: 245,
        countryName: 'Guinea-Bissau',
        countryFlag: '🇬🇼',
        countryAlphaTwoCode: 'GW',
    },
    {
        countryCodeValue: '+246',
        rawCountryCode: 246,
        countryName: 'British Indian Ocean Territory',
        countryFlag: '🇮🇴',
        countryAlphaTwoCode: 'IO',
    },
    {
        countryCodeValue: '+248',
        rawCountryCode: 248,
        countryName: 'Seychelles',
        countryFlag: '🇸🇨',
        countryAlphaTwoCode: 'SC',
    },
    {
        countryCodeValue: '+249',
        rawCountryCode: 249,
        countryName: 'Sudan',
        countryFlag: '🇸🇩',
        countryAlphaTwoCode: 'SD',
    },
    {
        countryCodeValue: '+250',
        rawCountryCode: 250,
        countryName: 'Rwanda',
        countryFlag: '🇷🇼',
        countryAlphaTwoCode: 'RW',
    },
    {
        countryCodeValue: '+251',
        rawCountryCode: 251,
        countryName: 'Ethiopia',
        countryFlag: '🇪🇹',
        countryAlphaTwoCode: 'ET',
    },
    {
        countryCodeValue: '+252',
        rawCountryCode: 252,
        countryName: 'Somalia',
        countryFlag: '🇸🇴',
        countryAlphaTwoCode: 'SO',
    },
    {
        countryCodeValue: '+253',
        rawCountryCode: 253,
        countryName: 'Djibouti',
        countryFlag: '🇩🇯',
        countryAlphaTwoCode: 'DJ',
    },
    {
        countryCodeValue: '+254',
        rawCountryCode: 254,
        countryName: 'Kenya',
        countryFlag: '🇰🇪',
        countryAlphaTwoCode: 'KE',
    },
    {
        countryCodeValue: '+255',
        rawCountryCode: 255,
        countryName: 'Tanzania, United Republic of Tanzania',
        countryFlag: '🇹🇿',
        countryAlphaTwoCode: 'TZ',
    },
    {
        countryCodeValue: '+256',
        rawCountryCode: 256,
        countryName: 'Uganda',
        countryFlag: '🇺🇬',
        countryAlphaTwoCode: 'UG',
    },
    {
        countryCodeValue: '+257',
        rawCountryCode: 257,
        countryName: 'Burundi',
        countryFlag: '🇧🇮',
        countryAlphaTwoCode: 'BI',
    },
    {
        countryCodeValue: '+258',
        rawCountryCode: 258,
        countryName: 'Mozambique',
        countryFlag: '🇲🇿',
        countryAlphaTwoCode: 'MZ',
    },
    {
        countryCodeValue: '+260',
        rawCountryCode: 260,
        countryName: 'Zambia',
        countryFlag: '🇿🇲',
        countryAlphaTwoCode: 'ZM',
    },
    {
        countryCodeValue: '+261',
        rawCountryCode: 261,
        countryName: 'Madagascar',
        countryFlag: '🇲🇬',
        countryAlphaTwoCode: 'MG',
    },
    {
        countryCodeValue: '+262',
        rawCountryCode: 262,
        countryName: 'French Southern Territories',
        countryFlag: '🇹🇫',
        countryAlphaTwoCode: 'TF',
    },
    {
        countryCodeValue: '+262',
        rawCountryCode: 262,
        countryName: 'Mayotte',
        countryFlag: '🇾🇹',
        countryAlphaTwoCode: 'YT',
    },
    {
        countryCodeValue: '+262',
        rawCountryCode: 262,
        countryName: 'Reunion',
        countryFlag: '🇷🇪',
        countryAlphaTwoCode: 'RE',
    },
    {
        countryCodeValue: '+263',
        rawCountryCode: 263,
        countryName: 'Zimbabwe',
        countryFlag: '🇿🇼',
        countryAlphaTwoCode: 'ZW',
    },
    {
        countryCodeValue: '+264',
        rawCountryCode: 264,
        countryName: 'Namibia',
        countryFlag: '🇳🇦',
        countryAlphaTwoCode: 'NA',
    },
    {
        countryCodeValue: '+265',
        rawCountryCode: 265,
        countryName: 'Malawi',
        countryFlag: '🇲🇼',
        countryAlphaTwoCode: 'MW',
    },
    {
        countryCodeValue: '+266',
        rawCountryCode: 266,
        countryName: 'Lesotho',
        countryFlag: '🇱🇸',
        countryAlphaTwoCode: 'LS',
    },
    {
        countryCodeValue: '+267',
        rawCountryCode: 267,
        countryName: 'Botswana',
        countryFlag: '🇧🇼',
        countryAlphaTwoCode: 'BW',
    },
    {
        countryCodeValue: '+268',
        rawCountryCode: 268,
        countryName: 'Swaziland',
        countryFlag: '🇸🇿',
        countryAlphaTwoCode: 'SZ',
    },
    {
        countryCodeValue: '+269',
        rawCountryCode: 269,
        countryName: 'Comoros',
        countryFlag: '🇰🇲',
        countryAlphaTwoCode: 'KM',
    },
    {
        countryCodeValue: '+27',
        rawCountryCode: 27,
        countryName: 'South Africa',
        countryFlag: '🇿🇦',
        countryAlphaTwoCode: 'ZA',
    },
    {
        countryCodeValue: '+290',
        rawCountryCode: 290,
        countryName: 'Saint Helena, Ascension and Tristan Da Cunha',
        countryFlag: '🇸🇭',
        countryAlphaTwoCode: 'SH',
    },
    {
        countryCodeValue: '+291',
        rawCountryCode: 291,
        countryName: 'Eritrea',
        countryFlag: '🇪🇷',
        countryAlphaTwoCode: 'ER',
    },
    {
        countryCodeValue: '+297',
        rawCountryCode: 297,
        countryName: 'Aruba',
        countryFlag: '🇦🇼',
        countryAlphaTwoCode: 'AW',
    },
    {
        countryCodeValue: '+298',
        rawCountryCode: 298,
        countryName: 'Faroe Islands',
        countryFlag: '🇫🇴',
        countryAlphaTwoCode: 'FO',
    },
    {
        countryCodeValue: '+299',
        rawCountryCode: 299,
        countryName: 'Greenland',
        countryFlag: '🇬🇱',
        countryAlphaTwoCode: 'GL',
    },
    {
        countryCodeValue: '+30',
        rawCountryCode: 30,
        countryName: 'Greece',
        countryFlag: '🇬🇷',
        countryAlphaTwoCode: 'GR',
    },
    {
        countryCodeValue: '+31',
        rawCountryCode: 31,
        countryName: 'Netherlands',
        countryFlag: '🇳🇱',
        countryAlphaTwoCode: 'NL',
    },
    {
        countryCodeValue: '+32',
        rawCountryCode: 32,
        countryName: 'Belgium',
        countryFlag: '🇧🇪',
        countryAlphaTwoCode: 'BE',
    },
    {
        countryCodeValue: '+33',
        rawCountryCode: 33,
        countryName: 'France',
        countryFlag: '🇫🇷',
        countryAlphaTwoCode: 'FR',
    },
    {
        countryCodeValue: '+34',
        rawCountryCode: 34,
        countryName: 'Spain',
        countryFlag: '🇪🇸',
        countryAlphaTwoCode: 'ES',
    },
    {
        countryCodeValue: '+345',
        rawCountryCode: 345,
        countryName: 'Cayman Islands',
        countryFlag: '🇰🇾',
        countryAlphaTwoCode: 'KY',
    },
    {
        countryCodeValue: '+350',
        rawCountryCode: 350,
        countryName: 'Gibraltar',
        countryFlag: '🇬🇮',
        countryAlphaTwoCode: 'GI',
    },
    {
        countryCodeValue: '+351',
        rawCountryCode: 351,
        countryName: 'Portugal',
        countryFlag: '🇵🇹',
        countryAlphaTwoCode: 'PT',
    },
    {
        countryCodeValue: '+352',
        rawCountryCode: 352,
        countryName: 'Luxembourg',
        countryFlag: '🇱🇺',
        countryAlphaTwoCode: 'LU',
    },
    {
        countryCodeValue: '+353',
        rawCountryCode: 353,
        countryName: 'Ireland',
        countryFlag: '🇮🇪',
        countryAlphaTwoCode: 'IE',
    },
    {
        countryCodeValue: '+354',
        rawCountryCode: 354,
        countryName: 'Iceland',
        countryFlag: '🇮🇸',
        countryAlphaTwoCode: 'IS',
    },
    {
        countryCodeValue: '+355',
        rawCountryCode: 355,
        countryName: 'Albania',
        countryFlag: '🇦🇱',
        countryAlphaTwoCode: 'AL',
    },
    {
        countryCodeValue: '+356',
        rawCountryCode: 356,
        countryName: 'Malta',
        countryFlag: '🇲🇹',
        countryAlphaTwoCode: 'MT',
    },
    {
        countryCodeValue: '+357',
        rawCountryCode: 357,
        countryName: 'Cyprus',
        countryFlag: '🇨🇾',
        countryAlphaTwoCode: 'CY',
    },
    {
        countryCodeValue: '+358',
        rawCountryCode: 358,
        countryName: 'Åland Islands',
        countryFlag: '🇦🇽',
        countryAlphaTwoCode: 'AX',
    },
    {
        countryCodeValue: '+358',
        rawCountryCode: 358,
        countryName: 'Finland',
        countryFlag: '🇫🇮',
        countryAlphaTwoCode: 'FI',
    },
    {
        countryCodeValue: '+359',
        rawCountryCode: 359,
        countryName: 'Bulgaria',
        countryFlag: '🇧🇬',
        countryAlphaTwoCode: 'BG',
    },
    {
        countryCodeValue: '+36',
        rawCountryCode: 36,
        countryName: 'Hungary',
        countryFlag: '🇭🇺',
        countryAlphaTwoCode: 'HU',
    },
    {
        countryCodeValue: '+370',
        rawCountryCode: 370,
        countryName: 'Lithuania',
        countryFlag: '🇱🇹',
        countryAlphaTwoCode: 'LT',
    },
    {
        countryCodeValue: '+371',
        rawCountryCode: 371,
        countryName: 'Latvia',
        countryFlag: '🇱🇻',
        countryAlphaTwoCode: 'LV',
    },
    {
        countryCodeValue: '+372',
        rawCountryCode: 372,
        countryName: 'Estonia',
        countryFlag: '🇪🇪',
        countryAlphaTwoCode: 'EE',
    },
    {
        countryCodeValue: '+373',
        rawCountryCode: 373,
        countryName: 'Moldova',
        countryFlag: '🇲🇩',
        countryAlphaTwoCode: 'MD',
    },
    {
        countryCodeValue: '+374',
        rawCountryCode: 374,
        countryName: 'Armenia',
        countryFlag: '🇦🇲',
        countryAlphaTwoCode: 'AM',
    },
    {
        countryCodeValue: '+375',
        rawCountryCode: 375,
        countryName: 'Belarus',
        countryFlag: '🇧🇾',
        countryAlphaTwoCode: 'BY',
    },
    {
        countryCodeValue: '+376',
        rawCountryCode: 376,
        countryName: 'Andorra',
        countryFlag: '🇦🇩',
        countryAlphaTwoCode: 'AD',
    },
    {
        countryCodeValue: '+377',
        rawCountryCode: 377,
        countryName: 'Monaco',
        countryFlag: '🇲🇨',
        countryAlphaTwoCode: 'MC',
    },
    {
        countryCodeValue: '+378',
        rawCountryCode: 378,
        countryName: 'San Marino',
        countryFlag: '🇸🇲',
        countryAlphaTwoCode: 'SM',
    },
    {
        countryCodeValue: '+379',
        rawCountryCode: 379,
        countryName: 'Holy See (Vatican City State)',
        countryFlag: '🇻🇦',
        countryAlphaTwoCode: 'VA',
    },
    {
        countryCodeValue: '+380',
        rawCountryCode: 380,
        countryName: 'Ukraine',
        countryFlag: '🇺🇦',
        countryAlphaTwoCode: 'UA',
    },
    {
        countryCodeValue: '+381',
        rawCountryCode: 381,
        countryName: 'Serbia',
        countryFlag: '🇷🇸',
        countryAlphaTwoCode: 'RS',
    },
    {
        countryCodeValue: '+382',
        rawCountryCode: 382,
        countryName: 'Montenegro',
        countryFlag: '🇲🇪',
        countryAlphaTwoCode: 'ME',
    },
    {
        countryCodeValue: '+383',
        rawCountryCode: 383,
        countryName: 'Kosovo',
        countryFlag: '🇽🇰',
        countryAlphaTwoCode: 'XK',
    },
    {
        countryCodeValue: '+385',
        rawCountryCode: 385,
        countryName: 'Croatia',
        countryFlag: '🇭🇷',
        countryAlphaTwoCode: 'HR',
    },
    {
        countryCodeValue: '+386',
        rawCountryCode: 386,
        countryName: 'Slovenia',
        countryFlag: '🇸🇮',
        countryAlphaTwoCode: 'SI',
    },
    {
        countryCodeValue: '+387',
        rawCountryCode: 387,
        countryName: 'Bosnia and Herzegovina',
        countryFlag: '🇧🇦',
        countryAlphaTwoCode: 'BA',
    },
    {
        countryCodeValue: '+389',
        rawCountryCode: 389,
        countryName: 'North Macedonia',
        countryFlag: '🇲🇰',
        countryAlphaTwoCode: 'MK',
    },
    {
        countryCodeValue: '+39',
        rawCountryCode: 39,
        countryName: 'Italy',
        countryFlag: '🇮🇹',
        countryAlphaTwoCode: 'IT',
    },
    {
        countryCodeValue: '+40',
        rawCountryCode: 40,
        countryName: 'Romania',
        countryFlag: '🇷🇴',
        countryAlphaTwoCode: 'RO',
    },
    {
        countryCodeValue: '+41',
        rawCountryCode: 41,
        countryName: 'Switzerland',
        countryFlag: '🇨🇭',
        countryAlphaTwoCode: 'CH',
    },
    {
        countryCodeValue: '+420',
        rawCountryCode: 420,
        countryName: 'Czech Republic',
        countryFlag: '🇨🇿',
        countryAlphaTwoCode: 'CZ',
    },
    {
        countryCodeValue: '+421',
        rawCountryCode: 421,
        countryName: 'Slovakia',
        countryFlag: '🇸🇰',
        countryAlphaTwoCode: 'SK',
    },
    {
        countryCodeValue: '+423',
        rawCountryCode: 423,
        countryName: 'Liechtenstein',
        countryFlag: '🇱🇮',
        countryAlphaTwoCode: 'LI',
    },
    {
        countryCodeValue: '+43',
        rawCountryCode: 43,
        countryName: 'Austria',
        countryFlag: '🇦🇹',
        countryAlphaTwoCode: 'AT',
    },
    {
        countryCodeValue: '+44',
        rawCountryCode: 44,
        countryName: 'Guernsey',
        countryFlag: '🇬🇬',
        countryAlphaTwoCode: 'GG',
    },
    {
        countryCodeValue: '+44',
        rawCountryCode: 44,
        countryName: 'Isle of Man',
        countryFlag: '🇮🇲',
        countryAlphaTwoCode: 'IM',
    },
    {
        countryCodeValue: '+44',
        rawCountryCode: 44,
        countryName: 'Jersey',
        countryFlag: '🇯🇪',
        countryAlphaTwoCode: 'JE',
    },
    {
        countryCodeValue: '+44',
        rawCountryCode: 44,
        countryName: 'United Kingdom',
        countryFlag: '🇬🇧',
        countryAlphaTwoCode: 'GB',
    },
    {
        countryCodeValue: '+45',
        rawCountryCode: 45,
        countryName: 'Denmark',
        countryFlag: '🇩🇰',
        countryAlphaTwoCode: 'DK',
    },
    {
        countryCodeValue: '+46',
        rawCountryCode: 46,
        countryName: 'Sweden',
        countryFlag: '🇸🇪',
        countryAlphaTwoCode: 'SE',
    },
    {
        countryCodeValue: '+47',
        rawCountryCode: 47,
        countryName: 'Bouvet Island',
        countryFlag: '🇧🇻',
        countryAlphaTwoCode: 'BV',
    },
    {
        countryCodeValue: '+47',
        rawCountryCode: 47,
        countryName: 'Norway',
        countryFlag: '🇳🇴',
        countryAlphaTwoCode: 'NO',
    },
    {
        countryCodeValue: '+47',
        rawCountryCode: 47,
        countryName: 'Svalbard and Jan Mayen',
        countryFlag: '🇸🇯',
        countryAlphaTwoCode: 'SJ',
    },
    {
        countryCodeValue: '+48',
        rawCountryCode: 48,
        countryName: 'Poland',
        countryFlag: '🇵🇱',
        countryAlphaTwoCode: 'PL',
    },
    {
        countryCodeValue: '+49',
        rawCountryCode: 49,
        countryName: 'Germany',
        countryFlag: '🇩🇪',
        countryAlphaTwoCode: 'DE',
    },
    {
        countryCodeValue: '+500',
        rawCountryCode: 500,
        countryName: 'Falkland Islands (Malvinas)',
        countryFlag: '🇫🇰',
        countryAlphaTwoCode: 'FK',
    },
    {
        countryCodeValue: '+500',
        rawCountryCode: 500,
        countryName: 'South Georgia and the South Sandwich Islands',
        countryFlag: '🇬🇸',
        countryAlphaTwoCode: 'GS',
    },
    {
        countryCodeValue: '+501',
        rawCountryCode: 501,
        countryName: 'Belize',
        countryFlag: '🇧🇿',
        countryAlphaTwoCode: 'BZ',
    },
    {
        countryCodeValue: '+502',
        rawCountryCode: 502,
        countryName: 'Guatemala',
        countryFlag: '🇬🇹',
        countryAlphaTwoCode: 'GT',
    },
    {
        countryCodeValue: '+503',
        rawCountryCode: 503,
        countryName: 'El Salvador',
        countryFlag: '🇸🇻',
        countryAlphaTwoCode: 'SV',
    },
    {
        countryCodeValue: '+504',
        rawCountryCode: 504,
        countryName: 'Honduras',
        countryFlag: '🇭🇳',
        countryAlphaTwoCode: 'HN',
    },
    {
        countryCodeValue: '+505',
        rawCountryCode: 505,
        countryName: 'Nicaragua',
        countryFlag: '🇳🇮',
        countryAlphaTwoCode: 'NI',
    },
    {
        countryCodeValue: '+506',
        rawCountryCode: 506,
        countryName: 'Costa Rica',
        countryFlag: '🇨🇷',
        countryAlphaTwoCode: 'CR',
    },
    {
        countryCodeValue: '+507',
        rawCountryCode: 507,
        countryName: 'Panama',
        countryFlag: '🇵🇦',
        countryAlphaTwoCode: 'PA',
    },
    {
        countryCodeValue: '+508',
        rawCountryCode: 508,
        countryName: 'Saint Pierre and Miquelon',
        countryFlag: '🇵🇲',
        countryAlphaTwoCode: 'PM',
    },
    {
        countryCodeValue: '+509',
        rawCountryCode: 509,
        countryName: 'Haiti',
        countryFlag: '🇭🇹',
        countryAlphaTwoCode: 'HT',
    },
    {
        countryCodeValue: '+51',
        rawCountryCode: 51,
        countryName: 'Peru',
        countryFlag: '🇵🇪',
        countryAlphaTwoCode: 'PE',
    },
    {
        countryCodeValue: '+52',
        rawCountryCode: 52,
        countryName: 'Mexico',
        countryFlag: '🇲🇽',
        countryAlphaTwoCode: 'MX',
    },
    {
        countryCodeValue: '+53',
        rawCountryCode: 53,
        countryName: 'Cuba',
        countryFlag: '🇨🇺',
        countryAlphaTwoCode: 'CU',
    },
    {
        countryCodeValue: '+54',
        rawCountryCode: 54,
        countryName: 'Argentina',
        countryFlag: '🇦🇷',
        countryAlphaTwoCode: 'AR',
    },
    {
        countryCodeValue: '+55',
        rawCountryCode: 55,
        countryName: 'Brazil',
        countryFlag: '🇧🇷',
        countryAlphaTwoCode: 'BR',
    },
    {
        countryCodeValue: '+56',
        rawCountryCode: 56,
        countryName: 'Chile',
        countryFlag: '🇨🇱',
        countryAlphaTwoCode: 'CL',
    },
    {
        countryCodeValue: '+57',
        rawCountryCode: 57,
        countryName: 'Colombia',
        countryFlag: '🇨🇴',
        countryAlphaTwoCode: 'CO',
    },
    {
        countryCodeValue: '+58',
        rawCountryCode: 58,
        countryName: 'Venezuela, Bolivarian Republic of Venezuela',
        countryFlag: '🇻🇪',
        countryAlphaTwoCode: 'VE',
    },
    {
        countryCodeValue: '+590',
        rawCountryCode: 590,
        countryName: 'Guadeloupe',
        countryFlag: '🇬🇵',
        countryAlphaTwoCode: 'GP',
    },
    {
        countryCodeValue: '+590',
        rawCountryCode: 590,
        countryName: 'Saint Barthelemy',
        countryFlag: '🇧🇱',
        countryAlphaTwoCode: 'BL',
    },
    {
        countryCodeValue: '+590',
        rawCountryCode: 590,
        countryName: 'Saint Martin',
        countryFlag: '🇲🇫',
        countryAlphaTwoCode: 'MF',
    },
    {
        countryCodeValue: '+591',
        rawCountryCode: 591,
        countryName: 'Bolivia, Plurinational State of bolivia',
        countryFlag: '🇧🇴',
        countryAlphaTwoCode: 'BO',
    },
    {
        countryCodeValue: '+592',
        rawCountryCode: 592,
        countryName: 'Guyana',
        countryFlag: '🇬🇾',
        countryAlphaTwoCode: 'GY',
    },
    {
        countryCodeValue: '+593',
        rawCountryCode: 593,
        countryName: 'Ecuador',
        countryFlag: '🇪🇨',
        countryAlphaTwoCode: 'EC',
    },
    {
        countryCodeValue: '+594',
        rawCountryCode: 594,
        countryName: 'French Guiana',
        countryFlag: '🇬🇫',
        countryAlphaTwoCode: 'GF',
    },
    {
        countryCodeValue: '+595',
        rawCountryCode: 595,
        countryName: 'Paraguay',
        countryFlag: '🇵🇾',
        countryAlphaTwoCode: 'PY',
    },
    {
        countryCodeValue: '+596',
        rawCountryCode: 596,
        countryName: 'Martinique',
        countryFlag: '🇲🇶',
        countryAlphaTwoCode: 'MQ',
    },
    {
        countryCodeValue: '+597',
        rawCountryCode: 597,
        countryName: 'Suriname',
        countryFlag: '🇸🇷',
        countryAlphaTwoCode: 'SR',
    },
    {
        countryCodeValue: '+598',
        rawCountryCode: 598,
        countryName: 'Uruguay',
        countryFlag: '🇺🇾',
        countryAlphaTwoCode: 'UY',
    },
    {
        countryCodeValue: '+599',
        rawCountryCode: 599,
        countryName: 'Netherlands Antilles',
        countryFlag: '',
        countryAlphaTwoCode: 'AN',
    },
    {
        countryCodeValue: '+60',
        rawCountryCode: 60,
        countryName: 'Malaysia',
        countryFlag: '🇲🇾',
        countryAlphaTwoCode: 'MY',
    },
    {
        countryCodeValue: '+61',
        rawCountryCode: 61,
        countryName: 'Australia',
        countryFlag: '🇦🇺',
        countryAlphaTwoCode: 'AU',
    },
    {
        countryCodeValue: '+61',
        rawCountryCode: 61,
        countryName: 'Christmas Island',
        countryFlag: '🇨🇽',
        countryAlphaTwoCode: 'CX',
    },
    {
        countryCodeValue: '+61',
        rawCountryCode: 61,
        countryName: 'Cocos (Keeling) Islands',
        countryFlag: '🇨🇨',
        countryAlphaTwoCode: 'CC',
    },
    {
        countryCodeValue: '+62',
        rawCountryCode: 62,
        countryName: 'Indonesia',
        countryFlag: '🇮🇩',
        countryAlphaTwoCode: 'ID',
    },
    {
        countryCodeValue: '+63',
        rawCountryCode: 63,
        countryName: 'Philippines',
        countryFlag: '🇵🇭',
        countryAlphaTwoCode: 'PH',
    },
    {
        countryCodeValue: '+64',
        rawCountryCode: 64,
        countryName: 'New Zealand',
        countryFlag: '🇳🇿',
        countryAlphaTwoCode: 'NZ',
    },
    {
        countryCodeValue: '+64',
        rawCountryCode: 64,
        countryName: 'Pitcairn',
        countryFlag: '🇵🇳',
        countryAlphaTwoCode: 'PN',
    },
    {
        countryCodeValue: '+65',
        rawCountryCode: 65,
        countryName: 'Singapore',
        countryFlag: '🇸🇬',
        countryAlphaTwoCode: 'SG',
    },
    {
        countryCodeValue: '+66',
        rawCountryCode: 66,
        countryName: 'Thailand',
        countryFlag: '🇹🇭',
        countryAlphaTwoCode: 'TH',
    },
    {
        countryCodeValue: '+670',
        rawCountryCode: 670,
        countryName: 'Timor-Leste',
        countryFlag: '🇹🇱',
        countryAlphaTwoCode: 'TL',
    },
    {
        countryCodeValue: '+672',
        rawCountryCode: 672,
        countryName: 'Antarctica',
        countryFlag: '🇦🇶',
        countryAlphaTwoCode: 'AQ',
    },
    {
        countryCodeValue: '+672',
        rawCountryCode: 672,
        countryName: 'Heard Island and Mcdonald Islands',
        countryFlag: '🇭🇲',
        countryAlphaTwoCode: 'HM',
    },
    {
        countryCodeValue: '+672',
        rawCountryCode: 672,
        countryName: 'Norfolk Island',
        countryFlag: '🇳🇫',
        countryAlphaTwoCode: 'NF',
    },
    {
        countryCodeValue: '+673',
        rawCountryCode: 673,
        countryName: 'Brunei Darussalam',
        countryFlag: '🇧🇳',
        countryAlphaTwoCode: 'BN',
    },
    {
        countryCodeValue: '+674',
        rawCountryCode: 674,
        countryName: 'Nauru',
        countryFlag: '🇳🇷',
        countryAlphaTwoCode: 'NR',
    },
    {
        countryCodeValue: '+675',
        rawCountryCode: 675,
        countryName: 'Papua New Guinea',
        countryFlag: '🇵🇬',
        countryAlphaTwoCode: 'PG',
    },
    {
        countryCodeValue: '+676',
        rawCountryCode: 676,
        countryName: 'Tonga',
        countryFlag: '🇹🇴',
        countryAlphaTwoCode: 'TO',
    },
    {
        countryCodeValue: '+677',
        rawCountryCode: 677,
        countryName: 'Solomon Islands',
        countryFlag: '🇸🇧',
        countryAlphaTwoCode: 'SB',
    },
    {
        countryCodeValue: '+678',
        rawCountryCode: 678,
        countryName: 'Vanuatu',
        countryFlag: '🇻🇺',
        countryAlphaTwoCode: 'VU',
    },
    {
        countryCodeValue: '+679',
        rawCountryCode: 679,
        countryName: 'Fiji',
        countryFlag: '🇫🇯',
        countryAlphaTwoCode: 'FJ',
    },
    {
        countryCodeValue: '+680',
        rawCountryCode: 680,
        countryName: 'Palau',
        countryFlag: '🇵🇼',
        countryAlphaTwoCode: 'PW',
    },
    {
        countryCodeValue: '+681',
        rawCountryCode: 681,
        countryName: 'Wallis and Futuna',
        countryFlag: '🇼🇫',
        countryAlphaTwoCode: 'WF',
    },
    {
        countryCodeValue: '+682',
        rawCountryCode: 682,
        countryName: 'Cook Islands',
        countryFlag: '🇨🇰',
        countryAlphaTwoCode: 'CK',
    },
    {
        countryCodeValue: '+683',
        rawCountryCode: 683,
        countryName: 'Niue',
        countryFlag: '🇳🇺',
        countryAlphaTwoCode: 'NU',
    },
    {
        countryCodeValue: '+685',
        rawCountryCode: 685,
        countryName: 'Samoa',
        countryFlag: '🇼🇸',
        countryAlphaTwoCode: 'WS',
    },
    {
        countryCodeValue: '+686',
        rawCountryCode: 686,
        countryName: 'Kiribati',
        countryFlag: '🇰🇮',
        countryAlphaTwoCode: 'KI',
    },
    {
        countryCodeValue: '+687',
        rawCountryCode: 687,
        countryName: 'New Caledonia',
        countryFlag: '🇳🇨',
        countryAlphaTwoCode: 'NC',
    },
    {
        countryCodeValue: '+688',
        rawCountryCode: 688,
        countryName: 'Tuvalu',
        countryFlag: '🇹🇻',
        countryAlphaTwoCode: 'TV',
    },
    {
        countryCodeValue: '+689',
        rawCountryCode: 689,
        countryName: 'French Polynesia',
        countryFlag: '🇵🇫',
        countryAlphaTwoCode: 'PF',
    },
    {
        countryCodeValue: '+690',
        rawCountryCode: 690,
        countryName: 'Tokelau',
        countryFlag: '🇹🇰',
        countryAlphaTwoCode: 'TK',
    },
    {
        countryCodeValue: '+691',
        rawCountryCode: 691,
        countryName: 'Micronesia, Federated States of Micronesia',
        countryFlag: '🇫🇲',
        countryAlphaTwoCode: 'FM',
    },
    {
        countryCodeValue: '+692',
        rawCountryCode: 692,
        countryName: 'Marshall Islands',
        countryFlag: '🇲🇭',
        countryAlphaTwoCode: 'MH',
    },
    {
        countryCodeValue: '+7',
        rawCountryCode: 7,
        countryName: 'Kazakhstan',
        countryFlag: '🇰🇿',
        countryAlphaTwoCode: 'KZ',
    },
    {
        countryCodeValue: '+7',
        rawCountryCode: 7,
        countryName: 'Russia',
        countryFlag: '🇷🇺',
        countryAlphaTwoCode: 'RU',
    },
    {
        countryCodeValue: '+81',
        rawCountryCode: 81,
        countryName: 'Japan',
        countryFlag: '🇯🇵',
        countryAlphaTwoCode: 'JP',
    },
    {
        countryCodeValue: '+82',
        rawCountryCode: 82,
        countryName: 'Korea, Republic of South Korea',
        countryFlag: '🇰🇷',
        countryAlphaTwoCode: 'KR',
    },
    {
        countryCodeValue: '+84',
        rawCountryCode: 84,
        countryName: 'Vietnam',
        countryFlag: '🇻🇳',
        countryAlphaTwoCode: 'VN',
    },
    {
        countryCodeValue: '+850',
        rawCountryCode: 850,
        countryName: "Korea, Democratic People's Republic of Korea",
        countryFlag: '🇰🇵',
        countryAlphaTwoCode: 'KP',
    },
    {
        countryCodeValue: '+852',
        rawCountryCode: 852,
        countryName: 'Hong Kong',
        countryFlag: '🇭🇰',
        countryAlphaTwoCode: 'HK',
    },
    {
        countryCodeValue: '+853',
        rawCountryCode: 853,
        countryName: 'Macao',
        countryFlag: '🇲🇴',
        countryAlphaTwoCode: 'MO',
    },
    {
        countryCodeValue: '+855',
        rawCountryCode: 855,
        countryName: 'Cambodia',
        countryFlag: '🇰🇭',
        countryAlphaTwoCode: 'KH',
    },
    {
        countryCodeValue: '+856',
        rawCountryCode: 856,
        countryName: 'Laos',
        countryFlag: '🇱🇦',
        countryAlphaTwoCode: 'LA',
    },
    {
        countryCodeValue: '+86',
        rawCountryCode: 86,
        countryName: 'China',
        countryFlag: '🇨🇳',
        countryAlphaTwoCode: 'CN',
    },
    {
        countryCodeValue: '+880',
        rawCountryCode: 880,
        countryName: 'Bangladesh',
        countryFlag: '🇧🇩',
        countryAlphaTwoCode: 'BD',
    },
    {
        countryCodeValue: '+886',
        rawCountryCode: 886,
        countryName: 'Taiwan',
        countryFlag: '🇹🇼',
        countryAlphaTwoCode: 'TW',
    },
    {
        countryCodeValue: '+90',
        rawCountryCode: 90,
        countryName: 'Türkiye',
        countryFlag: '🇹🇷',
        countryAlphaTwoCode: 'TR',
    },
    {
        countryCodeValue: '+92',
        rawCountryCode: 92,
        countryName: 'Pakistan',
        countryFlag: '🇵🇰',
        countryAlphaTwoCode: 'PK',
    },
    {
        countryCodeValue: '+93',
        rawCountryCode: 93,
        countryName: 'Afghanistan',
        countryFlag: '🇦🇫',
        countryAlphaTwoCode: 'AF',
    },
    {
        countryCodeValue: '+94',
        rawCountryCode: 94,
        countryName: 'Sri Lanka',
        countryFlag: '🇱🇰',
        countryAlphaTwoCode: 'LK',
    },
    {
        countryCodeValue: '+95',
        rawCountryCode: 95,
        countryName: 'Myanmar',
        countryFlag: '🇲🇲',
        countryAlphaTwoCode: 'MM',
    },
    {
        countryCodeValue: '+960',
        rawCountryCode: 960,
        countryName: 'Maldives',
        countryFlag: '🇲🇻',
        countryAlphaTwoCode: 'MV',
    },
    {
        countryCodeValue: '+961',
        rawCountryCode: 961,
        countryName: 'Lebanon',
        countryFlag: '🇱🇧',
        countryAlphaTwoCode: 'LB',
    },
    {
        countryCodeValue: '+962',
        rawCountryCode: 962,
        countryName: 'Jordan',
        countryFlag: '🇯🇴',
        countryAlphaTwoCode: 'JO',
    },
    {
        countryCodeValue: '+963',
        rawCountryCode: 963,
        countryName: 'Syrian Arab Republic',
        countryFlag: '🇸🇾',
        countryAlphaTwoCode: 'SY',
    },
    {
        countryCodeValue: '+964',
        rawCountryCode: 964,
        countryName: 'Iraq',
        countryFlag: '🇮🇶',
        countryAlphaTwoCode: 'IQ',
    },
    {
        countryCodeValue: '+965',
        rawCountryCode: 965,
        countryName: 'Kuwait',
        countryFlag: '🇰🇼',
        countryAlphaTwoCode: 'KW',
    },
    {
        countryCodeValue: '+966',
        rawCountryCode: 966,
        countryName: 'Saudi Arabia',
        countryFlag: '🇸🇦',
        countryAlphaTwoCode: 'SA',
    },
    {
        countryCodeValue: '+967',
        rawCountryCode: 967,
        countryName: 'Yemen',
        countryFlag: '🇾🇪',
        countryAlphaTwoCode: 'YE',
    },
    {
        countryCodeValue: '+968',
        rawCountryCode: 968,
        countryName: 'Oman',
        countryFlag: '🇴🇲',
        countryAlphaTwoCode: 'OM',
    },
    {
        countryCodeValue: '+970',
        rawCountryCode: 970,
        countryName: 'Palestinian Territory, Occupied',
        countryFlag: '🇵🇸',
        countryAlphaTwoCode: 'PS',
    },
    {
        countryCodeValue: '+971',
        rawCountryCode: 971,
        countryName: 'United Arab Emirates',
        countryFlag: '🇦🇪',
        countryAlphaTwoCode: 'AE',
    },
    {
        countryCodeValue: '+972',
        rawCountryCode: 972,
        countryName: 'Israel',
        countryFlag: '🇮🇱',
        countryAlphaTwoCode: 'IL',
    },
    {
        countryCodeValue: '+973',
        rawCountryCode: 973,
        countryName: 'Bahrain',
        countryFlag: '🇧🇭',
        countryAlphaTwoCode: 'BH',
    },
    {
        countryCodeValue: '+974',
        rawCountryCode: 974,
        countryName: 'Qatar',
        countryFlag: '🇶🇦',
        countryAlphaTwoCode: 'QA',
    },
    {
        countryCodeValue: '+975',
        rawCountryCode: 975,
        countryName: 'Bhutan',
        countryFlag: '🇧🇹',
        countryAlphaTwoCode: 'BT',
    },
    {
        countryCodeValue: '+976',
        rawCountryCode: 976,
        countryName: 'Mongolia',
        countryFlag: '🇲🇳',
        countryAlphaTwoCode: 'MN',
    },
    {
        countryCodeValue: '+977',
        rawCountryCode: 977,
        countryName: 'Nepal',
        countryFlag: '🇳🇵',
        countryAlphaTwoCode: 'NP',
    },
    {
        countryCodeValue: '+98',
        rawCountryCode: 98,
        countryName: 'Iran, Islamic Republic of Persian Gulf',
        countryFlag: '🇮🇷',
        countryAlphaTwoCode: 'IR',
    },
    {
        countryCodeValue: '+992',
        rawCountryCode: 992,
        countryName: 'Tajikistan',
        countryFlag: '🇹🇯',
        countryAlphaTwoCode: 'TJ',
    },
    {
        countryCodeValue: '+993',
        rawCountryCode: 993,
        countryName: 'Turkmenistan',
        countryFlag: '🇹🇲',
        countryAlphaTwoCode: 'TM',
    },
    {
        countryCodeValue: '+994',
        rawCountryCode: 994,
        countryName: 'Azerbaijan',
        countryFlag: '🇦🇿',
        countryAlphaTwoCode: 'AZ',
    },
    {
        countryCodeValue: '+995',
        rawCountryCode: 995,
        countryName: 'Georgia',
        countryFlag: '🇬🇪',
        countryAlphaTwoCode: 'GE',
    },
    {
        countryCodeValue: '+996',
        rawCountryCode: 996,
        countryName: 'Kyrgyzstan',
        countryFlag: '🇰🇬',
        countryAlphaTwoCode: 'KG',
    },
    {
        countryCodeValue: '+998',
        rawCountryCode: 998,
        countryName: 'Uzbekistan',
        countryFlag: '🇺🇿',
        countryAlphaTwoCode: 'UZ',
    },
];
