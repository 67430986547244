/**
 *
 * OnBoardingPage
 *
 */
import React, { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import AppWrapper from 'app/components/AppWrapper';
import { GetStarted } from './components/GetStarted';
import { useHistory } from 'react-router-dom';

interface Props {}

export const OnBoardingPage = memo((props: Props) => {
    const history = useHistory();
    const searchParams = history.location.search;
    return (
        <>
            <Helmet>
                <title>Home Page</title>
                <meta name="description" content="Home Page" />
            </Helmet>
            <AppWrapper
                className={`app-wrapper remove-padding`}
                id="app-wrapper"
            >
                <GetStarted
                    onGetStarted={() => {
                        history.push(`/signup${searchParams}`);
                    }}
                />
            </AppWrapper>
        </>
    );
});
