import React from 'react';
import './svg.css';

export default function CampaignIcon() {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.125 16.75V11.4375C3.125 10.8852 3.57272 10.4375 4.125 10.4375H7.76271C7.91874 10.4375 8.0726 10.401 8.21199 10.3309L17.3632 5.72883C18.0283 5.39439 18.8125 5.87783 18.8125 6.62222V21.7275C18.8125 22.478 18.0167 22.961 17.351 22.6147L8.21696 17.8629C8.07442 17.7887 7.91611 17.75 7.75544 17.75H4.125C3.57272 17.75 3.125 17.3023 3.125 16.75Z"
                fill="#F35627"
            />
            <path
                d="M22 11.5L24.875 9.125M8.21199 10.3309L17.3632 5.72883C18.0283 5.39439 18.8125 5.87783 18.8125 6.62222V21.7275C18.8125 22.478 18.0167 22.961 17.351 22.6147L8.21696 17.8629C8.07442 17.7887 7.91611 17.75 7.75544 17.75H4.125C3.57272 17.75 3.125 17.3023 3.125 16.75V11.4375C3.125 10.8852 3.57272 10.4375 4.125 10.4375H7.76271C7.91874 10.4375 8.0726 10.401 8.21199 10.3309Z"
                stroke="#F35627"
                strokeWidth="1.3"
                strokeLinecap="round"
            />
            <path
                d="M22.0005 14.375H25.4381"
                stroke="#F35627"
                strokeWidth="1.3"
                strokeLinecap="round"
            />
            <path
                d="M22.0005 17L24.6881 19.25"
                stroke="#F35627"
                strokeWidth="1.3"
                strokeLinecap="round"
            />
            <path d="M8 9V19" stroke="white" strokeWidth="1.3" />
        </svg>
    );
}
