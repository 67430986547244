import React, { memo } from 'react';
import { Popup } from 'app/components/Popup';
import { FamilyInfo } from 'app/pages/SignUpPage/components/Stepper/components/FamilyInfo';
interface Props {
    onClose: Function;
    prefillFamilyData: {
        is_married: number;
        kids_count: number;
        kids_details: any;
    };
}

export const FamilyInfoPopUp = memo((props: Props) => {
    const { onClose, prefillFamilyData } = props;

    return (
        <Popup onClose={onClose}>
            <FamilyInfo
                showIntroStyled={false}
                customFormStyled={true}
                prefillFamilyData={prefillFamilyData}
            />
        </Popup>
    );
});
