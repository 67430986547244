import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';

import AppWrapper from 'app/components/AppWrapper';
import { PanCard } from './components/PanCardDetails';
import { PaymentOptionTypes } from './components/PaymentOptions';

import PreviewBlockIcon from 'app/images/black-left-arrow.svg';
import { HeaderTittle } from '../About/index.styled';

interface Props {}

export const PaymentOptions = memo((props: Props) => {
    const history = useHistory();
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const {
        profileSummary: { payment_details = {} },
    } = profileSummarySelector;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <AppWrapper>
            <HeaderTittle>
                <img
                    src={PreviewBlockIcon}
                    alt="preview-icon"
                    onClick={history.goBack}
                />
                <span>Payment Info</span>
            </HeaderTittle>
            <PaymentOptionTypes
                prefilledPaymentOptionsDetails={{
                    prefilledUpiId: payment_details?.upi_id || '',
                    prefilledBankDetails: {
                        bank_account_name:
                            payment_details?.bank_account_name || '',
                        bank_account_number:
                            payment_details?.bank_account_number || '',
                        bank_account_ifsc:
                            payment_details?.bank_account_ifsc || '',
                    },
                    prefilledPaytmNumber: payment_details?.paytm_number,
                }}
            />
            <PanCard prefilledPanNumber={payment_details?.pan_number || ''} />
        </AppWrapper>
    );
});
