import styled from 'styled-components/macro';

export const AuthFooter = styled.div`
    position: fixed;
    padding: 16px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;

    > button {
        height: 48px;
        width: 100%;
    }

    &.hold-on-page__footer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;

        button.go-to-profile-btn {
            width: fit-content;
            font-weight: 700;
            font-size: 16px;
            height: 44px;
        }
    }
`;

export default AuthFooter;
