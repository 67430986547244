export const topContentCategories = [
    {
        api_id: '1',
        emoji: '👨‍👩‍👧',
        api_emoji_name: 'family',
    },
    {
        api_id: '2',
        emoji: '✨',
        api_emoji_name: 'star',
    },
    {
        api_id: '3',
        api_emoji_name: 'flight',
        emoji: '✈️',
    },
    {
        api_id: '4',
        emoji: '👗',
        api_emoji_name: 'dress',
    },
    {
        api_id: '5',
        api_emoji_name: 'lipstick',
        emoji: '💄',
    },
    {
        api_id: '7',
        emoji: '🏋',
        api_emoji_name: 'weightlifting',
    },
    {
        api_id: '8',
        api_emoji_name: 'camera',
        emoji: '📸',
    },
    {
        api_id: '9',
        api_emoji_name: 'phone',
        emoji: '📱',
    },
    {
        api_id: '10',
        emoji: '📈',
        api_emoji_name: 'stockchart',
    },
    {
        api_id: '11',
        api_emoji_name: 'bowl',
        emoji: '🍜',
    },
    {
        api_id: '12',
        emoji: '🚘',
        api_emoji_name: 'car',
    },
    {
        api_id: '14',
        api_emoji_name: 'clapperboard',
        emoji: '🎬',
    },
    {
        api_id: '16',
        emoji: '🏠',
        api_emoji_name: 'house',
    },
    {
        api_id: '17',
        emoji: '⚽',
        api_emoji_name: 'football',
    },
    {
        api_id: '18',
        emoji: '🥸',
        api_emoji_name: 'emoji',
    },
    {
        api_id: '21',
        emoji: '🧩',
        api_emoji_name: 'puzzle',
    },
    {
        api_id: '40',
        emoji: '🎮',
        api_emoji_name: 'joystick',
    },
    {
        api_id: '56',
        emoji: '📚',
        api_emoji_name: 'stationary',
    },
    {
        api_id: '58',
        emoji: '💻',
        api_emoji_name: 'laptop',
    },
    {
        api_id: '59',
        emoji: '🏻',
        api_emoji_name: 'yogamat',
    },
    {
        api_id: '61',
        api_emoji_name: 'dance',
        emoji: '💃',
    },
];
