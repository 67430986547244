import styled from 'styled-components';

const PanCardStyle = styled.div`
    width: 100%;
    margin-top: 24px;
    border: 1px solid #e4e7eb;
    box-shadow: 0px 0px 12px rgba(9, 30, 66, 0.08);
    border-radius: 8px;
    flex-direction: column;
    display: flex;

    .card-header-section {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        line-height: 24px;
        padding: 1rem;

        .header-profile {
            display: flex;
            gap: 10px;
            width: max-content;
            width: 100%;

            span {
                font-size: 14px;
                color: #1f2933;
            }

            .html-element-skeleton {
                margin-left: auto;
            }

            .pan-update-action-controls {
                margin-left: auto;
                font-size: 12px;
                display: flex;
                align-items: center;
                gap: 10px;

                span.control {
                    font-size: 12px;
                    color: #3956c6;
                }
            }
        }

        span {
            font-size: 12px;
            color: #3956c6;
            cursor: pointer;
        }
    }

    .prompt-message {
        height: 100%;
        background: #fffaeb;
        border-radius: 8px;
        padding: 16px 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: #1f2933;
        margin: 0px 1rem 1rem;
    }

    .pan-content {
        padding: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #1f2933;
    }
`;
export { PanCardStyle };
