import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import {
    signUpPageActions,
    useSignUpPageSlice,
} from 'app/pages/SignUpPage/slice';
import {
    selectInfluencerHelpdeskInfo,
    selectInfluencerProfileSummary,
} from 'app/pages/SignUpPage/slice/selectors';

import { BaseHelpdeskView } from './components/BaseHelpdeskView';
import { CategoriesFAQPage } from './components/CategoryFAQSection';
import { FaqDetailsViewPage } from './components/FaqDetailsViewPage';

interface Props {}

export const HelpTabSection = memo((props: Props) => {
    useSignUpPageSlice();
    const dispatch = useDispatch();
    let { path } = useRouteMatch();
    const history = useHistory();

    const influencerHelpdeskInfoSelector = useSelector(
        selectInfluencerHelpdeskInfo,
    );
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);

    const { helpdeskCategoryDetails = [] } = influencerHelpdeskInfoSelector;
    const { profileSummary = {} } = profileSummarySelector;

    useEffect(() => {
        if (localStorage.getItem('token')) {
            if (helpdeskCategoryDetails.length === 0) {
                dispatch(
                    signUpPageActions.getInfluencerHelpdeskInfo({
                        token: localStorage.getItem('token'),
                    }),
                );
            }
            if (!profileSummary.phone_number) {
                dispatch(
                    signUpPageActions.getInfluencerProfileSummary({
                        token: localStorage.getItem('token'),
                    }),
                );
            }
        } else {
            history.push('/signup');
        }
    }, []);

    return (
        <Switch>
            <Route
                exact
                path={path}
                render={props => (
                    <BaseHelpdeskView profile_id={profileSummary?.profile_id} />
                )}
            />
            {/* <Route exact path={path} component={BaseHelpdeskView} /> */}
            <Route
                exact
                path={
                    process.env.PUBLIC_URL + `${path}/category-faqs/:category`
                }
                component={CategoriesFAQPage}
            />
            <Route
                path={
                    process.env.PUBLIC_URL +
                    `${path}/category-faqs/:category/faq/:faqId`
                }
                component={FaqDetailsViewPage}
            />
        </Switch>
    );
});
