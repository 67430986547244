import styled from 'styled-components';

const CustomAppWrapper = styled.div`
    padding: 0;
    .custom-wrapper {
        padding: 0px 0px calc(56px + 2rem);
        .border-line {
            border-top: 3px solid #f5f7fa;
        }
    }
`;
export { CustomAppWrapper };
