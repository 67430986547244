import styled from 'styled-components';

const StyledSpinner = styled.div`
    position: relative;
    width: 24px;
    height: 24px;

    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        margin: 2px;
        border: 2px solid
            ${props => (props.color === 'light' ? '#ffffff' : '#3e4c59')};
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${props =>
                props.color === 'light' ? '#ffffff' : '#3e4c59'}
            transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }
        &:nth-child(2) {
            animation-delay: -0.3s;
        }
        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }

    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

export { StyledSpinner };
