/**
 *
 * AddUPIPopup
 *
 */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from 'utils/helper';

import {
    addUpiActions,
    useAddUpiSlice,
} from 'app/pages/HomePage/components/AddUPIPopup/slice';
import { usePaymentPopupSlice } from 'app/pages/HomePage/components/PaymentsPopup/slice';
import { selectUpdateOrDeletePaymentDetail } from 'app/pages/SignUpPage/slice/selectors';
import { selectAddUpi } from 'app/pages/HomePage/components/AddUPIPopup/slice/selectors';
import { selectVerifyOTP } from 'app/pages/HomePage/components/OtpVerifyPopUp/slice/selectors';
import { selectPaymentPopup } from 'app/pages/HomePage/components/PaymentsPopup/slice/selectors';
import { selectCampaignDetails } from 'app/pages/HomePage/components/CampaignDetails/slice/selectors';
import { AddUPIPopupProps } from 'app/pages/HomePage/components/AddUPIPopup/types';

import Spinner from 'app/components/Spinner';
import { Alert } from 'app/components/Alert';
import { Popup } from 'app/components/Popup';
import { Button } from 'app/components/Button';
import PopupContent from 'app/components/PopupContent';
import Form, { FormField, FormLabel, Required } from 'app/components/Form';
import {
    signUpPageActions,
    useSignUpPageSlice,
} from 'app/pages/SignUpPage/slice';

export const AddUPIPopup = memo((props: AddUPIPopupProps) => {
    usePaymentPopupSlice();
    useAddUpiSlice();
    useSignUpPageSlice();
    const dispatch = useDispatch();

    const {
        onClose,
        profileId,
        groupId,
        setPayments,
        isUpdatingProfileSummary = false,
        prefilledUpiId = '',
    } = props;

    const [upi, setUPI] = useState('');

    const addUpiStore = useSelector(selectAddUpi);
    const verifyOTPStore = useSelector(selectVerifyOTP);
    const paymentPopupStore = useSelector(selectPaymentPopup);
    const campaignDetailsStore = useSelector(selectCampaignDetails);
    const updatePaymentDetailsStore = useSelector(
        selectUpdateOrDeletePaymentDetail,
    );

    const { paymentDetails } = paymentPopupStore;
    const { campaignData } = campaignDetailsStore;
    const { loading, isPaymentDataAdded, isTokenValid } = addUpiStore;

    useEffect(() => {
        if (isPaymentDataAdded === 'yes') {
            onClose();
        }
        if (
            isTokenValid === 'no' &&
            verifyOTPStore &&
            !verifyOTPStore.isVerifySuccess
        ) {
            onClose('otp');
        }
    }, [isPaymentDataAdded, isTokenValid, verifyOTPStore]);

    useEffect(() => {
        if (prefilledUpiId) {
            setUPI(prefilledUpiId);
        }
    }, []);

    const dispatchAddUPI = () => {
        if (upi && !loading) {
            const dispatchData = {
                preferred_mode: 'upi',
                mode_type: 'update',
                upi_id: upi,
            };

            if (!isUpdatingProfileSummary) {
                dispatchData['profile_id_hash'] = profileId;
                dispatchData['upi_id'] = upi;
                dispatchData['groupId'] = groupId;
                dispatchData['token'] = getCookie(
                    `qzc_token_${campaignData.id}`,
                );
            } else {
                dispatchData['token'] = localStorage.getItem('token');
                dispatchData['request_type'] = 'profile_update';
            }

            !isUpdatingProfileSummary
                ? dispatch(addUpiActions.addUPINumber(dispatchData))
                : dispatch(
                      signUpPageActions.updateOrDeletePaymentDetail(
                          dispatchData,
                      ),
                  );
        }
    };

    const OnPopupClose = () => {
        onClose();
        setPayments(true);
    };

    return (
        <Popup onClose={OnPopupClose}>
            <PopupContent className="popup-content bank-account-popup">
                <h2>Link UPI ID</h2>
                <Form className="form-list">
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="upi">
                            UPI ID
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="text"
                            placeholder="Enter Your UPI"
                            id="upi"
                            value={upi}
                            onChange={e => {
                                setUPI(e.target.value);
                            }}
                        />
                    </FormField>

                    <Alert details={'Only 1 UPI ID can be added.'} />
                    <Button
                        className={`${!upi ? 'disabled' : ''}`}
                        onClick={e => {
                            e.preventDefault();
                            dispatchAddUPI();
                        }}
                    >
                        {loading || updatePaymentDetailsStore.loading ? (
                            <Spinner color="light" />
                        ) : (
                            'Link UPI'
                        )}
                    </Button>
                </Form>
            </PopupContent>
        </Popup>
    );
});
