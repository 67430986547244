import styled from 'styled-components';

const HeaderScrollAnimation = styled.div`
    height: 72px;
    position: sticky;
    top: -16px;
    z-index: 1;
    color: white;
    text-align: center;
    ::before,
    ::after {
        content: '';
        display: block;
        position: sticky;
        height: 16px;
    }

    /* SHADOW */
    ::before {
        top: 36px;
        box-shadow: 0px 4px 6px rgba(9, 30, 66, 0.06);
    }

    /* COVER */
    ::after {
        background: linear-gradient(
            white 10%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 0.4) 70%,
            transparent
        );
        top: 0;
        z-index: 2;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        background: white;
        height: 56px;
        position: sticky;
        top: 0px;
        margin-top: -16px;
        z-index: 3;
        .logo {
            width: 66px;
            height: 22px;
        }
        span {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #1f2933;
        }
        .influencer-profile {
            display: flex;
            gap: 16px;
            .creator-profile-image {
                border-radius: 50%;
                height: 28px;
                width: 28px;
                cursor: pointer;
            }
        }
    }
`;
export { HeaderScrollAnimation };
