import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { campaignDetailsActions as actions } from '.';
import { homeActions } from 'app/pages/HomePage/slice';

export function* getCampaignDetailsSaga({ payload }) {
    try {
        const requestURL = `/api/campaign.details`;

        const response = yield call(request, requestURL, {
            method: 'POST',
            headers: {
                // /Authorization: authHeader(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(payload),
        });
        if (
            response.data?.status === 'accepted' ||
            response.data?.status === 'confirmed'
        ) {
            yield put(homeActions.setIsAccepted(true));
        }
        if (
            response.data?.status === 'rejected' ||
            response.data?.status === 'declined' ||
            (response.data?.status === 'awaiting_acceptance' &&
                response.data?.campaign_details?.is_expired)
        ) {
            yield put(homeActions.setCampaignDeclined(true));
        }
        yield put(actions.getCampaignDetailsSuccess(response));
        // yield put(
        //     addToast({
        //         type: response.ok === true ? 'success' : 'error',
        //         message: response.stuff,
        //     }),
        // );
    } catch (error) {
        try {
            const response = yield error.response.json();
            yield put(actions.getCampaignDetailsError(response));
        } catch (jsonError) {
            yield put(actions.getCampaignDetailsError(error));
        }
    }
}

export function* campaignDetailsSaga() {
    yield takeLatest(actions.getCampaignDetails, getCampaignDetailsSaga);
}
