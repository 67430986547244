import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { otpVerifyPopUpSaga } from './saga';
import { OtpVerifyPopUpState } from './types';

export const initialState: OtpVerifyPopUpState = {
    generateOTP: {
        loading: false,
        error: '',
        stuff: '',
        isOTPSent: false,
    },
    verifyOTP: {
        loading: false,
        error: '',
        stuff: '',
        token: '',
        expiresAt: 0,
        isVerifySuccess: false,
    },
};

const slice = createSlice({
    name: 'otpVerifyPopUp',
    initialState,
    reducers: {
        generateOTP(state, action: PayloadAction<any>) {
            state.generateOTP.loading = true;
            state.generateOTP.isOTPSent = true;
            state.generateOTP.error = '';
            state.generateOTP.stuff = '';
        },
        generateOTPSuccess(state, action: PayloadAction<any>) {
            state.generateOTP.loading = false;
        },
        generateOTPError(state, action: PayloadAction<any>) {
            state.generateOTP.loading = false;
            state.generateOTP.error = action.payload?.error;
            state.generateOTP.stuff = action.payload?.stuff;
        },
        verifyOTP(state, action: PayloadAction<any>) {
            state.verifyOTP.loading = true;
            state.verifyOTP.isVerifySuccess = false;
            state.verifyOTP.error = '';
            state.verifyOTP.stuff = '';
            state.generateOTP.error = '';
            state.generateOTP.stuff = '';
        },
        verifyOTPSuccess(state, action: PayloadAction<any>) {
            state.verifyOTP.loading = false;
            state.verifyOTP.token = action.payload?.data.token;
            state.verifyOTP.expiresAt = action.payload?.data.expires_at;
            state.verifyOTP.isVerifySuccess = true;
            state.generateOTP.isOTPSent = false;
        },
        verifyOTPError(state, action: PayloadAction<any>) {
            state.verifyOTP.loading = false;
            state.verifyOTP.error = action.payload.error;
            state.verifyOTP.stuff = action.payload.stuff;
            if (action.payload.error === 'expired_otp') {
                state.generateOTP.isOTPSent = false;
            }
        },

        // Clear Validation
        clearValidationError(state, action: PayloadAction<any>) {
            state.verifyOTP.stuff = '';
        },

        //Set OtP UnVerified
        setOTPUnverified(state, action: PayloadAction<any>) {
            state.verifyOTP.isVerifySuccess = false;
        },
    },
});

export const { actions: otpVerifyPopUpActions } = slice;

export const useOtpVerifyPopUpSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga: otpVerifyPopUpSaga });
    return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useOtpVerifyPopUpSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
