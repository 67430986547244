import './svg.css';

export default function DoubleTickBlue() {
    return (
        <svg
            width="12"
            height="6"
            viewBox="0 0 12 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.17107 0.77878L3.22158 5.8542C3.03187 6.04882 2.72479 6.04855 2.53534 5.85368L0.0709804 3.32088C-0.0236601 3.22348 -0.0236601 3.06523 0.0709804 2.96793L0.414438 2.61515C0.509079 2.51775 0.662908 2.51775 0.757634 2.61515L2.71325 4.62511C2.80789 4.72251 2.96172 4.72251 3.05644 4.62511L7.48507 0.0731253C7.57971 -0.0242693 7.73354 -0.0242693 7.82827 0.0731253L8.17146 0.425914C8.26645 0.523657 8.26619 0.68165 8.17112 0.778948L8.17107 0.77878ZM11.9301 0.425834L11.5919 0.073046C11.4986 -0.0243487 11.347 -0.0243487 11.2536 0.073046L6.88995 4.62503C6.79642 4.72243 6.64507 4.72243 6.55152 4.62503L6.20166 4.26004C6.10839 4.16264 5.95702 4.16238 5.86341 4.25977L5.52474 4.61203C5.43121 4.70942 5.43095 4.86767 5.52448 4.96524L6.37642 5.85365C6.56288 6.04853 6.86577 6.04879 7.05249 5.85417L11.9296 0.778751C12.0234 0.681443 12.0234 0.523194 11.93 0.425806L11.9301 0.425834Z"
                fill="#0CAAD9"
            />
        </svg>
    );
}
