import styled from 'styled-components';

const Border = styled.div`
    border-top: 1px solid #e4e7eb;
`;

const ProfileHeaderSection = styled.div`
    height: 265px;
    background: #eefbef;
    text-align: center;
    .userprofile-contents {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
    }

    .img-upload {
        position: relative;
        label {
            bottom: 0px;
            left: 14px;
            position: absolute;
            background-color: #1f2933;
            height: 24px;
            border-radius: 50%;
            width: 24px;
            border: 1.5px solid #ffffff;
            text-align: center;
            cursor: pointer;
            img {
                width: 12px;
            }
        }

        input {
            display: none;
        }
    }

    .user-name {
        width: 158px;
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.0106667em;
        color: #1a202c;
    }

    span {
        width: 185px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.004em;
        color: #7b8794;
    }
`;

const PersonalStatusInfo = styled.div`
    width: auto;
    background: #ffffff;
    border-radius: 24px;
    position: relative;
    top: -30px;
    .list-types {
        list-style: none;
        margin: 0;
        padding: 2.9rem 0px 0px 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 40px;
        .list-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 12px;
            strong {
                cursor: pointer;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #1f2933;
                margin: 0;
            }

            img {
                cursor: pointer;
            }

            span {
                width: fit-content;
                height: 24px;
                border: 1px solid #e6a203;
                border-radius: 20px;
                position: absolute;
                right: 24px;
                font-weight: 700;
                font-size: 10px;
                line-height: 21px;
                padding: 0px 10px;
                color: #e6a203;
            }

            .update {
                color: #f9703e;
                border: 1px solid #f9703e;
            }
        }
    }
`;

export { Border, ProfileHeaderSection, PersonalStatusInfo };
