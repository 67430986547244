/**
 *
 * Logo
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import LogoSvg from '../../../app/images/logo.svg';
interface Props {}

export const Logo = memo((props: Props) => {
    return (
        <Brand className="brand" href="#">
            <img src={LogoSvg} alt="logo" />
        </Brand>
    );
});

const Brand = styled.a`
    display: flex;
    max-width: 96px;
    width: 100%;
    max-height: 32px;
    img {
        width: 100%;
    }
`;
