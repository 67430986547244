import styled from 'styled-components/macro';

const PopUpWrapper = styled.div`
    position: fixed;
    z-index: 4;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(31, 41, 51, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;

    &.share-link-styled {
        z-index: 10000000;
    }
`;

const PopUpContentBlock = styled.div`
    @keyframes SlideUp {
        0% {
            opacity: 0;
            transform: translateY(100%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    @keyframes SlideDown {
        0% {
            opacity: 1;
            transform: translateY(0%);
        }
        100% {
            opacity: 0;
            transform: translateY(100%);
        }
    }
    max-width: 640px;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 0;
    height: 100%;
    transform: translateY(0%);
    animation: SlideUp 0.25s alternate backwards;
    &.popup-delay {
        animation: SlideDown 0.25s alternate backwards;
        transform: translateY(100%);
    }
`;

const Close = styled.figure`
    margin-top: auto;
    margin-bottom: 16px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background: white;
    border-radius: 50%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PopUpStyled = styled.div`
    background: white;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    max-height: calc(100vh - 70px);
    overflow: auto;
    width: 100%;
    border-radius: 1.5rem 1.5rem 0px 0px;
    padding: 1.75rem 16px 1.5rem;
    position: relative;
    h2 {
        margin: 0;
    }

    span.input-format-text {
        font-size: 12px;
        margin-top: 5px;
        color: var(--gray-400);
    }
`;

export default PopUpWrapper;

export { Close, PopUpStyled, PopUpContentBlock };
