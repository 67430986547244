import React, { memo } from 'react';
import {
    AboutDetails,
    ContactDetails,
    ContactUserProfile,
} from './index.styled';
import LinkedinIcon from 'app/images/linkedin.svg';
import PhoneCallIcon from 'app/images/phonecall-icon.svg';
import EmailIcon from 'app/images/mail-icon.svg';
import ClockIcon from 'app/images/clockIcon.svg';
import MessangeIcon from 'app/images/message-square.svg';
import { getDefaultQLogo } from 'utils/helper';

import dayjs from 'dayjs';

interface Props {
    selectedThread: any;
}

export const UserAboutDetails = memo((props: Props) => {
    const { selectedThread } = props;

    return (
        <AboutDetails>
            <span>About {selectedThread?.user?.name}</span>
            <ContactUserProfile>
                <div className="user-page">
                    <div className="user-details">
                        <img
                            src={
                                selectedThread?.user?.profile_image_data
                                    ?.data_url || getDefaultQLogo()
                            }
                            className="user-img"
                            alt="profile-img"
                        />
                        <div className="">
                            <h4>{selectedThread?.user?.name} </h4>
                            <span>
                                {selectedThread?.user?.designation},{' '}
                                {selectedThread?.organization?.name}
                            </span>
                        </div>
                    </div>
                    {selectedThread?.user?.social_links?.linkedin_url && (
                        <div className="">
                            <img
                                onClick={() =>
                                    window.open(
                                        `${selectedThread?.user?.social_links?.linkedin_url}`,
                                        '_blank',
                                    )
                                }
                                src={LinkedinIcon}
                                alt="icon"
                                className="user-linkedin-icon"
                            />
                        </div>
                    )}
                </div>

                <ContactDetails>
                    Contact
                    <ul>
                        <a
                            href={`tel:${selectedThread?.user?.phone}`}
                            className="number"
                        >
                            <li>
                                <img src={PhoneCallIcon} alt="icon" />
                                {selectedThread?.user?.phone}
                            </li>
                        </a>

                        <a
                            href={`mailto:${selectedThread?.user?.email}`}
                            className="contact-details"
                        >
                            <li>
                                <img src={EmailIcon} alt="icon" />
                                {selectedThread?.user?.email}
                            </li>
                        </a>
                    </ul>
                </ContactDetails>

                <ContactDetails>
                    Activity
                    <ul>
                        <li>
                            <img src={ClockIcon} alt="icon" />
                            on {selectedThread?.organization?.name} since{' '}
                            {dayjs(
                                selectedThread?.organization?.created_at,
                            ).format('MMM YYYY')}
                        </li>
                        <li>
                            <img src={MessangeIcon} alt="icon" />
                            within{' '}
                            {
                                selectedThread?.profile
                                    ?.average_message_response_time
                            }
                        </li>
                    </ul>
                </ContactDetails>
            </ContactUserProfile>
        </AboutDetails>
    );
});
