/**
 *
 * PetInfo
 *
 */
import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import { selectSignUpPage } from 'app/pages/SignUpPage/slice/selectors';

import Intro from 'app/components/Intro';
import Spinner from 'app/components/Spinner';
import { Radio } from 'app/components/Radio';
import { Button } from 'app/components/Button';
import AuthScreen from 'app/components/AuthScreen';
import AuthFooter from 'app/components/AuthFooter';
import { OutlineButton } from 'app/components/OutlineButton';
import Form, { FormField, FormLabel, Required } from 'app/components/Form';

import PlusGrey from 'app/images/plus-grey.svg';
import { RadioGroup } from 'app/components/Radio/index.styled';
import { petTypeOptions, signupStepsKeyList } from 'app/pages/SignUpPage/label';
interface Props {
    onClickNext?: any;
    customFormStyled?: boolean;
    prefillPetDetails?: {
        has_pets: number;
        pets_list: any;
    };
}

export const PetInfo = memo((props: Props) => {
    const { onClickNext, customFormStyled, prefillPetDetails } = props;
    const dispatch = useDispatch();
    const signUpPageStore = useSelector(selectSignUpPage);

    const [petsInfoForm, setPetsInfoForm] = useState<{
        hasPets: number | null;
        petsList: Array<{
            pet_type: string;
        }>;
        petsCount: number;
    }>({
        hasPets: null,
        petsList: [],
        petsCount: 0,
    });

    const handleAddNewPet = () => {
        setPetsInfoForm({
            ...petsInfoForm,
            petsList: [
                ...petsInfoForm.petsList,
                {
                    pet_type: '',
                },
            ],
        });
    };

    useEffect(() => {
        if (customFormStyled && prefillPetDetails) {
            let formattedPetList: Array<{ pet_id: string; pet_type: string }> =
                [];
            // pet details format in response --> {dog: 2, cat: 1}
            // we convert it to following format -->
            // [{pet_id: 'dog1', pet_type: 'dog'}, {pet_id: 'dog2', pet_type: 'dog'}, {pet_id: 'cat', pet_type: 'cat'}]

            Object.keys(prefillPetDetails.pets_list).map(petKey => {
                let petTypeCount = prefillPetDetails.pets_list[petKey];

                if (petTypeCount > 1) {
                    // if pet count is > 1, e.g {Dog: 2}
                    // we separate them into two Dog type pets
                    // {pet_id: 'dog1', pet_type: 'dog'}, {pet_id: 'dog2', pet_type: 'dog'}

                    Array(petTypeCount)
                        .fill('')
                        .map((petItem, index) =>
                            formattedPetList.push({
                                pet_id: `${petKey}_${index}`,
                                pet_type: petKey,
                            }),
                        );
                } else {
                    formattedPetList.push({
                        pet_id: petKey,
                        pet_type: petKey,
                    });
                }
            });

            setPetsInfoForm({
                ...petsInfoForm,
                hasPets: Number(prefillPetDetails.has_pets),
                petsList: formattedPetList.map(pet => ({
                    pet_type: pet.pet_type,
                })),
            });
        }
    }, []);

    useEffect(() => {
        if (
            signUpPageStore.isStepSuccess &&
            signUpPageStore.currentStep === 'petDetails' &&
            !customFormStyled
        ) {
            onClickNext();
        }
    }, [signUpPageStore, onClickNext]);

    const canDispatch = () => {
        let tempPets = [...petsInfoForm.petsList];

        return petsInfoForm.hasPets === 0 ||
            (petsInfoForm.hasPets === 1 && tempPets.every(pet => pet.pet_type))
            ? true
            : false;
    };

    const onSavePetDetails = () => {
        let tempPets = [...petsInfoForm.petsList];

        if (petsInfoForm.hasPets !== null) {
            if (canDispatch()) {
                dispatch(
                    signUpPageActions.register({
                        current_step: signupStepsKeyList['petDetails'],
                        having_pets: petsInfoForm.hasPets ? 1 : 0,
                        pets: petsInfoForm.hasPets
                            ? tempPets.filter(pet => pet.pet_type)
                            : [],
                        currentStep: 'petDetails',
                        token: localStorage.getItem('token'),
                        action_type: !customFormStyled ? 'signup' : 'edit',
                    }),
                );
            }
        }
    };

    const renderPets = () => {
        return petsInfoForm.petsList.map((pet: any, index: number) => {
            return (
                <FormField className="form-list-item" key={index}>
                    <FormLabel htmlFor={`child-${index}`}>
                        <h3>Pet No. {index + 1}</h3>
                        <Required className="required">*</Required>
                    </FormLabel>
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="age">
                            What’s your pet?*
                            <Required className="required">*</Required>
                        </FormLabel>
                        <RadioGroup className="radio-group">
                            {petTypeOptions.map((petType, idx) => (
                                <Radio
                                    id={`${petType.petLabel}-${index}`}
                                    key={`${petType.petLabel}-${index}`}
                                    checked={
                                        pet.pet_type.toLowerCase() ===
                                        petType.petLabel.toLowerCase()
                                    }
                                    onChange={e => {
                                        if (e.target.checked) {
                                            let tempArray =
                                                petsInfoForm.petsList.slice();
                                            tempArray[index].pet_type =
                                                petType.petLabel;
                                            setPetsInfoForm({
                                                ...petsInfoForm,
                                                petsList: [...tempArray],
                                            });
                                        }
                                    }}
                                    label={petType.petLabel}
                                />
                            ))}
                        </RadioGroup>
                    </FormField>
                </FormField>
            );
        });
    };

    return (
        <AuthScreen
            className={`auth-screen pet-info ${
                customFormStyled ? 'custom-auth-styled' : false
            }`}
        >
            <Intro
                className={`intro ${
                    customFormStyled ? 'custom-intro-style' : false
                }`}
            >
                <h2>Pet(s) Info</h2>
            </Intro>
            <Form
                className={`form-list ${
                    customFormStyled ? 'custom-form-style' : false
                }`}
            >
                <FormField className="form-list-item">
                    <FormLabel htmlFor="age">
                        Do you have pets?
                        <Required className="required">*</Required>
                    </FormLabel>
                    <RadioGroup className="radio-group">
                        <Radio
                            id="petstrue"
                            checked={petsInfoForm.hasPets === 1}
                            onChange={e => {
                                if (e.target.checked) {
                                    setPetsInfoForm({
                                        ...petsInfoForm,
                                        hasPets: 1,
                                        petsList: [
                                            ...petsInfoForm.petsList,
                                            {
                                                pet_type: '',
                                            },
                                        ],
                                    });
                                }
                            }}
                            label={'Yes'}
                        />
                        <Radio
                            id="petsfalse"
                            checked={petsInfoForm.hasPets === 0}
                            onChange={e => {
                                if (e.target.checked) {
                                    setPetsInfoForm({
                                        ...petsInfoForm,
                                        hasPets: 0,
                                        petsList: [],
                                    });
                                }
                            }}
                            label={'No'}
                        />
                    </RadioGroup>
                </FormField>

                {petsInfoForm.hasPets === 1 && renderPets()}
                {petsInfoForm.hasPets === 1 && (
                    <OutlineButton
                        onClick={e => {
                            e.preventDefault();
                            handleAddNewPet();
                        }}
                        color="#3E4C59"
                        bgColor="#CBD2D9"
                        icon={PlusGrey}
                    >
                        Add Another Pet
                    </OutlineButton>
                )}
            </Form>

            <AuthFooter className="footer">
                <Button
                    onClick={e => {
                        e.preventDefault();
                        onSavePetDetails();
                    }}
                    className={
                        signUpPageStore.loading || !canDispatch()
                            ? 'disabled'
                            : ''
                    }
                >
                    {signUpPageStore.loading ? (
                        <Spinner color="light" />
                    ) : !customFormStyled ? (
                        'Next'
                    ) : (
                        'Save'
                    )}
                </Button>
            </AuthFooter>
        </AuthScreen>
    );
});
