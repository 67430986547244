/**
 *
 * Radio
 *
 */
import React, { memo } from 'react';
import { RadioStyled } from './index.styled';

interface Props {
    checked?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    id?: string;
    label?: string;
    value?: string;
    type?: string;
}

export const Radio = memo((props: Props) => {
    const { id, label, ...rest } = props;
    return (
        <RadioStyled className="radio">
            <input type="radio" id={id} {...rest} />
            <label htmlFor={id}>{label}</label>
        </RadioStyled>
    );
});
