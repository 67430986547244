import React, { memo, useEffect } from 'react';
import AppWrapper from 'app/components/AppWrapper';
import { ReadMore } from 'app/components/ReadMore';
import { UserAboutDetails } from '../CampaignManagerDetails';
import { useHistory } from 'react-router-dom';
import PreviewBlockIcon from 'app/images/black-left-arrow.svg';
import ProfileBackground from 'app/images/profile-backgrond.svg';
import BrandImg from 'app/images/pepsi-icon.svg';
import { BrandDetailsView, ProfileViewHeader } from './index.styled';
import { Border } from 'app/pages/Tabs/ProfileTabSection/index.styled';
import { platformsList } from '../../label';
import { Link } from 'app/components/Link';
import { getDefaultQLogo } from 'utils/helper';

interface Props {
    setShowBrandInfo: Function;
    selectedThread?: any;
}

export const MessengerBrandInfoPage = memo((props: Props) => {
    const { setShowBrandInfo, selectedThread } = props;
    const history = useHistory();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);

    return (
        <AppWrapper className="remove-padding">
            <ProfileViewHeader>
                <img
                    src={PreviewBlockIcon}
                    alt="preview-icon"
                    className="icon"
                    onClick={() => setShowBrandInfo(false)}
                />

                <img
                    src={ProfileBackground}
                    className="img"
                    alt="background-img"
                />

                <div className="brand">
                    <span className="brand-img">
                        <img
                            src={
                                selectedThread?.organization?.logo_data
                                    ?.data_url || getDefaultQLogo()
                            }
                            onError={e =>
                                ((e.target as HTMLInputElement).src =
                                    getDefaultQLogo())
                            }
                            alt="brand-logo"
                            className="brand-logo"
                        />
                    </span>

                    <div className="brand-details">
                        <span className="brand-title">
                            {selectedThread?.organization?.name}
                        </span>
                        <span>
                            {selectedThread?.organization?.industry_type}
                        </span>
                        {selectedThread?.organization?.company_size > 0 ? (
                            <span>
                                {selectedThread?.organization?.company_size}{' '}
                                employees
                            </span>
                        ) : (
                            ''
                        )}

                        <span className="link">
                            <a
                                href={`${selectedThread?.organization?.website}`}
                            >
                                {selectedThread?.organization?.website}
                            </a>
                        </span>
                        {Object.keys(selectedThread?.organization?.social_links)
                            .length && (
                            <span className="social-platform">
                                {Object.keys(
                                    selectedThread?.organization?.social_links,
                                ).map(platform =>
                                    selectedThread?.organization?.social_links[
                                        platform
                                    ] ? (
                                        <img
                                            onClick={() =>
                                                window.open(
                                                    `${selectedThread?.organization?.social_links[platform]}`,
                                                )
                                            }
                                            src={platformsList[platform]}
                                            className="social-media-platform"
                                            alt="platform-logo"
                                        />
                                    ) : (
                                        ''
                                    ),
                                )}
                            </span>
                        )}
                    </div>
                </div>
            </ProfileViewHeader>
            <Border />
            <BrandDetailsView>
                <>
                    <span>About {selectedThread?.organization?.name}</span>
                    <ReadMore>{selectedThread?.organization?.about}</ReadMore>
                </>
            </BrandDetailsView>
            <Border />

            <UserAboutDetails selectedThread={selectedThread} />
            {/* <Border /> */}

            {/* <BrandDetailsView>
                <span>Sister Companies</span>
                <div className="company-logo">
                    {showUserProfile.other_related_companies.map(media => {
                        return <img src={media} alt="company-logo" />;
                    })}
                </div>
            </BrandDetailsView> */}
        </AppWrapper>
    );
});
