import React, { memo, useRef, useState } from 'react';
import { SliderHandler, RowBody } from './index.styled';
import ArrowIcon from 'app/images/SVGComponents/ArrowIcon';

interface Props {
    children: any;
    scrollBarHeight?: number;
    paddingStyle?: boolean;
}

export const RowSliderComponent = memo((props: Props) => {
    const { children, scrollBarHeight, paddingStyle } = props;
    let rowBodyRef: any = useRef();

    const [scrolledBy, setScrolledBy] = useState({
        leftScroll: null as any,
        rightScroll: null as any,
    });

    const checkForScroll = target => {
        target.scrollWidth === target.clientWidth &&
            setScrolledBy({ leftScroll: null, rightScroll: null });
        target.scrollWidth > target.clientWidth &&
            scrolledBy.rightScroll !== 0 &&
            setScrolledBy({ ...scrolledBy, rightScroll: true });
    };
    const handleScroll = target => {
        let scrollableLength = target.scrollWidth - target.clientWidth;
        let scrollFromLeft = Math.floor(target.scrollLeft);

        setScrolledBy({
            leftScroll: scrollFromLeft,
            rightScroll: scrollableLength - scrollFromLeft,
        });
    };

    const handleScrollTrigger = direction => {
        if (rowBodyRef && rowBodyRef.current) {
            if (direction === 'left') {
                rowBodyRef.current.scrollLeft -= 400;
            }
            if (direction === 'right') {
                rowBodyRef.current.scrollLeft += 400;
            }
        }
    };
    return (
        <SliderHandler className="scroll" height={scrollBarHeight}>
            <div
                className={`slide-handler handle-prev ${
                    scrolledBy.leftScroll ? 'visible' : ''
                } `}
                onClick={e => handleScrollTrigger('left')}
            >
                <ArrowIcon />
            </div>
            <RowBody
                padding={paddingStyle}
                ref={rowBodyRef}
                onScroll={e => handleScroll(e.target)}
                onMouseOver={e => checkForScroll(e.currentTarget)}
                className="row-body"
            >
                {children}
            </RowBody>

            <div
                className={`slide-handler handle-next ${
                    scrolledBy.rightScroll ? 'visible' : ''
                } `}
                onClick={e => handleScrollTrigger('right')}
            >
                <ArrowIcon />
            </div>
        </SliderHandler>
    );
});
