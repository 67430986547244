import styled from 'styled-components';

const HomeSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    .header-profile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        padding: 0px 16px;
        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.00533333em;
            color: #7b8794;
        }

        h4 {
            font-weight: 600;
            font-size: 24px;
            line-height: 150%;
            letter-spacing: -0.00533333em;
            color: #1f2933;
        }

        img {
            width: 56px;
            height: 56px;
            border-radius: 50%;
        }
    }

    .status-card {
        padding: 0px 16px;
    }
    .share-link-popup {
        min-height: 200px;
    }
`;

const HeaderTitle = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #1f2933;
    display: flex;
    gap: 8px;
    padding: 0px 0px 16px 16px;
    img {
        width: 16px;
    }
    .profile-name {
        margin-bottom: 16px;
        h6 {
            line-height: 150%;
            font-weight: 500;
            font-size: 14px;
            color: #7b8794;
            margin: 0;
            letter-spacing: -0.00533333em;
        }
        span {
            line-height: 150%;
            font-weight: 600;
            font-size: 24px;
            letter-spacing: -0.00533333em;
        }
    }
`;
export { HomeSection, HeaderTitle };
