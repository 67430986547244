import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { getErrorKey } from '../label';
import { signUpPageSaga } from './saga';
import { SignUpPageState } from './types';

export const initialState: SignUpPageState = {
    currentStep: '',
    apiRefCurStep: '',
    loading: false,
    stuff: '',
    validationErrors: '',
    isStepSuccess: false,
    tokenExpired: false,
    steps: [],
    influencerData: '',
    isNewSignup: false,
    stepDetails: {
        loading: false,
        error: false,
    },
    categoryDetails: {
        loading: false,
        categories: [],
        error: false,
    },
    specialQualities: {
        loading: false,
        specialQualities: [],
        error: false,
    },
    cities: {
        loading: false,
        error: false,
        locations: [],
    },
    languageDetails: {
        loading: false,
        languages: [],
        error: false,
    },
    influencerProfileSummary: {
        loading: false,
        profileSummary: {},
        error: false,
    },
    influencerCampaigns: {
        loading: false,
        allCampaigns: [],
        error: false,
    },
    dashboardAnalyticsData: {
        loading: false,
        dashboardAnalytics: {},
        error: false,
    },
    additionalInfluencerInfo: {
        loading: false,
        additionalInfo: {},
        error: false,
    },
    influencerHelpdeskInfo: {
        loading: false,
        helpdeskCategoryDetails: [],
        error: false,
    },
    updateOrDeleteAddress: {
        loading: false,
        isUpdateOrDeleteAddressSuccess: false,
        currentlyUpdatingAddressId: null,
        error: false,
    },
    updateOrDeletePaymentDetail: {
        loading: false,
        isUpdateOrDeletePaymentDetailSuccess: false,
        currentlyUpdatingPayDetail: '',
        error: false,
    },
    logoutInfluencer: {
        loading: false,
        isLogOutSuccess: false,
        error: false,
    },
    isShowingPopup: false,
};

const slice = createSlice({
    name: 'signUpPage',
    initialState,
    reducers: {
        // Signup
        register(state, action: PayloadAction<any>) {
            state.loading = true;
            state.currentStep = action.payload.currentStep;
            state.apiRefCurStep = action.payload.current_step;
            state.isStepSuccess = false;
            state.tokenExpired = false;
            state.validationErrors = '';
        },
        registerSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.stuff = action.payload?.stuff;
            state.isStepSuccess = true;
            state.steps = action.payload?.steps_details;
            if (action.payload?.data) {
                state.influencerData = action.payload?.data;
            }
            state.isNewSignup = action.payload?.is_new_signup;

            // after details update request is successful
            // we update the corresponding detail under "influencerProfileSummary" state
            let targetCurrentStep = state.apiRefCurStep;

            if (targetCurrentStep) {
                if (action.payload[targetCurrentStep]) {
                    state.influencerProfileSummary.profileSummary[
                        targetCurrentStep
                    ] = action.payload[targetCurrentStep];
                }

                if (
                    (targetCurrentStep ===
                        'instagram_profile_handle_and_cost' ||
                        targetCurrentStep ===
                            'youtube_profile_handle_and_cost') &&
                    action.payload['social_profiles_details']
                ) {
                    state.influencerProfileSummary.profileSummary[
                        'social_profiles_details'
                    ] = action.payload['social_profiles_details'];
                }
            }
        },
        registerError(state, action: PayloadAction<any>) {
            state.loading = false;
            state.isStepSuccess = false;
            if (action.payload?.error === getErrorKey['tokenInvalid']) {
                state.tokenExpired = true;
            } else {
                state.validationErrors = action.payload.stuff;
            }
        },

        //Get Steps
        getSteps(state, action: PayloadAction<any>) {
            state.stepDetails.loading = true;
            state.stepDetails.error = false;
        },
        getStepsSuccess(state, action: PayloadAction<any>) {
            state.stepDetails.loading = false;
            state.steps = action.payload?.steps_details;
            state.influencerData = action.payload?.data;
        },
        getStepsError(state, action: PayloadAction<any>) {
            state.stepDetails.loading = false;
            state.stepDetails.error = action.payload?.error;
            state.tokenExpired =
                action.payload?.error === getErrorKey['tokenInvalid']
                    ? true
                    : false;
        },

        // Get Categories
        getCategories(state, action: PayloadAction<any>) {
            state.categoryDetails.loading = true;
            state.categoryDetails.error = false;
        },
        getCategoriesSuccess(state, action: PayloadAction<any>) {
            state.categoryDetails.loading = false;
            state.categoryDetails.categories = action.payload?.categories;
        },
        getCategoriesError(state, action: PayloadAction<any>) {
            state.categoryDetails.loading = false;
            state.categoryDetails.error = action.payload?.error;
        },

        //Get Special Qualities
        getSpecialQualities(state, action: PayloadAction<any>) {
            state.specialQualities.loading = true;
            state.specialQualities.error = false;
        },
        getSpecialQualitiesSuccess(state, action: PayloadAction<any>) {
            state.specialQualities.loading = false;
            state.specialQualities.specialQualities = action.payload?.tags;
        },
        getSpecialQualitiesError(state, action: PayloadAction<any>) {
            state.specialQualities.loading = false;
            state.specialQualities.error = action.payload?.error;
        },

        //Get Cities
        getCities(state, action: PayloadAction<any>) {
            state.cities.loading = true;
            state.cities.error = false;
        },
        getCitiesSuccess(state, action: PayloadAction<any>) {
            state.cities.loading = false;
            state.cities.locations = action.payload?.locations;
        },
        getCitiesError(state, action: PayloadAction<any>) {
            state.cities.loading = false;
            state.cities.error = action.payload?.error;
        },

        // Clear Validation
        clearValidationError(state, action: PayloadAction<any>) {
            state.validationErrors = '';
        },

        // Get Languages
        getLanguages(state, action: PayloadAction<any>) {
            state.languageDetails.loading = true;
            state.languageDetails.error = false;
        },
        getLanguagesSuccess(state, action: PayloadAction<any>) {
            state.languageDetails.loading = false;
            state.languageDetails.languages = action.payload?.data;
        },
        getLanguagesError(state, action: PayloadAction<any>) {
            state.languageDetails.loading = false;
            state.languageDetails.error = action.payload?.error;
        },

        // Profile Summary
        getInfluencerProfileSummary(state, action: PayloadAction<any>) {
            state.influencerProfileSummary.loading = true;
            state.influencerProfileSummary.error = false;
        },
        getInfluencerProfileSummarySuccess(state, action: PayloadAction<any>) {
            state.influencerProfileSummary.loading = false;
            state.influencerProfileSummary.error = false;
            state.influencerProfileSummary.profileSummary =
                action.payload?.profile_summary || {};
        },
        getInfluencerProfileSummaryError(state, action: PayloadAction<any>) {
            state.influencerProfileSummary.loading = false;
            state.influencerProfileSummary.error = action.payload?.error;
        },

        // Campaigns
        getInfluencerCampaigns(state, action: PayloadAction<any>) {
            state.influencerCampaigns.loading = true;
            state.influencerCampaigns.error = false;
        },
        getInfluencerCampaignsSuccess(state, action: PayloadAction<any>) {
            state.influencerCampaigns.loading = false;
            state.influencerCampaigns.error = false;
            state.influencerCampaigns.allCampaigns =
                action.payload?.all_campaigns || [];
        },
        getInfluencerCampaignsError(state, action: PayloadAction<any>) {
            state.influencerCampaigns.loading = false;
            state.influencerCampaigns.error = action.payload?.error;
        },

        // Dashboard analytics
        getDashboardAnalytics(state, action: PayloadAction<any>) {
            state.dashboardAnalyticsData.loading = true;
            state.dashboardAnalyticsData.error = false;
        },
        getDashboardAnalyticsSuccess(state, action: PayloadAction<any>) {
            state.dashboardAnalyticsData.loading = false;
            state.dashboardAnalyticsData.error = false;
            state.dashboardAnalyticsData.dashboardAnalytics =
                action.payload?.dashboard_analytics || {};
        },
        getDashboardAnalyticsError(state, action: PayloadAction<any>) {
            state.dashboardAnalyticsData.loading = false;
            state.dashboardAnalyticsData.error = action.payload?.error;
        },

        // Additional info
        getAdditionalInfluencerInfo(state, action: PayloadAction<any>) {
            state.additionalInfluencerInfo.loading = true;
            state.additionalInfluencerInfo.error = false;
        },
        getAdditionalInfluencerInfoSuccess(state, action: PayloadAction<any>) {
            state.additionalInfluencerInfo.loading = false;
            state.additionalInfluencerInfo.error = false;
            state.additionalInfluencerInfo.additionalInfo =
                action.payload?.additional_info_details || {};
        },
        getAdditionalInfluencerInfoError(state, action: PayloadAction<any>) {
            state.additionalInfluencerInfo.loading = false;
            state.additionalInfluencerInfo.error = action.payload?.error;
        },

        // Influencer helpdesk
        getInfluencerHelpdeskInfo(state, action: PayloadAction<any>) {
            state.influencerHelpdeskInfo.loading = true;
            state.influencerHelpdeskInfo.error = false;
        },
        getInfluencerHelpdeskInfoSuccess(state, action: PayloadAction<any>) {
            state.influencerHelpdeskInfo.loading = false;
            state.influencerHelpdeskInfo.error = false;
            state.influencerHelpdeskInfo.helpdeskCategoryDetails =
                action.payload?.helpdesk || [];
        },
        getInfluencerHelpdeskInfoError(state, action: PayloadAction<any>) {
            state.influencerHelpdeskInfo.loading = false;
            state.influencerHelpdeskInfo.error = action.payload?.error;
        },

        // Update or delete influencer address
        updateOrDeleteAddress(state, action: PayloadAction<any>) {
            state.updateOrDeleteAddress.loading = true;
            state.updateOrDeleteAddress.error = false;
            state.updateOrDeleteAddress.currentlyUpdatingAddressId =
                action.payload?.address_id;
        },
        updateOrDeleteAddressSuccess(state, action: PayloadAction<any>) {
            state.updateOrDeleteAddress.loading = false;
            state.updateOrDeleteAddress.error = false;
            state.updateOrDeleteAddress.isUpdateOrDeleteAddressSuccess =
                action.payload?.ok;
            state.influencerProfileSummary.profileSummary['address_details'] =
                action.payload?.address_details;
        },
        updateOrDeleteAddressError(state, action: PayloadAction<any>) {
            state.updateOrDeleteAddress.loading = false;
            state.updateOrDeleteAddress.error = action.payload?.error;
        },

        // Update or delete influencer payment details
        updateOrDeletePaymentDetail(state, action: PayloadAction<any>) {
            state.updateOrDeletePaymentDetail.loading = true;
            state.updateOrDeletePaymentDetail.error = false;
            state.updateOrDeletePaymentDetail.currentlyUpdatingPayDetail =
                action.payload?.preferred_mode || '';
        },
        updateOrDeletePaymentDetailSuccess(state, action: PayloadAction<any>) {
            state.updateOrDeletePaymentDetail.loading = false;
            state.updateOrDeletePaymentDetail.error = false;
            state.updateOrDeletePaymentDetail.isUpdateOrDeletePaymentDetailSuccess =
                action.payload?.ok;
            state.influencerProfileSummary.profileSummary['payment_details'] =
                action.payload?.payment_details;
            state.updateOrDeletePaymentDetail.currentlyUpdatingPayDetail = '';
        },
        updateOrDeletePaymentDetailError(state, action: PayloadAction<any>) {
            state.updateOrDeletePaymentDetail.loading = false;
            state.updateOrDeletePaymentDetail.error = action.payload?.error;
            state.updateOrDeletePaymentDetail.currentlyUpdatingPayDetail = '';
        },

        // Lougout influencer
        logoutInfluencer(state, action: PayloadAction<any>) {
            state.logoutInfluencer.loading = true;
            state.logoutInfluencer.error = false;
            state.steps = [];
            state.isStepSuccess = false;
        },
        logoutInfluencerSuccess(state, action: PayloadAction<any>) {
            state.logoutInfluencer.loading = false;
            state.logoutInfluencer.error = false;
            state.logoutInfluencer.isLogOutSuccess = action.payload?.ok;
        },
        logoutInfluencerError(state, action: PayloadAction<any>) {
            state.logoutInfluencer.loading = false;
            state.logoutInfluencer.error = action.payload?.error;
        },

        // handle isShowPopup
        setIsShowPopup(state, action: PayloadAction<any>) {
            state.isShowingPopup = action.payload;
        },

        // reset register isSuccess when opening profile summary update popup
        resetProfileSummaryUpdateSuccess(state) {
            state.isStepSuccess = false;
        },

        // reset register isUpdateOrDeletePaymentDetailSuccess when opening payment details update popup
        resetPaymentDetailUpdateSuccess(state) {
            state.updateOrDeletePaymentDetail.isUpdateOrDeletePaymentDetailSuccess =
                false;
        },

        // reset register isSuccess when opening address details update popup
        resetAddressDetailUpdateSuccess(state) {
            state.updateOrDeleteAddress.isUpdateOrDeleteAddressSuccess = false;
        },
    },
});

export const { actions: signUpPageActions } = slice;

export const useSignUpPageSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga: signUpPageSaga });
    return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSignUpPageSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
