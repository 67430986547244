import React, { memo, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
    signUpPageActions,
    useSignUpPageSlice,
} from 'app/pages/SignUpPage/slice';
import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';

import { AboutPage } from './components/About';
import { AddressPage } from './components/Address';
import { Preference } from './components/Preferences';
import { PaymentOptions } from './components/Payments';
import { SocialProfiles } from './components/SocialProfiles';
import { BaseProfileTab } from './components/BaseProfileTab';

interface Props {}

export const ProfileSection = memo((props: Props) => {
    useSignUpPageSlice();
    let { path } = useRouteMatch();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(
                signUpPageActions.getInfluencerProfileSummary({
                    token: localStorage.getItem('token'),
                }),
            );
        } else {
            history.push('/signup');
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <BaseProfileTab />
                </Route>

                <Route
                    path={process.env.PUBLIC_URL + `${path}/about`}
                    component={AboutPage}
                />
                <Route
                    path={process.env.PUBLIC_URL + `${path}/preferences`}
                    component={Preference}
                />
                <Route
                    path={process.env.PUBLIC_URL + `${path}/socialprofile`}
                    component={SocialProfiles}
                />
                <Route
                    path={process.env.PUBLIC_URL + `${path}/address`}
                    component={AddressPage}
                />
                <Route
                    path={process.env.PUBLIC_URL + `${path}/payment`}
                    component={PaymentOptions}
                />
            </Switch>
        </>
    );
});
