import styled from 'styled-components/macro';

const Block = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #cbd2d9;
    border-radius: 32px;
    padding: 8px 12px;
    margin-top: 8px;
    cursor: pointer;
`;

export { Block };
