import React, { memo, useEffect, useState } from 'react';
import { Popup } from 'app/components/Popup';
import PlusIcon from 'app/images/newplus.svg';
import { Border } from 'app/pages/Tabs/ProfileTabSection/components/index.styled';
import {
    AddressStyled,
    SavedAddressSection,
} from 'app/pages/Tabs/ProfileTabSection/components/Address/index.styled';
import { AddAddressPopup } from 'app/pages/Tabs/MessengerTabSection/components/AddAddressPopup';
import MapPinIcon from 'app/images/map-pin.svg';
import {
    useMessengerSlice,
    messengerActions,
} from 'app/pages/Tabs/MessengerTabSection/slice';
import {
    selectinfluencerAddress,
    selectCreateMessage,
} from 'app/pages/Tabs/MessengerTabSection/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { InfluncerAddressSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';

interface Props {
    onClose: Function;
    message?: any;
}

export const AddressListPopup = memo((props: Props) => {
    useMessengerSlice();
    const dispatch = useDispatch();
    const { address, loading } = useSelector(selectinfluencerAddress);
    const createMessageInfo = useSelector(selectCreateMessage);
    const [addressType, setAddressType] = useState('Home');

    const [addressDetails, setAddressDetails] = useState({
        pincode: '',
        address_1: '',
        address_2: '',
        landmark: '',
        city: '',
        state: '',
        label: '',
    });
    const [addAddresPopup, setAddressPopup] = useState(false);
    const { onClose, message } = props;

    useEffect(() => {
        if (localStorage.getItem('token')) {
            dispatch(
                messengerActions.getInfluencerAddress({
                    token: localStorage.getItem('token'),
                }),
            );
        }

        // this useEffect is used to not scroll the background when popup is open
        document.body.classList.add('no-scroll');
    }, []);

    const createAddressDetails = (savedAddress: any) => {
        if (savedAddress && Object.keys(savedAddress).length !== 0) {
            dispatch(
                messengerActions.createMessage({
                    thread_id_hash: message?.thread_id_hash,
                    message_action_type: 'update',
                    message_id: message?.id,
                    ...(localStorage.getItem('token') && {
                        token: localStorage.getItem('token'),
                    }),
                    type: 'influencer',
                    message_action: 'share_address',
                    message_action_status: 'accepted',
                    template_name: 'address_action_v1',
                    template_type: 'address',
                    message: {
                        address_id: savedAddress.id,
                        address: {
                            label: savedAddress.label,
                            address_1: savedAddress.address_1,
                            address_2: savedAddress.address_2,
                            city: savedAddress.city,
                            state: savedAddress.state,
                            landmark: savedAddress.landmark,
                            pincode: savedAddress.pincode,
                        },
                    },
                }),
            );
        }
    };

    return (
        <Popup onClose={onClose}>
            <AddressStyled className="customstyle">
                <span className="custom"> My Addresses</span>
                <div
                    className="add-address new-add-address"
                    onClick={() => setAddressPopup(true)}
                >
                    <img src={PlusIcon} alt="icon" />
                    <span>Add new address</span>
                </div>
                <Border />

                <div className="address-header">
                    {address?.length ? <span> saved Addresses</span> : ''}
                    {loading ? (
                        <InfluncerAddressSkeleton count={2} />
                    ) : address?.length ? (
                        address.map((addressDetails, index) => {
                            return (
                                <>
                                    <SavedAddressSection
                                        onClick={() => {
                                            createAddressDetails(
                                                address[index],
                                            );
                                        }}
                                    >
                                        <img src={MapPinIcon} alt="icon" />
                                        <div className="address-body">
                                            <span>
                                                {addressDetails.label} -{' '}
                                                {addressDetails.city}
                                            </span>
                                            <p>
                                                {addressDetails.address_1},
                                                {addressDetails.address_2}
                                                ,and {addressDetails.city},
                                                {addressDetails.state},
                                                {addressDetails.pincode}
                                            </p>
                                        </div>
                                    </SavedAddressSection>
                                    <Border />
                                </>
                            );
                        })
                    ) : (
                        ''
                    )}
                </div>

                {addAddresPopup && (
                    <AddAddressPopup
                        onClose={() => setAddressPopup(false)}
                        message={message}
                        messengerActions={messengerActions}
                        addressDetails={addressDetails}
                        setAddressDetails={setAddressDetails}
                        addressType={addressType}
                        setAddressType={setAddressType}
                        createMessageInfo={createMessageInfo}
                    />
                )}
            </AddressStyled>
        </Popup>
    );
});
