import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { homePageSaga } from './saga';
import { HomeState } from './types';

export const initialState: HomeState = {
    isAccepted: false,
    isDeclined: false,
    campaignDeclined: false,
    loading: false,
    error: false,
    closepopOnInviteSuccess: false,
};

const slice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setIsAccepted(state, action: PayloadAction<any>) {
            state.isAccepted = action.payload;
        },
        setIsDeclined(state, action: PayloadAction<any>) {
            state.isDeclined = action.payload;
        },
        setCampaignDeclined(state, action: PayloadAction<any>) {
            state.campaignDeclined = action.payload;
        },
        acceptCampaign(state, action: PayloadAction<any>) {
            state.loading = true;
            state.closepopOnInviteSuccess = false;
        },
        acceptCampaignSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.closepopOnInviteSuccess = true;
        },
        acceptCampaignError(state, action: PayloadAction<any>) {
            state.loading = false;
        },
    },
});

export const { actions: homeActions } = slice;

export const useHomeSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga: homePageSaga });
    return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useHomeSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
