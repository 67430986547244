import React from 'react';
import './svg.css';

export default function InstaStoryIcon() {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.0003 18.8334C14.6027 18.8334 18.3337 15.1025 18.3337 10.5001C18.3337 5.89771 14.6027 2.16675 10.0003 2.16675C5.39795 2.16675 1.66699 5.89771 1.66699 10.5001C1.66699 15.1025 5.39795 18.8334 10.0003 18.8334Z"
                stroke="#7B8794"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="4 4"
            />
            <path
                d="M8.125 6.75L13.7963 10.3958L8.125 14.0417V6.75Z"
                stroke="#7B8794"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
