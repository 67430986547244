import React from 'react';
import './svg.css';

export default function PlayerIcon() {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.16699 5.7475C4.16699 4.56075 5.47986 3.84399 6.47813 4.48573L13.8709 9.23823C14.7894 9.82869 14.7894 11.1713 13.8709 11.7618L6.47813 16.5143C5.47986 17.156 4.16699 16.4393 4.16699 15.2525V5.7475Z"
                stroke="#7B8794"
                strokeWidth="1.7"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
