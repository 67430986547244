import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noScroll } from 'utils/helper';
import Skeleton from 'react-loading-skeleton';

import {
    selectInfluencerProfileSummary,
    selectShowPopupStatus,
    selectSignUpPage,
} from 'app/pages/SignUpPage/slice/selectors';
import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import { HtmlElementSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';

import UnmarriedIcon from 'app/images/user.svg';
import RingIcon from 'app/images/ring-icon.svg';
import SmileIcon from 'app/images/smile-icon.svg';
import PregnantIcon from 'app/images/pregnant-icon.svg';

import { FamilyInfoPopUp } from '../FamilyInfoPopup';
import { PersonalInfoCard } from '../../index.styled';

interface Props {
    familyDetails: any;
}

export const FamilyInfo = memo((props: Props) => {
    const {
        familyDetails = {},
        familyDetails: { is_married = 0, kids_count = 0, kids_details = [] },
    } = props;
    const dispatch = useDispatch();
    const signUpPageStore = useSelector(selectSignUpPage);
    const isShowingPopup = useSelector(selectShowPopupStatus);
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const { loading: profileSummaryLoading } = profileSummarySelector;

    const [showFamilyInfoFormPopup, setShowFamilyInfoFormPopup] =
        useState(false);

    useEffect(() => {
        noScroll(showFamilyInfoFormPopup);
    }, [showFamilyInfoFormPopup]);

    const handleShowFamilyInfoPopup = (showState: boolean = false) => {
        setShowFamilyInfoFormPopup(showState);
        dispatch(signUpPageActions.setIsShowPopup(showState));
    };

    const hasFamilyDetails = () => {
        return (
            is_married !== null ||
            kids_count !== null ||
            kids_details.length !== 0
        );
    };

    useEffect(() => {
        if (!signUpPageStore.loading && signUpPageStore.isStepSuccess) {
            handleShowFamilyInfoPopup(false);
        }
    }, [signUpPageStore]);

    return (
        <>
            <PersonalInfoCard className="family-info-card">
                <div className="info-card__header">
                    <h4>Family Info</h4>
                    {profileSummaryLoading ? (
                        <HtmlElementSkeleton width={25} height={12} />
                    ) : (
                        <span
                            onClick={() => {
                                handleShowFamilyInfoPopup(true);
                                dispatch(
                                    signUpPageActions.resetProfileSummaryUpdateSuccess(),
                                );
                            }}
                        >
                            {Object.keys(familyDetails).length !== 0 &&
                            hasFamilyDetails()
                                ? 'Edit'
                                : '+ Add'}
                        </span>
                    )}
                </div>

                <div className="info-card__body">
                    {profileSummaryLoading ? (
                        <ul>
                            <Skeleton
                                count={3}
                                height={20}
                                style={{ marginBottom: '10px' }}
                            />
                        </ul>
                    ) : Object.keys(familyDetails).length !== 0 &&
                      hasFamilyDetails() ? (
                        <>
                            <span className="header-border"></span>
                            <ul className="family-details__list">
                                {is_married === 0 && (
                                    <li>
                                        <img
                                            src={UnmarriedIcon}
                                            alt="unmarried-icon"
                                        />
                                        <span>Unmarried</span>
                                    </li>
                                )}
                                {is_married === 1 && (
                                    <>
                                        <li>
                                            <img
                                                src={RingIcon}
                                                alt="ring-icon"
                                            />

                                            <span>Married</span>
                                        </li>

                                        <li>
                                            <img
                                                src={SmileIcon}
                                                alt="smile-icon"
                                            />
                                            {kids_details.length !== 0 ? (
                                                <ul className="kids-details-list">
                                                    {kids_details.length !==
                                                        0 &&
                                                        kids_details.map(
                                                            (kid, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="kid-detail__list-item"
                                                                >
                                                                    <p>
                                                                        <span className="kid-age">
                                                                            {kid?.age ||
                                                                                'NA'}
                                                                        </span>{' '}
                                                                        year old{' '}
                                                                        <span className="kid-gender">
                                                                            {kid?.gender ===
                                                                            'male'
                                                                                ? 'boy'
                                                                                : 'girl'}
                                                                        </span>
                                                                    </p>
                                                                </li>
                                                            ),
                                                        )}
                                                </ul>
                                            ) : (
                                                <span className="kid-details-na">
                                                    NA
                                                </span>
                                            )}
                                        </li>
                                    </>
                                )}
                                {is_married === 2 && (
                                    <li>
                                        <img
                                            src={PregnantIcon}
                                            alt="unmarried-icon"
                                        />
                                        <span>Expecting</span>
                                    </li>
                                )}
                            </ul>
                        </>
                    ) : (
                        <p className="prompt-message">
                            Adding family info increases your chances of getting
                            campaign by <span>80%</span>
                        </p>
                    )}
                </div>
            </PersonalInfoCard>

            {showFamilyInfoFormPopup && isShowingPopup && (
                <FamilyInfoPopUp
                    onClose={() => handleShowFamilyInfoPopup(false)}
                    prefillFamilyData={{ is_married, kids_count, kids_details }}
                />
            )}
        </>
    );
});
