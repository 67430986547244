import React, { memo } from 'react';

import DatabaseAlertIcon from 'app/images/messenger-banner.png';
import { DataIsProcessingAlertWrapper } from 'app/pages/Tabs/MessengerTabSection/index.styled';

interface Props {}

export const DataUnavailable = memo((props: Props) => {
    return (
        <DataIsProcessingAlertWrapper>
            <section className="data-unavailable-alert">
                <img
                    src={DatabaseAlertIcon}
                    className="processing-data-gears-icon"
                    alt="processing data gears icon"
                />
            </section>
        </DataIsProcessingAlertWrapper>
    );
});
