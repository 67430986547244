import React, { memo, useRef, useState, useEffect } from 'react';
import Intro from 'app/components/Intro';
import { Popup } from 'app/components/Popup';
import { Button } from 'app/components/Button';
import AuthScreen from 'app/components/AuthScreen';
import AuthFooter from 'app/components/AuthFooter';
import Form, { FormField, FormLabel } from 'app/components/Form';
import EmailIcon from 'app/images/mail-icon.svg';
import RewindIcon from 'app/images/SVGComponents/Rewind';
import { selectOptions, contactIconList } from 'app/pages/Tabs/label';
import { OutlineButton } from 'app/components/OutlineButton';
import PlusGrey from 'app/images/plus-grey.svg';
import { Checkbox } from 'app/pages/SignUpPage/components/Stepper/components/ContactDetails/index.styled';
import { onlyNumberKey } from 'utils/helper';
import {
    DropDownBtn,
    SelectOptionSection,
    CheckboxStyle,
} from './index.styled';
import {
    useMessengerSlice,
    messengerActions,
} from 'app/pages/Tabs/MessengerTabSection/slice';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'app/components/Spinner';
import { selectCreateMessage } from 'app/pages/Tabs/MessengerTabSection/slice/selectors';

interface Props {
    onClose: Function;
    message?: any;
}

export const AddContactPopup = memo((props: Props) => {
    useMessengerSlice();
    const createMessageInfo = useSelector(selectCreateMessage);
    const dispatch = useDispatch();
    const { onClose, message } = props;
    const [contactIconListShow, setContactIconListShow]: any = useState();
    const searchRef: any = useRef();

    const handleClickOutside = event => {
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setContactIconListShow(true);
            document.removeEventListener('click', handleClickOutside, true);
        }
    };
    const [emailList, setEmailList] = useState<any>([]);
    const [contactList, setContactList] = useState<any>([]);
    const [same, setSame] = useState(false);
    // this useEffect is used to not scroll the background when popup is open
    useEffect(() => {
        document.body.classList.add('no-scroll');
    }, []);

    useEffect(() => {
        if (message?.template_value) {
            setContactList(
                message.template_value?.contact_details?.phone?.length
                    ? message.template_value.contact_details.phone
                    : [
                          {
                              alpha_2_code: 'IN',
                              phone_number: '',
                              icon: 'call',
                              checked: true,
                              type: 'call',
                              value: '',
                          },
                          {
                              alpha_2_code: 'IN',
                              phone_number: '',
                              icon: 'whatsapp',
                              checked: true,
                              type: 'whatsapp',
                              value: '',
                          },
                      ],
            );
            setEmailList(
                message.template_value?.contact_details?.emails?.length
                    ? message.template_value.contact_details.emails
                    : [{ icon: 'email', email: '', checked: true, value: '' }],
            );
            setSame(
                message.template_value?.contact_details?.contact?.length &&
                    message.template_value.contact_details.phone[0][
                        'phone_number'
                    ] ===
                        message.template_value.contact_details.phone[1][
                            'phone_number'
                        ]
                    ? true
                    : false,
            );
        }
    }, [message]);

    const getFormattedList = contactArray => {
        const formattedList =
            contactArray && contactArray.length
                ? contactArray.filter(item => item.value && item.checked)
                : '';
        return formattedList;
    };

    const addContactAction = () => {
        dispatch(
            messengerActions.createMessage({
                thread_id_hash: message?.thread_id_hash,
                message_action_type: 'update',
                message_id: message && message.id,
                ...(localStorage.getItem('token') && {
                    token: localStorage.getItem('token'),
                }),
                message_action: 'share_contact',
                message_action_status: 'accepted',
                template_name: 'contact_action_v1',
                template_type: 'contact',
                type: 'influencer',
                message: {
                    contact_details: {
                        phone: getFormattedList(contactList).length
                            ? getFormattedList(contactList)
                            : [],
                        emails: getFormattedList(emailList).length
                            ? getFormattedList(emailList)
                            : [],
                    },
                },
            }),
        );
    };

    const addNewContact = () => {
        setContactList([
            ...contactList,
            {
                alpha_2_code: 'IN',
                icon: 'call',
                phone_number: '',
                checked: true,
                type: 'call',
                value: '',
            },
        ]);
    };
    return (
        <Popup onClose={onClose}>
            <AuthScreen className="auth-screen custom-auth-styled">
                <Intro className="intro custom-intro-style">
                    <h2>Contact Details</h2>
                </Intro>
                <Form className="form-list custom-form-style">
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="Phone Number">
                            Phone Number
                        </FormLabel>
                        {contactList?.length &&
                            contactList.map((option, index) => (
                                <SelectOptionSection>
                                    {/* <DropDownBtn>
                                        <div
                                            className={`dropdown-btn  ${contactIconListShow}`}
                                            onClick={() => {
                                                setContactIconListShow(index);
                                            }}
                                        >
                                            <img
                                                src={
                                                    contactIconList[option.icon]
                                                }
                                                alt="icon"
                                            />
                                            <span>
                                                <RewindIcon />
                                            </span>
                                        </div>

                                        {contactIconListShow === index && (
                                            <ul
                                                ref={searchRef}
                                                className={`dropdown-content ${
                                                    contactIconListShow
                                                        ? 'show'
                                                        : ''
                                                }`}
                                            >
                                                {selectOptions.length &&
                                                    selectOptions.map(item => {
                                                        return (
                                                            <li
                                                                className="dropdown-itm"
                                                                id={`select ${index}`}
                                                                onClick={() => {
                                                                    setContactIconListShow(
                                                                        false,
                                                                    );
                                                                    document.body.addEventListener(
                                                                        'click',
                                                                        handleClickOutside,
                                                                        true,
                                                                    );

                                                                    let contactListCopy =
                                                                        [
                                                                            ...contactList,
                                                                        ];
                                                                    let contactObj =
                                                                        {
                                                                            ...contactListCopy[
                                                                                index
                                                                            ],
                                                                        };
                                                                    contactObj.type =
                                                                        item.label;
                                                                    contactObj.icon =
                                                                        item.label;
                                                                    contactListCopy[
                                                                        index
                                                                    ] = contactObj;
                                                                    setContactList(
                                                                        contactListCopy,
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        item.icon
                                                                    }
                                                                    alt="icon"
                                                                />
                                                                {item.label}
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        )}

                                        <input
                                            // id={`contactNumber ${index}`}
                                            className="phone-number-input"
                                            type="tel"
                                            min={0}
                                            placeholder="Eg. +91 84774 46637"
                                            value={
                                                contactList[index].phone_number
                                            }
                                            onChange={e => {
                                                e.preventDefault();
                                                let contactListCopy = [
                                                    ...contactList,
                                                ];
                                                let contactObj = {
                                                    ...contactListCopy[index],
                                                };
                                                contactObj.phone_number =
                                                    e.target.value;
                                                contactObj.value =
                                                    e.target.value;
                                                contactListCopy[index] =
                                                    contactObj;
                                                setContactList(contactListCopy);
                                            }}
                                            onKeyPress={e => {
                                                !onlyNumberKey(e) &&
                                                    e.preventDefault();
                                            }}
                                        />

                                        <Checkbox className="checkbox">
                                            <input
                                                type="checkbox"
                                                checked={option.checked}
                                                onChange={() => {
                                                    let contactListCopy = [
                                                        ...contactList,
                                                    ];
                                                    let contactObj = {
                                                        ...contactListCopy[
                                                            index
                                                        ],
                                                    };
                                                    contactObj.checked =
                                                        !option.checked;
                                                    contactListCopy[index] =
                                                        contactObj;
                                                    setContactList(
                                                        contactListCopy,
                                                    );
                                                }}
                                                id={`contactNumberChecked ${index}`}
                                            />
                                            <label
                                                htmlFor={`contactNumberChecked ${index}`}
                                            ></label>
                                        </Checkbox>
                                    </DropDownBtn> */}

                                    <DropDownBtn>
                                        <div
                                            className={`dropdown-btn  ${contactIconListShow}`}
                                            onClick={() => {
                                                setContactIconListShow(index);
                                            }}
                                        >
                                            <img
                                                src={
                                                    contactIconList[option.icon]
                                                }
                                                alt="icon"
                                            />
                                        </div>
                                        <input
                                            // id={`contactNumber ${index}`}
                                            className="phone-number-input"
                                            type="tel"
                                            min={0}
                                            placeholder="Eg. +91 84774 46637"
                                            value={
                                                contactList[index].phone_number
                                            }
                                            onChange={e => {
                                                e.preventDefault();
                                                let contactListCopy = [
                                                    ...contactList,
                                                ];
                                                let contactObj = {
                                                    ...contactListCopy[index],
                                                };
                                                contactObj.phone_number =
                                                    e.target.value;
                                                contactObj.value =
                                                    e.target.value;
                                                contactListCopy[index] =
                                                    contactObj;
                                                setContactList(contactListCopy);
                                            }}
                                            onKeyPress={e => {
                                                !onlyNumberKey(e) &&
                                                    e.preventDefault();
                                            }}
                                        />
                                    </DropDownBtn>
                                    {option.type === 'whatsapp' && (
                                        <CheckboxStyle className="checkbox">
                                            <input
                                                type="checkbox"
                                                id="same_as_phone_number"
                                                checked={same}
                                                onChange={e => {
                                                    setSame(!same);
                                                    if (e.target.checked) {
                                                        let tempArray = [
                                                            ...contactList,
                                                        ];
                                                        let tempObj = {
                                                            ...tempArray[1],
                                                        };
                                                        tempObj.phone_number =
                                                            tempArray[0]?.phone_number;
                                                        tempObj.value =
                                                            tempArray[0]?.phone_number;
                                                        tempArray[1] = tempObj;
                                                        setContactList(
                                                            tempArray,
                                                        );
                                                    } else {
                                                        let tempArray = [
                                                            ...contactList,
                                                        ];
                                                        let tempObj = {
                                                            ...tempArray[1],
                                                        };
                                                        tempObj.phone_number =
                                                            '';
                                                        tempObj.value = '';
                                                        tempArray[1] = tempObj;
                                                        setContactList(
                                                            tempArray,
                                                        );
                                                    }
                                                }}
                                                onKeyPress={e => {
                                                    !onlyNumberKey(e) &&
                                                        e.preventDefault();
                                                }}
                                            />
                                            <label htmlFor="same_as_phone_number">
                                                Same as phone number
                                            </label>
                                        </CheckboxStyle>
                                    )}
                                </SelectOptionSection>
                            ))}
                        {/* <OutlineButton
                            className="add-btn"
                            onClick={(e: any) => {
                                e.preventDefault();
                                addNewContact();
                            }}
                            color="#3E4C59"
                            bgColor="#CBD2D9"
                            icon={PlusGrey}
                        >
                            Add New
                        </OutlineButton> */}
                    </FormField>

                    <FormField className="form-list-item">
                        <FormLabel htmlFor="AddEmailId">Email ID</FormLabel>

                        <SelectOptionSection className="email">
                            {emailList.map((email, index) => (
                                <DropDownBtn key={index}>
                                    <div
                                        className="dropdown-btn"
                                        id="emailList"
                                    >
                                        <img src={EmailIcon} alt="email-icon" />
                                    </div>

                                    <input
                                        className="phone-number-input"
                                        type="text"
                                        id={`emailList${index}`}
                                        placeholder="Enter your email"
                                        value={emailList[index].email}
                                        onChange={e => {
                                            e.preventDefault();
                                            let emailListCopy = [...emailList];
                                            let emailObj = {
                                                ...emailListCopy[index],
                                            };
                                            emailObj.email = e.target.value;
                                            emailObj.value = e.target.value;
                                            emailListCopy[index] = emailObj;
                                            setEmailList(emailListCopy);
                                        }}
                                    />

                                    {/* <Checkbox className="checkbox">
                                        <input
                                            type="checkbox"
                                            checked={email.checked}
                                            onChange={() => {
                                                let emailListCopy = [
                                                    ...emailList,
                                                ];
                                                let emailObj = {
                                                    ...emailListCopy[index],
                                                };
                                                emailObj.checked =
                                                    !email.checked;
                                                emailListCopy[index] = emailObj;
                                                setEmailList(emailListCopy);
                                            }}
                                            id={`emailChecked ${index}`}
                                        />
                                        <label
                                            htmlFor={`emailChecked ${index}`}
                                        ></label>
                                    </Checkbox> */}
                                </DropDownBtn>
                            ))}
                            {/* <OutlineButton
                                className="add-btn"
                                onClick={index => {
                                    index.preventDefault();
                                    setEmailList([
                                        ...emailList,
                                        {
                                            icon: 'email',
                                            email: '',
                                            checked: true,
                                            value: '',
                                        },
                                    ]);
                                }}
                                color="#3E4C59"
                                bgColor="#CBD2D9"
                                icon={PlusGrey}
                            >
                                Add New
                            </OutlineButton> */}
                        </SelectOptionSection>
                    </FormField>
                </Form>

                <AuthFooter className="footer">
                    <Button
                        onClick={() => addContactAction()}
                        disabled={
                            getFormattedList(contactList).length &&
                            getFormattedList(emailList).length
                                ? false
                                : true
                        }
                    >
                        {!createMessageInfo?.loading ? (
                            'Save Contact'
                        ) : (
                            <Spinner color="light" />
                        )}
                    </Button>
                </AuthFooter>
            </AuthScreen>
        </Popup>
    );
});
