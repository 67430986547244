import React, { memo, useEffect, useState } from 'react';
import { Button } from 'app/components/Button';
import Form, {
    FormField,
    FormLabel,
    FormRadio,
    FormRadioLabel,
    RadioIcon,
    RadioLabel,
    Required,
} from 'app/components/Form';
import { Popup } from 'app/components/Popup';
import PopupContent from 'app/components/PopupContent';
import { indianStatesList } from 'app/pages/HomePage/components/EnterAddressPopup/label';
import { useDispatch } from 'react-redux';
import Spinner from 'app/components/Spinner';
import MapPinIcon from 'app/images/map-pin.svg';
import { Block } from 'app/pages/HomePage/components/EnterAddressPopup/index.styled';
import LocationIcon from 'app/images/location.svg';
import HomeIcon from 'app/images/home.svg';
import OfficeIcon from 'app/images/briefcase.svg';
import { SerachInputType } from './index.styled';

interface Props {
    onClose: Function;
    message?: any;
    messengerActions: any;
    addressDetails?: any;
    setAddressDetails?: any;
    addressType?: any;
    setAddressType: any;
    createMessageInfo: any;
}

export const AddAddressPopup = memo((props: Props) => {
    const dispatch = useDispatch();
    const {
        onClose,
        message,
        messengerActions,
        addressDetails,
        setAddressDetails,
        addressType,
        setAddressType,
        createMessageInfo,
    } = props;

    const onAddressChange = ({ target: { name, value } }) => {
        setAddressDetails({
            ...addressDetails,
            [name]: value,
        });
    };

    const dispatchAddressDetails = () => {
        dispatch(
            messengerActions.createMessage({
                thread_id_hash: message?.thread_id_hash,
                message_action_type: 'update',
                message_id: message?.id,
                ...(localStorage.getItem('token') && {
                    token: localStorage.getItem('token'),
                }),
                type: 'influencer',
                message_action: 'share_address',
                message_action_status: 'accepted',
                template_name: 'address_action_v1',
                template_type: 'address',
                message: {
                    address_id: 0,
                    address: {
                        label:
                            addressType === 'Other'
                                ? addressDetails.label
                                : addressType,
                        address_1: addressDetails.address_1,
                        address_2: addressDetails.address_2,
                        city: addressDetails.city,
                        state: addressDetails.state,
                        landmark: addressDetails.landmark,
                        pincode: addressDetails.pincode,
                    },
                },
            }),
        );
    };

    const canDispatch = () => {
        return addressDetails.pincode &&
            addressDetails.pincode.length &&
            addressDetails.state &&
            addressDetails.city &&
            addressDetails.address_1 &&
            addressDetails.address_2 &&
            (addressType === 'Other' ? addressDetails.label : addressType)
            ? true
            : false;
    };

    // this useEffect is used to not scroll the background when popup is open
    useEffect(() => {
        document.body.classList.add('no-scroll');
    }, []);
    return (
        <Popup onClose={onClose}>
            <PopupContent className="popup-content edit-address-popup">
                <>
                    <h2>
                        {' '}
                        <img
                            src={MapPinIcon}
                            className="location-pin"
                            alt="map-pin"
                        />
                        New Address
                    </h2>
                    <Form className="form-list">
                        <FormField className="form-list-item">
                            <FormLabel htmlFor="pincode">
                                Pincode
                                <Required className="required">*</Required>
                            </FormLabel>
                            <input
                                type="number"
                                placeholder="Eg. 457001 (0-6 digits)"
                                id="pincode"
                                name="pincode"
                                min={0}
                                value={addressDetails.pincode}
                                onChange={onAddressChange}
                            />
                        </FormField>
                        <FormField className="form-list-item">
                            <FormLabel htmlFor="flatNumber">
                                Flat/House no., building, company apartment
                                <Required className="required">*</Required>
                            </FormLabel>
                            <input
                                type="text"
                                placeholder="Eg. KC 1801"
                                name="address_1"
                                id="flatNumber"
                                value={addressDetails.address_1}
                                onChange={onAddressChange}
                            />
                        </FormField>
                        <FormField className="form-list-item">
                            <FormLabel htmlFor="areaStreet">
                                Area, street, sector, village
                                <Required className="required">*</Required>
                            </FormLabel>
                            <input
                                type="text"
                                name="address_2"
                                placeholder="Eg. Sesame Street"
                                id="areaStreet"
                                value={addressDetails.address_2}
                                onChange={onAddressChange}
                            />
                        </FormField>
                        <FormField className="form-list-item">
                            <FormLabel htmlFor="landmark">Landmark</FormLabel>
                            <input
                                type="text"
                                name="landmark"
                                placeholder="Eg. near Apollo Hospital"
                                id="landmark"
                                value={addressDetails.landmark}
                                onChange={onAddressChange}
                            />
                        </FormField>
                        <FormField className="form-list-item">
                            <FormLabel htmlFor="townCity">
                                Town/City
                                <Required className="required">*</Required>
                            </FormLabel>
                            <input
                                type="text"
                                name="city"
                                placeholder="Eg. Bangalore"
                                id="townCity"
                                value={addressDetails.city}
                                onChange={onAddressChange}
                            />
                        </FormField>
                        <FormField className="form-list-item">
                            <FormLabel htmlFor="state">
                                State
                                <Required className="required">*</Required>
                            </FormLabel>
                            <select
                                name="state"
                                id="state"
                                value={addressDetails.state}
                                onChange={onAddressChange}
                                placeholder="Select your state"
                                className="state-select-dropdown"
                            >
                                <option value="" disabled selected>
                                    Select your state
                                </option>
                                {indianStatesList.map(state => (
                                    <option value={state}>{state}</option>
                                ))}
                            </select>
                        </FormField>

                        <FormField className="input-radius other location">
                            <FormLabel htmlFor="addressName">
                                Save this address as
                            </FormLabel>
                            <FormRadio className="form-list-item radio-group">
                                <FormRadioLabel htmlFor="home">
                                    <input
                                        type="radio"
                                        id="home"
                                        value={'Home'}
                                        name="saveAddress"
                                        checked={addressType === 'Home'}
                                        onChange={e => {
                                            setAddressType(e.target.value);
                                            setAddressDetails({
                                                ...addressDetails,
                                                label: '',
                                            });
                                        }}
                                    />
                                    <Block className="value-block">
                                        <RadioIcon>
                                            <img src={HomeIcon} alt="home" />
                                        </RadioIcon>
                                        <RadioLabel>Home</RadioLabel>
                                    </Block>
                                </FormRadioLabel>
                                <FormRadioLabel htmlFor="office">
                                    <input
                                        type="radio"
                                        id="office"
                                        value={'Office'}
                                        name="saveAddress"
                                        checked={addressType === 'Office'}
                                        onChange={e => {
                                            setAddressType(e.target.value);
                                            setAddressDetails({
                                                ...addressDetails,
                                                label: '',
                                            });
                                        }}
                                    />
                                    <Block className="value-block">
                                        <RadioIcon>
                                            <img
                                                src={OfficeIcon}
                                                alt="office"
                                            />
                                        </RadioIcon>
                                        <RadioLabel>Office</RadioLabel>
                                    </Block>
                                </FormRadioLabel>
                                <FormRadioLabel htmlFor="other">
                                    <input
                                        type="radio"
                                        id="other"
                                        value={'Other'}
                                        name="saveAddress"
                                        checked={addressType === 'Other'}
                                        onChange={e => {
                                            setAddressType(e.target.value);
                                        }}
                                    />
                                    <Block className="value-block">
                                        <RadioIcon>
                                            <img
                                                src={LocationIcon}
                                                alt="other"
                                            />
                                        </RadioIcon>
                                        <RadioLabel>Other</RadioLabel>
                                    </Block>
                                </FormRadioLabel>
                            </FormRadio>
                            <SerachInputType>
                                {addressType === 'Other' && (
                                    <input
                                        type="search"
                                        name="label"
                                        placeholder="Name this address"
                                        id="addressName"
                                        value={addressDetails.label}
                                        onChange={onAddressChange}
                                        autoFocus={false}
                                        autoComplete="off"
                                    />
                                )}
                            </SerachInputType>
                        </FormField>

                        <Button
                            bgColor="#F35627"
                            onClick={e => {
                                e.preventDefault();
                                dispatchAddressDetails();
                            }}
                            disabled={!canDispatch()}
                        >
                            {!createMessageInfo?.loading ? (
                                'Save Address'
                            ) : (
                                <Spinner color="light" />
                            )}
                        </Button>
                    </Form>{' '}
                </>
            </PopupContent>
        </Popup>
    );
});
