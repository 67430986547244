/**
 *
 * AppWrapper
 *
 */
import styled from 'styled-components/macro';

const AppWrapper = styled.div`
    /* box-shadow: 0px 0px 1.75182px rgba(0, 0, 0, 0.12),
        0px 1.75182px 1.75182px rgba(0, 0, 0, 0.08); */
    background-color: white;
    max-width: 640px;
    width: 100%;
    min-height: 100vh;
    margin: 0px auto;
    padding: 0 16px calc(56px + 2rem);
    position: relative;
    &.hide-footer {
        padding-bottom: 2rem;
    }
    &.remove-padding {
        padding: 0;
    }
    &.splash-active {
        overflow: hidden;
    }
`;

export default AppWrapper;
