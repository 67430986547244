/**
 *
 * FamilyInfo
 *
 */
import React, { memo, useState, useEffect } from 'react';
import Form, { FormField, FormLabel, Required } from 'app/components/Form';
import { Button } from 'app/components/Button';
import { OutlineButton } from 'app/components/OutlineButton';
import { Radio } from 'app/components/Radio';
import { RadioGroup } from 'app/components/Radio/index.styled';
import AuthScreen from 'app/components/AuthScreen';
import AuthFooter from 'app/components/AuthFooter';
import Intro from 'app/components/Intro';
import PlusGrey from 'app/images/plus-grey.svg';
import Spinner from 'app/components/Spinner';

import { useDispatch, useSelector } from 'react-redux';
import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import { genderOptions, signupStepsKeyList } from 'app/pages/SignUpPage/label';
import { selectSignUpPage } from 'app/pages/SignUpPage/slice/selectors';
import { onlyNumberKey } from 'utils/helper';

interface Props {
    onClickNext?: any;
    customFormStyled?: boolean;
    showIntroStyled?: boolean;
    prefillFamilyData?: {
        is_married: number;
        kids_count: number;
        kids_details: any;
    };
}

export const FamilyInfo = memo((props: Props) => {
    const { onClickNext, customFormStyled, prefillFamilyData } = props;
    const dispatch = useDispatch();
    const signUpPageStore = useSelector(selectSignUpPage);

    const [familyDetailsForm, setFamilyDetailsForm] = useState<{
        isMarried: number | null;
        hasKids: number | null;
        isExpecting: boolean | null;
        childrenCount: number;
        children: any;
    }>({
        isMarried: null,
        hasKids: null,
        isExpecting: null,
        childrenCount: 0,
        children: [],
    });

    useEffect(() => {
        if (
            customFormStyled &&
            prefillFamilyData &&
            Object.keys(prefillFamilyData).length !== 0
        ) {
            const { is_married, kids_count, kids_details } = prefillFamilyData;

            !is_married
                ? setFamilyDetailsForm({
                      ...familyDetailsForm,
                      isMarried: 0,
                  })
                : setFamilyDetailsForm({
                      ...familyDetailsForm,
                      isMarried: 1,
                      hasKids: Boolean(Number(kids_count)) ? 1 : 0,
                      isExpecting:
                          is_married === 2 && Number(kids_count) === 0
                              ? true
                              : null,
                      childrenCount: Number(kids_count),
                      children:
                          kids_details.length !== 0
                              ? [...kids_details]
                              : [{ gender: '', age: '' }],
                  });
        }
    }, []);

    useEffect(() => {
        if (
            signUpPageStore.isStepSuccess &&
            signUpPageStore.currentStep === 'familyDetails' &&
            !customFormStyled
        ) {
            onClickNext();
        }
    }, [signUpPageStore, onClickNext]);

    const renderKids = () => {
        return familyDetailsForm.children.map((child: any, index: number) => {
            return (
                <FormField className="form-list-item" key={index}>
                    <FormLabel htmlFor={`child-${index}`}>
                        <h3>Child No. {index + 1}</h3>
                        <Required className="required">*</Required>
                    </FormLabel>
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="age">
                            What’s the child’s gender?
                            <Required className="required">*</Required>
                        </FormLabel>
                        <RadioGroup className="radio-group">
                            {genderOptions.map(
                                (
                                    genderOption: {
                                        genderVal: string;
                                        genderLabel: string;
                                    },
                                    idx,
                                ) => (
                                    <Radio
                                        id={`${genderOption.genderVal}-${index}`}
                                        key={`${genderOption.genderVal}-${index}`}
                                        checked={
                                            child.gender ===
                                            genderOption.genderVal
                                        }
                                        onChange={e => {
                                            if (e.target.checked) {
                                                let tempArray = [
                                                    ...familyDetailsForm.children,
                                                ];
                                                //making additional copy of specific object to prevent
                                                // "cannot-assign-to-read-only-property-of-object" typeError
                                                let tempObj = {
                                                    ...tempArray[index],
                                                    gender: genderOption.genderVal,
                                                };
                                                tempArray[index] = tempObj;

                                                setFamilyDetailsForm({
                                                    ...familyDetailsForm,
                                                    children: tempArray,
                                                });
                                            }
                                        }}
                                        label={genderOption.genderLabel}
                                    />
                                ),
                            )}
                        </RadioGroup>
                    </FormField>
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="name">
                            How old is the child?
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="tel"
                            min={0}
                            id={`age${index}`}
                            placeholder="Enter kid’s age (in years)"
                            value={child.age}
                            onChange={e => {
                                let tempArray = [...familyDetailsForm.children];
                                tempArray[index].age = e.target.value;
                                setFamilyDetailsForm({
                                    ...familyDetailsForm,
                                    children: tempArray,
                                });
                            }}
                            onKeyPress={e => {
                                !onlyNumberKey(e) && e.preventDefault();
                            }}
                        />
                    </FormField>
                </FormField>
            );
        });
    };

    const updateFamilyDetailsForm = (
        fieldToUpdate: string,
        valueToUpdate: any,
    ) => {
        setFamilyDetailsForm({
            ...familyDetailsForm,
            [fieldToUpdate]: valueToUpdate,
        });
    };

    const handleAddNewChildForm = () => {
        setFamilyDetailsForm({
            ...familyDetailsForm,
            children: [
                ...familyDetailsForm.children,
                {
                    gender: '',
                    age: '',
                },
            ],
            childrenCount: familyDetailsForm.childrenCount + 1,
        });
    };

    const canDispatch = () => {
        const { isMarried, hasKids, isExpecting, children } = familyDetailsForm;
        let tempChildren = [...children];

        return !signUpPageStore.loading &&
            (isMarried === 0 ||
                (isMarried === 1 &&
                    ((hasKids !== null &&
                        hasKids === 1 &&
                        tempChildren.every(
                            child => child.gender && child.age,
                        )) ||
                        hasKids === 0)) ||
                (isMarried === 2 && hasKids === 0 && isExpecting === true))
            ? true
            : false;
    };

    const onSaveFamilyDetails = () => {
        let tempChildren = [...familyDetailsForm.children];

        if (familyDetailsForm.isMarried !== null) {
            if (canDispatch()) {
                dispatch(
                    signUpPageActions.register({
                        current_step: signupStepsKeyList['familyDetails'],
                        is_married: Number(familyDetailsForm.isMarried),
                        having_kids: Number(familyDetailsForm.hasKids) ? 1 : 0,
                        children: tempChildren.filter(
                            children => children.gender && children.age,
                        ),
                        currentStep: 'familyDetails',
                        token: localStorage.getItem('token'),
                        action_type: !customFormStyled ? 'signup' : 'edit',
                    }),
                );
            }
        }
    };

    return (
        <AuthScreen
            className={`auth-screen family-info ${
                customFormStyled ? 'custom-auth-styled' : false
            }`}
        >
            <Intro
                className={`intro ${
                    customFormStyled ? 'custom-intro-style' : false
                }`}
            >
                <h2>Family Info</h2>
            </Intro>
            <Form
                className={`form-list custom-form ${
                    customFormStyled ? 'custom-form-style' : false
                }`}
            >
                {/* --------------------- UPDATE MARRIAGE STATUS */}
                <FormField className="form-list-item">
                    <FormLabel htmlFor="age">
                        Are you married?
                        <Required className="required">*</Required>
                    </FormLabel>
                    <RadioGroup className="radio-group">
                        <Radio
                            id="marriedtrue"
                            checked={
                                familyDetailsForm.isMarried === 1 ||
                                familyDetailsForm.isMarried === 2
                            }
                            onChange={e => {
                                if (e.target.checked) {
                                    updateFamilyDetailsForm('isMarried', 1);
                                }
                            }}
                            label={'Yes'}
                        />
                        <Radio
                            id="marriedfalse"
                            checked={familyDetailsForm.isMarried === 0}
                            onChange={e => {
                                if (e.target.checked) {
                                    setFamilyDetailsForm({
                                        ...familyDetailsForm,
                                        isMarried: 0,
                                        isExpecting: null,
                                        hasKids: null,
                                        children: [],
                                        childrenCount: 0,
                                    });
                                }
                            }}
                            label={'No'}
                        />
                    </RadioGroup>
                </FormField>

                {/* --------------------- UPDATE WHETHER HAS KIDS  */}
                {(familyDetailsForm.isMarried === 1 ||
                    familyDetailsForm.isMarried === 2) && (
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="age">
                            Do you have {''}
                            {customFormStyled ? 'children?' : 'kids?'}
                            <Required className="required">*</Required>
                        </FormLabel>
                        <RadioGroup className="radio-group">
                            <Radio
                                id="kidstrue"
                                checked={familyDetailsForm.hasKids === 1}
                                onChange={e => {
                                    if (e.target.checked) {
                                        setFamilyDetailsForm({
                                            ...familyDetailsForm,
                                            isMarried: 1,
                                            hasKids: 1,
                                            isExpecting: null,
                                            childrenCount: 1,
                                            children: [
                                                ...familyDetailsForm.children,
                                                {
                                                    gender: '',
                                                    age: '',
                                                },
                                            ],
                                        });
                                    }
                                }}
                                label={'Yes'}
                            />
                            <Radio
                                id="kidsfalse"
                                checked={
                                    familyDetailsForm.hasKids === 0 &&
                                    familyDetailsForm.isExpecting === null
                                }
                                onChange={e => {
                                    if (e.target.checked) {
                                        setFamilyDetailsForm({
                                            ...familyDetailsForm,
                                            isMarried: 1,
                                            hasKids: 0,
                                            isExpecting: null,
                                            children: [],
                                            childrenCount: 0,
                                        });
                                    }
                                }}
                                label={'No'}
                            />
                            <Radio
                                id="kidsexpecting"
                                checked={familyDetailsForm.isExpecting === true}
                                onChange={e => {
                                    if (e.target.checked) {
                                        setFamilyDetailsForm({
                                            ...familyDetailsForm,
                                            isMarried: 2,
                                            hasKids: 0,
                                            isExpecting: true,
                                            children: [],
                                            childrenCount: 0,
                                        });
                                    }
                                }}
                                label={'I’m expecting'}
                            />
                        </RadioGroup>
                    </FormField>
                )}

                {/* --------------------- ADD KIDS DETAILS  */}
                {familyDetailsForm.hasKids === 1 && renderKids()}
                {familyDetailsForm.hasKids === 1 && (
                    <OutlineButton
                        onClick={e => {
                            e.preventDefault();
                            handleAddNewChildForm();
                        }}
                        color="#3E4C59"
                        bgColor="#CBD2D9"
                        icon={PlusGrey}
                    >
                        Add Another Child
                    </OutlineButton>
                )}
            </Form>

            <AuthFooter className="footer">
                <Button
                    onClick={(e: any) => {
                        e.preventDefault();
                        onSaveFamilyDetails();
                    }}
                    className={!canDispatch() ? 'disabled' : ''}
                >
                    {signUpPageStore.loading ? (
                        <Spinner color="light" />
                    ) : !customFormStyled ? (
                        'Next'
                    ) : (
                        'Save'
                    )}
                </Button>
            </AuthFooter>
        </AuthScreen>
    );
});

{
    /* 
-------------------------- LOGIC TO HANDLE FAMILY INFO FORM OPTIONS:
--------------------------------------------------------------------

married --> YES
-- set isMarried to 'true'
-- show kids options: [yes, no, expecting]
    -- select [yes]
        -- set hasKids to 'true'
        -- set isExpecting to null
            -- show kids details form
    -- select [no]
        -- set hasKids to 'no'
        -- set isExpecting to null
    -- select [expecting]
        -- set hasKids to 'no'
        -- set isExpecting to 'true'


married --> NO
-- reset isMarried to 'false'
-- reset hasKids to 0
-- reset isExpecting to null
-- reset kids details to []


-------------------- LOGIC TO HANDLE KIDS WHEN "I'M EXPECTING" OPTION SELECTED:
--------------------------------------------------------------------

MARRIED --> "NO" ==> KIDS = 0
MARRIED --> "YES" || HAS_KIDS --> "NO" ==> {KIDS = 0 & "IS_MARRIED" = 1}
MARRIED --> "YES" || HAS_KIDS --> "YES" ==> {KIDS = 0 OR "AS ENTERED BY USER" & "IS_MARRIED" = 1}
MARRIED --> "YES" || IS_EXPECTING --> "YES" ==> {KIDS = 0 & "IS_MARRIED" = 2}
*/
}
