import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'app/components/Button';
import { Popup } from 'app/components/Popup';
import PopupContent from 'app/components/PopupContent';
import { selectDeclineCampaign } from 'app/pages/HomePage/components/DeclinePopUp/slice/selectors';
import Spinner from 'app/components/Spinner';
import {
    CheckboxWrapper,
    Label,
} from 'app/pages/HomePage/components/DeclinePopUp/index.styled';

import {
    useMessengerSlice,
    messengerActions,
} from 'app/pages/Tabs/MessengerTabSection/slice';
import {
    selectCreateMessage,
    selectThreadMessages,
} from 'app/pages/Tabs/MessengerTabSection/slice/selectors';
import { campaignTypeList } from 'app/pages/Tabs/MessengerTabSection/label';
interface Props {
    onClose: Function;
    message?: any;
}
export const AppDeclinePopUp = memo((props: Props) => {
    useMessengerSlice();
    const dispatch = useDispatch();
    const { onClose, message } = props;
    const { loading } = useSelector(selectCreateMessage);
    const { threadInfo } = useSelector(selectThreadMessages);
    const [selected, setSelected]: any = useState(null);
    const [value, setValue]: any = useState('');

    const campaignRequestDecline = () => {
        dispatch(
            messengerActions.createMessage({
                thread_id_hash: message?.thread_id_hash,
                message_action_type: 'update',
                message_id: message?.id,
                token: localStorage.getItem('token'),
                message_action: 'invite_status',
                message_action_status: 'declined',
                template_name: 'invitation_action_v1',
                template_type: 'Invitation',
                type: 'influencer',
                message: {
                    invite_status: 'declined',
                    declined_reason:
                        selected == 'Campaign not relevant' ? value : selected,
                },
            }),
        );
    };
    return (
        <Popup onClose={onClose}>
            <PopupContent className="popup-content decline-popup">
                <h2>Kindly provide a reason for declining</h2>
                <CheckboxWrapper className="checkbox-wrapper">
                    {/* <Label>
                        <input
                            type="radio"
                            name="decline"
                            checked={
                                selected ===
                                'Not interested in marketing campaigns'
                            }
                            value="Not interested in marketing campaigns"
                            onChange={e => {
                                if (e.target.checked) {
                                    setSelected(e.target.value);
                                } else {
                                    setSelected(null);
                                }
                            }}
                        />
                        <span>Not interested in marketing campaigns</span>
                    </Label> */}
                    <Label>
                        <input
                            type="radio"
                            value={`Not interested in campaigns`}
                            name="decline"
                            checked={selected === 'Not interested in campaigns'}
                            onChange={e => {
                                if (e.target.checked) {
                                    setSelected(e.target.value);
                                    setValue('');
                                } else {
                                    setSelected(null);
                                }
                            }}
                        />
                        <span>{`Not interested in ${
                            campaignTypeList[threadInfo?.reason_to_connect]
                        } campaigns`}</span>
                    </Label>
                    <Label>
                        <input
                            type="radio"
                            value="Don’t know what Qoruz is"
                            name="decline"
                            checked={selected === 'Don’t know what Qoruz is'}
                            onChange={e => {
                                if (e.target.checked) {
                                    setSelected(e.target.value);
                                    setValue('');
                                } else {
                                    setSelected(null);
                                }
                            }}
                        />
                        <span>Don’t know what Qoruz is</span>
                    </Label>
                    <Label>
                        <input
                            type="radio"
                            value="Campaign not relevant"
                            name="decline"
                            checked={selected === 'Campaign not relevant'}
                            onChange={e => {
                                if (e.target.checked) {
                                    setSelected(e.target.value);
                                    setValue('');
                                } else {
                                    setSelected(null);
                                }
                            }}
                        />
                        <span>Campaign not relevant</span>
                    </Label>
                    {selected === 'Campaign not relevant' ? (
                        <textarea
                            placeholder="Why did you not find the campaign relevant?"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        ></textarea>
                    ) : (
                        <></>
                    )}
                    <Button
                        className={`${
                            !selected || (selected === 'relevant' && !value)
                                ? 'disabled'
                                : ''
                        }`}
                        bgColor="#F35627"
                        onClick={e => campaignRequestDecline()}
                    >
                        {!loading ? (
                            'Submit Feedback'
                        ) : (
                            <Spinner color="light" />
                        )}
                    </Button>
                </CheckboxWrapper>
            </PopupContent>
        </Popup>
    );
});
