import React, { memo, useEffect, useState } from 'react';
import InstagramIcon from 'app/images/instagram.svg';
import AuthScreen from 'app/components/AuthScreen';
import Intro from 'app/components/Intro';
import Form, { FormField, FormLabel, Required } from 'app/components/Form';
import { AuthFooter } from 'app/components/AuthFooter';
import { Button } from 'app/components/Button';
import {
    convertToInternationalFormat,
    onlyNumberKey,
    replaceStringToNumber,
} from 'utils/helper';
import { Popup } from 'app/components/Popup';
import InstagramReelsIcon from 'app/images/instagram-reels-icon.svg';
import { Rupee } from 'app/pages/HomePage/components/CampaignCostPopUp/index.styled';
import { CampaignCostPopupStyle } from './index.styled';

import { renderDeliverableIcon } from 'utils/helper';

import {
    useMessengerSlice,
    messengerActions,
} from 'app/pages/Tabs/MessengerTabSection/slice';
import { selectCreateMessage } from 'app/pages/Tabs/MessengerTabSection/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'app/components/Spinner';
interface Props {
    onClose: Function;
    deliverables: [
        {
            deliverable_name: string;
            cost: Number | null;
            count: Number;
            deliverable_value: string;
            platform: string;
        },
    ];
    message: any;
    threadInfo: any;
    renderDeliverablesList: any;
}

export const CampaignCostPopup = memo((props: Props) => {
    useMessengerSlice();
    const createMessageInfo = useSelector(selectCreateMessage);
    const {
        onClose,
        deliverables,
        message,
        threadInfo,
        renderDeliverablesList,
    } = props;
    const dispatch = useDispatch();
    const [deliverableList, updateDeliverableList] =
        useState<any>(deliverables);

    // this useEffect is used to not scroll the background when popup is open
    useEffect(() => {
        document.body.classList.add('no-scroll');
    }, []);

    const handleChangeDeliverableCost = (targetDeliverableIndex, value) => {
        const tempDeliverableList = [...deliverableList];

        const updatedTempDeliverables = tempDeliverableList.map(
            (deliverable, index) => {
                let tempDeliverable = { ...deliverable };
                if (index === targetDeliverableIndex) {
                    tempDeliverable['cost'] = value
                        ? replaceStringToNumber(value)
                        : '';
                    return tempDeliverable;
                } else {
                    return deliverable;
                }
            },
        );
        updateDeliverableList(updatedTempDeliverables);
    };

    const updateDeliverableCost = () => {
        dispatch(
            messengerActions.createMessage({
                thread_id_hash: message?.thread_id_hash,
                message_action_type: 'update',
                message_id: message?.id,
                ...(localStorage.getItem('token') && {
                    token: localStorage.getItem('token'),
                }),
                message_action: 'share_cost',
                message_action_status: 'accepted',
                template_name: 'cost_action_v1',
                template_type: 'cost',
                type: 'influencer',
                message: {
                    deliverables: deliverableList,
                },
            }),
        );
    };
    const canDispatch = () => {
        return deliverableList?.length &&
            deliverableList.every(deliverable => deliverable.cost)
            ? true
            : false;
    };
    return (
        <Popup onClose={onClose}>
            <AuthScreen className="auth-screen custom-auth-styled">
                <Intro className="intro custom-intro-style">
                    <h2>Campaign Cost</h2>
                </Intro>
                <Form className="form-list custom-form-style">
                    {['another_reason', 'event_invitation'].includes(
                        threadInfo?.reason_to_connect,
                    ) ? (
                        <FormField className="form-list-item rupee">
                            <FormLabel htmlFor="postCost">
                                <CampaignCostPopupStyle>
                                    Cost
                                </CampaignCostPopupStyle>
                                <Required className="required">*</Required>
                            </FormLabel>
                            <input
                                type="text"
                                id="postCost"
                                placeholder="Enter your charges for 1 post"
                                // value={convertToInternationalFormat(
                                //     deliverableList[0].cost,
                                // )}
                                onChange={e => {
                                    e.preventDefault();
                                    handleChangeDeliverableCost(
                                        0,
                                        e.target.value,
                                    );
                                }}
                                onKeyPress={e => {
                                    !onlyNumberKey(e) && e.preventDefault();
                                }}
                                autoFocus={false}
                                autoComplete="off"
                            />
                            <Rupee className="rupee-symbol custom-style">
                                ₹
                            </Rupee>
                        </FormField>
                    ) : deliverables?.length ? (
                        deliverables.map((deliverable: any, index) => (
                            <FormField className="form-list-item rupee">
                                <FormLabel htmlFor="postCost">
                                    <img
                                        src={renderDeliverableIcon(
                                            renderDeliverablesList,
                                            deliverable.deliverable_value,
                                        )}
                                        alt={`${deliverable.deliverable_value}-icon`}
                                        width="16px"
                                        height="16px"
                                    />

                                    <CampaignCostPopupStyle>
                                        <span className="value">
                                            {deliverable.count}
                                        </span>{' '}
                                        x {deliverable.deliverable_name}
                                    </CampaignCostPopupStyle>

                                    <Required className="required">*</Required>
                                </FormLabel>
                                <input
                                    type="text"
                                    id="postCost"
                                    placeholder="Enter your charges for 1 post"
                                    // value={convertToInternationalFormat(
                                    //     deliverable.cost,
                                    // )}
                                    onChange={e => {
                                        e.preventDefault();
                                        handleChangeDeliverableCost(
                                            index,
                                            e.target.value,
                                        );
                                    }}
                                    onKeyPress={e => {
                                        !onlyNumberKey(e) && e.preventDefault();
                                    }}
                                    autoFocus={false}
                                    autoComplete="off"
                                />
                                <Rupee className="rupee-symbol custom-style">
                                    ₹
                                </Rupee>
                            </FormField>
                        ))
                    ) : (
                        ''
                    )}

                    <FormField className="form-list-item">
                        <CampaignCostPopupStyle>
                            <div className="showtotal">
                                <span>Your Quote</span>
                                <span className="total">
                                    ₹{' '}
                                    {convertToInternationalFormat(
                                        deliverableList?.length &&
                                            deliverableList.reduce(
                                                (prev: any, cur: any) =>
                                                    prev +
                                                    cur.cost *
                                                        (cur.count
                                                            ? cur.count
                                                            : 1),
                                                0,
                                            ),
                                    )}
                                </span>
                            </div>
                        </CampaignCostPopupStyle>
                    </FormField>
                </Form>
                <AuthFooter className="footer">
                    <Button
                        // className={!canDispatch() ? 'disabled' : ''}
                        onClick={() => updateDeliverableCost()}
                        disabled={!canDispatch()}
                    >
                        {!createMessageInfo?.loading ? (
                            'Submit Cost'
                        ) : (
                            <Spinner color="light" />
                        )}
                    </Button>
                </AuthFooter>
            </AuthScreen>
        </Popup>
    );
});
