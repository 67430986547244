import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { campaignCostSliceActions as actions } from '.';
import { request } from 'utils/request';

function* updateCampaignCostSaga({payload}) {
  try {
    const requestURL = `/api/campaign.influencer.updateCost`;

    const response = yield call(request, requestURL, {
        method: 'POST',
        headers: {
            // /Authorization: authHeader(),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(payload),
    });
    yield put(actions.updateCampaignCostSuccess(response));
    // yield put(
    //     addToast({
    //         type: response.ok === true ? 'success' : 'error',
    //         message: response.stuff,
    //     }),
    // );
   
} catch (error) {
    try {
        const response = yield error.response.json();
        yield put(actions.updateCampaignCostError(response));
    } catch (jsonError) {
        yield put(actions.updateCampaignCostError(error));
    }
}
}

export function* campaignCostSliceSaga() {
  yield takeLatest(actions.updateCampaignCost, updateCampaignCostSaga);
}
