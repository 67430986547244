import {
    baseCountryCodeData,
    baseCountryCodesList,
} from 'app/pages/SignUpPage/CountryCodeData';
import { deliverablesIconList } from 'app/pages/Tabs/MessengerTabSection/label';

import dayjs, { Dayjs } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const getCountryCodeListDetails = (
    countryCode: string,
    keyName?: string,
    keyValue?: string,
) => {
    const completeCountryCodeData = generateCountryCodeData();
    const filteredCode = completeCountryCodeData.filter(
        (countryCodeData: any) => countryCodeData.value === countryCode,
    );

    const countryCodeDetails = filteredCode.length
        ? filteredCode.length > 1
            ? keyValue
                ? filteredCode.find(
                      codeDetails => codeDetails.country === keyValue,
                  )
                : filteredCode[0]
            : filteredCode[0]
        : '';
    return countryCodeDetails
        ? keyName
            ? countryCodeDetails[keyName]
            : countryCodeDetails
        : '';
};

export const getCountryCodeDetails = (
    targetCountryCode: string = '+91',
    targetCountryAlphaTwoCode: string = 'IN',
) => {
    const formattedCountryCodes = generateFormattedCountryCodes();

    const filteredCountry = formattedCountryCodes.filter(
        (country: any) =>
            country.countryCodeValue === targetCountryCode &&
            country.countryAlphaTwoCode === targetCountryAlphaTwoCode,
    );

    return filteredCountry.length !== 0 ? filteredCountry[0] : '';
};

export const generateFormattedCountryCodes = () => {
    const formattedCountryCodes = baseCountryCodesList.map(country => {
        return {
            ...country,
            label: `${country.countryFlag} (${country.countryCodeValue})`,
            value: `${country.countryFlag} (${country.countryCodeValue})`,
            digits: 10,
        };
    });

    return formattedCountryCodes;
};

export const generateCountryCodeData = () => {
    const completeCountryCodeData = baseCountryCodeData.map(countryCode => {
        return {
            label: `+${String(countryCode.code)}`,
            value: `+${String(countryCode.code)}`,
            country: countryCode.country,
            digits: 10,
            codeValue: countryCode.code,
        };
    });
    return completeCountryCodeData;
};

export const onlyNumberKey = evt => {
    // Only ASCII character in that range allowed
    const ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
};

export const setCookie = (
    cookieName: string,
    cookieValue: string,
    maxAge: number,
) => {
    let d = maxAge ? new Date(maxAge * 1000) : new Date();
    if (!maxAge) {
        const maxAgeInMinutes = 60;
        d.setTime(d.getTime() + maxAgeInMinutes * 60 * 1000); // maxAge in Minutes
    }
    let expires = 'expires=' + d.toUTCString();
    document.cookie =
        cookieName + '=' + cookieValue + ';' + expires + ';path=/';
};

export const getCookie = (cookieName: string) => {
    var match = document.cookie.match(
        new RegExp('(^| )' + cookieName + '=([^;]+)'),
    );
    if (match) {
        return match[2]; //Cookie Key's Value Stored in 3rd index of Array
    }
    return '';
};

export const checkCookie = (cookieName: string) => {
    let cookie = getCookie(cookieName);
    if (cookie !== '') {
        return true;
    } else {
        return false;
    }
};

export const panCardValidation = (text: string) => {
    let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (regex.test(text)) {
        return true;
    }
    return false;
};

export const noScroll = (value: any) => {
    if (value) {
        document.body.classList.add('no-scroll');
    } else {
        document.body.classList.remove('no-scroll');
    }
};

export const dueDateComparator = (
    rawDueDate: any,
    dateToCompareTo: any,
): {
    isDue?: boolean | null;
    expiresIn?: string | boolean | null;
    hasExpired?: boolean | null;
    expiredSince?: string | boolean | null;
    hasAccepted?: boolean | null;
    acceptedSince?: string | null;
} => {
    if (rawDueDate) {
        const formattedDueDate = dayjs(rawDueDate).format();
        const params = {};

        if (dateToCompareTo !== 'current') {
            const formattedCurrentDate = dayjs(dateToCompareTo).format();
            const acceptedOn = dayjs(formattedCurrentDate).from(
                formattedDueDate,
                true,
            );
            params['hasAccepted'] = true;
            params['acceptedSince'] = acceptedOn;
        } else {
            const formattedCurrentDate = dayjs().format();
            const isDateExpired =
                dayjs(formattedCurrentDate).isAfter(formattedDueDate) ||
                dayjs(formattedCurrentDate).isSame(formattedDueDate);
            const isDateDue =
                dayjs(formattedCurrentDate).isBefore(formattedDueDate);

            const expiresIn =
                isDateDue &&
                dayjs(formattedDueDate).to(formattedCurrentDate, true);
            const expiredSince =
                isDateExpired &&
                dayjs(formattedDueDate).to(formattedCurrentDate, true);

            params['expiredSince'] = expiredSince;
            params['hasExpired'] = expiredSince;
            params['isDue'] = isDateDue;
            params['expiresIn'] = expiresIn;
        }

        return params;
    }
    return {
        isDue: null,
        expiresIn: null,
        hasExpired: null,
        expiredSince: null,
        hasAccepted: null,
        acceptedSince: null,
    };
};

export const convertToInternationalFormat = (number: any) => {
    if (!number || number === null) {
        return '';
    }
    let convertValue = new Intl.NumberFormat('en-IN');
    return convertValue.format(replaceStringToNumber(number, false));
};

export const replaceStringToNumber = (string: any, parseToInt = true) => {
    if (string === null) {
        return null;
    }
    let replacedString = string.toString().replace(/[,.]/g, '');
    return parseToInt ? parseInt(replacedString) : replacedString;
};

export const stripCommasFromNumber = (inputVal: any) => {
    return inputVal ? parseFloat(inputVal.replace(/,/g, '')) : '';
};

export const renderMessengerTime = (
    targetTimestamp: any,
): {
    messageCalendarDay: any;
    messageTime: any;
    isMsgDayToday: any;
    isMsgDayYesterday: any;
} => {
    if (targetTimestamp) {
        const messageCalendarDay =
            dayjs(targetTimestamp).format('DD MMMM YYYY');
        const messageTime = dayjs(targetTimestamp).format(`hh:mm A`);

        const isMsgDayToday = dayjs(targetTimestamp).isToday();
        const isMsgDayYesterday = dayjs(targetTimestamp).isYesterday();

        return {
            messageCalendarDay,
            messageTime,
            isMsgDayToday,
            isMsgDayYesterday,
        };
    }
    return {
        messageCalendarDay: null,
        messageTime: null,
        isMsgDayToday: null,
        isMsgDayYesterday: null,
    };
};

export function getDefaultQLogo() {
    return 'https://s3-ap-southeast-1.amazonaws.com/terareach/q_red.png';
}

export const renderDeliverableIcon = (
    renderDeliverablesList: any,
    deliverableType: any,
) => {
    const targetDeliverable: any =
        renderDeliverablesList && renderDeliverablesList.length
            ? renderDeliverablesList.filter(
                  deliverableOption =>
                      deliverableOption.value === deliverableType,
              )
            : [];
    return (
        targetDeliverable.length !== 0 &&
        deliverablesIconList[targetDeliverable[0].value]
    );
};
