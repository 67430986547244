import styled from 'styled-components/macro';
import SelectArrow from 'app/images/select-arrow.svg';
import LocationIcon from 'app/images/location.svg';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    button {
        width: 100%;
        margin-top: 1.5rem;
        padding-top: 12px;
        padding-bottom: 12px;
        font-size: 16px;
    }
    &.custom-form-style {
        padding-left: 0px;
        padding-right: 0px;
    }
`;
const FormField = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    position: relative;
    &.rupee {
        input {
            padding-left: 30px;
        }
        .rupee-symbol {
            display: block;
        }
    }
    .progress-bar {
        margin-top: 8px;
    }
    .rupee-symbol {
        display: none;
    }
    select,
    input {
        height: 44px;
    }
    textarea {
        height: 104px;
        padding-top: 8px;
        padding-bottom: 8px;
        resize: none;
    }
    textarea,
    select,
    input {
        background-color: #f5f7fa;
        border-radius: 8px;
        border: 1px solid #f5f7fa;
        margin-top: 8px;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 16px;
        font-weight: 500;
        &:focus {
            border: 1px solid #7792e4;
            outline: none;
        }
        &::placeholder {
            color: #9aa5b1;
        }

        &.state-select-dropdown {
            color: #9aa5b1;

            &:focus {
                color: #1f2933 !important;
            }
        }
    }
    select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-position: center left 12px;
        background-repeat: no-repeat;
        padding-left: 34px;
        background-image: url(${SelectArrow});
        &:-ms-expand {
            display: none;
        }
    }
    &.input-radius {
        input {
            border-radius: 32px;
        }
    }
    &.location {
        input {
            background-image: url(${LocationIcon});
            background-position: center left 12px;
            background-repeat: no-repeat;
            padding-left: 34px;
        }
    }

    @media screen and (max-height: 680px) {
        &.city-form-field {
            position: relative;

            &#autocomplete-field-focused {
                margin-bottom: 65%;
            }

            input.autocomplete-city-input + div {
                width: 100%;
                position: absolute !important;
                top: 72px !important;
                left: 0 !important;
                max-height: 200px !important;
                overflow-y: scroll;
            }
        }
        /* Influencer app select dropdown customize */
        &.city-form-field {
            &#autocomplete-field-focused {
                margin: 0;
            }
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    /* Influencer app select dropdown customize */
    &.custom-form-style {
        &.city-form-field {
            position: relative;
            input.autocomplete-city-input + div {
                width: 100%;
                position: relative !important;
                top: 0px !important;
                left: 0 !important;
                max-height: 200px !important;
                overflow-y: scroll;
            }
        }
    }
`;
const FormLabel = styled.label`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #52606d;
    h3 {
        margin: 0;
    }
    > img {
        margin-right: 8px;
    }
    b {
        font-weight: 900;
    }
`;
const Icon = styled.figure`
    width: 20px;
    height: 20px;
    margin: 0 12px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Required = styled.span`
    color: #ba2525;
`;
const ProgressBar = styled.div<{ percentageCompleted: number }>`
    border-radius: 8px;
    height: 44px;
    background-color: #f5f7fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    position: relative;
    overflow: hidden;
    span {
        position: relative;
    }
    &:before {
        content: '';
        background-color: #ffd0b5;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: ${props => props.percentageCompleted}%;
    }

    .upload-progress-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        span {
            color: var(--gray-700);
        }
    }
`;
const RadioIcon = styled.figure`
    margin: -2px 8px 0 0;
`;
const RadioLabel = styled.span`
    font-size: 14px;
    color: #1f2933;
`;
const FormRadioLabel = styled.label`
    input {
        display: none;
        &:checked {
            & + .value-block {
                border-color: #1f2933;
                background-color: #1f2933;
                > * {
                    filter: invert(1);
                }
            }
        }
    }
`;
const FormRadio = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 100%;
    margin-left: -8px;
    margin-right: -8px;
    > * {
        margin-left: 8px;
        margin-right: 8px;
    }
`;

const FormValidatonError = styled.div`
    font-size: 12px;
    margin-top: 1em;
    color: #ff554f;

    &.enter-mobile-number-page {
        text-align: left;
    }

    &.enter-otp-page {
        text-align: center;
    }
`;

export default Form;
export {
    FormField,
    FormLabel,
    Icon,
    Required,
    RadioIcon,
    FormRadio,
    FormRadioLabel,
    RadioLabel,
    ProgressBar,
    FormValidatonError,
};
