import styled from 'styled-components/macro';

const AlertStyled = styled.div<{ bgColor?: string; borderColor?: string }>`
  background-color: ${props => props.bgColor || '#fffaeb'};
  border: 1px solid ${props => props.borderColor || '#f8e3a3'};
  border-radius: 8px;
  padding: 16px 12px;
  display: flex;
  margin-top: 1.25rem;
  h4 {
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 500;
    & + span {
      margin-top: 4px;
      color: #3e4c59;
    }
  }
`;
const AlertDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  > span {
    font-size: 12px;
  }
`;
const AlertIcon = styled.figure`
  margin: 0 12px 0 0;
`;

const AlertLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 100%;
  margin-left: -4px;
  margin-right: -4px;
  > * {
    margin-left: 4px;
    margin-right: 4px;
  }
  > span {
    font-size: 14px;
  }
`;

export default AlertStyled;
export { AlertDetails, AlertIcon, AlertLink };
