import styled from 'styled-components';

const HelpCategoryTitle = styled.div`
    padding: 1.5rem 1rem;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #1f2933;

    &.helpcategory-title {
        padding-left: 0 !important;
    }
`;

const CategoriesCardSection = styled.div`
    padding: 0 1rem;

    ul.helpdesk-loader {
        & > span {
            display: grid !important;
            grid-template-columns: repeat(2, 1fr) !important;
        }
    }
`;

const TicketListSection = styled.div`
    display: flex;
    gap: 12px;
    padding-left: 16px;
    margin-bottom: 32px;
`;

export { HelpCategoryTitle, CategoriesCardSection, TicketListSection };
