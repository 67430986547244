import styled from 'styled-components';
import TickIcon from 'app/images/tick.svg';

const SelectOptionSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
    &.add-btn {
        margin-top: 0 !important;
    }
`;

const DropDownBtn = styled.div`
    display: flex;
    border-radius: 8px;
    position: relative;
    .checkbox {
        position: absolute;
        right: 10px;
        margin-top: 0 !important;
    }

    .dropdown-btn {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
        user-select: none;
        position: relative;
        height: 44px;
        width: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5f7fa;
        cursor: pointer;
        img {
            width: 16px;
            height: 16px;
        }
    }

    ul.show {
        /* display: block; */
        user-select: none;
    }

    .phone-number-input {
        outline: none !important;
        font-size: 16px;
        background-color: #f5f7fa;
        margin-top: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        flex: 1;
        &:focus {
            outline: none !important;
            box-shadow: none;
            border: none;
        }
        * {
            font-size: 18px;
            border: 0;
            height: 100%;
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
            &:focus {
                outline: none !important;
                box-shadow: none;
                border: none;
            }
        }
    }
    .dropdown-content {
        z-index: 100000;
        position: absolute;
        top: 36px;
        width: 142px;
        height: 82px;
        background: #ffffff;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1),
            0px 16px 40px rgba(9, 30, 66, 0.2);
        border-radius: 8px;
        padding: 4px;

        .dropdown-itm {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 12px;
            gap: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #1f2933;
            img {
                width: 16px;
                height: 16px;
            }
            &:hover {
                background-color: #f8f9f9;
            }
        }
    }
`;
const CheckboxStyle = styled.div`
    margin-top: 8px;
    label {
        cursor: pointer;
        position: relative;
        padding-left: 20px;
        font-size: 13px;
        color: #52606d;
        margin-top: -5px;
        font-weight: 400;
        &:before {
            content: '';
            width: 14px;
            height: 14px;
            border: 1px solid #616e7c;
            left: 0;
            top: 0;
            position: absolute;
            border-radius: 2px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 8px;
            margin-top: 2px;
        }
    }

    input {
        display: none;

        &:checked {
            & + label {
                &:before {
                    margin-top: 2px;
                    background-color: #1f2933;
                    border-color: #1f2933;
                    background-image: url(${TickIcon});
                }
            }
        }
    }
`;

export { DropDownBtn, SelectOptionSection, CheckboxStyle };
