import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noScroll } from 'utils/helper';
import Skeleton from 'react-loading-skeleton';

import {
    selectInfluencerProfileSummary,
    selectShowPopupStatus,
    selectSignUpPage,
} from 'app/pages/SignUpPage/slice/selectors';
import { signUpPageActions } from 'app/pages/SignUpPage/slice';

import { HtmlElementSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';
import { PersonelInfoPopUp } from '../personalinfoPopUp';

import UserIcon from 'app/images/user.svg';
import CakeIcon from 'app/images/cake-icon.svg';
import MaleGenderIcon from 'app/images/gender.png';
import LocationIcon from 'app/images/location-icon.svg';
import { PersonalInfoCard } from '../../index.styled';

interface Props {
    personalDetails: any;
}

export const PersonalInfo = memo((props: Props) => {
    const { personalDetails = {} } = props;
    const { full_name, gender, age, city } = personalDetails;

    const dispatch = useDispatch();
    const signUpPageStore = useSelector(selectSignUpPage);
    const isShowingPopup = useSelector(selectShowPopupStatus);
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const { loading: profileSummaryLoading } = profileSummarySelector;

    const [showPersonalInfoForm, setShowPersonalInfoForm] = useState(false);

    useEffect(() => {
        noScroll(showPersonalInfoForm);
    }, [showPersonalInfoForm]);

    const handleShowPersonalInfoPopup = (showState: boolean = false) => {
        setShowPersonalInfoForm(showState);
        dispatch(signUpPageActions.setIsShowPopup(showState));
    };

    useEffect(() => {
        if (!signUpPageStore.loading && signUpPageStore.isStepSuccess) {
            handleShowPersonalInfoPopup(false);
        }
    }, [signUpPageStore]);

    const hasPersonalInfo = () => {
        return (
            full_name ||
            gender ||
            age ||
            (city && Object.values(city).every(item => item))
        );
    };

    return (
        <>
            <PersonalInfoCard>
                <div className="info-card__header">
                    <h4>Personal Info</h4>
                    {profileSummaryLoading ? (
                        <HtmlElementSkeleton width={25} height={12} />
                    ) : (
                        <span
                            onClick={() => {
                                handleShowPersonalInfoPopup(true);
                                dispatch(
                                    signUpPageActions.resetProfileSummaryUpdateSuccess(),
                                );
                            }}
                        >
                            {Object.keys(personalDetails).length &&
                            hasPersonalInfo()
                                ? 'Edit'
                                : '+ Add'}
                        </span>
                    )}
                </div>

                <div className="info-card__body">
                    {profileSummaryLoading ? (
                        <ul>
                            <Skeleton
                                count={3}
                                height={20}
                                style={{ marginBottom: '10px' }}
                            />
                        </ul>
                    ) : Object.keys(personalDetails).length !== 0 &&
                      hasPersonalInfo() ? (
                        <>
                            <span className="header-border"></span>
                            <ul>
                                {full_name && (
                                    <li>
                                        <img src={UserIcon} alt="user-icon" />
                                        {full_name}
                                    </li>
                                )}
                                {gender && (
                                    <li>
                                        <img
                                            src={MaleGenderIcon}
                                            alt="gender-icon"
                                        />
                                        {gender}
                                    </li>
                                )}
                                {age && (
                                    <li>
                                        <img
                                            src={CakeIcon}
                                            alt="birthday-cake-icon"
                                        />
                                        {age}
                                    </li>
                                )}
                                {city?.name && (
                                    <li>
                                        <img
                                            src={LocationIcon}
                                            alt="location-icon"
                                        />
                                        {city?.name}
                                    </li>
                                )}
                            </ul>
                        </>
                    ) : (
                        <p className="prompt-message">
                            Adding personal info increases your chances of
                            getting campaign by{' '}
                            <span className="percentage-indicator">80%</span>
                        </p>
                    )}
                </div>
            </PersonalInfoCard>

            {showPersonalInfoForm && isShowingPopup && (
                <PersonelInfoPopUp
                    onClose={() => handleShowPersonalInfoPopup(false)}
                    prefillPersonalData={{
                        influencerName: full_name,
                        age: age,
                        gender: gender?.toLowerCase(),
                        city: city?.name,
                    }}
                />
            )}
        </>
    );
});
