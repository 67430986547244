import React from 'react';
import './svg.css';

export default function YoutubeIcon() {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_3063_11629)">
                <path
                    d="M9.96647 0.878105L3.29504 4.36382C1.97375 5.19106 1.53031 7.64463 3.18354 8.84843C3.58855 9.14334 3.68791 10.0279 3.30571 10.3518C1.48585 11.8941 2.0753 13.9376 3.48107 14.8399C4.92401 15.766 6.71421 14.7478 8.23091 13.9482L12.0395 11.9403C12.5313 11.681 13.0105 11.3715 13.333 10.9187C14.4222 9.38949 13.7355 7.91437 12.6996 7.13053C12.3041 6.83121 12.1657 5.93889 12.5906 5.68298C12.7892 5.56341 12.9338 5.46965 12.995 5.40668C15.2522 2.89239 12.3236 -0.107608 9.96647 0.878105Z"
                    stroke="#D64545"
                    strokeWidth="1.5"
                />
                <path
                    d="M6.40039 10.3068V6.18134C6.40039 6.1026 6.48716 6.05475 6.55375 6.09676L9.86466 8.18578C9.92726 8.22528 9.92674 8.31674 9.86368 8.35553L6.55278 10.392C6.48615 10.433 6.40039 10.385 6.40039 10.3068Z"
                    stroke="#D64545"
                    strokeWidth="1.2"
                />
            </g>
            <defs>
                <clipPath id="clip0_3063_11629">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
