/**
 *
 * Categories
 *
 */
import React, { memo, useState, useEffect } from 'react';
import { Button } from 'app/components/Button';
import AuthScreen from 'app/components/AuthScreen';
import Intro from 'app/components/Intro';
import Form from 'app/components/Form';
import AuthFooter from 'app/components/AuthFooter';
import { RadioGroup } from 'app/components/Radio/index.styled';
import { Radio } from 'app/components/Radio';
import { useDispatch, useSelector } from 'react-redux';
import {
    signUpPageActions,
    useSignUpPageSlice,
} from 'app/pages/SignUpPage/slice';
import {
    getCategoryIcon,
    signupStepsKeyList,
} from 'app/pages/SignUpPage/label';
import {
    selectSignUpPage,
    selectGetCategories,
} from 'app/pages/SignUpPage/slice/selectors';
import _ from 'lodash';
import Spinner from 'app/components/Spinner';
import { ListSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';
import Skeleton from 'react-loading-skeleton';

interface Props {
    onClickNext?: any;
    customFormStyled?: boolean;
    prefillSelectedCategories?: Array<{
        id?: number;
        name?: string;
        emoji_name?: string;
    }>;
}

export const Categories = memo((props: Props) => {
    useSignUpPageSlice();
    const signUpPageStore = useSelector(selectSignUpPage);
    const categoryDetailsStore = useSelector(selectGetCategories);

    const dispatch = useDispatch();
    const {
        onClickNext,
        customFormStyled = false,
        prefillSelectedCategories = [],
    } = props;

    const { loading, categories } = categoryDetailsStore;
    const [selectedCategories, setSelectedCategories]: any = useState([]);

    useEffect(() => {
        dispatch(signUpPageActions.getCategories({}));

        if (customFormStyled && prefillSelectedCategories.length) {
            setSelectedCategories([
                ...selectedCategories,
                ...prefillSelectedCategories.map(
                    selectedCategory => selectedCategory.id,
                ),
            ]);
        }
    }, []);

    useEffect(() => {
        if (
            signUpPageStore.isStepSuccess &&
            signUpPageStore.currentStep === 'categoryDetails' &&
            !customFormStyled
        ) {
            onClickNext();
        }
    }, [signUpPageStore, onClickNext]);

    const handleCheck = (event: any, categoryId) => {
        setSelectedCategories(_.xor(selectedCategories, [categoryId]));
    };

    const isChecked = (item: number) => {
        return selectedCategories.includes(item) ? true : false;
    };

    const getCategoryDetails = (keyName: string) => {
        if (keyName) {
            const category = getCategoryIcon.find(
                (category: any) => category.value === keyName.toLowerCase(),
            );
            return category ? category.label : '';
        }
        return '';
    };

    const onSaveCategories = () => {
        if (selectedCategories.length) {
            dispatch(
                signUpPageActions.register({
                    current_step: signupStepsKeyList['categoryDetails'],
                    categories: [...selectedCategories],
                    currentStep: 'categoryDetails',
                    token: localStorage.getItem('token'),
                    action_type: !customFormStyled ? 'signup' : 'edit',
                }),
            );
        }
    };

    return (
        <AuthScreen
            className={`auth-screen categories ${
                customFormStyled ? 'custom-auth-styled' : false
            }`}
        >
            <>
                <Intro
                    className={`intro ${
                        customFormStyled ? 'custom-intro-style' : false
                    }`}
                >
                    <h2>Content Categories</h2>
                    <span>
                        {!customFormStyled
                            ? 'Select upto 3 categories that you enjoy creating content for..'
                            : 'Select 3 content categories you create'}
                    </span>
                </Intro>
                <Form
                    className={`form-list ${
                        customFormStyled ? 'custom-form-style' : false
                    }`}
                >
                    {loading ? (
                        <ListSkeleton
                            flexDirection="row"
                            listItemCount={10}
                            width={100}
                            height={40}
                            flexGap={15}
                            customPadding={15}
                        />
                    ) : (
                        <RadioGroup className="radio-group">
                            {categories.map((tag: any) => (
                                <Radio
                                    type="checkbox"
                                    value={tag.id}
                                    id={tag.id}
                                    key={tag.id}
                                    checked={isChecked(tag.id)}
                                    onChange={e => handleCheck(e, tag.id)}
                                    label={`${getCategoryDetails(
                                        tag.emoji_name,
                                    )}${' '}${tag.name}`}
                                ></Radio>
                            ))}
                        </RadioGroup>
                    )}
                </Form>
                <AuthFooter className="footer">
                    <Button
                        onClick={() => onSaveCategories()}
                        className={
                            signUpPageStore.loading ||
                            !selectedCategories.length
                                ? 'disabled'
                                : ''
                        }
                    >
                        {signUpPageStore.loading ? (
                            <Spinner color="light" />
                        ) : !customFormStyled ? (
                            'Next'
                        ) : (
                            'Save'
                        )}
                    </Button>
                </AuthFooter>
            </>
        </AuthScreen>
    );
});
