import React from 'react';
import './svg.css';

export default function ArrowIcon() {
    return (
        <svg width="8px" height="14px" viewBox="0 0 8 14">
            <g id="-" className="stroke-one stroke-style">
                <g
                    id="Iconography-"
                    transform="translate(-413.000000, -676.000000)"
                    className="fill-theme-color"
                    fillRule="nonzero"
                >
                    <path
                        d="M413.274649,689.725819 C413.639597,690.091394 414.227982,690.091394 414.59293,689.725819 L420.782148,683.525979 C421.072617,683.235012 421.072617,682.764988 420.782148,682.474021 L414.59293,676.274181 C414.227982,675.908606 413.639597,675.908606 413.274649,676.274181 C412.909701,676.639755 412.909701,677.22915 413.274649,677.594724 L418.66694,683.00373 L413.267201,688.412736 C412.909701,688.77085 412.909701,689.367706 413.274649,689.725819 Z"
                        id="Next"
                    ></path>
                </g>
            </g>
        </svg>
    );
}
