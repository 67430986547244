import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { noScroll } from 'utils/helper';

import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import {
    selectInfluencerProfileSummary,
    selectShowPopupStatus,
    selectUpdateOrDeleteAddress,
} from 'app/pages/SignUpPage/slice/selectors';
import { selectAddAddressSlice } from 'app/pages/HomePage/components/EnterAddressPopup/slice/selectors';

import AppWrapper from 'app/components/AppWrapper';
import { EnterAddressPopup } from 'app/pages/HomePage/components/EnterAddressPopup';
import { AddressCardSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';

import PlusIcon from 'app/images/newplus.svg';
import MapPinIcon from 'app/images/map-pin.svg';
import PreviewBlockIcon from 'app/images/black-left-arrow.svg';

import { Border } from '../../index.styled';
import { HeaderTittle } from '../About/index.styled';
import { CustomAppWrapper } from 'app/pages/Tabs/index.styled';
import { AddressStyled, SavedAddressSection } from './index.styled';
import { PromptMessage } from 'app/pages/Tabs/TabMenu/CommonComponents/PromptMessage';

interface Props {}

export const AddressPage = memo((props: Props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const addAddressPopUpStore = useSelector(selectAddAddressSlice);
    const updateAddressDetailsStore = useSelector(selectUpdateOrDeleteAddress);
    const isShowingPopup = useSelector(selectShowPopupStatus);

    const { isAddAddressSuccess } = addAddressPopUpStore;
    const {
        isUpdateOrDeleteAddressSuccess,
        currentlyUpdatingAddressId,
        loading: addressUpdateLoading,
    } = updateAddressDetailsStore;

    const {
        profileSummary: {
            address_details,
            address_details: savedAddressList = [],
        },
    } = profileSummarySelector;

    const [addAddress, setAddress] = useState({
        showPopup: false,
        addressIndex: null,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        noScroll(addAddress.showPopup);
    }, [addAddress]);

    const handleDeleteAddress = (targetAddressId: any) => {
        const token = localStorage.getItem('token');

        targetAddressId &&
            dispatch(
                signUpPageActions.updateOrDeleteAddress({
                    address_id: targetAddressId,
                    mode_type: 'delete',
                    is_new_address: 0,
                    token: token,
                    request_type: 'profile_update',
                }),
            );
    };

    const handleShowAddressDetailsPopup = (
        showBool: boolean = false,
        targetAddressIndex: any,
    ) => {
        setAddress({
            showPopup: showBool,
            addressIndex: targetAddressIndex,
        });
        dispatch(signUpPageActions.setIsShowPopup(showBool));
    };

    useEffect(() => {
        if (address_details) {
            if (
                !updateAddressDetailsStore.loading &&
                isUpdateOrDeleteAddressSuccess
            ) {
                handleShowAddressDetailsPopup(false, null);
            }
        } else {
            if (!addAddressPopUpStore.loading && isAddAddressSuccess) {
                handleShowAddressDetailsPopup(false, null);
            }
        }
    }, [addAddressPopUpStore, updateAddressDetailsStore]);

    return (
        <>
            <CustomAppWrapper>
                <AppWrapper className="custom-wrapper">
                    <AddressStyled>
                        <HeaderTittle className="header-style">
                            <img
                                src={PreviewBlockIcon}
                                alt="preview-icon"
                                onClick={history.goBack}
                            />
                            <span> Addresses</span>
                        </HeaderTittle>

                        <div
                            className="add-address"
                            onClick={() => {
                                handleShowAddressDetailsPopup(true, null);
                            }}
                        >
                            <img src={PlusIcon} alt="icon" />
                            <span>Add new address</span>
                        </div>
                        <Border />

                        <div className="address-header">
                            <span>Saved Addresses</span>
                            {profileSummarySelector.loading ? (
                                <AddressCardSkeleton count={3} />
                            ) : savedAddressList &&
                              savedAddressList.length !== 0 ? (
                                savedAddressList.map(
                                    (savedAddress: any, index: number) => (
                                        <div key={index}>
                                            {addressUpdateLoading &&
                                            currentlyUpdatingAddressId ===
                                                savedAddress?.id ? (
                                                <AddressCardSkeleton
                                                    count={1}
                                                />
                                            ) : (
                                                <>
                                                    <SavedAddressSection>
                                                        <img
                                                            src={MapPinIcon}
                                                            alt="map location icon"
                                                        />
                                                        <div className="address-body">
                                                            <span>
                                                                {savedAddress?.label ||
                                                                    'NA'}
                                                            </span>
                                                            <p>
                                                                {savedAddress?.address_1 &&
                                                                    savedAddress?.address_1}
                                                                {savedAddress?.address_2 &&
                                                                    `, ${savedAddress?.address_2}`}
                                                                {savedAddress?.city &&
                                                                    `, ${savedAddress?.city}`}
                                                                {savedAddress?.state &&
                                                                    `, ${savedAddress?.state}`}
                                                                {savedAddress?.pincode &&
                                                                    ` - ${savedAddress?.pincode}`}
                                                            </p>
                                                            <div className="button">
                                                                {' '}
                                                                <span
                                                                    onClick={() => {
                                                                        handleShowAddressDetailsPopup(
                                                                            true,
                                                                            index,
                                                                        );
                                                                    }}
                                                                >
                                                                    Edit
                                                                </span>
                                                                <span
                                                                    onClick={() =>
                                                                        handleDeleteAddress(
                                                                            savedAddress?.id,
                                                                        )
                                                                    }
                                                                >
                                                                    Delete
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </SavedAddressSection>
                                                    <Border />
                                                </>
                                            )}
                                        </div>
                                    ),
                                )
                            ) : (
                                <PromptMessage>
                                    No saved address available
                                </PromptMessage>
                            )}
                        </div>
                    </AddressStyled>
                </AppWrapper>
            </CustomAppWrapper>

            {addAddress.showPopup && isShowingPopup && (
                <EnterAddressPopup
                    onClose={() => handleShowAddressDetailsPopup(false, null)}
                    profileId={''}
                    groupId={''}
                    isUpdatingProfileSummary={true}
                    prefilledAddressDetails={
                        addAddress?.addressIndex !== null
                            ? savedAddressList[addAddress.addressIndex || 0]
                            : {}
                    }
                />
            )}
        </>
    );
});
