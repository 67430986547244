import React, { memo, useState } from 'react';
import { Popup } from 'app/components/Popup';
import PopupContent from 'app/components/PopupContent';
import PopupHeader from 'app/components/PopupHeader';
import { Button } from 'app/components/Button';
import RewardImg from 'app/images/reward.png';
import ShareIcon from 'app/images/referwhiteicon.svg';
import { ShareReferralLink, ShareReferralSection, Text } from './index.styled';
interface Props {
    onClose: Function;
    copyLink: String;
}

export const ShareLinkPopup = memo((props: Props) => {
    const { onClose, copyLink } = props;
    const [linkCopied, setLinkCopied] = useState(false);

    const copyToClipboard = () => {
        setLinkCopied(!linkCopied);
        let formattedData: any = copyLink;
        navigator.clipboard.writeText(formattedData);

        setTimeout(() => {
            setLinkCopied(linkCopied => !linkCopied);
        }, 500);
    };

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener');
    };

    return (
        <Popup onClose={onClose} CustomStyled={true}>
            <PopupContent className="popup-content share-link-popup">
                <PopupHeader headerText={'Refer your friend '} />
                <ShareReferralSection>
                    <div className="reward-contents">
                        <img
                            src={RewardImg}
                            className="share-png"
                            alt="reward-png"
                        />
                    </div>
                    <ShareReferralLink>
                        <div className="share-contents">
                            <span>Invite your friends</span>
                            <Text fontSize={13}>
                                Be a good Samaritan. Share this link with your
                                friend to help them enjoy Qoruz privileges.
                            </Text>
                        </div>
                        <div className="copylink">
                            <span>{copyLink}</span>
                            <span
                                className="share-btn"
                                onClick={() => {
                                    copyToClipboard();
                                }}
                            >
                                {linkCopied ? 'Copied' : 'Copy'}
                            </span>
                        </div>

                        <Text fontSize={12}>
                            {' '}
                            ✨ Go through our{' '}
                            <span
                                onClick={e =>
                                    window.open(
                                        'https://qoruz.com/terms/influencers',
                                    )
                                }
                            >
                                Terms & Conditions{' '}
                            </span>
                            &nbsp;for more details.
                        </Text>
                    </ShareReferralLink>

                    <Button
                        className="sharelink"
                        onClick={() =>
                            openInNewTab(
                                'https://api.whatsapp.com/send/?text=I am exclusively inviting you to be part of Qoruz Network, a place to connect and collaborate with our dream brands.%0D%0A %0D%0AClick this link to signup and await collaboration opportunities - https://creators.qoruz.com',
                            )
                        }
                    >
                        <span>
                            <img src={ShareIcon} alt="share-icon" />
                        </span>
                        Share with friends
                    </Button>
                </ShareReferralSection>
            </PopupContent>
        </Popup>
    );
});
