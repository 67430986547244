/**
 *
 * EnterAddressPopup
 *
 */
import React, { memo, useEffect, useState } from 'react';

import { Button } from 'app/components/Button';
import Form, {
    FormField,
    FormLabel,
    FormRadio,
    FormRadioLabel,
    RadioIcon,
    RadioLabel,
    Required,
} from 'app/components/Form';
import { Popup } from 'app/components/Popup';
import PopupContent from 'app/components/PopupContent';
import LocationIcon from 'app/images/location.svg';
import HomeIcon from 'app/images/home.svg';
import OfficeIcon from 'app/images/briefcase.svg';
import { Block } from 'app/pages/HomePage/components/EnterAddressPopup/index.styled';
import { indianStatesList } from 'app/pages/HomePage/components/EnterAddressPopup/label';
import {
    useAddAddressSlice,
    addAddressSliceActions,
} from 'app/pages/HomePage/components/EnterAddressPopup/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddAddressSlice } from 'app/pages/HomePage/components/EnterAddressPopup/slice/selectors';
import { EnterAddressProps } from 'app/pages/HomePage/components/EnterAddressPopup/types';
import { PopupNotification } from 'app/components/PopupNotification';
import Spinner from 'app/components/Spinner';
import { getCookie } from 'utils/helper';
import { selectCampaignDetails } from 'app/pages/HomePage/components/CampaignDetails/slice/selectors';
import { selectVerifyOTP } from 'app/pages/HomePage/components/OtpVerifyPopUp/slice/selectors';
import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import { selectUpdateOrDeleteAddress } from 'app/pages/SignUpPage/slice/selectors';

export const EnterAddressPopup = memo((props: EnterAddressProps) => {
    useAddAddressSlice();
    const {
        onClose,
        profileId,
        groupId,
        isUpdatingProfileSummary = false,
        prefilledAddressDetails = {},
    } = props;
    const [addressType, setAddressType] = useState('home');
    const [addressDetails, setAddressDetails] = useState({
        full_name: '',
        pincode: '',
        address_1: '',
        address_2: '',
        landmark: '',
        city: '',
        state: '',
        label: '',
    });

    const dispatch = useDispatch();
    const addAddressPopUpStore = useSelector(selectAddAddressSlice);
    const campaignDetailsStore = useSelector(selectCampaignDetails);
    const updateAddressDetailsStore = useSelector(selectUpdateOrDeleteAddress);

    const {
        loading,
        closeAddAddressPopUp,
        notificationHeading,
        notificationSubHeading,
        isTokenValid,
    } = addAddressPopUpStore;
    const verifyOTPStore = useSelector(selectVerifyOTP);
    const { campaignData } = campaignDetailsStore;

    useEffect(() => {
        if (
            prefilledAddressDetails &&
            Object.keys(prefilledAddressDetails).length !== 0
        ) {
            setAddressDetails({
                ...addressDetails,
                full_name: prefilledAddressDetails?.full_name || '',
                address_1: prefilledAddressDetails?.address_1 || '',
                address_2: prefilledAddressDetails?.address_2 || '',
                landmark: prefilledAddressDetails?.landmark || '',
                city: prefilledAddressDetails?.city || '',
                state: prefilledAddressDetails?.state || '',
                pincode: prefilledAddressDetails?.pincode || '',
                label: prefilledAddressDetails?.label || '',
            });
        }
    }, []);

    const onAddressChange = ({ target: { name, value } }) => {
        setAddressDetails({
            ...addressDetails,
            [name]: value,
        });
    };

    const dispatchAddressDetails = () => {
        if (
            addressDetails.full_name &&
            addressDetails.pincode &&
            addressDetails.pincode.length === 6 &&
            addressDetails.state &&
            addressDetails.city &&
            addressDetails.address_1 &&
            addressDetails.address_2
        ) {
            const dispatchData = { ...addressDetails };
            dispatchData['label'] =
                addressType === 'other' ? addressDetails.label : addressType;

            if (!isUpdatingProfileSummary) {
                // handling request params when updating address FOR CAMPAIGN
                dispatchData['profile_id_hash'] = profileId;
                dispatchData['groupId'] = groupId;
                dispatchData['is_new_address'] = 1;
                dispatchData['token'] = getCookie(
                    `qzc_token_${campaignData.id}`,
                );
            } else {
                // handling request params when updating address FOR PROFILE SUMMARY
                dispatchData['token'] = localStorage.getItem('token');
                dispatchData['request_type'] = 'profile_update';

                if (Object.keys(prefilledAddressDetails).length !== 0) {
                    dispatchData['is_new_address'] = 0;
                    dispatchData['mode_type'] = 'update';
                    dispatchData['address_id'] =
                        prefilledAddressDetails?.id || '';
                } else {
                    dispatchData['mode_type'] = 'add';
                    dispatchData['is_new_address'] = 1;
                }
            }

            if (dispatchData.label && !loading) {
                !isUpdatingProfileSummary
                    ? dispatch(addAddressSliceActions.addAddress(dispatchData))
                    : dispatch(
                          signUpPageActions.updateOrDeleteAddress(dispatchData),
                      );
            }
        }
    };

    useEffect(() => {
        if (
            isTokenValid === 'no' &&
            verifyOTPStore &&
            !verifyOTPStore.isVerifySuccess
        ) {
            onClose('otp');
        }
    }, [isTokenValid, verifyOTPStore]);

    return (
        <Popup onClose={onClose}>
            <PopupContent className="popup-content edit-address-popup">
                {!closeAddAddressPopUp ? (
                    <>
                        <h2>Enter address details</h2>
                        <Form className="form-list">
                            <FormField className="form-list-item">
                                <FormLabel htmlFor="full_name">
                                    Full name (First and Last name)
                                    <Required className="required">*</Required>
                                </FormLabel>
                                <input
                                    type="text"
                                    placeholder="Enter your full name"
                                    id="full_name"
                                    name="full_name"
                                    value={addressDetails.full_name}
                                    onChange={onAddressChange}
                                />
                            </FormField>

                            <FormField className="form-list-item">
                                <FormLabel htmlFor="pincode">
                                    Pincode
                                    <Required className="required">*</Required>
                                </FormLabel>
                                <input
                                    type="number"
                                    placeholder="Eg. 457001 (0-6 digits)"
                                    id="pincode"
                                    name="pincode"
                                    min={0}
                                    value={addressDetails.pincode}
                                    onChange={onAddressChange}
                                />
                            </FormField>

                            <FormField className="form-list-item">
                                <FormLabel htmlFor="flatNumber">
                                    Flat/House no., building, company apartment
                                    <Required className="required">*</Required>
                                </FormLabel>
                                <input
                                    type="text"
                                    placeholder="Eg. KC 1801"
                                    name="address_1"
                                    id="flatNumber"
                                    value={addressDetails.address_1}
                                    onChange={onAddressChange}
                                />
                            </FormField>

                            <FormField className="form-list-item">
                                <FormLabel htmlFor="areaStreet">
                                    Area, street, sector, village
                                    <Required className="required">*</Required>
                                </FormLabel>
                                <input
                                    type="text"
                                    name="address_2"
                                    placeholder="Eg. Sesame Street"
                                    id="areaStreet"
                                    value={addressDetails.address_2}
                                    onChange={onAddressChange}
                                />
                            </FormField>

                            <FormField className="form-list-item">
                                <FormLabel htmlFor="landmark">
                                    Landmark
                                </FormLabel>
                                <input
                                    type="text"
                                    name="landmark"
                                    placeholder="Eg. near Apollo Hospital"
                                    id="landmark"
                                    value={addressDetails.landmark}
                                    onChange={onAddressChange}
                                />
                            </FormField>

                            <FormField className="form-list-item">
                                <FormLabel htmlFor="townCity">
                                    Town/City
                                    <Required className="required">*</Required>
                                </FormLabel>
                                <input
                                    type="text"
                                    name="city"
                                    placeholder="Eg. Bangalore"
                                    id="townCity"
                                    value={addressDetails.city}
                                    onChange={onAddressChange}
                                />
                            </FormField>

                            <FormField className="form-list-item">
                                <FormLabel htmlFor="state">
                                    State
                                    <Required className="required">*</Required>
                                </FormLabel>
                                <select
                                    name="state"
                                    id="state"
                                    value={addressDetails.state}
                                    onChange={onAddressChange}
                                    placeholder="Select your state"
                                    className="state-select-dropdown"
                                >
                                    <option value="" disabled>
                                        Select your state
                                    </option>
                                    {indianStatesList.map(
                                        (state, index: number) => (
                                            <option key={index} value={state}>
                                                {state}
                                            </option>
                                        ),
                                    )}
                                </select>
                            </FormField>

                            <FormField className="input-radius other location">
                                <FormLabel htmlFor="addressName">
                                    Save this address as
                                </FormLabel>
                                <FormRadio className="form-list-item radio-group">
                                    <FormRadioLabel htmlFor="home">
                                        <input
                                            type="radio"
                                            id="home"
                                            value={'home'}
                                            name="saveAddress"
                                            checked={addressType === 'home'}
                                            onChange={e => {
                                                setAddressType(e.target.value);
                                                setAddressDetails({
                                                    ...addressDetails,
                                                    label: '',
                                                });
                                            }}
                                        />
                                        <Block className="value-block">
                                            <RadioIcon>
                                                <img
                                                    src={HomeIcon}
                                                    alt="home"
                                                />
                                            </RadioIcon>
                                            <RadioLabel>Home</RadioLabel>
                                        </Block>
                                    </FormRadioLabel>
                                    <FormRadioLabel htmlFor="office">
                                        <input
                                            type="radio"
                                            id="office"
                                            value={'office'}
                                            name="saveAddress"
                                            checked={addressType === 'office'}
                                            onChange={e => {
                                                setAddressType(e.target.value);
                                                setAddressDetails({
                                                    ...addressDetails,
                                                    label: '',
                                                });
                                            }}
                                        />
                                        <Block className="value-block">
                                            <RadioIcon>
                                                <img
                                                    src={OfficeIcon}
                                                    alt="office"
                                                />
                                            </RadioIcon>
                                            <RadioLabel>Office</RadioLabel>
                                        </Block>
                                    </FormRadioLabel>
                                    <FormRadioLabel htmlFor="other">
                                        <input
                                            type="radio"
                                            id="other"
                                            value={'other'}
                                            name="saveAddress"
                                            checked={addressType === 'other'}
                                            onChange={e => {
                                                setAddressType(e.target.value);
                                            }}
                                        />
                                        <Block className="value-block">
                                            <RadioIcon>
                                                <img
                                                    src={LocationIcon}
                                                    alt="other"
                                                />
                                            </RadioIcon>
                                            <RadioLabel>Other</RadioLabel>
                                        </Block>
                                    </FormRadioLabel>
                                </FormRadio>
                                {addressType === 'other' && (
                                    <input
                                        type="text"
                                        name="label"
                                        placeholder="Name this address"
                                        id="addressName"
                                        value={addressDetails.label}
                                        onChange={onAddressChange}
                                    />
                                )}
                            </FormField>

                            <Button
                                className={`${
                                    !addressDetails.full_name ||
                                    !addressDetails.pincode ||
                                    addressDetails.pincode.length !== 6 ||
                                    !addressDetails.state ||
                                    !addressDetails.city ||
                                    !addressDetails.address_1 ||
                                    !addressDetails.address_2 ||
                                    (addressType === 'other' &&
                                        !addressDetails.label)
                                        ? 'disabled'
                                        : ''
                                }`}
                                bgColor="#F35627"
                                onClick={e => {
                                    e.preventDefault();
                                    dispatchAddressDetails();
                                }}
                            >
                                {loading ||
                                updateAddressDetailsStore.loading ? (
                                    <Spinner color="light" />
                                ) : (
                                    'Save Address'
                                )}
                            </Button>
                        </Form>{' '}
                    </>
                ) : (
                    <PopupNotification
                        responseType="step"
                        iconType="success"
                        title={notificationHeading}
                        subTitle={notificationSubHeading}
                    />
                )}
            </PopupContent>
        </Popup>
    );
});
