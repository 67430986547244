import styled from 'styled-components';
import CloseIcon from 'app/images/close-icon.svg';

const SerachInputType = styled.div`
    input {
        width: 100%;
        &:focus {
            background-color: #ffffff !important;
        }
    }

    input[type='search']::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 1em;
        width: 1em;
        border-radius: 50em;
        background: url(${CloseIcon});
        cursor: pointer;
        pointer-events: none;
    }

    input[type='search']:focus::-webkit-search-cancel-button {
        opacity: 1;
        pointer-events: all;
    }

    input[type='search'].dark::-webkit-search-cancel-button {
        filter: invert(1);
    }
`;
export { SerachInputType };
