import styled from 'styled-components';

const PaymentSection = styled.div`
    width: 100%;
    margin-top: 16px;
    border: 1px solid #e4e7eb;
    box-shadow: 0px 0px 12px rgba(9, 30, 66, 0.08);
    border-radius: 8px;
    .cardheader {
        padding: 1rem;
        display: flex;
        gap: 8px;
        span {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
        }
    }

    .payment-types {
        padding: 0 8px;
    }
`;

const PaymentTypes = styled.div`
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 10px;
    .payment-type {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .account-details {
            display: flex;
            flex-direction: column;
            text-align: right;
            font-weight: 600;
            font-size: 12px;
            h6 {
                margin: 0;
                font-weight: 600;
                font-size: 14px;
                line-height: 185%;
                letter-spacing: -0.008em;
                color: #3e4c59;
            }

            span {
                font-size: 12px;
                color: #616e7c;
                font-weight: 400;
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: end;
        gap: 12px;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        color: #3956c6;
        span {
            cursor: pointer;
        }
    }
`;
export { PaymentSection, PaymentTypes };
