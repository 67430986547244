import React, { memo } from 'react';

import { Popup } from 'app/components/Popup';
import { PetInfo } from 'app/pages/SignUpPage/components/Stepper/components/PetInfo';
interface Props {
    onClose: Function;
    prefillPetDetails: {
        has_pets: number;
        pets_list: any;
    };
}

export const PetInfoPopUp = memo((props: Props) => {
    const { onClose, prefillPetDetails } = props;

    return (
        <Popup onClose={onClose}>
            <PetInfo
                customFormStyled={true}
                prefillPetDetails={prefillPetDetails}
            />
        </Popup>
    );
});
