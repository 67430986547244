import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { messengerSaga } from './saga';
import { MessengerState } from './types';

export const initialState: MessengerState = {
    messengerthreadInfo: {
        loading: false,
        error: null,
        messenger_thread_list: [],
        meta_details: {},
    },
    threadMessagesInfo: {
        loading: false,
        error: false,
        selectedThreadId: '',
        messages: [],
        threadInfo: {},
    },
    messagesReadInfo: {
        loading: false,
        error: false,
        stuff: null,
        isThreadRead: false,
    },
    createMessageInfo: {
        loading: false,
        error: false,
        stuff: '',
        messageCreatedSuccess: '',
    },
    addressListInfo: {
        loading: false,
        error: false,
        address: [],
    },
    deliverablesList: {
        loading: false,
        error: false,
        deliverables: [],
    },
    unreadThreadCount: {
        loading: false,
        error: false,
        thread_count: 0,
    },
};

const slice = createSlice({
    name: 'messenger',
    initialState,
    reducers: {
        getMessengerThread(state, action: PayloadAction<any>) {
            state.messengerthreadInfo.loading = true;
            state.messagesReadInfo.isThreadRead = false;
            if (!action.payload.loadMore) {
                state.messengerthreadInfo.messenger_thread_list = [];
            }
        },
        getMessengerThreadSuccess(state, action: PayloadAction<any>) {
            state.messengerthreadInfo.loading = false;
            if (
                state.messengerthreadInfo.messenger_thread_list?.length &&
                action.payload?.threads.length
            ) {
                state.messengerthreadInfo.messenger_thread_list = Array.from(
                    new Set([
                        ...state.messengerthreadInfo.messenger_thread_list,
                        ...action.payload.threads,
                    ]),
                );
            } else {
                state.messengerthreadInfo.messenger_thread_list =
                    action.payload.threads;
            }
            state.messengerthreadInfo.meta_details =
                action.payload.pagination_meta_data;
        },
        getMessengerThreadError(state, action: PayloadAction<any>) {
            state.messengerthreadInfo.loading = false;
            state.messengerthreadInfo.error = action.payload.data?.error;
        },

        //Get thread messages
        threadMessagesList(state, action: PayloadAction<any>) {
            state.threadMessagesInfo.loading = true;
            state.threadMessagesInfo.error = false;
            state.threadMessagesInfo.selectedThreadId =
                action.payload.thread_id_hash;
            state.threadMessagesInfo.threadInfo = {};
        },
        threadMessagesListSuccess(state, action: PayloadAction<any>) {
            state.threadMessagesInfo.loading = false;
            state.threadMessagesInfo.messages = action.payload.messages;
            state.threadMessagesInfo.threadInfo = action.payload.thread;
        },
        threadMessagesListError(state, action: PayloadAction<any>) {
            state.threadMessagesInfo.error = false;
        },

        // read thread messages
        readThreadMessages(state, action: PayloadAction<any>) {
            state.messagesReadInfo.loading = true;
            state.messagesReadInfo.error = false;
            state.messagesReadInfo.isThreadRead = false;
        },

        readThreadMessagesSuccess(state, action: PayloadAction<any>) {
            state.messagesReadInfo.loading = false;
            state.messagesReadInfo.stuff = action.payload.stuff;
            state.messagesReadInfo.isThreadRead = action.payload.ok;

            const tempThreadList =
                state.messengerthreadInfo.messenger_thread_list;
            var tempThread: any = null;
            tempThreadList.map((thread, index) => {
                if (
                    tempThreadList[index][0].id_hash ===
                    state.threadMessagesInfo.selectedThreadId
                ) {
                    tempThread = tempThreadList[index][0] = [
                        action.payload.thread,
                    ];
                    tempThreadList.splice(index, 1, tempThread);
                    state.messengerthreadInfo.messenger_thread_list =
                        tempThreadList;
                }
            });
        },
        readThreadMessagesError(state, action: PayloadAction<any>) {
            state.messagesReadInfo.error = false;
            state.messagesReadInfo.isThreadRead = false;
        },

        // create message
        createMessage(state, action: PayloadAction<any>) {
            state.createMessageInfo.loading = true;
            state.createMessageInfo.error = false;
        },
        createMessageSuccess(state, action: PayloadAction<any>) {
            state.createMessageInfo.loading = false;
            state.createMessageInfo.stuff = action.payload.stuff;
            state.createMessageInfo.messageCreatedSuccess = action.payload?.ok;
            state.threadMessagesInfo.messages = Array.from(
                new Set([
                    ...state.threadMessagesInfo.messages,
                    ...action.payload.recent_messages,
                ]),
            );
        },
        createMessageError(state, action: PayloadAction<any>) {
            state.createMessageInfo.loading = false;
            state.createMessageInfo.error = false;
        },

        // get influencer address
        getInfluencerAddress(state, action: PayloadAction<any>) {
            state.addressListInfo.loading = true;
            state.addressListInfo.error = false;
        },
        getInfluencerAddressSuccess(state, action: PayloadAction<any>) {
            state.addressListInfo.loading = false;
            state.addressListInfo.address = action.payload.addresses;
        },
        getInfluencerAddressError(state, action: PayloadAction<any>) {
            state.addressListInfo.error = false;
            state.addressListInfo.loading = false;
        },

        // get deliverables list
        getDeliverables(state, action: PayloadAction<any>) {
            state.deliverablesList.loading = true;
            state.deliverablesList.error = false;
        },
        getDeliverablesSuccess(state, action: PayloadAction<any>) {
            state.deliverablesList.loading = false;
            state.deliverablesList.deliverables = action.payload;
        },
        getDeliverablesError(state, action: PayloadAction<any>) {
            state.deliverablesList.error = false;
        },

        // get unread thread count action
        getUnreadThreadCount(state, action: PayloadAction<any>) {
            state.unreadThreadCount.loading = true;
            state.unreadThreadCount.error = false;
        },
        getUnreadThreadCountSuccess(state, action: PayloadAction<any>) {
            state.unreadThreadCount.loading = false;
            state.unreadThreadCount.thread_count =
                action.payload.unread_messages_threads_count;
        },
        getUnreadThreadCountError(state, action: PayloadAction<any>) {
            state.unreadThreadCount.error = false;
        },
    },
});

export const { actions: messengerActions } = slice;

export const useMessengerSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga: messengerSaga });
    return { actions: slice.actions };
};
