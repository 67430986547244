import styled from 'styled-components';

const CategoryHeaderSection = styled.div`
    height: 180px;
    min-height: 180px;
    background: #1f2933;

    .left-Arrow {
        position: relative;
        left: 16px;
        top: 13px;
        cursor: pointer;
    }

    .header-contents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 16px;

        text-align: center;
        height: 85%;

        .category-icon {
            font-size: 24px;
            margin-bottom: 0 !important;
        }

        .category-content {
            font-weight: 600;
            font-size: 18px;
            color: #ffffff !important;
            letter-spacing: -0.008em;
        }

        p.category-description {
            padding-top: 4px;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            color: var(--gray-400);
            text-transform: capitalize;
        }
    }
`;

const CategoryBodySection = styled.div`
    padding: 16px;
    h5 {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.008em;
        color: #1f2933;
        padding: 16px 4px;
        display: flex;
        justify-content: space-between;
        span {
            cursor: pointer;
        }
    }

    .raise-ticket {
        padding-top: 24px;
    }
`;

export { CategoryHeaderSection, CategoryBodySection };
