import Skeleton from 'react-loading-skeleton';
import {
    StyledAddressCardLoader,
    StyledListSkeleton,
    StyledHtmlElementSkeleton,
    StyleHomepageCardSkeleton,
    StyledMessengerSkeletonLoader,
    StyledChatMessageSkeleton,
    StyledChatWindowHeaderSkeleton,
    StyledCampaignDetailsPageSkeleton,
} from './index.styled';

interface ListSkeletonProps {
    listItemCount?: number;
    customClassName?: string;
    flexDirection?: string;
    width?: number;
    height?: number;
    flexGap?: number;
    customPadding?: number;
    customListItemStyles?: any;
}

interface ElementSkeletonProps {
    count?: number;
    width?: number;
    height?: number;
    marginTop?: any;
    marginBottom?: any;
    marginLeft?: any;
    marginRight?: any;
    customStyles?: any;
    borderRadius?: any;
}

interface AddressSkeletonProps {
    count?: number;
}

interface MessengerSkeletonProps {
    count?: number;
}

export const HtmlElementSkeleton = (props: ElementSkeletonProps) => {
    const {
        count = 1,
        width,
        height,
        customStyles = {},
        borderRadius = 3,
    } = props;
    return (
        <StyledHtmlElementSkeleton className="html-element-skeleton">
            <Skeleton
                count={count}
                width={width}
                height={height}
                borderRadius={borderRadius}
                style={customStyles}
            />
        </StyledHtmlElementSkeleton>
    );
};

export const HomepageCampaignCardSkeleton = () => {
    return (
        <StyleHomepageCardSkeleton className="homepage-campaign-card-skeleton">
            <Skeleton borderRadius={8} width={312} height={365} />
        </StyleHomepageCardSkeleton>
    );
};

export const ProfileImageSkeleton = (props: any) => {
    const { imgWidth } = props;
    return (
        <Skeleton
            circle
            width={imgWidth}
            height={imgWidth}
            borderRadius={100}
        />
    );
};

export const ListSkeleton = (props: ListSkeletonProps) => {
    const {
        listItemCount = 3,
        customClassName = '',
        flexDirection = '',
        width = '',
        height = '',
        flexGap = 0,
        customPadding = 0,
        customListItemStyles,
    } = props;

    return (
        <StyledListSkeleton
            className={`${customClassName} ${flexDirection}`}
            flexDirection={flexDirection}
            flexGap={flexGap}
            customPadding={customPadding}
        >
            <Skeleton
                count={listItemCount}
                width={width}
                height={height}
                style={customListItemStyles}
            />
        </StyledListSkeleton>
    );
};

export const AddressCardSkeleton = (props: AddressSkeletonProps) => {
    const { count = 1 } = props;

    return (
        <>
            {Array(count)
                .fill('')
                .map((item: any, index) => (
                    <StyledAddressCardLoader key={index}>
                        <Skeleton circle width={20} height={20} />
                        <div className="address-info-skeleton">
                            <Skeleton width={50} height={20} />
                            <Skeleton height={15} width={250} />
                        </div>
                    </StyledAddressCardLoader>
                ))}
        </>
    );
};

export const MessengerSkeleton = (props: MessengerSkeletonProps) => {
    const { count = 10 } = props;

    return (
        <>
            {Array(count)
                .fill('')
                .map((item: any, index) => (
                    <StyledMessengerSkeletonLoader key={index}>
                        <Skeleton circle width={40} height={40} />
                        <div className="message-info-body">
                            <Skeleton width={100} height={18} />
                            <Skeleton width={200} height={10} />
                        </div>
                        <Skeleton
                            width={40}
                            height={8}
                            borderRadius={2}
                            className="message-time"
                        />
                    </StyledMessengerSkeletonLoader>
                ))}
        </>
    );
};

export const ChatMessageSkeleton = (props: any) => {
    return (
        <StyledChatMessageSkeleton>
            <Skeleton width={250} height={100} borderRadius={8} />
            <Skeleton width={220} height={90} borderRadius={8} />
            <Skeleton width={180} height={50} borderRadius={8} />
        </StyledChatMessageSkeleton>
    );
};

export const ChatWindowHeaderSkeleton = (props: any) => {
    return (
        <StyledChatWindowHeaderSkeleton>
            <Skeleton circle width={32} height={32} />
            <div className="sender-brief">
                <Skeleton width={100} height={16} />
                <Skeleton width={120} height={10} />
            </div>
            {/* <div className="actions">
                <Skeleton width={30} height={30} />
                <Skeleton width={30} height={30} />
            </div> */}
        </StyledChatWindowHeaderSkeleton>
    );
};

export const CampaignDetailsPageSkeleton = () => {
    return (
        <StyledCampaignDetailsPageSkeleton>
            <Skeleton circle width={20} height={20} />
            <Skeleton width={200} height={25} />
            <Skeleton width={250} height={15} />
        </StyledCampaignDetailsPageSkeleton>
    );
};

export const InfluncerAddressSkeleton = (props: MessengerSkeletonProps) => {
    const { count = 10 } = props;

    return (
        <>
            {Array(count)
                .fill('')
                .map((item: any, index) => (
                    <StyledMessengerSkeletonLoader key={index}>
                        <Skeleton circle width={24} height={24} />
                        <div className="message-info-body">
                            <Skeleton width={100} height={18} />
                            <Skeleton width={200} height={10} />
                        </div>
                    </StyledMessengerSkeletonLoader>
                ))}
        </>
    );
};
