import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';

import HelpIcon from 'app/images/SVGComponents/HelpIcon';
import HomeIcon from 'app/images/SVGComponents/HomeIcon';
import ProfileIcon from 'app/images/SVGComponents/ProfileIcon';
import MessengerIcon from 'app/images/SVGComponents/Messenger';
import CampaignIcon from 'app/images/SVGComponents/CampaignIcon';
import GrayCampaignIcon from 'app/images/SVGComponents/GrayCampaignIcon';

import { TabListSection, TabContent } from './index.styled';
import OrageMessengerIcon from 'app/images/SVGComponents/OrangeMesgIcon';
import { Notify } from '../MessengerTabSection/index.styled';

import {
    useMessengerSlice,
    messengerActions,
} from 'app/pages/Tabs/MessengerTabSection/slice/index';
import {
    signUpPageActions,
    useSignUpPageSlice,
} from 'app/pages/SignUpPage/slice';
import { useDispatch, useSelector } from 'react-redux';
import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';
import { selectGetUnreadThreadCount } from 'app/pages/Tabs/MessengerTabSection/slice/selectors';

interface Props {
    profile_id?: number;
}

export const TabList = memo((props: Props) => {
    useMessengerSlice();
    useSignUpPageSlice();
    const dispatch = useDispatch();
    const history = useHistory();

    const unreadThreadCount = useSelector(selectGetUnreadThreadCount);
    const { thread_count } = unreadThreadCount;
    const { profile_id } = props;

    const [activeTab, setActiveTab] = useState(
        history ? history.location.pathname.slice(1) : 'home',
    );

    useEffect(() => {
        if (profile_id) {
            dispatch(
                messengerActions.getUnreadThreadCount({
                    type: 'influencer',
                    profile_id: profile_id,
                }),
            );
        }
    }, [profile_id]);

    return (
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <TabListSection>
                <TabContent
                    className={`${activeTab === 'home' ? 'active' : ''} `}
                    onClick={() => {
                        history.push('/home');
                    }}
                >
                    <HomeIcon />
                    <span>Home</span>
                </TabContent>

                <TabContent
                    className={`${activeTab === 'campaign' ? 'active' : ''} `}
                    onClick={() => {
                        history.push('/campaign');
                    }}
                >
                    {activeTab === 'campaign' ? (
                        <CampaignIcon />
                    ) : (
                        <GrayCampaignIcon />
                    )}

                    <span>Campaigns</span>
                </TabContent>

                <TabContent
                    className={`${activeTab === 'helpdesk' ? 'active' : ''} `}
                    onClick={() => {
                        history.push('/helpdesk');
                    }}
                >
                    <HelpIcon />
                    <span>Helpdesk</span>
                </TabContent>

                <TabContent
                    className={`${activeTab === 'messenger' ? 'active' : ''} `}
                    onClick={() => {
                        history.push('/messenger');
                        localStorage.setItem('isMessageOpen', '1');
                    }}
                >
                    <>
                        {thread_count > 0 ? (
                            <Notify className="custom-notify">
                                <span className="notification-count">
                                    {thread_count > 99 ? '99+' : thread_count}
                                </span>
                            </Notify>
                        ) : (
                            ''
                        )}
                        {activeTab === 'messenger' ? (
                            <OrageMessengerIcon />
                        ) : (
                            <MessengerIcon />
                        )}
                    </>
                    <span>Messenger</span>
                </TabContent>
            </TabListSection>
        </SkeletonTheme>
    );
});
