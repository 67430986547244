import styled from 'styled-components';

const Border = styled.div`
    border-top: 1px solid #e4e7eb;
`;

const ProfileHeaderSection = styled.div`
    height: 325px;
    background: #eefbef;
    text-align: center;
    .previous-arrow {
        position: absolute;
        left: 16px;
        top: 12px;
        cursor: pointer;
    }

    .userprofile-contents {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        img.creator-profile-image {
            border-radius: 50%;
            height: 80px;
            width: 80px;
            margin-bottom: 8px;
        }

        span {
            width: fit-content;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
        }

        span.influencer-bio {
            color: var(--gray-400);
            margin-top: 5px;
            max-width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .user-name {
            font-weight: 700;
            font-size: 28px;
            line-height: 36px;
            color: var(--gray-900);
        }
    }

    .img-upload {
        position: relative;

        label {
            bottom: 0px;
            left: 14px;
            position: absolute;
            background-color: #1f2933;
            height: 24px;
            border-radius: 50%;
            width: 24px;
            border: 1.5px solid #ffffff;
            text-align: center;
            cursor: pointer;

            img {
                width: 12px;
            }
        }

        input {
            display: none;
        }
    }
`;

const PersonalStatusInfo = styled.div`
    width: auto;
    background: #ffffff;
    position: relative;
    border-radius: 24px;
    top: -20px;

    .list-types {
        list-style: none;
        margin: 0;
        padding: 2.9rem 0px 0px 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 40px;

        .list-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 12px;

            strong {
                cursor: pointer;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #1f2933;
                margin: 0;
            }

            img {
                cursor: pointer;
            }

            span {
                width: fit-content;
                height: 24px;
                border: 1px solid #e6a203;
                border-radius: 20px;
                position: absolute;
                right: 24px;
                font-weight: 700;
                font-size: 10px;
                line-height: 21px;
                padding: 0px 10px;
                color: #e6a203;
            }

            .update {
                color: #f9703e;
                border: 1px solid #f9703e;
            }

            .logout-link {
                width: 100%;
                display: flex;
                gap: 12px;
                align-items: center;
                text-decoration: none !important;
            }
        }
    }
`;

export { Border, ProfileHeaderSection, PersonalStatusInfo };
