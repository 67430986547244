/**
 *
 * Popup
 *
 */
import React, { memo, useRef, useEffect } from 'react';
import PopUpWrapper, {
    Close,
    PopUpStyled,
    PopUpContentBlock,
} from './index.styled';

import CloseSvg from './assets/close.svg';

interface Props {
    onClose: Function;
    children: any;
    CustomStyled?: boolean;
}

export const Popup = memo((props: Props) => {
    const { onClose: onCloseProp, children, CustomStyled } = props;

    const ref: any = useRef();
    const popupRef: any = useRef();
    const onClose = () => {
        if (popupRef.current) {
            popupRef.current.classList.add('popup-delay');
        }
        setTimeout(() => {
            onCloseProp();
        }, 250);
    };

    const handleClick = e => {
        if (ref.current && ref.current.isEqualNode(e.target)) {
            onClose();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    });

    return (
        <PopUpWrapper
            className={`popup ${CustomStyled ? 'share-link-styled' : ''}`}
            ref={ref}
        >
            <PopUpContentBlock className="pop-up-content-block" ref={popupRef}>
                <Close className="close" onClick={() => onClose()}>
                    <img src={CloseSvg} alt="close" />
                </Close>
                <PopUpStyled className="popup-styled">{children}</PopUpStyled>
            </PopUpContentBlock>
        </PopUpWrapper>
    );
});
