import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { campaignDetailsSaga } from './saga';
import { CampaignDetailsState } from './types';

export const initialState: CampaignDetailsState = {
    loading: false,
    error: null,
    campaignData: null,
    variableCost: 0,
    campaignStatus: '',
    campaignMessageCount: 0,
};

const slice = createSlice({
    name: 'campaignDetails',
    initialState,
    reducers: {
        getCampaignDetails(state, action: PayloadAction<any>) {
            state.loading = true;
        },
        getCampaignDetailsSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.campaignData = action.payload.data.campaign_details;
            state.campaignStatus = action.payload.data.status;
            state.variableCost = action.payload.data?.cost_quoted;
            state.campaignMessageCount = action.payload.data.new_messages_count;
        },
        getCampaignDetailsError(state, action: PayloadAction<any>) {
            state.loading = false;
            state.error = action.payload;
        },

        clearCampaignMessageCount(state, action: PayloadAction<any>) {
            state.campaignMessageCount = 0;
        },
    },
});

export const { actions: campaignDetailsActions } = slice;

export const useCampaignDetailsSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga: campaignDetailsSaga });
    return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCampaignDetailsSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
