import styled from 'styled-components';

const CategoryViewDetails = styled.div`
    .view-detail-header {
        width: auto;
        height: 50px;
        display: flex;
        align-items: center;
        img {
            cursor: pointer;
        }
    }

    .view-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.311111px;
        color: #1f2933;
        p {
            margin-top: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.222222px;
        }
    }
`;

const CategoryFooterSection = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 640px;
    margin: auto;
    background-color: #f1f4f8;
    height: 127px;
    padding: 24px 16px;
    text-align: center;
    h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.222222px;
        color: #3e4c59;
        padding-bottom: 8px;
    }

    .help-buttons {
        display: flex;
        justify-content: center;
        display: flex;
        gap: 24px;
        height: 44px;
        .button {
            width: 108px;
            background: #ffffff;
            border: 1px solid #cbd2d9;
            border-radius: 4px;
            outline: none !important;
            .btn-text-yes {
                color: #3e4c59;
            }
            span {
                display: contents;
                color: #1f2933;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 0.355556px;
            }
            &:hover {
                box-shadow: 0px 2px 6px rgb(9 30 66 / 4%),
                    0px 8px 12px rgb(9 30 66 / 8%);
            }
        }
    }
`;

export { CategoryViewDetails, CategoryFooterSection };
