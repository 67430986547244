import React, { memo } from 'react';
import { Popup } from 'app/components/Popup';
import { PersonalDetails } from 'app/pages/SignUpPage/components/Stepper/components/PersonalDetails';
interface Props {
    onClose: Function;
    prefillPersonalData: {
        influencerName: string;
        age: any;
        gender: string;
        city: string;
    };
}

export const PersonelInfoPopUp = memo((props: Props) => {
    const { onClose, prefillPersonalData } = props;

    return (
        <Popup onClose={onClose}>
            <PersonalDetails
                showIntro={false}
                customFormStyled={true}
                prefillPersonalData={prefillPersonalData}
            />
        </Popup>
    );
});
