import React from 'react';
import './svg.css';

export default function InstagramReelsIcon() {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.375 1.75H5.625C3.20875 1.75 1.25 3.70875 1.25 6.125V14.875C1.25 17.2912 3.20875 19.25 5.625 19.25H14.375C16.7912 19.25 18.75 17.2912 18.75 14.875V6.125C18.75 3.70875 16.7912 1.75 14.375 1.75Z"
                stroke="#7B8794"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.89551 1.75L8.54134 7.58333"
                stroke="#7B8794"
                strokeWidth="1.5"
            />
            <path
                d="M10.7295 1.75L14.3753 7.58333"
                stroke="#7B8794"
                strokeWidth="1.5"
            />
            <path d="M1.25 7.58325H18.75" stroke="#7B8794" strokeWidth="1.5" />
            <path
                d="M8.54102 10.5L13.0781 13.4167L8.54102 16.3333V10.5Z"
                stroke="#7B8794"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
