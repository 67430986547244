import styled from 'styled-components';

const ReferRewardStyle = styled.div`
    width: auto;
    height: 254px;
    box-shadow: 1px 2px 4px rgba(9, 30, 66, 0.08),
        0px -1px 2px rgba(9, 30, 66, 0.08);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin: 0px 16px;
    justify-content: space-between;
    .Reward-contents {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        span {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            text-align: center;
            letter-spacing: -0.00533333em;
            color: #52606d;
        }
    }

    .link-btn {
        height: 40px;
        background: #3956c6;
        border-radius: 4px;
        span {
            font-weight: 600;
            font-size: 12px;
            line-height: 150%;
            letter-spacing: -0.00533333em;
            color: #ffffff;
        }
    }
`;

const RewardSection = styled.div``;

export { RewardSection, ReferRewardStyle };
