import React, { memo } from 'react';
import { Popup } from 'app/components/Popup';
import { ContactDetails } from 'app/pages/SignUpPage/components/Stepper/components/ContactDetails';
interface Props {
    onClose: Function;
    prefillContactDetails?: {
        whatsapp_number?: any;
        phone_number?: any;
        email?: any;
    };
}

export const ContactInfoPopUp = memo((props: Props) => {
    const { onClose, prefillContactDetails } = props;

    return (
        <Popup onClose={onClose}>
            <ContactDetails
                showIntro={false}
                customFormStyled={true}
                prefillContactDetails={prefillContactDetails}
            />
        </Popup>
    );
});
