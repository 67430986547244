import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { noScroll } from 'utils/helper';

import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import {
    selectInfluencerProfileSummary,
    selectShowPopupStatus,
    selectUpdateOrDeletePaymentDetail,
} from 'app/pages/SignUpPage/slice/selectors';
import { selectPanCardPopup } from 'app/pages/HomePage/components/PanCardPopup/slice/selectors';

import { PanCardPopup } from 'app/pages/HomePage/components/PanCardPopup';
import { PromptMessage } from 'app/pages/Tabs/TabMenu/CommonComponents/PromptMessage';
import { HtmlElementSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';

import Pancard from 'app/images/fi_PAN.png';
import { PanCardStyle } from './index.styled';
import { Border } from 'app/pages/Tabs/ProfileTabSection/index.styled';

interface Props {
    prefilledPanNumber?: string;
}

export const PanCard = memo((props: Props) => {
    const { prefilledPanNumber } = props;
    const dispatch = useDispatch();
    const panCardPopUpStore = useSelector(selectPanCardPopup);
    const isShowingPopup = useSelector(selectShowPopupStatus);
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const updatePaymentDetailsStore = useSelector(
        selectUpdateOrDeletePaymentDetail,
    );

    const { isPanCardUpdated, isTokenValid } = panCardPopUpStore;
    const { loading: profileSummaryLoading } = profileSummarySelector;
    const {
        isUpdateOrDeletePaymentDetailSuccess,
        loading: paymentDetailUpdateLoading,
        currentlyUpdatingPayDetail,
    } = updatePaymentDetailsStore;
    const [addpanCard, setPanCard] = useState(false);

    useEffect(() => {
        noScroll(addpanCard);
    }, [addpanCard]);

    const handleShowPanInfoPopup = (showBool: boolean = false) => {
        setPanCard(showBool);
        dispatch(signUpPageActions.setIsShowPopup(showBool));
    };

    useEffect(() => {
        if (prefilledPanNumber) {
            if (
                !updatePaymentDetailsStore.loading &&
                isUpdateOrDeletePaymentDetailSuccess
            ) {
                handleShowPanInfoPopup(false);
            }
        } else {
            if (!panCardPopUpStore.loading && isPanCardUpdated) {
                handleShowPanInfoPopup(false);
            }
        }
    }, [panCardPopUpStore, updatePaymentDetailsStore]);

    const handleDeletePanDetails = () => {
        const token = localStorage.getItem('token');
        dispatch(
            signUpPageActions.updateOrDeletePaymentDetail({
                is_pan_exists: 1,
                pan_number: null,
                preferred_mode: 'pan',
                mode_type: 'delete',
                token: token,
                request_type: 'profile_update',
            }),
        );
    };

    return (
        <>
            <PanCardStyle>
                <div className="card-header-section">
                    <div className="header-profile ">
                        <img src={Pancard} alt="pancard-icon" />
                        <span>PAN Details</span>
                        {profileSummaryLoading ? (
                            <HtmlElementSkeleton
                                width={60}
                                height={15}
                                customStyles={{
                                    marginTop: '5px',
                                }}
                            />
                        ) : (
                            <div className="pan-update-action-controls">
                                {prefilledPanNumber ? (
                                    <>
                                        <span
                                            className="control"
                                            onClick={() => {
                                                handleShowPanInfoPopup(true);
                                                dispatch(
                                                    signUpPageActions.resetProfileSummaryUpdateSuccess(),
                                                );
                                            }}
                                        >
                                            Edit
                                        </span>
                                        <span
                                            className="control"
                                            onClick={e => {
                                                e.preventDefault();
                                                handleDeletePanDetails();
                                            }}
                                        >
                                            Delete
                                        </span>
                                    </>
                                ) : (
                                    <span
                                        className="control"
                                        onClick={() => {
                                            handleShowPanInfoPopup(true);
                                        }}
                                    >
                                        + Add PAN number
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    {/* {profileSummaryLoading ? (
                        <HtmlElementSkeleton
                            width={80}
                            height={15}
                            customStyles={{
                                marginTop: '5px',
                            }}
                        />
                    ) : (
                        <span
                            onClick={() => {
                                handleShowPanInfoPopup(true);
                            }}
                            className="add-label"
                        >
                            {' '}
                            {prefilledPanNumber ? '' : '+ Add PAN number'}
                        </span>
                    )} */}
                </div>

                {profileSummaryLoading ||
                (paymentDetailUpdateLoading &&
                    currentlyUpdatingPayDetail === 'pan') ? (
                    <HtmlElementSkeleton
                        width={200}
                        height={20}
                        customStyles={{ margin: '0 0 25px 15px' }}
                    />
                ) : prefilledPanNumber ? (
                    <>
                        <Border />
                        <span className="pan-content">
                            {prefilledPanNumber}
                        </span>
                    </>
                ) : (
                    <PromptMessage>
                        You’ll be charged 20% TDS, if you don’t add PAN details.
                    </PromptMessage>
                )}
            </PanCardStyle>

            {addpanCard && isShowingPopup && (
                <PanCardPopup
                    onClose={() => handleShowPanInfoPopup(false)}
                    profileId={''}
                    groupId={''}
                    isUpdatingProfileSummary={true}
                    prefilledPanNumber={prefilledPanNumber || ''}
                />
            )}
        </>
    );
});
