import React, { memo, useEffect } from 'react';
import AppWrapper from 'app/components/AppWrapper';
import { HeaderTittle, ProfileSection } from '../About/index.styled';
import PreviewBlockIcon from 'app/images/black-left-arrow.svg';
import { useHistory } from 'react-router-dom';
import { PreferenceCard } from './components/BarterCampaign';
import { ContentCategories } from '../Preferences/components/ContentCategories';
import { useSelector } from 'react-redux';
import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';

interface Props {}

export const Preference = memo((props: Props) => {
    const history = useHistory();
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const {
        profileSummary: { category = [], campaign_type = {} },
    } = profileSummarySelector;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <AppWrapper>
            <HeaderTittle>
                <img
                    src={PreviewBlockIcon}
                    onClick={history.goBack}
                    alt="preview-icon"
                />
                <span>Preferences</span>
            </HeaderTittle>
            <ProfileSection>
                <PreferenceCard
                    isInterestedInBarterCampaign={Boolean(
                        campaign_type?.interested_in_barter_campaign,
                    )}
                    isInterestedInEvents={Boolean(
                        campaign_type?.interested_in_events,
                    )}
                />
                <ContentCategories contentCategories={category} />
            </ProfileSection>
        </AppWrapper>
    );
});
