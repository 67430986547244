import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { addBankActions as actions } from '.';
import { campaignProgressActions } from 'app/pages/HomePage/components/AcceptedTimeline/slice';
import { otpVerifyPopUpActions } from 'app/pages/HomePage/components/OtpVerifyPopUp/slice';

export function* addBankDetailsSaga({ payload }) {
    try {
        const requestURL = `/api/campaign.profile.addPaymentDetails`;

        const response = yield call(request, requestURL, {
            method: 'POST',
            headers: {
                // /Authorization: authHeader(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(payload),
        });
        yield put(
            campaignProgressActions.getCampaignProgressDetails({
                profile_id_hash: payload.profile_id_hash,
                group_id_hash: payload.groupId,
            }),
        );
        yield put(actions.addBankDetailsSuccess(response));
    } catch (error) {
        try {
            const response = yield error.response.json();
            yield put(actions.addBankDetailsError(response));
            if (response.error === 'token_invalid') {
                yield put(otpVerifyPopUpActions.setOTPUnverified(false));
            }
        } catch (jsonError) {
            yield put(actions.addBankDetailsError(error));
        }
    }
}

export function* addBankSaga() {
    yield takeLatest(actions.addBankDetails, addBankDetailsSaga);
}
