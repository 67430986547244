import styled from 'styled-components';

const HelpCategorySection = styled.div`
    &.grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    display: block;
    margin-bottom: 32px;

    .category-types {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        gap: 1rem;
        padding: 16px 10px;
        height: 138px;

        background: #f5f7fa;
        border-radius: 8px;
        cursor: pointer;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: #7b8794;
        text-align: center;
        line-height: 140%;
        letter-spacing: -0.008em;

        .category-title {
            font-weight: 600;
            font-size: 14px;
            color: #323f4b;
        }

        .category-description {
            text-transform: capitalize;
        }

        p {
            padding-top: 4px;
            margin: 0;
        }

        .category-icon {
            font-size: 24px;
        }
    }
`;
export { HelpCategorySection };
