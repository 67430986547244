import React from 'react';
import './svg.css';

export default function HelpIcon() {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.9991 4C19.5214 4 23.9979 8.47645 23.9979 13.9988C23.9979 19.5211 19.5214 23.9976 13.9991 23.9976C12.3815 23.9997 10.7878 23.6078 9.35567 22.8557L5.06619 23.9726C4.92287 24.0099 4.77227 24.0091 4.62934 23.9703C4.4864 23.9315 4.35609 23.856 4.25131 23.7514C4.14653 23.6467 4.07092 23.5164 4.03198 23.3735C3.99305 23.2306 3.99213 23.08 4.02932 22.9367L5.14518 18.6482C4.39107 17.2146 3.99809 15.6186 4.00032 13.9988C4.00032 8.47645 8.47678 4 13.9991 4ZM13.9991 17.4984C13.7339 17.4984 13.4796 17.6037 13.2921 17.7912C13.1046 17.9787 12.9992 18.233 12.9992 18.4982C12.9992 18.7634 13.1046 19.0177 13.2921 19.2053C13.4796 19.3928 13.7339 19.4981 13.9991 19.4981C14.2643 19.4981 14.5186 19.3928 14.7061 19.2053C14.8936 19.0177 14.999 18.7634 14.999 18.4982C14.999 18.233 14.8936 17.9787 14.7061 17.7912C14.5186 17.6037 14.2643 17.4984 13.9991 17.4984ZM13.9991 8.74942C13.2698 8.74942 12.5705 9.03912 12.0548 9.55478C11.5391 10.0704 11.2494 10.7698 11.2494 11.4991C11.2495 11.6891 11.3217 11.872 11.4514 12.0108C11.5811 12.1496 11.7587 12.2341 11.9482 12.247C12.1378 12.26 12.3252 12.2005 12.4726 12.0806C12.62 11.9607 12.7164 11.7893 12.7423 11.6011L12.7563 11.3711C12.79 11.0532 12.9443 10.7603 13.1874 10.5527C13.4305 10.3451 13.7439 10.2385 14.0632 10.2549C14.3825 10.2713 14.6833 10.4094 14.9039 10.6408C15.1244 10.8723 15.2479 11.1794 15.2489 11.4991C15.2489 12.038 15.114 12.304 14.604 12.8309L14.3001 13.1409C13.5462 13.9248 13.2492 14.4877 13.2492 15.4986C13.2492 15.6975 13.3282 15.8882 13.4688 16.0289C13.6095 16.1695 13.8002 16.2485 13.9991 16.2485C14.198 16.2485 14.3887 16.1695 14.5294 16.0289C14.67 15.8882 14.749 15.6975 14.749 15.4986C14.749 14.9597 14.884 14.6937 15.3939 14.1668L15.6979 13.8568C16.4518 13.0729 16.7488 12.51 16.7488 11.4991C16.7488 10.7698 16.4591 10.0704 15.9434 9.55478C15.4277 9.03912 14.7284 8.74942 13.9991 8.74942Z"
                fill="#7B8794"
            />
        </svg>
    );
}
