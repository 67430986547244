import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { paymentPopupActions as actions } from '.';

export function* getPaymentDetailsSaga({ payload }) {
    try {
        const requestURL = `/api/campaign.profile.getPaymentDetails`;

        const response = yield call(request, requestURL, {
            method: 'POST',
            headers: {
                // /Authorization: authHeader(),
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(payload),
        });

        yield put(actions.getPaymentDetailsSuccess(response));

        // yield put(
        //     addToast({
        //         type: response.ok === true ? 'success' : 'error',
        //         message: response.stuff,
        //     }),
        // );
    } catch (error) {
        try {
            const response = yield error.response.json();
            yield put(actions.getPaymentDetailsError(response));
        } catch (jsonError) {
            yield put(actions.getPaymentDetailsError(error));
        }
    }
}

export function* paymentPopupSaga() {
    yield takeLatest(actions.getPaymentDetails, getPaymentDetailsSaga);
}
