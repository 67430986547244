import styled from 'styled-components/macro';

const PopupContent = styled.div`
    display: flex;
    flex-direction: column;
    > button {
        min-height: 44px;
        margin-top: 2.5rem;
    }
    h2 {
        display: flex;
        align-items: center;
        font-weight: 600;
        line-height: 140%;
        img {
            margin-right: 12px;
        }
        .location-pin {
            width: 20px;
            height: 20px;
        }
    }
`;

export default PopupContent;
