import styled from 'styled-components';

const QoruzRecommendedHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 27px;
    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: #1f2933;
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: -0.00466667em;
            color: #7b8794;
        }
    }
`;

const QoruzRecommendedBody = styled.div`
    padding: 12px;
    gap: 16px;
    height: 232px;
    background: #fff3eb;
    border-radius: 8px;
    color: #1f2933;

    ul {
        /* position: relative; */
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        p {
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 12px;
            line-height: 28px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            color: #1f2933;
        }

        li {
            display: flex;
            justify-content: space-between;
            padding-left: 8px;
            div {
                display: flex;
                align-items: baseline;
                .img {
                    position: relative;
                    top: 3px;
                }
            }
            .total {
                display: grid;
                text-align: end;
                font-weight: 700;
                font-size: 14px;
                color: #1f2933;
                gap: 2px;

                .estimate {
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 150%;
                    color: #7b8794;
                }
            }
            span {
                padding-left: 8px;
                padding-right: 2px;
                font-weight: 700;
                font-size: 14px;
                line-height: 150%;
                color: #1f2933;
                /* &.total {
                   
                    right: 12px;
                } */
            }
        }
    }
`;

const QoruzRecommendedFooter = styled.div`
    height: 81px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .button {
        display: flex;
        gap: 8px;
        .editCost {
            width: 30%;
            color: #3e4c59;
            border: 1px solid #cbd2d9;
            background-color: #ffffff;
        }
        .acceptCost {
            width: 70%;
            height: 44px;
        }
    }

    span {
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #7b8794;
    }
    &.editCost {
        height: 44px;
        width: 70% !important;
    }
`;

const QoruzRcommendedPopup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export {
    QoruzRecommendedHeader,
    QoruzRecommendedBody,
    QoruzRcommendedPopup,
    QoruzRecommendedFooter,
};
