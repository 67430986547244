/**
 *
 * PersonalDetails
 *
 */
import React, { memo, useState, useEffect } from 'react';
import Autocomplete from 'react-autocomplete';
import { useDispatch, useSelector } from 'react-redux';

import {
    signUpPageActions,
    useSignUpPageSlice,
} from 'app/pages/SignUpPage/slice';

import Intro from 'app/components/Intro';
import Spinner from 'app/components/Spinner';
import { Radio } from 'app/components/Radio';
import { Button } from 'app/components/Button';
import AuthScreen from 'app/components/AuthScreen';
import AuthFooter from 'app/components/AuthFooter';
import Form, { FormField, FormLabel, Required } from 'app/components/Form';

import { ListItem } from './index.styled';
import { RadioGroup } from 'app/components/Radio/index.styled';
import { genderOptions, signupStepsKeyList } from 'app/pages/SignUpPage/label';

import {
    selectGetCities,
    selectSignUpPage,
} from 'app/pages/SignUpPage/slice/selectors';
import { onlyNumberKey } from 'utils/helper';

interface Props {
    onClickNext?: any;
    showIntro?: boolean;
    customFormStyled?: boolean;
    prefillPersonalData?: {
        influencerName: string;
        age: any;
        gender: string;
        city: string;
    };
}

export const PersonalDetails = memo((props: Props) => {
    useSignUpPageSlice();
    const dispatch = useDispatch();
    const signUpPageStore = useSelector(selectSignUpPage);
    const citiesStore = useSelector(selectGetCities);
    const { locations } = citiesStore;

    const { onClickNext, showIntro, customFormStyled, prefillPersonalData } =
        props;

    const [autocompleteFieldFocus, setAutocompleteFieldFocus] = useState(false);
    const [personalDetailsForm, setPersonalDetailsForm] = useState({
        name: '',
        age: '',
        city: '',
        gender: '',
    });

    useEffect(() => {
        if (
            signUpPageStore.isStepSuccess &&
            signUpPageStore.currentStep === 'personalDetails' &&
            !customFormStyled
        ) {
            onClickNext();
        }
    }, [signUpPageStore, onClickNext]);

    useEffect(() => {
        getCitiesDetails('');
        if (
            customFormStyled &&
            prefillPersonalData &&
            Object.keys(prefillPersonalData).length !== 0
        ) {
            const { influencerName, ...rest } = prefillPersonalData;
            setPersonalDetailsForm({
                name: prefillPersonalData.influencerName,
                ...rest,
            });
        }
    }, []);

    const getCitiesDetails = (keyword?: string) => {
        dispatch(
            signUpPageActions.getCities({
                keyword,
            }),
        );
    };

    const getCityDetails = (keyName: string) => {
        const targetLocation = locations.find(
            (location: any) =>
                location.name.toLowerCase() ===
                personalDetailsForm.city.toLowerCase(),
        );
        return targetLocation ? targetLocation[keyName] : '';
    };

    const updatePersonalDetailsForm = (
        fieldToUpdate: string,
        fieldValToUpdate: string | number,
    ) => {
        setPersonalDetailsForm({
            ...personalDetailsForm,
            [fieldToUpdate]: fieldValToUpdate,
        });
    };

    const canDispatch = () => {
        const { name, age, gender, city } = personalDetailsForm;
        return name && age && gender && city ? true : false;
    };

    const onSavePersonalDetails = () => {
        if (canDispatch()) {
            dispatch(
                signUpPageActions.register({
                    current_step: signupStepsKeyList['personalDetails'],
                    name: personalDetailsForm.name,
                    gender: personalDetailsForm.gender,
                    age: personalDetailsForm.age,
                    ...(getCityDetails('name')
                        ? { city_id: getCityDetails('_id') }
                        : { city: personalDetailsForm.city }),
                    currentStep: 'personalDetails',
                    token: localStorage.getItem('token'),
                    action_type: !customFormStyled ? 'signup' : 'edit',
                }),
            );
        }
    };

    const handleFocus = (state: string) => {
        state === 'focus'
            ? setAutocompleteFieldFocus(true)
            : setAutocompleteFieldFocus(false);
    };

    return (
        <AuthScreen
            className={`auth-screen personal-details ${
                showIntro ? false : 'custom-auth-styled'
            }`}
        >
            <Intro
                className={`intro ${showIntro ? false : 'custom-intro-style'}`}
            >
                {customFormStyled ? (
                    <h2>Personal Info</h2>
                ) : (
                    <h2>Personal Details</h2>
                )}

                {showIntro && (
                    <span>
                        This will help us give you more relevant campaigns
                    </span>
                )}
            </Intro>

            <Form
                className={`form-list ${
                    customFormStyled ? 'custom-form-style' : false
                }`}
            >
                <FormField className="form-list-item">
                    <FormLabel htmlFor="name">
                        Full name
                        <Required className="required">*</Required>
                    </FormLabel>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter your full name"
                        value={personalDetailsForm.name}
                        onChange={e => {
                            updatePersonalDetailsForm(
                                e.target.name,
                                e.target.value,
                            );
                        }}
                    />
                </FormField>

                <FormField className="form-list-item">
                    <FormLabel htmlFor="age">
                        Gender
                        <Required className="required">*</Required>
                    </FormLabel>
                    <RadioGroup className="radio-group">
                        {genderOptions.map((genderOption: any) => (
                            <Radio
                                key={genderOption.genderVal}
                                id={genderOption.genderVal}
                                checked={
                                    personalDetailsForm.gender ===
                                    genderOption.genderVal
                                }
                                onChange={e => {
                                    if (e.target.checked) {
                                        updatePersonalDetailsForm(
                                            'gender',
                                            genderOption.genderVal,
                                        );
                                    }
                                }}
                                label={genderOption.genderLabel}
                            />
                        ))}
                    </RadioGroup>
                </FormField>

                <FormField className="form-list-item">
                    <FormLabel htmlFor="age">
                        Age
                        <Required className="required">*</Required>
                    </FormLabel>
                    <input
                        type="number"
                        id="age"
                        name="age"
                        min={0}
                        placeholder="Enter your age in years"
                        value={personalDetailsForm.age}
                        onChange={e => {
                            updatePersonalDetailsForm(
                                e.target.name,
                                e.target.value ? parseInt(e.target.value) : '',
                            );
                        }}
                        onKeyPress={e => {
                            !onlyNumberKey(e) && e.preventDefault();
                        }}
                    />
                </FormField>

                <FormField
                    className={`form-list-item city-form-field ${
                        customFormStyled ? 'custom-form-style' : false
                    }`}
                    id={
                        autocompleteFieldFocus
                            ? 'autocomplete-field-focused'
                            : ''
                    }
                >
                    <FormLabel htmlFor="city">
                        City
                        <Required className="required">*</Required>
                    </FormLabel>
                    <Autocomplete
                        inputProps={{
                            className: 'autocomplete-city-input',
                            ariaExpanded: autocompleteFieldFocus,
                            onFocus: () => handleFocus('focus'),
                            onBlur: () => handleFocus('blur'),
                        }}

                       
                        getItemValue={(item: any) => item.name}
                        items={locations}
                        renderItem={(item: any, isHighlighted: boolean) => (
                            <ListItem
                                className="list-item"
                                key={item._id}
                                style={{
                                    background: isHighlighted
                                        ? 'lightgray'
                                        : 'white',
                                }}
                            >
                                {item.name}
                            </ListItem>
                        )}
                        renderInput={(props: any) => (
                            <input
                                {...props}
                                type="text"
                                id="city"
                                name="city"
                                autoComplete="off"
                                placeholder="What city are you based out of?"
                                style={{
                                    width: '100%',
                                }}
                            />
                        )}
                        value={personalDetailsForm.city}
                        onChange={(e: any) => {
                            getCitiesDetails(e.target.value);
                            updatePersonalDetailsForm(
                                e.target.name,
                                e.target.value,
                            );
                        }}
                        onSelect={(val: string) => {
                            updatePersonalDetailsForm('city', val);
                        }}
                    />
                </FormField>
            </Form>
            <AuthFooter className="footer">
                <Button
                    onClick={e => {
                        e.preventDefault();
                        onSavePersonalDetails();
                    }}
                    className={
                        !canDispatch() || signUpPageStore.loading
                            ? 'disabled'
                            : ''
                    }
                >
                    {signUpPageStore.loading ? (
                        <Spinner color="light" />
                    ) : showIntro ? (
                        'Next'
                    ) : (
                        'Save'
                    )}
                </Button>
            </AuthFooter>
        </AuthScreen>
    );
});
