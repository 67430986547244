import React, { memo, useEffect, useState } from 'react';
import {
    convertToInternationalFormat,
    onlyNumberKey,
    stripCommasFromNumber,
} from 'utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { socialDeliverableTypes } from '../data';

import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import { selectSignUpPage } from 'app/pages/SignUpPage/slice/selectors';

import Form, {
    FormField,
    FormLabel,
    FormValidatonError,
    Required,
} from 'app/components/Form';
import Intro from 'app/components/Intro';
import Spinner from 'app/components/Spinner';
import { Popup } from 'app/components/Popup';
import { Button } from 'app/components/Button';
import AuthFooter from 'app/components/AuthFooter';
import AuthScreen from 'app/components/AuthScreen';

import LinkIcon from 'app/images/link-url.svg';
import YoutubeIcon from 'app/images/redyoutubeicon.svg';
import YoutubeShortsIcon from 'app/images/youtubeshorts.svg';
import { signupStepsKeyList } from 'app/pages/SignUpPage/label';
interface Props {
    onClose: Function;
    prefilledYoutubeProfileInfo?: any;
}

export const YouTubePopUp = memo((props: Props) => {
    const { onClose, prefilledYoutubeProfileInfo = {} } = props;

    const dispatch = useDispatch();
    const signUpPageStore = useSelector(selectSignUpPage);

    const [youtubeCostDetails, setYoutubeCostDetails] = useState({
        youtubeChannel: '',
        youtubeVideoCost: '',
        youtubeShortsCost: '',
    });

    useEffect(() => {
        if (
            prefilledYoutubeProfileInfo &&
            Object.keys(prefilledYoutubeProfileInfo).length !== 0
        ) {
            const { basic_details, deliverables } = prefilledYoutubeProfileInfo;
            const youtubeChannelName = basic_details?.channel_title || '';

            let deliverablesObj = {};
            deliverables &&
                deliverables.length !== 0 &&
                deliverables.map((del: any) => {
                    deliverablesObj[del.value] = del.cost;
                });

            setYoutubeCostDetails({
                youtubeChannel: youtubeChannelName || '',
                youtubeVideoCost: deliverablesObj['youtube_videos'] || '',
                youtubeShortsCost: deliverablesObj['youtube_shorts'] || '',
            });
        }
    }, []);

    const canDispatch = () => {
        return Object.values(youtubeCostDetails).every(val => val !== '')
            ? true
            : false;
    };

    const handleInputChange = (e: any) => {
        setYoutubeCostDetails({
            ...youtubeCostDetails,
            [e.target.name]:
                e.target.type === 'tel' && e.target.value !== ''
                    ? Number(stripCommasFromNumber(e.target.value))
                    : e.target.value,
        });
        dispatch(signUpPageActions.clearValidationError(''));
    };

    const saveYoutubeCostDetails = () => {
        if (canDispatch()) {
            const dispatchData = {
                token: localStorage.getItem('token'),
                current_step: signupStepsKeyList['youtubeDetails'],
                currentStep: 'youtubeDetails',
                action_type: 'edit',
                youtube_channel: youtubeCostDetails.youtubeChannel,
                deliverables_cost: socialDeliverableTypes[
                    'youtube'
                ].deliverables.map(deliverable => ({
                    deliverable_type: deliverable.type,
                    cost: youtubeCostDetails[deliverable.formCostKey],
                })),
            };

            if (
                prefilledYoutubeProfileInfo &&
                prefilledYoutubeProfileInfo?.basic_details?.channel_title
            ) {
                dispatchData['profile_id'] =
                    prefilledYoutubeProfileInfo?.basic_details?.profile_id;

                dispatchData['profile_exists'] = prefilledYoutubeProfileInfo
                    ?.basic_details?.profile_id
                    ? 1
                    : 0;

                dispatchData['marketplace_profile_exists'] = 1;
                dispatchData['marketplace_profile_id'] =
                    prefilledYoutubeProfileInfo?.basic_details?.marketplace_id;

                dispatchData['social_handle_type'] =
                    prefilledYoutubeProfileInfo?.basic_details
                        ?.channel_title === youtubeCostDetails.youtubeChannel
                        ? 'update'
                        : 'create';
            } else {
                dispatchData['profile_exists'] = 0;
                dispatchData['marketplace_profile_exists'] = 0;
                dispatchData['social_handle_type'] = 'create';
            }

            dispatch(signUpPageActions.register(dispatchData));
        }
    };

    return (
        <Popup onClose={onClose}>
            <AuthScreen className="auth-screen custom-auth-styled">
                <Intro className="intro custom-intro-style">
                    <h2>YouTube</h2>
                </Intro>
                <Form className="form-list custom-form-style">
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="youtubeChannel">
                            <img src={LinkIcon} alt="Link Icon" />
                            Channel name/ URL
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="text"
                            id="youtubeChannel"
                            name="youtubeChannel"
                            value={youtubeCostDetails.youtubeChannel}
                            placeholder="Enter your channel url/ username"
                            onChange={e => {
                                handleInputChange(e);
                            }}
                            disabled={
                                prefilledYoutubeProfileInfo?.basic_details
                                    ?.profile_id
                            }
                        />
                        <span className="supported-youtube-handle-eg input-format-text">
                            Eg. https://www.youtube.com/c/TechBurner, TechBurner
                        </span>
                    </FormField>
                    <FormValidatonError>
                        {signUpPageStore.validationErrors}
                    </FormValidatonError>
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="youtubeVideoCost">
                            <img src={YoutubeIcon} alt="youtube-icon" />
                            Youtube video cost
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="tel"
                            id="youtubeVideoCost"
                            name="youtubeVideoCost"
                            min={0}
                            placeholder="Enter your charges for 1 video"
                            value={convertToInternationalFormat(
                                youtubeCostDetails.youtubeVideoCost,
                            )}
                            onChange={e => {
                                handleInputChange(e);
                            }}
                            onKeyPress={e => {
                                !onlyNumberKey(e) && e.preventDefault();
                            }}
                        />
                    </FormField>
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="youTubeShortsCost">
                            <img
                                src={YoutubeShortsIcon}
                                alt="youtubeShort-icon"
                            />
                            Youtube shorts cost
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="tel"
                            id="youTubeShortsCost"
                            name="youtubeShortsCost"
                            min={0}
                            placeholder="Enter your charges for 1 Shorts"
                            value={convertToInternationalFormat(
                                youtubeCostDetails.youtubeShortsCost,
                            )}
                            onChange={e => {
                                handleInputChange(e);
                            }}
                            onKeyPress={e => {
                                !onlyNumberKey(e) && e.preventDefault();
                            }}
                        />
                        <AuthFooter className="footer">
                            <Button
                                className={
                                    signUpPageStore.loading || !canDispatch()
                                        ? 'disabled'
                                        : ''
                                }
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    saveYoutubeCostDetails();
                                }}
                            >
                                {signUpPageStore.loading ? (
                                    <Spinner color="light" />
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </AuthFooter>
                    </FormField>
                </Form>
            </AuthScreen>
        </Popup>
    );
});
