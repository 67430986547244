export const signupStepsKeyList = {
    signUp: 'otp_verification',
    instagramDetails: 'instagram_profile_handle_and_cost',
    youtubeDetails: 'youtube_profile_handle_and_cost',
    contactDetails: 'contact_details',
    personalDetails: 'personal_details',
    categoryDetails: 'category',
    campaignDetails: 'campaign_type',
    specialQualitiesDetails: 'special_qualities',
    familyDetails: 'family_info',
    petDetails: 'pet_info',
    completed: 'completed',
    languageDetails: 'language_details',
};

// Keep Steps In Order
export const getStepNumber = {
    otp_verification: 1,
    instagram_profile_handle_and_cost: 2,
    youtube_profile_handle_and_cost: 3,
    contact_details: 4,
    personal_details: 5,
    language_details: 6,
    category: 7,
    campaign_type: 8,
    special_qualities: 9,
    family_info: 10,
    pet_info: 11,
    completed: 12,
};

//Error Keys

export const getErrorKey = {
    otpVerificationFailed: 'otp_verification_failed',
    handleExist: 'handle_already_claimed',
    instagramOrYoutubeRequired: 'instagram_or_youtube_required',
    tokenInvalid: 'token_invalid',
};

//Categories

export const getCategoryIcon = [
    {
        label: '🍜',
        value: 'bowl',
    },
    {
        label: '🎧',
        value: 'music',
    },
    {
        label: '✈️',
        value: 'flight',
    },
    {
        label: '🎬',
        value: 'entertainment',
    },
    {
        label: '⛵️',
        value: 'sailing',
    },
    {
        label: '🎤',
        value: 'mic',
    },
    {
        label: '📹,',
        value: 'cinematography',
    },
    {
        label: '🎮',
        value: 'joystick',
    },
    {
        label: '📸',
        value: 'camera',
    },
    {
        label: '🏻',
        value: 'yogamat',
    },
    {
        label: '🏋',
        value: 'weightlifting',
    },
    {
        label: '💄',
        value: 'lipstick',
    },
    {
        label: '📱',
        value: 'phone',
    },
    {
        label: '🚘',
        value: 'car',
    },
    {
        label: '💃',
        value: 'dance',
    },
    {
        label: '📚',
        value: 'stationary',
    },
    {
        label: '👨‍👩‍👧',
        value: 'family',
    },
    {
        label: '🧩',
        value: 'puzzle',
    },
    {
        label: '🏠',
        value: 'house',
    },
    {
        label: '✨',
        value: 'star',
    },
    {
        label: '🥸',
        value: 'emoji',
    },
    {
        label: '⚽',
        value: 'football',
    },
    {
        label: '👗',
        value: 'dress',
    },
    {
        label: '📈',
        value: 'stockchart',
    },
    {
        label: '💻',
        value: 'laptop',
    },
    {
        label: '🎬',
        value: 'clapperboard',
    },
];

//Languages

export const getLanguagesIcon = [
    {
        label: '🍜',
        value: 'bowl',
    },
    {
        label: '🎧',
        value: 'music',
    },
    {
        label: '✈️',
        value: 'flight',
    },
    {
        label: '🎬',
        value: 'entertainment',
    },
    {
        label: '⛵️',
        value: 'sailing',
    },
    {
        label: '🎤',
        value: 'mic',
    },
    {
        label: '📹,',
        value: 'cinematography',
    },
    {
        label: '🎮',
        value: 'gaming',
    },
    {
        label: '📸',
        value: 'camera',
    },
    {
        label: '🏻',
        value: 'meditation',
    },
    {
        label: '🏋',
        value: 'weightlifting',
    },
    {
        label: '💄',
        value: 'lipstick',
    },
    {
        label: '📱',
        value: 'phone',
    },
];

export const genderOptions = [
    {
        genderVal: 'male',
        genderLabel: 'Male',
    },
    {
        genderVal: 'female',
        genderLabel: 'Female',
    },
    {
        genderVal: 'other',
        genderLabel: 'Other',
    },
];

export const petTypeOptions = [
    {
        petLabel: 'Dog',
        petVal: 'dog',
    },
    {
        petLabel: 'Cat',
        petVal: 'cat',
    },
    {
        petLabel: 'Bird',
        petVal: 'bird',
    },
    {
        petLabel: 'Other',
        petVal: 'other',
    },
];
