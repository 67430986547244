import styled from 'styled-components';

const TabListSection = styled.div`
    position: fixed;
    padding: 14px 1rem;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    height: 76px;
    box-shadow: 0px 2px 8px rgba(9, 30, 66, 0.04),
        0px 12px 32px rgba(9, 30, 66, 0.12);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 680px) {
        padding: 14px 14px;
    }
`;

const TabContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    &.active {
        svg path {
            fill: #f35627;
        }
        span {
            color: #f35627 !important;
        }
    }
    span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.00533333em;
        color: #7b8794;
    }
`;
export { TabListSection, TabContent };
