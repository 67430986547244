import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectInfluencerCampaigns } from 'app/pages/SignUpPage/slice/selectors';

import CampaignIcon from 'app/images/helpcampaign.svg';
import { RowSliderComponent } from 'app/components/ScrollComponent';
import { CreatorCampaignCard } from 'app/pages/Tabs/TabMenu/CommonComponents/CampaignCard';

import { CampaignSection } from './index.styled';
import { HeaderTitle } from '../../index.styled';
import Skeleton from 'react-loading-skeleton';
import { HomepageCampaignCardSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';
import { PromptMessage } from 'app/pages/Tabs/TabMenu/CommonComponents/PromptMessage';

interface Props {}

export const CampignsCard = memo((props: Props) => {
    const influencerCampaignsSelector = useSelector(selectInfluencerCampaigns);
    const { allCampaigns = [], loading } = influencerCampaignsSelector;

    return (
        <>
            {loading ? (
                <CampaignSection>
                    <HeaderTitle>
                        <img src={CampaignIcon} alt="icon" />
                        <span>Live Campaigns</span>
                    </HeaderTitle>
                    <RowSliderComponent scrollBarHeight={378}>
                        <HomepageCampaignCardSkeleton />
                        {/* <Skeleton borderRadius={8} width={312} height={365} /> */}
                    </RowSliderComponent>
                </CampaignSection>
            ) : (
                <CampaignSection>
                    <HeaderTitle>
                        <img src={CampaignIcon} alt="icon" />
                        <span>Live Campaigns</span>
                    </HeaderTitle>

                    {allCampaigns &&
                    allCampaigns.length > 0 &&
                    allCampaigns.filter(
                        (campaign: any) => campaign.status === 'active',
                    ).length !== 0 ? (
                        <RowSliderComponent scrollBarHeight={378}>
                            {allCampaigns
                                .filter(
                                    (campaign: any) =>
                                        campaign.status === 'active',
                                )
                                .map((liveCampaign: any, index: number) => (
                                    <CreatorCampaignCard
                                        key={liveCampaign?.id || index}
                                        campaignInfo={liveCampaign}
                                    />
                                ))}
                        </RowSliderComponent>
                    ) : (
                        <PromptMessage customStyles={{ margin: '0 20px' }}>
                            No live campaigns available
                        </PromptMessage>
                    )}
                </CampaignSection>
            )}
        </>
    );
});
