import React, { memo, useEffect, useState } from 'react';
import { noScroll } from 'utils/helper';
import { useDispatch, useSelector } from 'react-redux';

import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import {
    selectInfluencerProfileSummary,
    selectShowPopupStatus,
    selectUpdateOrDeletePaymentDetail,
} from 'app/pages/SignUpPage/slice/selectors';
import { selectAddBank } from 'app/pages/HomePage/components/AddBankPopup/slice/selectors';
import { HtmlElementSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';

import { AddBankPopup } from 'app/pages/HomePage/components/AddBankPopup';
import BankIcon from 'app/images/bankaccount-icon.svg';
import { PaymentTypes } from '../index.styled';

interface Props {
    prefilledBankDetails?: any;
}

export const BackAccount = memo((props: Props) => {
    const {
        prefilledBankDetails,
        prefilledBankDetails: {
            bank_account_name = '',
            bank_account_number = '',
            bank_account_ifsc = '',
        } = {},
    } = props;
    const dispatch = useDispatch();
    const addBankStore = useSelector(selectAddBank);
    const updatePaymentDetailsStore = useSelector(
        selectUpdateOrDeletePaymentDetail,
    );
    const isShowingPopup = useSelector(selectShowPopupStatus);
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);

    const { isPaymentDataAdded } = addBankStore;
    const {
        isUpdateOrDeletePaymentDetailSuccess,
        loading: paymentDetailUpdateLoading,
        currentlyUpdatingPayDetail,
    } = updatePaymentDetailsStore;
    const { loading: profileSummaryLoading } = profileSummarySelector;

    const [addBankPopup, setAddBankPopup] = useState(false);

    useEffect(() => {
        noScroll(addBankPopup);
    }, [addBankPopup]);

    const handleDeleteBankDetails = () => {
        dispatch(
            signUpPageActions.updateOrDeletePaymentDetail({
                mode_type: 'delete',
                preferred_mode: 'bank',
                request_type: 'profile_update',
                token: localStorage.getItem('token'),
            }),
        );
    };

    const handleShowBankDetailsPopup = (showBool: boolean = false) => {
        setAddBankPopup(showBool);
        dispatch(signUpPageActions.setIsShowPopup(showBool));
    };

    useEffect(() => {
        if (prefilledBankDetails) {
            if (
                !updatePaymentDetailsStore.loading &&
                isUpdateOrDeletePaymentDetailSuccess
            ) {
                handleShowBankDetailsPopup(false);
            }
        } else {
            if (!addBankStore.loading && isPaymentDataAdded) {
                handleShowBankDetailsPopup(false);
            }
        }
    }, [addBankStore, updatePaymentDetailsStore]);

    return (
        <>
            <PaymentTypes>
                <div className="payment-type">
                    <img src={BankIcon} alt="bank-icon" />
                    <div className="account-details">
                        <h6>
                            {profileSummaryLoading ||
                            (paymentDetailUpdateLoading &&
                                currentlyUpdatingPayDetail === 'bank') ? (
                                <HtmlElementSkeleton
                                    width={120}
                                    height={15}
                                    customStyles={{
                                        marginBottom: '10px',
                                    }}
                                />
                            ) : (
                                bank_account_name || 'Bank Account'
                            )}
                        </h6>
                        {profileSummaryLoading ||
                        (paymentDetailUpdateLoading &&
                            currentlyUpdatingPayDetail === 'bank') ? (
                            <HtmlElementSkeleton
                                width={80}
                                height={10}
                                customStyles={{
                                    marginLeft: 'auto',
                                }}
                            />
                        ) : (
                            bank_account_number && (
                                <span className="">{bank_account_number}</span>
                            )
                        )}
                    </div>
                </div>

                <div className="buttons">
                    {profileSummaryLoading ? (
                        <HtmlElementSkeleton
                            width={60}
                            height={15}
                            customStyles={{
                                marginTop: '12px',
                            }}
                        />
                    ) : bank_account_number ? (
                        <>
                            <span
                                onClick={() => {
                                    handleShowBankDetailsPopup(true);
                                    dispatch(
                                        signUpPageActions.resetPaymentDetailUpdateSuccess(),
                                    );
                                }}
                            >
                                Edit
                            </span>
                            <span onClick={() => handleDeleteBankDetails()}>
                                Delete
                            </span>
                        </>
                    ) : (
                        <span
                            onClick={() => {
                                handleShowBankDetailsPopup(true);
                            }}
                        >
                            + Add Account
                        </span>
                    )}
                </div>
            </PaymentTypes>

            {addBankPopup && isShowingPopup && (
                <AddBankPopup
                    onClose={() => handleShowBankDetailsPopup(false)}
                    profileId={''}
                    groupId={''}
                    setPayments={() => {}}
                    isUpdatingProfileSummary={true}
                    prefilledBankDetails={prefilledBankDetails}
                />
            )}
        </>
    );
});
