/**
 *
 * PanCardPopup
 *
 */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { panCardValidation } from 'utils/helper';

import {
    panCardPopupActions,
    usePanCardPopupSlice,
} from 'app/pages/HomePage/components/PanCardPopup/slice';
import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import { selectPanCardPopup } from 'app/pages/HomePage/components/PanCardPopup/slice/selectors';
import { PanCardPopupProps } from 'app/pages/HomePage/components/PanCardPopup/types';

import Spinner from 'app/components/Spinner';
import { Alert } from 'app/components/Alert';
import { Popup } from 'app/components/Popup';
import { Button } from 'app/components/Button';
import PopupContent from 'app/components/PopupContent';
import Form, { FormField, FormLabel, Required } from 'app/components/Form';
import { selectUpdateOrDeletePaymentDetail } from 'app/pages/SignUpPage/slice/selectors';

export const PanCardPopup = memo((props: PanCardPopupProps) => {
    usePanCardPopupSlice();
    const {
        onClose,
        profileId,
        groupId,
        isUpdatingProfileSummary,
        prefilledPanNumber = '',
    } = props;
    const [panNumber, setPanNumber] = useState('');

    const dispatch = useDispatch();
    const panCardPopUpStore = useSelector(selectPanCardPopup);
    const updatePaymentDetailsStore = useSelector(
        selectUpdateOrDeletePaymentDetail,
    );

    const { loading, isPanCardUpdated, isTokenValid } = panCardPopUpStore;

    useEffect(() => {
        if (isPanCardUpdated === 'yes') {
            onClose();
        }
        if (isTokenValid === 'no') {
            onClose('otp');
        }
    }, [isPanCardUpdated, isTokenValid]);

    useEffect(() => {
        if (isUpdatingProfileSummary && prefilledPanNumber) {
            setPanNumber(prefilledPanNumber || '');
        }
    }, []);

    const dispatchUpdatePanCardDetails = () => {
        if (panNumber && panCardValidation(panNumber) && !loading) {
            const dispatchData = {
                pan_number: panNumber,
                is_pan_exists: 1,
                preferred_mode: 'pan',
                mode_type: 'update',
                token: localStorage.getItem('token'),
            };

            if (!isUpdatingProfileSummary) {
                dispatchData['profile_id_hash'] = profileId;
                dispatchData['groupId'] = groupId;
            } else {
                dispatchData['request_type'] = 'profile_update';
            }

            !isUpdatingProfileSummary
                ? dispatch(
                      panCardPopupActions.updatePanCardDetails(dispatchData),
                  )
                : dispatch(
                      signUpPageActions.updateOrDeletePaymentDetail(
                          dispatchData,
                      ),
                  );
        }
    };

    return (
        <Popup onClose={onClose}>
            <PopupContent className="popup-content pan-card-popup">
                <h2>PAN Card</h2>
                <Form className="form-list">
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="accountHolder">
                            PAN number
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="text"
                            placeholder="Eg. DDBIK3445E"
                            id="accountHolder"
                            value={panNumber}
                            onChange={e => {
                                setPanNumber(e.target.value.toUpperCase());
                            }}
                        />
                    </FormField>
                    <Alert
                        details={
                            'If you don’t add PAN details, you’ll be charged 20% TDS.'
                        }
                    />
                    <Button
                        className={`${
                            panNumber && panCardValidation(panNumber)
                                ? ''
                                : 'disabled'
                        }`}
                        onClick={e => {
                            e.preventDefault();
                            dispatchUpdatePanCardDetails();
                        }}
                    >
                        {loading || updatePaymentDetailsStore.loading ? (
                            <Spinner color="light" />
                        ) : (
                            'Submit'
                        )}
                    </Button>
                </Form>
            </PopupContent>
        </Popup>
    );
});
