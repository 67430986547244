import styled from 'styled-components/macro';

const Rupee = styled.div`
    position: absolute;
    bottom: 12px;
    left: 12px;
    font-weight: 500;
    font-size: 16px;
    &.custom-style {
        font-weight: 600;
        bottom: 10px;
    }
`;
const RowStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    padding-bottom: 8px;
    &:last-child {
        border-top: 1px solid #e4e7eb;
    }
`;
const Figure = styled.figure`
    width: 16px;
    height: 16px;
    margin: 0 0 0 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    img {
        display: flex;
        &.polygon {
            position: absolute;
            top: 21px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
`;
const Group = styled.div`
    display: inline-flex;
    align-items: center;
`;
const PopupStyle = styled.div`
    display: flex;
    align-items: center;
    background: #3e4c59;
    border-radius: 3px;
    padding: 8px;
    margin-top: 1px;
    margin-bottom: 16px;
    span {
        color: white;
    }
`;
const CloseIcon = styled.figure`
    margin: 0 0 0 8px;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        cursor: pointer;
    }
`;
const TotalCost = styled.div`
    background-color: #edf2fd;
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 14px;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    b {
        font-weight: bold;
        font-size: 16px;
        margin-left: 8px;
        color: #1f2933;
    }
    &.low {
        background-color: #ffeeee;
    }
    .connect-fee {
        color: #f9703e;
        text-decoration: line-through;
    }
`;

export { TotalCost, Rupee, RowStyle, Figure, Group, PopupStyle, CloseIcon };
