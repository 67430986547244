import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectDashboardAnalytics } from 'app/pages/SignUpPage/slice/selectors';
import 'react-loading-skeleton/dist/skeleton.css';
import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';
import { HeaderTitle } from '../../index.styled';
import { DashboardSection, DashboardStyle } from './index.styled';
import Skeleton from 'react-loading-skeleton';
import { PromptMessage } from 'app/pages/Tabs/TabMenu/CommonComponents/PromptMessage';

interface Props {}

export const UserDashbord = memo((props: Props) => {
    const dashboardAnalyticsSelector = useSelector(selectDashboardAnalytics);
    const { loading, dashboardAnalytics } = dashboardAnalyticsSelector;
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const { profileSummary = {} } = profileSummarySelector;
    return (
        <DashboardSection>
            <HeaderTitle>
                <div className="profile-name">
                    <h6>Welcome,</h6>
                    {loading ? (
                        <Skeleton width={100} />
                    ) : (
                        <span>{profileSummary?.name}</span>
                    )}
                </div>
            </HeaderTitle>

            <HeaderTitle>
                <span>
                    📊 Your Dashboard<span> (last 90 days)</span>
                </span>
            </HeaderTitle>
            <PromptMessage customStyles={{ margin: '0 20px' }}>
                💡 Know how many brand users have seen your qoruz profile and
                no. of times qoruz profile appears in search results.
            </PromptMessage>
            <DashboardStyle>
                <ul className="dashboard-contents">
                    <div className="count-card-section">
                        <li className="list-count">
                            <span>
                                {loading ? (
                                    <Skeleton width={50} />
                                ) : (
                                    dashboardAnalytics?.total_profile_views ||
                                    'NA'
                                )}
                            </span>
                            Profile{' '}
                            {dashboardAnalytics?.total_profile_views === 1
                                ? 'View'
                                : 'Views'}
                        </li>
                        <li className="list-count">
                            <span>
                                {loading ? (
                                    <Skeleton width={50} />
                                ) : (
                                    dashboardAnalytics?.total_profile_searches ||
                                    'NA'
                                )}
                            </span>
                            Search{' '}
                            {dashboardAnalytics?.total_profile_searches === 1
                                ? 'Appearance'
                                : 'Appearances'}
                        </li>
                    </div>

                    {dashboardAnalytics?.brands_shown_interest?.length >= 1 ? (
                        <>
                            {loading ? (
                                <li className="brand-type">
                                    <Skeleton />
                                </li>
                            ) : (
                                <li className="brand-type">
                                    <span className="brands">
                                        {dashboardAnalytics?.brands_shown_interest &&
                                        dashboardAnalytics
                                            ?.brands_shown_interest.length !== 0
                                            ? dashboardAnalytics?.brands_shown_interest
                                                  .map(
                                                      (brand: any) =>
                                                          brand.name,
                                                  )
                                                  .join(', ')
                                            : 'NA'}
                                    </span>
                                    {dashboardAnalytics?.brands_shown_interest &&
                                    dashboardAnalytics?.brands_shown_interest
                                        .length === 1
                                        ? 'brand'
                                        : 'brands'}{' '}
                                    viewed your profile.
                                </li>
                            )}
                        </>
                    ) : (
                        ''
                    )}
                </ul>
            </DashboardStyle>
        </DashboardSection>
    );
});
