import React from 'react';
import './svg.css';

export default function HomeIcon() {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_7473_116926)">
                <path
                    d="M9.66666 23.3333C10.7712 23.3333 11.6667 22.4379 11.6667 21.3333V18.6667C11.6667 17.378 12.7113 16.3333 14 16.3333C15.2887 16.3333 16.3333 17.378 16.3333 18.6667V21.3333C16.3333 22.4379 17.2288 23.3333 18.3333 23.3333H20.1667C21.2712 23.3333 22.1667 22.4379 22.1667 21.3333V14H23.0607C23.9772 14 24.4109 12.8698 23.7297 12.2567L15.3379 4.70414C14.5773 4.01959 13.4227 4.01959 12.6621 4.70414L4.27032 12.2567C3.5891 12.8698 4.0228 14 4.93929 14H5.83333V21.3333C5.83333 22.4379 6.72876 23.3333 7.83333 23.3333H9.66666Z"
                    fill="#7B8794"
                />
            </g>
            <defs>
                <clipPath id="clip0_7473_116926">
                    <rect width="28" height="28" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
