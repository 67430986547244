import { PayloadAction } from '@reduxjs/toolkit';
import { profile } from 'console';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { addAddressSliceSaga } from './saga';
import { AddAddressSliceState } from './types';

export const initialState: AddAddressSliceState = {
    loading: false,
    error: null,
    closeAddAddressPopUp: '',
    validation_error: null,
    notificationHeading: '',
    notificationSubHeading: '',
    isTokenValid: '',
    isAddAddressSuccess: false,
};

const slice = createSlice({
    name: 'addAddressSlice',
    initialState,
    reducers: {
        addAddress(state, action: PayloadAction<any>) {
            state.loading = true;
            state.closeAddAddressPopUp = '';
            state.notificationHeading = '';
            state.notificationSubHeading = '';
            state.isAddAddressSuccess = false;
        },
        addAddressSuccess(state, action: PayloadAction<any>) {
            state.loading = false;
            state.closeAddAddressPopUp = 'yes';
            state.notificationHeading = action.payload?.stuff;
            state.notificationSubHeading = action.payload?.stuff_subtext;
            state.isTokenValid = 'yes';
            state.isAddAddressSuccess = true;
        },
        addAddressError(state, action: PayloadAction<any>) {
            state.loading = false;
            state.error = action.payload?.error;
            state.isAddAddressSuccess = false;
            state.validation_error = action.payload?.validation_error;
            if (action.payload && action.payload.error === 'token_invalid') {
                state.isTokenValid = 'no';
            }
        },
    },
});

export const { actions: addAddressSliceActions } = slice;

export const useAddAddressSlice = () => {
    useInjectReducer({ key: slice.name, reducer: slice.reducer });
    useInjectSaga({ key: slice.name, saga: addAddressSliceSaga });
    return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAddAddressSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
