import IGTVIcon from 'app/images/SVGComponents/IgTvIcon';
import InstagramImagePostIcon from 'app/images/SVGComponents/CameraIcon';
import VideoPlayIcon from 'app/images/SVGComponents/PlayerIcon';
import InstagramStoryIcon from 'app/images/SVGComponents/InstaStoryIcon';
import InstagramReelsIcon from 'app/images/SVGComponents/InstagramReelsIcon';
import YoutubeShortsIcon from 'app/images/SVGComponents/YoutubeShortIcon';
import YoutubeIcon from 'app/images/SVGComponents/YoutubeIcon';

export const socialDeliverableTypes = {
    instagram: {
        deliverables: [
            {
                type: 'instagram_images',
                formCostKey: 'postCost',
            },
            {
                type: 'instagram_reels',
                formCostKey: 'reelsPostCost',
            },
            {
                type: 'instagram_videos',
                formCostKey: 'videoPostCost',
            },
            // {
            //     type: 'instagram_igtv',
            //     formCostKey: 'IGTVPostCost',
            // },
            {
                type: 'instagram_story',
                formCostKey: 'storyPostCost',
            },
        ],
    },
    youtube: {
        deliverables: [
            {
                type: 'youtube_videos',
                formCostKey: 'youtubeVideoCost',
            },
            {
                type: 'youtube_shorts',
                formCostKey: 'youtubeShortsCost',
            },
        ],
    },
};

export const baseDeliverablesData = {
    instagram: {
        instagram_reels: {
            deliverable_type: 'reel',
            deliverable_icon: <InstagramReelsIcon />,
        },
        instagram_images: {
            deliverable_type: 'post',
            deliverable_icon: <InstagramImagePostIcon />,
        },
        instagram_videos: {
            deliverable_type: 'video',
            deliverable_icon: <VideoPlayIcon />,
        },
        // instagram_igtv: {
        //     deliverable_type: 'IGTV video',
        //     deliverable_icon: <IGTVIcon />,
        // },
        instagram_story: {
            deliverable_type: 'story',
            deliverable_icon: <InstagramStoryIcon />,
        },
        instagram_carousel: {
            deliverable_type: 'caraousel',
            deliverable_icon: <InstagramImagePostIcon />,
        },
    },
    youtube: {
        youtube_shorts: {
            deliverable_type: 'short',
            deliverable_icon: <YoutubeShortsIcon />,
        },
        youtube_videos: {
            deliverable_type: 'video',
            deliverable_icon: <YoutubeIcon />,
        },
    },
};
