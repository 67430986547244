import React, { memo } from 'react';
import { convertToInternationalFormat, dueDateComparator } from 'utils/helper';

import {
    CampaignDetailSection,
    CampaignCard,
    InviteSection,
} from 'app/pages/Tabs/HomeTabSection/components/Campaigns/index.styled';
import { platformIcons } from 'app/pages/Tabs/HomeTabSection/components/label';

import ProductImg from 'app/images/burger.png';
import BrandImg from 'app/images/mbrand.png';
import PackageIcon from 'app/images/SVGComponents/Package';
import MoneyBagIcon from 'app/images/dollers-money-bag.svg';
import InstagramIcon from 'app/images/blueinstagram.svg';
import { useHistory } from 'react-router-dom';

interface Props {
    campaignInfo?: any;
    styledClassName?: string;
}

export const CreatorCampaignCard = memo((props: Props) => {
    const { campaignInfo, styledClassName } = props;
    const history = useHistory();

    const renderCampaignCost = (campaignCostDetails: any = {}) => {
        if (Object.keys(campaignCostDetails).length !== 0) {
            const isCostAvailable =
                campaignCostDetails['barter_product_cost'] ||
                campaignCostDetails['fixed_cost'] ||
                campaignCostDetails['variable_max_cost'];

            if (isCostAvailable && campaignCostDetails?.type) {
                return (
                    <div className="product-cost">
                        <img src={MoneyBagIcon} alt="icon" />
                        <span>
                            {campaignCostDetails?.type === 'barter'
                                ? 'Products worth'
                                : 'Earn'}
                        </span>
                        <span className="cost-value">
                            {campaignCostDetails?.type === 'barter'
                                ? campaignCostDetails?.barter_product_cost
                                    ? `₹${convertToInternationalFormat(
                                          campaignCostDetails.barter_product_cost,
                                      )}`
                                    : 'NA'
                                : campaignCostDetails?.fixed_cost
                                ? `₹${convertToInternationalFormat(
                                      campaignCostDetails.fixed_cost,
                                  )}`
                                : 'NA' || campaignCostDetails?.variable_max_cost
                                ? `₹${convertToInternationalFormat(
                                      campaignCostDetails.variable_max_cost,
                                  )}`
                                : 'NA'}
                        </span>
                    </div>
                );
            }
        }
    };

    const renderCampaignStatus = (campaignStatus: string = '') => {
        return (
            <span className="campaign-status-pill">
                <PackageIcon />
                {campaignStatus === 'under_review' ? 'Review' : campaignStatus}
            </span>
        );
    };

    const renderAcceptanceExpireStatus = (
        campaignProfileStatusDetails: any = {},
    ) => {
        if (campaignProfileStatusDetails?.acceptance_due_on) {
            //[CASE 1]: Invite was accepted
            // --> compare due date with accepted on data to calculate time since acceptance
            // send the accepted_on date as 2nd argument of comparator function

            //[CASE 2]: Invite not yet accepted
            //--> compare with currnt time to calculate whether date IS DUE or HAS EXPIRED
            // send the 'curent' as 2nd argument of comparator function
            // (current timestamp is calculated inside the function)

            const {
                isDue,
                expiresIn,
                hasExpired,
                expiredSince,
                hasAccepted,
                acceptedSince,
            } = dueDateComparator(
                campaignProfileStatusDetails.acceptance_due_on,
                campaignProfileStatusDetails?.accepted_at || 'current',
            );

            return (
                <InviteSection
                    className={`${isDue ? 'is-due' : ''} ${
                        hasExpired ? 'has-expired' : ''
                    } ${hasAccepted ? 'has-accepted' : ''}`}
                >
                    {isDue && (
                        <>
                            Accept Campaign Invite
                            <span>Expires in {expiresIn}</span>
                        </>
                    )}
                    {hasExpired && (
                        <>
                            Campaign Invite Expired{' '}
                            <span>{expiredSince} ago</span>
                        </>
                    )}
                    {hasAccepted && (
                        <>
                            Campaign Invite Accepted
                            <span>{acceptedSince} ago</span>
                        </>
                    )}
                </InviteSection>
            );
        }
        return '';
    };

    return (
        <CampaignCard
            className={styledClassName}
            onClick={() =>
                campaignInfo?.campaign_group_details?.id_hash &&
                campaignInfo?.campaign_profile_status_details?.id_hash &&
                history.push(
                    `/campaign/details/group/${campaignInfo?.campaign_group_details?.id_hash}/profile/${campaignInfo?.campaign_profile_status_details?.id_hash}`,
                )
            }
        >
            <img
                src={
                    campaignInfo?.campaign_group_details?.cover_image ||
                    ProductImg
                }
                className="campaign-cover-img"
                alt="post-img"
            />
            <div className="brand-type">
                <div className="product-name">
                    <span className="campaign-name">
                        {campaignInfo?.name || 'NA'}
                    </span>
                    <span>for {campaignInfo?.brand?.name || 'NA'}</span>
                </div>

                <img
                    src={campaignInfo?.brand?.image || BrandImg}
                    alt="brand-icon"
                />
            </div>
            <CampaignDetailSection>
                <div className="product-details">
                    {campaignInfo?.status &&
                        renderCampaignStatus(campaignInfo?.status)}
                    {campaignInfo?.platform ? (
                        <span className="platform-name-pill">
                            <img
                                src={platformIcons[campaignInfo.platform].icon}
                                alt="socialMedia-icon"
                            />

                            {campaignInfo?.platform}
                        </span>
                    ) : (
                        ''
                    )}
                </div>

                {campaignInfo?.campaign_group_details &&
                    renderCampaignCost(campaignInfo?.campaign_group_details)}
            </CampaignDetailSection>

            {campaignInfo?.campaign_profile_status_details &&
                renderAcceptanceExpireStatus(
                    campaignInfo?.campaign_profile_status_details,
                )}
        </CampaignCard>
    );
});
