import styled from 'styled-components';

const CampaignStyle = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .logo-header {
        background-color: #ffffff;
        padding: 16px;
        height: 56px;

        position: sticky;
        top: 0;
        width: 100%;

        filter: drop-shadow(0px 4px 6px rgba(9, 30, 66, 0.06));

        img {
            width: 72px;
            height: 24px;
        }
    }

    .campaign-list {
        padding: 16px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
`;

const EmptyCampaignPage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    .campaign-content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        h4 {
            font-weight: 700;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: -0.0106667em;
            color: #1f2933;
            margin: 0;
        }
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            letter-spacing: -0.0106667em;
            color: #7b8794;
        }
    }
    Button {
        margin-top: 32px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        gap: 8px;
        width: 184px;
        height: 44px;
        background: #f35627;
        border-radius: 8px;
    }
`;
export { CampaignStyle, EmptyCampaignPage };
