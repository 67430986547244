import styled from 'styled-components';
const SliderHandler = styled.div<{
    height?: number;
}>`
    display: flex;
    justify-content: space-between;
    svg {
        width: 12px;
        height: 18px;
    }

    .slide-handler {
        position: absolute;
        backdrop-filter: blur(10px);
        background-color: rgba(173, 181, 189, 0.4);
        width: 48px;
        height: ${props => props.height}px;
        cursor: pointer;
        display: grid;
        place-content: center;
        z-index: 20;
        opacity: 0;
        &.handle-prev {
            display: none;
            left: 0;
            svg {
                transform: rotate(180deg);
            }

            &.visible {
                display: grid;
                @media screen and (max-width: 680px) {
                    display: none;
                }
            }
        }

        &.handle-next {
            display: none;
            right: 0;
            &.visible {
                display: grid;
                @media screen and (max-width: 680px) {
                    display: none;
                }
            }
        }
    }

    &:hover {
        .slide-handler {
            opacity: 1 !important;
            @media screen and (max-width: 680px) {
                display: none;
            }
        }
    }
`;
const RowBody = styled.div<{
    padding?: boolean;
}>`
    padding-bottom: 12px;
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
    gap: 12px;
    padding-left: ${props => (props.padding ? 0 : 16)}px;
    transition: all 0.5s ease;
    scroll-behavior: smooth;
    ::-webkit-scrollbar {
        display: none;
    }
`;

export { SliderHandler, RowBody };
