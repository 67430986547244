import styled from 'styled-components/macro';

const CountryCode = styled.div`
    border: 1px solid #cbd2d9;
    border-radius: 8px;
    display: flex;
    margin-top: 8px;

    &#contact-details-page {
        border: 0;

        input.phone-number-input {
            font-size: 16px;
            background-color: #f5f7fa;
            margin-top: 0;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
            flex: 1;
        }

        .country-code__select-menu__container {
            background-color: #f5f7fa;

            .country-code__select-menu__value-container {
                font-size: 16px;
            }

            svg {
                width: 18px;
            }
        }
        .custom-style {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }

    &.focus {
        border: 1px solid #7792e4;
    }

    > * {
        font-size: 18px;
        border: 0;
        border-radius: 8px;
        height: 100%;
        &:focus {
            outline: none;
            box-shadow: none;
            border: 0;
        }
    }

    input.phone-number-input {
        font-size: 18px;
        width: 100%;
        padding: 0 12px;
        margin-top: 0 !important;
        border: 0 !important;
        height: 44px;
    }

    .country-code__select-menu__container {
        height: 44px;

        .country-code__select-menu {
            &__control {
                &--menu-is-open {
                    .country-code__select-menu__single-value {
                        opacity: 0.3;
                    }
                }
            }

            &__menu {
                width: max-content;
            }

            &__indicators {
                width: 20px;
                transform: translateX(-5px);
            }

            &__dropdown-indicator {
                padding: 0;
            }

            &__single-value {
                text-overflow: initial;
                width: max-content;
            }

            &__option {
                padding: 5px;
                font-size: 15px;
                width: 100%;
            }

            &__indicator-separator {
                display: none;
            }
        }
        svg:not([fill]) {
            fill: #7b8794;
            stroke-width: 0.2;
        }
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
`;

export { CountryCode };
