import styled from 'styled-components';

const DashboardStyle = styled.div`
    padding: 0px 16px;
    .dashboard-contents {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        list-style: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.008em;
        color: #7b8794;
        flex-wrap: wrap;

        .count-card-section {
            display: flex;
            width: 100%;
            gap: 8px;
        }

        li {
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex-direction: column;
            align-items: flex-start;
            padding: 16px;
            box-shadow: 0px 2px 6px rgba(9, 30, 66, 0.04),
                0px 8px 12px rgba(9, 30, 66, 0.08);
            border-radius: 8px;
            width: 100%;
            margin-top:16px ;

            @media screen and (max-width: 680px) {
                padding: 16px;
            }

            span {
                width: 30%;
                font-weight: 700;
                font-size: 20px;
                line-height: 150%;
                letter-spacing: -0.008em;
                color: #3047bb;
            }
        }

        .brand-type {
            display: block;
            background-color: #ffffff;
            width: 100%;

            .brands {
                font-size: 14px;
                font-weight: 600;
                color: #1f2933;
                padding-right: 4px;
            }
        }
    }
`;

const DashboardSection = styled.div`
    padding-top: 16px;
`;

export { DashboardSection, DashboardStyle };
