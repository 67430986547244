import React from 'react';
import { Spinner } from 'react-bootstrap';
import { SpinnerSection } from './index.styled';

const SpinnerLoader = () => {
    return (
        <SpinnerSection>
            <Spinner
                animation="border"
                variant="danger"
                className="threads-spinner"
            />{' '}
            {'\u00a0\u00a0'} Loading...
        </SpinnerSection>
    );
};

export default SpinnerLoader;
