/**
 *
 * ContactDetails
 *
 */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    generateFormattedCountryCodes,
    getCountryCodeDetails,
} from 'utils/helper';

import {
    signUpPageActions,
    useSignUpPageSlice,
} from 'app/pages/SignUpPage/slice';
import { signupStepsKeyList } from 'app/pages/SignUpPage/label';
import { selectSignUpPage } from 'app/pages/SignUpPage/slice/selectors';

import Form, {
    FormField,
    FormLabel,
    FormValidatonError,
    Required,
} from 'app/components/Form';
import Intro from 'app/components/Intro';
import Spinner from 'app/components/Spinner';
import { Button } from 'app/components/Button';
import AuthScreen from 'app/components/AuthScreen';
import AuthFooter from 'app/components/AuthFooter';
import InputWithCountryCode from 'app/components/InputWithCountryCode';

import { Checkbox } from './index.styled';

interface Props {
    onClickNext?: any;
    showIntro?: boolean;
    customFormStyled?: boolean;
    prefillContactDetails?: any;
}

interface IContactForm {
    phoneNo: string;
    whatsappNo: string;
    emailId: string;
}

interface ICountryCodeDetail {
    label: string;
    value: string;
    digits: number;
    countryCodeValue: string;
    rawCountryCode: number;
    countryName: string;
    countryFlag: string;
    countryAlphaTwoCode: string;
}

export const ContactDetails = memo((props: Props) => {
    useSignUpPageSlice();
    const dispatch = useDispatch();
    const signUpPageStore = useSelector(selectSignUpPage);
    const { influencerData = {} } = signUpPageStore;

    const {
        onClickNext,
        showIntro,
        customFormStyled = false,
        prefillContactDetails,
    } = props;

    const [contactDetailsForm, setContactDetailsForm] = useState<IContactForm>({
        phoneNo: influencerData?.phone_number?.phone_number || '',
        whatsappNo: influencerData?.whatsapp_phone_number?.phone_number || '',
        emailId: '',
    });

    const countryCodeList = generateFormattedCountryCodes();
    const [whatsappCountryCode, setWhatsappCountryCode] = useState<
        ICountryCodeDetail | any
    >(countryCodeList[0]);

    const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState<
        ICountryCodeDetail | any
    >(
        getCountryCodeDetails(
            influencerData?.phone_number?.country_code,
            influencerData?.phone_number?.alpha_2_code,
        ) || countryCodeList[0],
    );

    const [same, setSame] = useState(
        // keeping optional chaining "?", might cause situation like
        // undefined === undefined or null === null, which will cause "same" to be "true"
        // hence this additional check
        influencerData?.phone_number?.phone_number &&
            influencerData?.phone_number?.alpha_2_code &&
            influencerData?.whatsapp_phone_number?.phone_number &&
            influencerData?.whatsapp_phone_number?.alpha_2_code
            ? influencerData?.phone_number?.phone_number ===
                  influencerData?.whatsapp_phone_number?.phone_number &&
              influencerData?.phone_number?.alpha_2_code ===
                  influencerData?.whatsapp_phone_number?.alpha_2_code
                ? true
                : false
            : false,
    );

    useEffect(() => {
        if (
            signUpPageStore.isStepSuccess &&
            signUpPageStore.currentStep === 'contactDetails' &&
            !customFormStyled
        ) {
            onClickNext();
        }
    }, [signUpPageStore, onClickNext]);

    useEffect(() => {
        if (
            customFormStyled &&
            Object.keys(prefillContactDetails).length !== 0
        ) {
            // assigning variables
            const prefilledPhoneNo =
                prefillContactDetails?.phone_number?.phone_number;
            const prefilledWhatsappNo =
                prefillContactDetails?.whatsapp_number?.phone_number;
            const prefilledPhoneNoCountryCode =
                prefillContactDetails?.phone_number?.country_code;
            const prefilledPhoneNoCountryAlpha2Code =
                prefillContactDetails?.phone_number?.alpha_2_code;
            const prefilledWhatsappNoCountryCode =
                prefillContactDetails?.whatsapp_number?.country_code;
            const prefilledWhatsappNoCountryAlpha2Code =
                prefillContactDetails?.whatsapp_number?.alpha_2_code;

            const isSameNumber =
                prefilledPhoneNo && prefilledWhatsappNo
                    ? prefilledPhoneNo === prefilledWhatsappNo
                    : false;

            const isSameCode =
                prefilledPhoneNoCountryCode &&
                prefilledPhoneNoCountryAlpha2Code &&
                prefilledWhatsappNoCountryCode &&
                prefilledWhatsappNoCountryAlpha2Code
                    ? prefilledPhoneNoCountryCode ===
                          prefilledWhatsappNoCountryCode &&
                      prefilledPhoneNoCountryAlpha2Code ===
                          prefilledWhatsappNoCountryAlpha2Code
                    : false;

            const whatsappCountryCodeData = getCountryCodeDetails(
                prefilledWhatsappNoCountryCode,
                prefilledWhatsappNoCountryAlpha2Code,
            );

            const phoneNoCountryCodeData = getCountryCodeDetails(
                prefilledPhoneNoCountryCode,
                prefilledWhatsappNoCountryAlpha2Code,
            );

            setWhatsappCountryCode(whatsappCountryCodeData);
            setPhoneNumberCountryCode(phoneNoCountryCodeData);
            setSame(isSameNumber && isSameCode);

            setContactDetailsForm({
                ...contactDetailsForm,
                phoneNo: prefilledPhoneNo || '',
                whatsappNo: prefilledWhatsappNo || '',
                emailId: prefillContactDetails?.email || '',
            });
        }
    }, []);

    const isValidEmail = () => {
        return /\S+@\S+\.\S+/.test(contactDetailsForm.emailId);
    };

    const canDispatch = () => {
        const { phoneNo, whatsappNo, emailId } = contactDetailsForm;

        return emailId && phoneNo && whatsappNo && isValidEmail()
            ? true
            : false;
    };

    const onSaveContactDetails = () => {
        if (canDispatch()) {
            const dispatchData = {
                current_step: signupStepsKeyList['contactDetails'],
                phone_number: contactDetailsForm.phoneNo,
                whatsapp_phone_number: same
                    ? contactDetailsForm.phoneNo
                    : contactDetailsForm.whatsappNo,
                email: contactDetailsForm.emailId,
                currentStep: 'contactDetails',
                token: localStorage.getItem('token'),
                whatsapp_country_code:
                    whatsappCountryCode.countryCodeValue || '+91',
                whatsapp_alpha_2_code:
                    whatsappCountryCode.countryAlphaTwoCode || 'IN',
                alpha_2_code:
                    phoneNumberCountryCode.countryAlphaTwoCode || 'IN',
                action_type: !customFormStyled ? 'signup' : 'edit',
            };

            dispatch(signUpPageActions.register(dispatchData));
        }
    };

    const handleSameNumbers = () => {
        setContactDetailsForm({
            ...contactDetailsForm,
            whatsappNo: contactDetailsForm.phoneNo || '',
        });
    };

    const hasCompletePhoneNumberInfo = (sourceInfo: any) => {
        return (
            sourceInfo &&
            Object.keys(sourceInfo).length !== 0 &&
            Object.values(sourceInfo).every(item => item) &&
            Boolean(
                sourceInfo?.phone_number &&
                    sourceInfo?.alpha_2_code &&
                    sourceInfo?.country_code,
            ) &&
            phoneNumberCountryCode
        );
    };

    return (
        <AuthScreen
            className={`auth-screen contact-details ${
                customFormStyled ? 'custom-auth-styled' : false
            }`}
        >
            <Intro
                className={`intro ${
                    customFormStyled ? 'custom-intro-style' : false
                }`}
            >
                {customFormStyled ? (
                    <h2>Contact Info</h2>
                ) : (
                    <h2>Contact Details</h2>
                )}

                {showIntro && (
                    <span>
                        This will help us give you more relevant campaigns
                    </span>
                )}
            </Intro>
            <Form
                className={`form-list ${
                    customFormStyled ? 'custom-form-style' : false
                }`}
            >
                <FormField className="form-list-item">
                    <FormLabel htmlFor="phoneNo">
                        Phone number
                        <Required className="required">*</Required>
                    </FormLabel>
                    {(
                        !customFormStyled
                            ? hasCompletePhoneNumberInfo(
                                  influencerData?.phone_number,
                              )
                            : hasCompletePhoneNumberInfo(
                                  prefillContactDetails?.phone_number,
                              )
                    ) ? (
                        <input
                            type="text"
                            id="phoneNo"
                            value={`${phoneNumberCountryCode.countryFlag} ${
                                phoneNumberCountryCode.countryCodeValue
                            } ${contactDetailsForm.phoneNo || ''}`}
                            disabled
                            onChange={e => {
                                setContactDetailsForm({
                                    ...contactDetailsForm,
                                    phoneNo: e.target.value,
                                });
                            }}
                            onKeyDown={e =>
                                ['e', 'E', '+', '-'].includes(e.key) &&
                                e.preventDefault()
                            }
                        />
                    ) : (
                        <InputWithCountryCode
                            pageIdForComp="contact-details-page"
                            placeholderText={'Enter your phone number'}
                            phoneNumber={contactDetailsForm.phoneNo}
                            setPhoneNumber={(value: any) => {
                                if (
                                    value === contactDetailsForm.whatsappNo &&
                                    whatsappCountryCode.countryCodeValue ===
                                        phoneNumberCountryCode.countryCodeValue &&
                                    whatsappCountryCode.countryAlphaTwoCode ===
                                        phoneNumberCountryCode.countryAlphaTwoCode
                                ) {
                                    setSame(true);
                                } else {
                                    setSame(false);
                                }
                                setContactDetailsForm({
                                    ...contactDetailsForm,
                                    phoneNo: value,
                                });
                            }}
                            countryCode={
                                phoneNumberCountryCode?.countryCodeValue
                            }
                            countryAlphaTwoCode={
                                phoneNumberCountryCode?.countryAlphaTwoCode
                            }
                            setCountryCode={(selectedCountryCode: any) => {
                                setPhoneNumberCountryCode(selectedCountryCode);

                                if (
                                    selectedCountryCode.countryCodeValue ===
                                        whatsappCountryCode.countryCodeValue &&
                                    selectedCountryCode.countryAlphaTwoCode ===
                                        whatsappCountryCode.countryAlphaTwoCode &&
                                    contactDetailsForm.whatsappNo ===
                                        contactDetailsForm.phoneNo
                                ) {
                                    setSame(true);
                                } else {
                                    setSame(false);
                                }
                            }}
                            countryCodeList={countryCodeList}
                        />
                    )}
                </FormField>
                <FormField className="form-list-item">
                    <FormLabel htmlFor="whatsappNo">
                        Whatsapp number
                        <Required className="required">*</Required>
                    </FormLabel>
                    <InputWithCountryCode
                        customFormStyle={true}
                        pageIdForComp="contact-details-page"
                        placeholderText={'Enter your whatsapp number'}
                        phoneNumber={contactDetailsForm.whatsappNo}
                        setPhoneNumber={(value: any) => {
                            setContactDetailsForm({
                                ...contactDetailsForm,
                                whatsappNo: value,
                            });

                            if (
                                value === contactDetailsForm.phoneNo &&
                                whatsappCountryCode.countryCodeValue ===
                                    phoneNumberCountryCode.countryCodeValue &&
                                whatsappCountryCode.countryAlphaTwoCode ===
                                    phoneNumberCountryCode.countryAlphaTwoCode
                            ) {
                                setSame(true);
                            } else {
                                setSame(false);
                            }
                            setContactDetailsForm({
                                ...contactDetailsForm,
                                whatsappNo: value,
                            });
                        }}
                        countryCode={whatsappCountryCode?.countryCodeValue}
                        countryAlphaTwoCode={
                            whatsappCountryCode?.countryAlphaTwoCode
                        }
                        setCountryCode={(selectedCountryCode: any) => {
                            setWhatsappCountryCode(selectedCountryCode);

                            if (
                                selectedCountryCode.countryCodeValue ===
                                    phoneNumberCountryCode.countryCodeValue &&
                                selectedCountryCode.countryAlphaTwoCode ===
                                    phoneNumberCountryCode.countryAlphaTwoCode &&
                                contactDetailsForm.whatsappNo ===
                                    contactDetailsForm.phoneNo
                            ) {
                                setSame(true);
                            } else {
                                setSame(false);
                            }
                        }}
                        countryCodeList={countryCodeList}
                    />
                    <Checkbox className="checkbox">
                        <input
                            type="checkbox"
                            id="same"
                            checked={same}
                            onChange={e => {
                                setSame(!same);
                                if (!e.target.checked) {
                                    setContactDetailsForm({
                                        ...contactDetailsForm,
                                        whatsappNo: '',
                                    });
                                } else {
                                    const countryCodeDetails =
                                        getCountryCodeDetails(
                                            phoneNumberCountryCode?.countryCodeValue ||
                                                '+91',
                                            phoneNumberCountryCode?.countryAlphaTwoCode ||
                                                'IN',
                                        );
                                    setWhatsappCountryCode(countryCodeDetails);
                                    handleSameNumbers();
                                }
                            }}
                        />
                        <label htmlFor="same">Same as phone number</label>
                    </Checkbox>
                </FormField>
                <FormValidatonError>
                    {signUpPageStore.validationErrors}
                </FormValidatonError>

                <FormField className="form-list-item">
                    <FormLabel htmlFor="emailId">
                        Email
                        <Required className="required">*</Required>
                    </FormLabel>
                    <input
                        type="text"
                        id="emailId"
                        placeholder="Enter your email"
                        value={contactDetailsForm.emailId}
                        onChange={e => {
                            setContactDetailsForm({
                                ...contactDetailsForm,
                                emailId: e.target.value,
                            });
                        }}
                    />
                </FormField>
                
            </Form>
            <AuthFooter className="footer">
                <Button
                    onClick={(e: any) => {
                        e.preventDefault();
                        onSaveContactDetails();
                    }}
                    className={
                        !canDispatch() || signUpPageStore.loading
                            ? 'disabled'
                            : ''
                    }
                >
                    {signUpPageStore.loading ? (
                        <Spinner color="light" />
                    ) : !customFormStyled ? (
                        'Next'
                    ) : (
                        'Save'
                    )}
                </Button>
            </AuthFooter>
        </AuthScreen>
    );
});
