import InstagramIcon from 'app/images/blue-instagram.svg';
import YouTubeIcon from 'app/images/youtube-icon.svg';
import UserBlueIcon from 'app/images/userblue-icon.svg';
import ReferIcon from 'app/images/refer-icon.svg';
import SettingIcon from 'app/images/setting-icon.svg';
import ShareIcon from 'app/images/referwhiteicon.svg';
import LinkIcon from 'app/images/link-icon.svg';
import WhiteSettingIcon from 'app/images/smallsetting-icon.svg';
import WhitePlusIcon from 'app/images/whiteplus.svg';
import Insta from 'app/images/instagram-white-icon.svg';
import WhatsAppIcon from 'app/images/whatasapp-icon.svg';
import PhoneCallIcon from 'app/images/phonecall-icon.svg';
import MapPinIcon from 'app/images/map-pin.svg';
import EmailIcon from 'app/images/mail-icon.svg';

export const ListSocialplatforms = [
    {
        icon: InstagramIcon,
        platformsType: 'VERIFY INSTAGRAM PROFILE',
        platformContent: ' Kindly verify your instagram profiles for x reason.',
        buttonIcon: Insta,
        buttonText: 'Link Instagram',
        id: 'instagram',
        background: '#F7F3FB',
        color: '#6E3AB9',
        backgroundiconcolor: '#E9E0F5',
        iconbackgroundColor: '#F6F2FA',
        apiRefId: 'instagram',
    },
    {
        icon: YouTubeIcon,
        platformsType: 'VERIFY YOUTUBE PROFILE',
        platformContent: ' Kindly verify your YouTube profiles for x reason.',
        buttonIcon: LinkIcon,
        buttonText: 'Link YouTube',
        id: 'youTube',
        background: '#FFF5F5',
        color: '#D64545',
        backgroundiconcolor: '#FCE3E3',
        iconbackgroundColor: '#FFEEEE',
        apiRefId: 'youtube',
    },
    {
        icon: UserBlueIcon,
        platformsType: 'ADD MORE INFO',
        platformContent:
            ' Get more campaign offers by telling the campaigners more about you.',
        buttonIcon: WhitePlusIcon,
        buttonText: 'Add Information',
        id: 'add_Information',
        background: '#E7F9FE',
        color: '#0985A9',
        backgroundiconcolor: '#C5EFFC',
        iconbackgroundColor: '#E7F9FE',
        apiRefId: 'add_more_info',
    },
    {
        icon: SettingIcon,
        platformsType: 'SET UP PREFERENCES',
        platformContent: 'Set up preferences to get more relevant campaigns.',
        buttonIcon: WhiteSettingIcon,
        buttonText: 'Set Up Preferences',
        id: 'performance',
        background: '#F2FCF3',
        color: '#3F9142',
        backgroundiconcolor: '#DDF3DF',
        iconbackgroundColor: '#EEFBEF',
        apiRefId: 'set_up_preferences',
    },
    {
        icon: ReferIcon,
        platformsType: 'REFER & EARN',
        platformContent: ' Refer your friends to Qoruz & you earn $20, each!',
        buttonIcon: ShareIcon,
        buttonText: 'Refer Friends',
        id: 'refer',
        background: '#EDF2FD',
        color: '#3956C6',
        backgroundiconcolor: '#CFDDFC',
        iconbackgroundColor: '#EDF2FD',
        apiRefId: 'refer_and_earn',
    },
];

export const additionalStepsData = {
    instagram: {
        icon: InstagramIcon,
        platformsType: 'VERIFY INSTAGRAM PROFILE',
        platformContent: ' Kindly verify your instagram profiles for x reason.',
        buttonIcon: Insta,
        buttonText: 'Link Instagram',
        id: 'instagram',
        background: '#F7F3FB',
        color: '#6E3AB9',
        backgroundiconcolor: '#E9E0F5',
        iconbackgroundColor: '#F6F2FA',
        apiRefId: 'instagram',
    },
    youtube: {
        icon: UserBlueIcon,
        platformsType: 'ADD MORE INFO',
        platformContent:
            ' Get more campaign offers by telling the campaigners more about you.',
        buttonIcon: WhitePlusIcon,
        buttonText: 'Add Information',
        id: 'add_Information',
        background: '#E7F9FE',
        color: '#0985A9',
        backgroundiconcolor: '#C5EFFC',
        iconbackgroundColor: '#E7F9FE',
        apiRefId: 'youtube',
    },
    add_more_info: {
        icon: UserBlueIcon,
        platformsType: 'ADD MORE INFO',
        platformContent:
            ' Get more campaign offers by telling the campaigners more about you.',
        buttonIcon: WhitePlusIcon,
        buttonText: 'Add Information',
        id: 'add_Information',
        background: '#E7F9FE',
        color: '#0985A9',
        backgroundiconcolor: '#C5EFFC',
        iconbackgroundColor: '#E7F9FE',
        apiRefId: 'add_more_info',
    },
    set_up_preferences: {
        icon: SettingIcon,
        platformsType: 'SET UP PREFERENCES',
        platformContent: 'Set up preferences to get more relevant campaigns.',
        buttonIcon: WhiteSettingIcon,
        buttonText: 'Set Up Preferences',
        id: 'performance',
        background: '#F2FCF3',
        color: '#3F9142',
        backgroundiconcolor: '#DDF3DF',
        iconbackgroundColor: '#EEFBEF',
        apiRefId: 'set_up_preferences',
    },
    refer_and_earn: {
        icon: ReferIcon,
        platformsType: 'REFER & EARN',
        platformContent: ' Refer your friends to Qoruz & you earn $20, each!',
        buttonIcon: ShareIcon,
        buttonText: 'Refer Friends',
        id: 'refer',
        background: '#EDF2FD',
        color: '#3956C6',
        backgroundiconcolor: '#CFDDFC',
        iconbackgroundColor: '#EDF2FD',
        apiRefId: 'refer_and_earn',
    },
};
export const selectOptions = [
    {
        id: 1,
        label: 'call',
        icon: PhoneCallIcon,
    },
    {
        id: 2,
        label: 'whatsapp',
        icon: WhatsAppIcon,
    },
];

export const contactIconList = {
    call: PhoneCallIcon,
    whatsapp: WhatsAppIcon,
    email: EmailIcon,
};

export const addressList = [
    {
        icon: MapPinIcon,
        addresType: 'Home- Bangalore',
        fullAddrress:
            ' KC 1801, Salarpuria Sattva Greenage,and Bommanahall Banglore...',
    },
    {
        icon: MapPinIcon,
        addresType: 'Home- Bangalore',
        fullAddrress:
            ' KC 1801, Salarpuria Sattva Greenage,and Dharumapuri ,TamilNadu,636704 ',
    },
    {
        icon: MapPinIcon,
        addresType: 'Home- Bangalore',
        fullAddrress: ' KC 1801, Salarpuria Sattva Greenage,and Bommanahall...',
    },
];
