import { createGlobalStyle } from 'styled-components';
/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.4;
  }

  body {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #3E4C59;
    background-color: #e4e7eb;
  }

  body.fontLoaded {
    font-family: 'Inter', sans-serif;
  }

  body {
    &.no-scroll {
      overflow: hidden;
    }
  }

  p,
  label {
    line-height: 1.4;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  h2,
  h3,
  h4 {
    font-weight: 500;
    color: #1F2933;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
    letter-spacing: -0.008em;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.08em;
  }

  textarea,
  input {
    &::-webkit-input-placeholder {
      font-family: 'Inter',sans-serif;
    }
    
    &:-ms-input-placeholder {
      font-family: 'Inter',sans-serif;
    }
    
    &::placeholder {
      font-family: 'Inter',sans-serif;
    }
    
  }

  :root {
      // gray pallet
    --gray-900: #1f2933;
    --gray-800: #323f4b;
    --gray-700: #3e4c59;
    --gray-600: #52606d;
    --gray-500: #616e7c;
    --gray-400: #7b8794;
    --gray-300: #9aa5b1;
    --gray-200: #cbd2d9;
    --gray-100: #e4e7eb;
    --gray-75: #ebeef1;
    --gray-50: #f1f4f8;
    --gray-10: #f8fafc;
    --gray-00: #ffffff;
    --gray-hover: #f8fafc;

    --red-50: #FFEEEE;

    --green-50: #EEFBEF;

    --yellow-50: #fffaeb;

    --lavender-50: #F5F7FA;
  }
`;
