/**
 *
 * Footer
 *
 */
import { selectCampaignCostSlice } from 'app/pages/HomePage/components/CampaignCostPopUp/slice/selectors';
import { homeActions, useHomeSlice } from 'app/pages/HomePage/slice';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

interface Props {
    profileId: string;
    groupId: string;
    onAccept: Function;
}

export const Footer = memo((props: Props) => {
    useHomeSlice();
    const { profileId, groupId, onAccept } = props;
    const dispatch = useDispatch();
    const selectCampaignCostStore = useSelector(selectCampaignCostSlice);
    const { closeCampaignCostPopUp } = selectCampaignCostStore;

    const onDecline = () => {
        dispatch(homeActions.setIsDeclined(true));
    };

    return (
        <FooterStyled className="footer">
            <Decline className="decline" onClick={() => onDecline()}>
                Decline
            </Decline>
            <Accept className="accept" onClick={() => onAccept()}>
                Accept Invite
            </Accept>
        </FooterStyled>
    );
});

const Decline = styled.div`
    width: 40%;
    background-color: #e4e7eb;
    color: #1f2933;
    font-weight: 600;
    &.add-decline {
        width: 50% !important;
        background-color: #ffffff;
    }
`;
const Accept = styled.div`
    width: 60%;
    background-color: #f35627;
    color: white;
    &.addaccept-style {
        width: 50% !important ;
        color: white !important;
    }
`;
const FooterStyled = styled.footer`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    z-index: 3;
    height: 56px;
    display: flex;
    align-items: center;

    > * {
        cursor: pointer;
        align-items: center;
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: 16px;
        height: 100%;
    }
    &.addfooter-style {
        height: 44px !important;
        filter: drop-shadow(0px -2px 6px rgba(9, 30, 66, 0.06));
        > * {
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.00466667em;
            font-weight: 600;
            color: #1f2933;
        }
    }
`;

export { Decline, Accept, FooterStyled };
