import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { renderMessengerTime } from 'utils/helper';

dayjs.extend(isYesterday);
dayjs.extend(isToday);

// check is date exist in array
export const checkIsDateExist = (formttedMessages, created_at) => {
    const isExistDate = formttedMessages.filter(
        message => Object.keys(message)[0] === created_at,
    );
    return isExistDate && isExistDate.length ? true : false;
};

// filter messages based on created date
export const handleFilterByCreatedDate = (messages: any) => {
    let filterMessages: any = [];
    const messageObj =
        messages &&
        messages.length &&
        messages.filter(message => {
            let createdAt = dayjs(message.created_at).format('YYYY/MM/DD');
            if (
                filterMessages &&
                filterMessages.length &&
                checkIsDateExist(filterMessages, createdAt)
            ) {
                const updatedMessages = filterMessages.map(
                    (existMessage, index) => {
                        const date = Object.keys(existMessage)[0];
                        if (date === createdAt) {
                            const tempArray = existMessage[date];
                            tempArray.push(message);
                        }
                    },
                );
            } else {
                if (message.created_at) {
                    filterMessages.push({
                        [createdAt]: [message],
                    });
                }
            }
        });
    return filterMessages;
};

export const renderMsgTime = (created_date: any, is_same_day: any = false) => {
    const {
        messageCalendarDay,
        messageTime,
        isMsgDayToday,
        isMsgDayYesterday,
    } = renderMessengerTime(created_date);

    if (isMsgDayToday) {
        return is_same_day ? 'Today' : messageTime;
    }
    if (isMsgDayYesterday) {
        return 'Yesterday';
    }
    if (!isMsgDayToday && !isMsgDayYesterday) {
        return messageCalendarDay;
    }
};
