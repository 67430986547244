import React, { memo } from 'react';
import { Popup } from 'app/components/Popup';
import PopupContent from 'app/components/PopupContent';
import TrendingUpIcon from 'app/images/trending-up.svg';
import InstagramReelsIcon from 'app/images/instagram-reels-icon.svg';
import CameraIcon from 'app/images/redcamera-icon.svg';
import { Border } from 'app/pages/Tabs/ProfileTabSection/index.styled';
import { Button } from 'app/components/Button';

import {
    QoruzRecommendedHeader,
    QoruzRecommendedBody,
    QoruzRcommendedPopup,
    QoruzRecommendedFooter,
} from './index.styled';

interface Props {
    onClose: Function;
}

export const QoruzRecommendedCostPopup = memo((props: Props) => {
    const { onClose } = props;
    return (
        <Popup onClose={onClose}>
            <PopupContent className="popup-content decline-popup">
                <QoruzRcommendedPopup>
                    <QoruzRecommendedHeader>
                        <img src={TrendingUpIcon} alt="icon" />
                        <div className="header">
                            Hey Amit, this cost seems a little high!
                            <span>
                                Over 80% influencers with similar profiles have
                                lower rates. Improve your chances of acceptance
                                by lowering your estimate!
                            </span>
                        </div>
                    </QoruzRecommendedHeader>
                    <QoruzRecommendedBody>
                        <ul>
                            <p>Qoruz recommended cost</p>
                            <li>
                                <div>
                                    <img
                                        src={InstagramReelsIcon}
                                        alt="reels_icon"
                                        className="img"
                                    />{' '}
                                    <span>1 </span>x Instagram Post{' '}
                                </div>
                                <div className="total">
                                    $800
                                    <span className="estimate">
                                        (was $1000)
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img
                                        src={CameraIcon}
                                        alt="icon"
                                        className="img"
                                    />
                                    <span>2</span>x Instagram Reels{' '}
                                </div>

                                <div className="total">
                                    $1800
                                    <span className="estimate">
                                        (was $2200)
                                    </span>
                                </div>
                            </li>
                            <Border />
                            <li>
                                <div>Total Cost</div>

                                <div className="total">
                                    $2600
                                    <span className="estimate">
                                        (was $3200)
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </QoruzRecommendedBody>

                    <QoruzRecommendedFooter>
                        <div className="button">
                            <Button className="editCost"> Edit cost</Button>
                            <Button className="acceptCost">
                                {' '}
                                Accept Recommendation
                            </Button>
                        </div>

                        <span>I’ll go with my original cost</span>
                    </QoruzRecommendedFooter>
                </QoruzRcommendedPopup>
            </PopupContent>
        </Popup>
    );
});
