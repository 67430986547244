import React, { memo } from 'react';
import { Popup } from 'app/components/Popup';
import { Categories } from 'app/pages/SignUpPage/components/Stepper/components/Categories';
interface Props {
    onClose: Function;
    categoriesList: Array<{
        id: number;
        name: string;
        emoji_name: string;
    }>;
}

export const Categoriespopup = memo((props: Props) => {
    const { onClose, categoriesList = [] } = props;

    return (
        <Popup onClose={onClose}>
            <Categories
                customFormStyled={true}
                prefillSelectedCategories={categoriesList}
            />
        </Popup>
    );
});
