import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    convertToInternationalFormat,
    onlyNumberKey,
    stripCommasFromNumber,
} from 'utils/helper';

import { signUpPageActions } from 'app/pages/SignUpPage/slice';
import { selectSignUpPage } from 'app/pages/SignUpPage/slice/selectors';

import Intro from 'app/components/Intro';
import Spinner from 'app/components/Spinner';
import { Popup } from 'app/components/Popup';
import { Button } from 'app/components/Button';
import AuthFooter from 'app/components/AuthFooter';
import AuthScreen from 'app/components/AuthScreen';
import Form, {
    FormField,
    FormLabel,
    FormValidatonError,
    Required,
} from 'app/components/Form';

import LinkIcon from 'app/images/link-url.svg';
import InstagramIcon from 'app/images/instagram.svg';
import InstagramIGTVIcon from 'app/images/instagram-igtv.svg';
import InstagramReelsIcon from 'app/images/instagram-reels-icon.svg';
import InstagramStoryIcon from 'app/images/instagram-story-icon.svg';
import InstagramPlayerIcon from 'app/images/instagram-player-icon.svg';
import { socialDeliverableTypes } from '../data';
import { signupStepsKeyList } from 'app/pages/SignUpPage/label';


interface Props {
    onClose: Function;
    prefilledInstaProfileInfo?: any;
}

export const InstagramPopUp = memo((props: Props) => {
    const { onClose, prefilledInstaProfileInfo } = props;

    const dispatch = useDispatch();
    const signUpPageStore = useSelector(selectSignUpPage);

    const [instagramCostDetails, setInstagramCostDetails] = useState({
        instagramHandle: '',
        postCost: '',
        reelsPostCost: '',
        videoPostCost: '',
        // IGTVPostCost: '',
        storyPostCost: '',
    });

    useEffect(() => {
        if (
            prefilledInstaProfileInfo &&
            Object.keys(prefilledInstaProfileInfo).length !== 0
        ) {
            const { basic_details, deliverables } = prefilledInstaProfileInfo;

            const instaProfileHandle = basic_details.username;

            let deliverablesObj = {};
            deliverables &&
                deliverables.length !== 0 &&
                deliverables.map((del: any) => {
                    deliverablesObj[del.value] = del.cost;
                });

            setInstagramCostDetails({
                instagramHandle: instaProfileHandle || '',
                postCost: deliverablesObj['instagram_images'] || '',
                reelsPostCost: deliverablesObj['instagram_reels'] || '',
                videoPostCost: deliverablesObj['instagram_videos'] || '',
                // IGTVPostCost: deliverablesObj['instagram_igtv'] || '',
                storyPostCost: deliverablesObj['instagram_story'] || '',
            });
        }
    }, []);

    const handleInputChange = (e: any) => {
        setInstagramCostDetails({
            ...instagramCostDetails,
            [e.target.name]:
                e.target.type === 'tel' && e.target.value !== ''
                    ? Number(stripCommasFromNumber(e.target.value))
                    : e.target.value,
        });
        dispatch(signUpPageActions.clearValidationError(''));
    };

    const canDispatch = () => {
        return Object.values(instagramCostDetails).every(val => val !== '')
            ? true
            : false;
    };

    const saveInstagramCostDetails = () => {
        if (canDispatch()) {
            const dispatchData = {
                token: localStorage.getItem('token'),
                current_step: signupStepsKeyList['instagramDetails'],
                currentStep: 'instagramDetails',
                action_type: 'edit',
                instagram_handle: instagramCostDetails.instagramHandle,
                deliverables_cost: socialDeliverableTypes[
                    'instagram'
                ].deliverables.map(deliverable => ({
                    deliverable_type: deliverable.type,
                    cost: instagramCostDetails[deliverable.formCostKey],
                })),
            };

            if (
                prefilledInstaProfileInfo &&
                prefilledInstaProfileInfo?.basic_details?.username
            ) {
                dispatchData['profile_id'] =
                    prefilledInstaProfileInfo?.basic_details?.profile_id;

                dispatchData['profile_exists'] = prefilledInstaProfileInfo
                    ?.basic_details?.profile_id
                    ? 1
                    : 0;

                dispatchData['marketplace_profile_exists'] = 1;
                dispatchData['marketplace_profile_id'] =
                    prefilledInstaProfileInfo?.basic_details?.marketplace_id;

                // if user updates the instagram handle when updating existing data
                // send 'social_handle_type' as 'create' otherwise 'update'
                dispatchData['social_handle_type'] =
                    prefilledInstaProfileInfo?.basic_details?.username ===
                    instagramCostDetails.instagramHandle
                        ? 'update'
                        : 'create';
            } else {
                dispatchData['profile_exists'] = 0;
                dispatchData['marketplace_profile_exists'] = 0;
                dispatchData['social_handle_type'] = 'create';
            }

            dispatch(signUpPageActions.register(dispatchData));
        }
    };

    return (
        <Popup onClose={onClose}>
            <AuthScreen className="auth-screen custom-auth-styled">
                <Intro className="intro custom-intro-style">
                    <h2>Instagram</h2>
                </Intro>
                <Form className="form-list custom-form-style">
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="instagramHandle">
                            <img src={LinkIcon} alt="Link-Icon" />
                            Profile handle / URL
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="text"
                            id="instagramHandle"
                            name="instagramHandle"
                            value={instagramCostDetails.instagramHandle}
                            placeholder="Enter your profile handle / URL"
                            onChange={e => {
                                handleInputChange(e);
                            }}
                            disabled={
                                prefilledInstaProfileInfo?.basic_details
                                    ?.profile_id
                            }
                        />
                        <FormValidatonError>
                            {signUpPageStore.validationErrors}
                        </FormValidatonError>
                    </FormField>

                    {/* using "tel" type for number type input field 
                    to prevent value increment/decrement with up/down arrow keys 
                    OR scroll-up/scroll-down */}

                    <FormField className="form-list-item">
                        <FormLabel htmlFor="postCost">
                            <img src={InstagramIcon} alt="Instagram Icon" />
                            Instagram post cost
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="tel"
                            id="postCost"
                            name="postCost"
                            min={0}
                            placeholder="Enter your charges for 1 post"
                            value={convertToInternationalFormat(
                                instagramCostDetails.postCost,
                            )}
                            onChange={e => {
                                handleInputChange(e);
                            }}
                            onKeyPress={e => {
                                !onlyNumberKey(e) && e.preventDefault();
                            }}
                        />
                    </FormField>
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="reelsPostCost">
                            <img
                                src={InstagramReelsIcon}
                                alt="Instagram Icon"
                            />
                            Instagram reel cost
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="tel"
                            id="reelsPostCost"
                            name="reelsPostCost"
                            min={0}
                            placeholder="Enter your charges for 1 reel"
                            value={convertToInternationalFormat(
                                instagramCostDetails.reelsPostCost,
                            )}
                            onChange={e => {
                                handleInputChange(e);
                            }}
                            onKeyPress={e => {
                                !onlyNumberKey(e) && e.preventDefault();
                            }}
                        />
                    </FormField>
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="videoPostCost">
                            <img
                                src={InstagramPlayerIcon}
                                alt="Instagram Icon"
                            />
                            Instagram video cost
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="tel"
                            id="videoPostCost"
                            name="videoPostCost"
                            min={0}
                            placeholder="Enter your charges for 1 video"
                            value={convertToInternationalFormat(
                                instagramCostDetails.videoPostCost,
                            )}
                            onChange={e => {
                                handleInputChange(e);
                            }}
                            onKeyPress={e => {
                                !onlyNumberKey(e) && e.preventDefault();
                            }}
                        />
                    </FormField>
                    {/* <FormField className="form-list-item">
                        <FormLabel htmlFor="IGTVPostCost">
                            <img src={InstagramIGTVIcon} alt="Instagram Icon" />
                            IGTV video cost
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="tel"
                            id="IGTVPostCost"
                            name="IGTVPostCost"
                            min={0}
                            placeholder="Enter your charges for 1 IGTV post"
                            value={instagramCostDetails.IGTVPostCost}
                            onChange={e => {
                                handleInputChange(e);
                            }}
                            onKeyPress={e => {
                                !onlyNumberKey(e) && e.preventDefault();
                            }}
                        />
                    </FormField> */}
                    <FormField className="form-list-item">
                        <FormLabel htmlFor="storyPostCost">
                            <img
                                src={InstagramStoryIcon}
                                alt="Instagram Icon"
                            />
                            Instagram story cost
                            <Required className="required">*</Required>
                        </FormLabel>
                        <input
                            type="tel"
                            id="storyPostCost"
                            name="storyPostCost"
                            min={0}
                            placeholder="Enter your charges for 1 story"
                            value={convertToInternationalFormat(
                                instagramCostDetails.storyPostCost,
                            )}
                            onChange={e => {
                                handleInputChange(e);
                            }}
                            onKeyPress={e => {
                                !onlyNumberKey(e) && e.preventDefault();
                            }}
                        />
                    </FormField>
                </Form>
                <AuthFooter className="footer">
                    <Button
                        className={
                            signUpPageStore.loading || !canDispatch()
                                ? 'disabled'
                                : ''
                        }
                        onClick={(e: any) => {
                            e.preventDefault();
                            saveInstagramCostDetails();
                        }}
                    >
                        {signUpPageStore.loading ? (
                            <Spinner color="light" />
                        ) : (
                            'Save'
                        )}
                    </Button>
                </AuthFooter>
            </AuthScreen>
        </Popup>
    );
});
