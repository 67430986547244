import { take, call, put, select, takeLatest } from 'redux-saga/effects';
import { campaignProgressActions as actions } from '.';
import { request } from 'utils/request';

export function* getCampaignProgressDetailsSaga({payload}) {
  try {
    const requestURL = `/api/campaign/steps`;

    const response = yield call(request, requestURL, {
        method: 'POST',
        headers: {
            // /Authorization: authHeader(),
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(payload),
    });
    yield put(actions.getCampaignProgressDetailsSuccess(response));
    // yield put(
    //     addToast({
    //         type: response.ok === true ? 'success' : 'error',
    //         message: response.stuff,
    //     }),
    // );
   
} catch (error) {
    try {
        const response = yield error.response.json();
        yield put(actions.getCampaignProgressDetailsError(response));
    } catch (jsonError) {
        yield put(actions.getCampaignProgressDetailsError(error));
    }
}
}

export function* campaignProgressSaga() {
  yield takeLatest(actions.getCampaignProgressDetails, getCampaignProgressDetailsSaga);
}
