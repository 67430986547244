import styled from 'styled-components';

const MessageBoxContainer = styled.div<{
    // height?: number;
}>`
    height: fit-content;
    padding: 12px;
    width: 296px;
    background: var(--lavender-50);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px;
    font-size: 13px;
    line-height: 140%;
    letter-spacing: -0.00466667em;
    color: #1f2933;
    p {
        margin-bottom: 8px;
    }
    .bold-text {
        font-weight: 700;
    }

    span.creator-name {
        margin: 0;
    }

    p.salutation {
        margin-bottom: 5px !important;
    }

    .time {
        display: flex;
        justify-content: end;
        align-items: center;
        font-size: 10px;
        line-height: 12px;
        color: #52606d;
        gap: 4px;
        margin-top: 5px;
        font-weight: 400;

        svg path {
            fill: #0caad9;
        }
    }

    &.bgcolor {
        background: #fff3eb;
    }
`;

const MessageTimeCategoryPill = styled.div`
    align-self: center;
    padding: 5px;
    border-radius: 8px;
    width: fit-content;
    font-size: 11px;
    font-weight: 600;
    background-color: var(--lavender-50);
    text-transform: capitalize;
    margin-bottom: 5px;
`;

const CampaignDeliverables = styled.div`
    min-height: 216px;

    .campaign-deliverables {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;

        & .campaign-details {
            gap: 4px;
            .title {
                font-weight: 700;
                font-size: 12px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #1f2933;
            }
        }

        &.deliverables-types {
            gap: 8px;
            .deliverable-icon {
                width: 16px;
                height: 16px;
            }
            span {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 8px;
                .count {
                    display: contents;
                    color: #1f2933;
                    font-weight: 800;
                }
            }
        }
    }
`;

const Button = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    letter-spacing: -0.00466667em;
    color: #de3a11;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    height: 44px;
    background: #ffffff;
    border-radius: 8px;
    cursor: pointer;
    gap: 4px;

    &.custom {
        cursor: default;
        padding: 8px 10px;
        gap: 8px;
        display: flex;
        justify-content: flex-start;
        font-weight: 600;
        font-size: 13px;
        line-height: 24px;
        color: #1f2933;

        img {
            width: 16px;
        }
    }
`;

const SocialPlatformStyle = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: flex;
            gap: 8px;

            span {
                font-weight: 800;
                font-size: 13px;
                line-height: 150%;
                color: #1f2933;
            }
        }
    }

    .time {
        position: absolute;
        right: 12px;
        bottom: 12px;
    }
`;

const DefaultMessegeSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
`;
export const EventInformation = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
    span {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        color: var(--gray-900);
        width: 75px;
    }
    p {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 150%;
        color: var(--gray-900);
        margin: 0;
    }
`;
export {
    MessageBoxContainer,
    Button,
    SocialPlatformStyle,
    DefaultMessegeSection,
    CampaignDeliverables,
    MessageTimeCategoryPill,
};
