import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getDefaultQLogo, noScroll } from 'utils/helper';

import { selectInfluencerProfileSummary } from 'app/pages/SignUpPage/slice/selectors';

import AppWrapper from 'app/components/AppWrapper';
import { AppDeclinePopUp } from '../DeclinePopup';
import { ChatWindowHeaderSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';
import PreviewBlockIcon from 'app/images/black-left-arrow.svg';
import PhoneCallIcon from 'app/images/phonecall-icon.svg';
import InfoIcon from 'app/images/info-icon.svg';

import { MessengerChatSection, BrandDetails } from './index.styled';
import { HeaderSection } from '../../index.styled';
import { CustomAppWrapper } from 'app/pages/Tabs/index.styled';
import { getTemplateName, getMessageTemplate } from '../renderMessageTemplates';
import { ChatMessageSkeleton } from 'app/pages/Tabs/TabMenu/CustomLoaderSkeletons/SkeletonLoaders';
import { selectMessengerThreadList } from 'app/pages/Tabs/MessengerTabSection/slice/selectors';
import { MessengerBrandInfoPage } from '../BrandInfoPage';
import { useParams } from 'react-router-dom';
import Spinner from 'app/components/Spinner_V2';
import {
    renderMsgTime,
    handleFilterByCreatedDate,
} from 'app/pages/Tabs/MessengerTabSection/helpers';

interface Props {
    [x: string]: any;
    selectThreadId?: any;
    setSelectThreadId?: any;
    threadMessagesInfo?: any;
    renderDeliverablesList?: any;
    selectedThreadDetails?: any;
}

export const MessageChatPage = memo((props: Props) => {
    const {
        selectThreadId,
        setSelectThreadId,
        threadMessagesInfo,
        selectedThreadDetails,
        renderDeliverablesList,
    } = props;

    const searchParams = useParams();

    const history = useHistory();
    const profileSummarySelector = useSelector(selectInfluencerProfileSummary);
    const threadListInfo = useSelector(selectMessengerThreadList);

    const { profileSummary = {}, loading: profileSummaryLoading } =
        profileSummarySelector;
    const [DeclinePopup, setDeclinePopup] = useState(false);
    const [showBrandInfo, setShowBrandInfo] = useState(false);

    // update thread id on url change
    useEffect(() => {
        setSelectThreadId(searchParams[`threadId`]);
    }, [searchParams[`threadId`]]);

    useEffect(() => {
        noScroll(DeclinePopup);
    }, [DeclinePopup]);

    return (
        <CustomAppWrapper>
            <AppWrapper className="custom-wrapper">
                {showBrandInfo ? (
                    <MessengerBrandInfoPage
                        setShowBrandInfo={setShowBrandInfo}
                        selectedThread={selectedThreadDetails}
                    />
                ) : (
                    <>
                        <HeaderSection height={62}>
                            {localStorage.getItem('token') ? (
                                <img
                                    src={PreviewBlockIcon}
                                    alt="preview-icon"
                                    className="icon"
                                    onClick={() => {
                                        history.push('/messenger');
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            {threadMessagesInfo?.loading ? (
                                <ChatWindowHeaderSkeleton />
                            ) : (
                                <>
                                    <BrandDetails>
                                        <img
                                            src={
                                                selectedThreadDetails?.user
                                                    ?.profile_image_data
                                                    ?.data_url ||
                                                getDefaultQLogo()
                                            }
                                            className="user-img"
                                            alt="User_img"
                                            onClick={() =>
                                                setShowBrandInfo(true)
                                            }
                                        />
                                        {selectedThreadDetails &&
                                        Object.keys(selectedThreadDetails)
                                            .length ? (
                                            <div
                                                className="user-contents"
                                                onClick={() =>
                                                    setShowBrandInfo(true)
                                                }
                                            >
                                                {selectedThreadDetails?.user
                                                    ?.name !== undefined
                                                    ? selectedThreadDetails
                                                          ?.user?.name
                                                    : ''}
                                                <p>
                                                    {selectedThreadDetails?.user
                                                        ?.designation !== null
                                                        ? `${selectedThreadDetails?.user?.designation},`
                                                        : ''}
                                                    {` `}
                                                    {
                                                        selectedThreadDetails
                                                            ?.organization?.name
                                                    }
                                                </p>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </BrandDetails>
                                    {selectedThreadDetails &&
                                    Object.keys(selectedThreadDetails)
                                        .length ? (
                                        <div className="profile-info">
                                            {/* <img
                                                    src={PhoneCallIcon}
                                                    alt="Phone-icon"
                                                /> */}

                                            <img
                                                src={InfoIcon}
                                                alt="status-icon"
                                                onClick={() =>
                                                    setShowBrandInfo(true)
                                                }
                                                width="24px"
                                                height="24px"
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </>
                            )}
                        </HeaderSection>

                        <MessengerChatSection>
                            {threadMessagesInfo?.loading ? (
                                <Spinner />
                            ) : threadMessagesInfo?.messages &&
                              threadMessagesInfo?.messages.length ? (
                                handleFilterByCreatedDate(
                                    threadMessagesInfo?.messages,
                                ).map((messageGroup, messageGroupIndex) => {
                                    const messageGroupKeys =
                                        Object.keys(messageGroup)[0];
                                    return (
                                        <>
                                            <span
                                                className="message-date-pills"
                                                key={messageGroupIndex}
                                            >
                                                <p>
                                                    {renderMsgTime(
                                                        messageGroupKeys,
                                                        true,
                                                    )}
                                                </p>
                                            </span>
                                            {messageGroup[messageGroupKeys]
                                                .length
                                                ? messageGroup[
                                                      messageGroupKeys
                                                  ].map(
                                                      (
                                                          message,
                                                          messageIndex,
                                                      ) => {
                                                          return (
                                                              <>
                                                                  {getMessageTemplate(
                                                                      message,
                                                                      getTemplateName(
                                                                          message,
                                                                      ),
                                                                      threadMessagesInfo?.messages,
                                                                      renderDeliverablesList,
                                                                  )}
                                                              </>
                                                          );
                                                      },
                                                  )
                                                : ''}
                                        </>
                                    );
                                })
                            ) : (
                                ''
                            )}
                        </MessengerChatSection>

                        {DeclinePopup && (
                            <AppDeclinePopUp
                                onClose={() => setDeclinePopup(false)}
                            />
                        )}
                    </>
                )}
            </AppWrapper>
        </CustomAppWrapper>
    );
});
